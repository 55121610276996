import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: { input: any; output: any; }
};

export type Advisory = {
  __typename?: 'Advisory';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  externalId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  package: Scalars['String']['output'];
  parentPackage?: Maybe<Scalars['String']['output']>;
  patchedVersion?: Maybe<Scalars['String']['output']>;
  raw: Scalars['String']['output'];
  scan?: Maybe<Scan>;
  scanId: Scalars['ID']['output'];
  severity?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  version: Scalars['String']['output'];
};

export type AdvisoryFilterArgs = {
  createdAt?: InputMaybe<DateFieldComparison>;
  deletedAt?: InputMaybe<DateFieldComparison>;
  externalId?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<StringFieldComparison>;
  package?: InputMaybe<StringFieldComparison>;
  parentPackage?: InputMaybe<StringFieldComparison>;
  patchedVersion?: InputMaybe<StringFieldComparison>;
  raw?: InputMaybe<StringFieldComparison>;
  scan?: InputMaybe<ScanFilterArgs>;
  scanId?: InputMaybe<StringFieldComparison>;
  severity?: InputMaybe<StringFieldComparison>;
  title?: InputMaybe<StringFieldComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  version?: InputMaybe<StringFieldComparison>;
};

export type AdvisorySortArgs = {
  direction: SortDirection;
  field: AdvisorySortFields;
};

export enum AdvisorySortFields {
  CreatedAt = 'createdAt',
  DeletedAt = 'deletedAt',
  ExternalId = 'externalId',
  Id = 'id',
  Package = 'package',
  ParentPackage = 'parentPackage',
  PatchedVersion = 'patchedVersion',
  Raw = 'raw',
  Severity = 'severity',
  Title = 'title',
  UpdatedAt = 'updatedAt',
  Version = 'version'
}

export type BooleanFieldComparison = {
  eq?: InputMaybe<Scalars['Boolean']['input']>;
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Branch = {
  __typename?: 'Branch';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  gitRepository?: Maybe<GitRepository>;
  gitRepositoryId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  scans: PaginatedScan;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type BranchFilterArgs = {
  createdAt?: InputMaybe<DateFieldComparison>;
  deletedAt?: InputMaybe<DateFieldComparison>;
  gitRepository?: InputMaybe<GitRepositoryFilterArgs>;
  gitRepositoryId?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<StringFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  scans?: InputMaybe<ScanFilterArgs>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type BranchSortArgs = {
  direction: SortDirection;
  field: BranchSortFields;
};

export enum BranchSortFields {
  CreatedAt = 'createdAt',
  DeletedAt = 'deletedAt',
  Id = 'id',
  Name = 'name',
  UpdatedAt = 'updatedAt'
}

export type Content = {
  __typename?: 'Content';
  contentFills: PaginatedContentFill;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type ContentFill = {
  __typename?: 'ContentFill';
  content: Content;
  contentId: Scalars['ID']['output'];
  fill: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  language: Scalars['String']['output'];
};

export type ContentFillFilterArgs = {
  content?: InputMaybe<ContentFilterArgs>;
  contentId?: InputMaybe<StringFieldComparison>;
  fill?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<StringFieldComparison>;
  language?: InputMaybe<StringFieldComparison>;
};

export type ContentFillSortArgs = {
  direction: SortDirection;
  field: ContentFillSortFields;
};

export enum ContentFillSortFields {
  Fill = 'fill',
  Id = 'id',
  Language = 'language'
}

export type ContentFilterArgs = {
  contentFills?: InputMaybe<ContentFillFilterArgs>;
  id?: InputMaybe<StringFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
};

export type ContentSortArgs = {
  direction: SortDirection;
  field: ContentSortFields;
};

export enum ContentSortFields {
  Id = 'id',
  Name = 'name'
}

export type DateFieldComparison = {
  between?: InputMaybe<DateFieldComparisonBetween>;
  eq?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  neq?: InputMaybe<Scalars['DateTime']['input']>;
  notBetween?: InputMaybe<DateFieldComparisonBetween>;
  notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type DateFieldComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

export type ErrorLog = {
  __typename?: 'ErrorLog';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  domain: Scalars['String']['output'];
  error: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  stack?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ErrorLogFilterArgs = {
  createdAt?: InputMaybe<DateFieldComparison>;
  deletedAt?: InputMaybe<DateFieldComparison>;
  domain?: InputMaybe<StringFieldComparison>;
  error?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<StringFieldComparison>;
  stack?: InputMaybe<StringFieldComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type ErrorLogSortArgs = {
  direction: SortDirection;
  field: ErrorLogSortFields;
};

export enum ErrorLogSortFields {
  CreatedAt = 'createdAt',
  DeletedAt = 'deletedAt',
  Domain = 'domain',
  Error = 'error',
  Id = 'id',
  Stack = 'stack',
  UpdatedAt = 'updatedAt'
}

export type EventLog = {
  __typename?: 'EventLog';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  log: Scalars['String']['output'];
  type: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type EventLogFilterArgs = {
  createdAt?: InputMaybe<DateFieldComparison>;
  deletedAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<StringFieldComparison>;
  log?: InputMaybe<StringFieldComparison>;
  type?: InputMaybe<StringFieldComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type EventLogSortArgs = {
  direction: SortDirection;
  field: EventLogSortFields;
};

export enum EventLogSortFields {
  CreatedAt = 'createdAt',
  DeletedAt = 'deletedAt',
  Id = 'id',
  Log = 'log',
  Type = 'type',
  UpdatedAt = 'updatedAt'
}

export type GitRepository = {
  __typename?: 'GitRepository';
  branches: PaginatedBranch;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  notificationSettings: PaginatedNotificationSetting;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type GitRepositoryFilterArgs = {
  branches?: InputMaybe<BranchFilterArgs>;
  createdAt?: InputMaybe<DateFieldComparison>;
  deletedAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<StringFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  notificationSettings?: InputMaybe<NotificationSettingFilterArgs>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type GitRepositorySortArgs = {
  direction: SortDirection;
  field: GitRepositorySortFields;
};

export enum GitRepositorySortFields {
  CreatedAt = 'createdAt',
  DeletedAt = 'deletedAt',
  Id = 'id',
  Name = 'name',
  UpdatedAt = 'updatedAt'
}

export type Job = {
  __typename?: 'Job';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  log?: Maybe<Scalars['String']['output']>;
  queue: Scalars['String']['output'];
  status: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type JobFilterArgs = {
  createdAt?: InputMaybe<DateFieldComparison>;
  deletedAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<StringFieldComparison>;
  log?: InputMaybe<StringFieldComparison>;
  queue?: InputMaybe<StringFieldComparison>;
  status?: InputMaybe<StringFieldComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type JobSortArgs = {
  direction: SortDirection;
  field: JobSortFields;
};

export enum JobSortFields {
  CreatedAt = 'createdAt',
  DeletedAt = 'deletedAt',
  Id = 'id',
  Log = 'log',
  Queue = 'queue',
  Status = 'status',
  UpdatedAt = 'updatedAt'
}

export type MailLog = {
  __typename?: 'MailLog';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  emailTemplate?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  message?: Maybe<Scalars['String']['output']>;
  reason?: Maybe<Scalars['String']['output']>;
  sentBy: Scalars['String']['output'];
  sentTo: Scalars['String']['output'];
  status?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type MailLogFilterArgs = {
  createdAt?: InputMaybe<DateFieldComparison>;
  deletedAt?: InputMaybe<DateFieldComparison>;
  emailTemplate?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<StringFieldComparison>;
  message?: InputMaybe<StringFieldComparison>;
  reason?: InputMaybe<StringFieldComparison>;
  sentBy?: InputMaybe<StringFieldComparison>;
  sentTo?: InputMaybe<StringFieldComparison>;
  status?: InputMaybe<StringFieldComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type MailLogSortArgs = {
  direction: SortDirection;
  field: MailLogSortFields;
};

export enum MailLogSortFields {
  CreatedAt = 'createdAt',
  DeletedAt = 'deletedAt',
  EmailTemplate = 'emailTemplate',
  Id = 'id',
  Message = 'message',
  Reason = 'reason',
  SentBy = 'sentBy',
  SentTo = 'sentTo',
  Status = 'status',
  UpdatedAt = 'updatedAt'
}

export type MailTemplate = {
  __typename?: 'MailTemplate';
  active: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  mailTemplateFills: PaginatedMailTemplateFill;
};

export type MailTemplateFill = {
  __typename?: 'MailTemplateFill';
  disclaimer?: Maybe<Scalars['String']['output']>;
  heading?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  language: Scalars['String']['output'];
  mailTemplate: MailTemplate;
  mailTemplateId: Scalars['ID']['output'];
  plainText: Scalars['String']['output'];
  preHeader?: Maybe<Scalars['String']['output']>;
  subject: Scalars['String']['output'];
  text: Scalars['String']['output'];
  title?: Maybe<Scalars['String']['output']>;
  urlText?: Maybe<Scalars['String']['output']>;
};

export type MailTemplateFillFilterArgs = {
  disclaimer?: InputMaybe<StringFieldComparison>;
  heading?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<StringFieldComparison>;
  language?: InputMaybe<StringFieldComparison>;
  mailTemplate?: InputMaybe<MailTemplateFilterArgs>;
  mailTemplateId?: InputMaybe<StringFieldComparison>;
  plainText?: InputMaybe<StringFieldComparison>;
  preHeader?: InputMaybe<StringFieldComparison>;
  subject?: InputMaybe<StringFieldComparison>;
  text?: InputMaybe<StringFieldComparison>;
  title?: InputMaybe<StringFieldComparison>;
  urlText?: InputMaybe<StringFieldComparison>;
};

export type MailTemplateFillSortArgs = {
  direction: SortDirection;
  field: MailTemplateFillSortFields;
};

export enum MailTemplateFillSortFields {
  Disclaimer = 'disclaimer',
  Heading = 'heading',
  Id = 'id',
  Language = 'language',
  PlainText = 'plainText',
  PreHeader = 'preHeader',
  Subject = 'subject',
  Text = 'text',
  Title = 'title',
  UrlText = 'urlText'
}

export type MailTemplateFilterArgs = {
  active?: InputMaybe<BooleanFieldComparison>;
  id?: InputMaybe<StringFieldComparison>;
  mailTemplateFills?: InputMaybe<MailTemplateFillFilterArgs>;
};

export type MailTemplateSortArgs = {
  direction: SortDirection;
  field: MailTemplateSortFields;
};

export enum MailTemplateSortFields {
  Active = 'active',
  Id = 'id'
}

export type Mutation = {
  __typename?: 'Mutation';
  createAdvisory: Advisory;
  createBranch: Branch;
  createContent: Content;
  createContentFill: ContentFill;
  createContentWithFill: Scalars['Boolean']['output'];
  createErrorLog: ErrorLog;
  createEventLog: EventLog;
  createGitRepository: GitRepository;
  createJob: Job;
  createMailLog: MailLog;
  createMailTemplate: MailTemplate;
  createMailTemplateFill: MailTemplateFill;
  createNotificationSetting: NotificationSetting;
  createPermission: Permission;
  createResetPassword: ResetPassword;
  createRole: Role;
  createScan: Scan;
  createScanFile: ScanFile;
  createTenant: Tenant;
  createUser: User;
  createUserTenantRole: UserTenantRole;
  deleteAdvisory: Advisory;
  deleteBranch: Branch;
  deleteContent: Content;
  deleteContentFill: ContentFill;
  deleteErrorLog: ErrorLog;
  deleteEventLog: EventLog;
  deleteGitRepository: GitRepository;
  deleteJob: Job;
  deleteMailLog: MailLog;
  deleteMailTemplate: MailTemplate;
  deleteMailTemplateFill: MailTemplateFill;
  deleteNotificationSetting: NotificationSetting;
  deletePermission: Permission;
  deleteResetPassword: ResetPassword;
  deleteRole: Role;
  deleteScan: Scan;
  deleteScanFile: ScanFile;
  deleteTenant: Tenant;
  deleteUser: User;
  deleteUserNotificationSetting: Scalars['Boolean']['output'];
  deleteUserTenantRole: UserTenantRole;
  restoreAdvisory: Advisory;
  restoreBranch: Branch;
  restoreErrorLog: ErrorLog;
  restoreEventLog: EventLog;
  restoreGitRepository: GitRepository;
  restoreJob: Job;
  restoreMailLog: MailLog;
  restoreNotificationSetting: NotificationSetting;
  restoreResetPassword: ResetPassword;
  restoreScan: Scan;
  restoreScanFile: ScanFile;
  restoreUser: User;
  updateAdvisory: Advisory;
  updateBranch: Branch;
  updateContent: Content;
  updateContentFill: ContentFill;
  updateContentWithFill: Scalars['Boolean']['output'];
  updateErrorLog: ErrorLog;
  updateEventLog: EventLog;
  updateGitRepository: GitRepository;
  updateJob: Job;
  updateMailLog: MailLog;
  updateMailTemplate: MailTemplate;
  updateMailTemplateFill: MailTemplateFill;
  updateNotificationSetting: NotificationSetting;
  updatePermission: Permission;
  updateResetPassword: ResetPassword;
  updateRole: Role;
  updateScan: Scan;
  updateScanFile: ScanFile;
  updateTenant: Tenant;
  updateUser: User;
  updateUserTenantRole: UserTenantRole;
};


export type MutationCreateAdvisoryArgs = {
  externalId: Scalars['String']['input'];
  package: Scalars['String']['input'];
  parentPackage?: InputMaybe<Scalars['String']['input']>;
  patchedVersion?: InputMaybe<Scalars['String']['input']>;
  raw: Scalars['String']['input'];
  scanId: Scalars['ID']['input'];
  severity?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
  version: Scalars['String']['input'];
};


export type MutationCreateBranchArgs = {
  gitRepositoryId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  scanIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};


export type MutationCreateContentArgs = {
  contentFillIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  name: Scalars['String']['input'];
};


export type MutationCreateContentFillArgs = {
  contentId: Scalars['ID']['input'];
  fill: Scalars['String']['input'];
  language: Scalars['String']['input'];
};


export type MutationCreateContentWithFillArgs = {
  fill: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  language: Scalars['String']['input'];
  name: Scalars['String']['input'];
};


export type MutationCreateErrorLogArgs = {
  domain: Scalars['String']['input'];
  error: Scalars['String']['input'];
  stack?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreateEventLogArgs = {
  log: Scalars['String']['input'];
  type: Scalars['String']['input'];
};


export type MutationCreateGitRepositoryArgs = {
  branchIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  name: Scalars['String']['input'];
  notificationSettingIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};


export type MutationCreateJobArgs = {
  log?: InputMaybe<Scalars['String']['input']>;
  queue: Scalars['String']['input'];
  status: Scalars['String']['input'];
};


export type MutationCreateMailLogArgs = {
  emailTemplate?: InputMaybe<Scalars['String']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  reason?: InputMaybe<Scalars['String']['input']>;
  sentBy: Scalars['String']['input'];
  sentTo: Scalars['String']['input'];
  status?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreateMailTemplateArgs = {
  active: Scalars['Boolean']['input'];
  mailTemplateFillIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};


export type MutationCreateMailTemplateFillArgs = {
  disclaimer?: InputMaybe<Scalars['String']['input']>;
  heading?: InputMaybe<Scalars['String']['input']>;
  language: Scalars['String']['input'];
  mailTemplateId: Scalars['ID']['input'];
  plainText: Scalars['String']['input'];
  preHeader?: InputMaybe<Scalars['String']['input']>;
  subject: Scalars['String']['input'];
  text: Scalars['String']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
  urlText?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreateNotificationSettingArgs = {
  gitRepositoryId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type MutationCreatePermissionArgs = {
  description: Scalars['String']['input'];
  name: Scalars['String']['input'];
  roleIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};


export type MutationCreateResetPasswordArgs = {
  email: Scalars['String']['input'];
  token: Scalars['String']['input'];
  type: Scalars['String']['input'];
};


export type MutationCreateRoleArgs = {
  description: Scalars['String']['input'];
  displayName: Scalars['String']['input'];
  permissionIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  userTenantRoleIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};


export type MutationCreateScanArgs = {
  advisoryIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  branchId: Scalars['ID']['input'];
  directory: Scalars['String']['input'];
  scanFileIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  type: Scalars['String']['input'];
};


export type MutationCreateScanFileArgs = {
  contents: Scalars['String']['input'];
  name: Scalars['String']['input'];
  scanId: Scalars['ID']['input'];
};


export type MutationCreateTenantArgs = {
  name: Scalars['String']['input'];
  timeZone: Scalars['String']['input'];
  userTenantRoleIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};


export type MutationCreateUserArgs = {
  active: Scalars['Boolean']['input'];
  avatar?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  invite?: InputMaybe<Scalars['Boolean']['input']>;
  locale: Scalars['String']['input'];
  name: Scalars['String']['input'];
  notificationSettingIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  password?: InputMaybe<Scalars['String']['input']>;
  roleId?: InputMaybe<Scalars['String']['input']>;
  tenantId?: InputMaybe<Scalars['String']['input']>;
  termsAndConditions?: InputMaybe<Scalars['Boolean']['input']>;
  timeZone: Scalars['String']['input'];
  userTenantRoleIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};


export type MutationCreateUserTenantRoleArgs = {
  roleId: Scalars['ID']['input'];
  tenantId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type MutationDeleteAdvisoryArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteBranchArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteContentArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteContentFillArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteErrorLogArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteEventLogArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteGitRepositoryArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteJobArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteMailLogArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteMailTemplateArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteMailTemplateFillArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteNotificationSettingArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeletePermissionArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteResetPasswordArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteRoleArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteScanArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteScanFileArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteTenantArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteUserArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteUserNotificationSettingArgs = {
  gitRepositoryId: Scalars['ID']['input'];
};


export type MutationDeleteUserTenantRoleArgs = {
  id: Scalars['ID']['input'];
};


export type MutationRestoreAdvisoryArgs = {
  id: Scalars['ID']['input'];
};


export type MutationRestoreBranchArgs = {
  id: Scalars['ID']['input'];
};


export type MutationRestoreErrorLogArgs = {
  id: Scalars['ID']['input'];
};


export type MutationRestoreEventLogArgs = {
  id: Scalars['ID']['input'];
};


export type MutationRestoreGitRepositoryArgs = {
  id: Scalars['ID']['input'];
};


export type MutationRestoreJobArgs = {
  id: Scalars['ID']['input'];
};


export type MutationRestoreMailLogArgs = {
  id: Scalars['ID']['input'];
};


export type MutationRestoreNotificationSettingArgs = {
  id: Scalars['ID']['input'];
};


export type MutationRestoreResetPasswordArgs = {
  id: Scalars['ID']['input'];
};


export type MutationRestoreScanArgs = {
  id: Scalars['ID']['input'];
};


export type MutationRestoreScanFileArgs = {
  id: Scalars['ID']['input'];
};


export type MutationRestoreUserArgs = {
  id: Scalars['ID']['input'];
};


export type MutationUpdateAdvisoryArgs = {
  externalId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  package?: InputMaybe<Scalars['String']['input']>;
  parentPackage?: InputMaybe<Scalars['String']['input']>;
  patchedVersion?: InputMaybe<Scalars['String']['input']>;
  raw?: InputMaybe<Scalars['String']['input']>;
  scanId?: InputMaybe<Scalars['ID']['input']>;
  severity?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  version?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateBranchArgs = {
  gitRepositoryId?: InputMaybe<Scalars['ID']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  scanIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};


export type MutationUpdateContentArgs = {
  contentFillIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateContentFillArgs = {
  contentId?: InputMaybe<Scalars['ID']['input']>;
  fill?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  language?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateContentWithFillArgs = {
  fill: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  language: Scalars['String']['input'];
  name: Scalars['String']['input'];
};


export type MutationUpdateErrorLogArgs = {
  domain?: InputMaybe<Scalars['String']['input']>;
  error?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  stack?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateEventLogArgs = {
  id: Scalars['ID']['input'];
  log?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateGitRepositoryArgs = {
  branchIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  notificationSettingIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};


export type MutationUpdateJobArgs = {
  id: Scalars['ID']['input'];
  log?: InputMaybe<Scalars['String']['input']>;
  queue?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateMailLogArgs = {
  emailTemplate?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  message?: InputMaybe<Scalars['String']['input']>;
  reason?: InputMaybe<Scalars['String']['input']>;
  sentBy?: InputMaybe<Scalars['String']['input']>;
  sentTo?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateMailTemplateArgs = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  mailTemplateFillIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};


export type MutationUpdateMailTemplateFillArgs = {
  disclaimer?: InputMaybe<Scalars['String']['input']>;
  heading?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  language?: InputMaybe<Scalars['String']['input']>;
  mailTemplateId?: InputMaybe<Scalars['ID']['input']>;
  plainText?: InputMaybe<Scalars['String']['input']>;
  preHeader?: InputMaybe<Scalars['String']['input']>;
  subject?: InputMaybe<Scalars['String']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  urlText?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateNotificationSettingArgs = {
  gitRepositoryId?: InputMaybe<Scalars['ID']['input']>;
  id: Scalars['ID']['input'];
  userId?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationUpdatePermissionArgs = {
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  roleIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};


export type MutationUpdateResetPasswordArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  token?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateRoleArgs = {
  description?: InputMaybe<Scalars['String']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  permissionIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  userTenantRoleIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};


export type MutationUpdateScanArgs = {
  advisoryIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  branchId?: InputMaybe<Scalars['ID']['input']>;
  directory?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  scanFileIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  type?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateScanFileArgs = {
  contents?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  scanId?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationUpdateTenantArgs = {
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  timeZone?: InputMaybe<Scalars['String']['input']>;
  userTenantRoleIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};


export type MutationUpdateUserArgs = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  avatar?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  notificationSettingIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  password?: InputMaybe<Scalars['String']['input']>;
  termsAndConditions?: InputMaybe<Scalars['Boolean']['input']>;
  timeZone?: InputMaybe<Scalars['String']['input']>;
  userTenantRoleIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};


export type MutationUpdateUserTenantRoleArgs = {
  id: Scalars['ID']['input'];
  roleId?: InputMaybe<Scalars['ID']['input']>;
  tenantId?: InputMaybe<Scalars['ID']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type NotificationSetting = {
  __typename?: 'NotificationSetting';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  gitRepository?: Maybe<GitRepository>;
  gitRepositoryId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  user?: Maybe<User>;
  userId: Scalars['ID']['output'];
};

export type NotificationSettingFilterArgs = {
  createdAt?: InputMaybe<DateFieldComparison>;
  deletedAt?: InputMaybe<DateFieldComparison>;
  gitRepository?: InputMaybe<GitRepositoryFilterArgs>;
  gitRepositoryId?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<StringFieldComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  user?: InputMaybe<UserFilterArgs>;
  userId?: InputMaybe<StringFieldComparison>;
};

export type NotificationSettingSortArgs = {
  direction: SortDirection;
  field: NotificationSettingSortFields;
};

export enum NotificationSettingSortFields {
  CreatedAt = 'createdAt',
  DeletedAt = 'deletedAt',
  Id = 'id',
  UpdatedAt = 'updatedAt'
}

export type PageArgs = {
  page: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
};

export type PaginatedAdvisory = {
  __typename?: 'PaginatedAdvisory';
  items?: Maybe<Array<Advisory>>;
  totalCount: Scalars['Int']['output'];
};

export type PaginatedBranch = {
  __typename?: 'PaginatedBranch';
  items?: Maybe<Array<Branch>>;
  totalCount: Scalars['Int']['output'];
};

export type PaginatedContent = {
  __typename?: 'PaginatedContent';
  items?: Maybe<Array<Content>>;
  totalCount: Scalars['Int']['output'];
};

export type PaginatedContentFill = {
  __typename?: 'PaginatedContentFill';
  items?: Maybe<Array<ContentFill>>;
  totalCount: Scalars['Int']['output'];
};

export type PaginatedErrorLog = {
  __typename?: 'PaginatedErrorLog';
  items?: Maybe<Array<ErrorLog>>;
  totalCount: Scalars['Int']['output'];
};

export type PaginatedEventLog = {
  __typename?: 'PaginatedEventLog';
  items?: Maybe<Array<EventLog>>;
  totalCount: Scalars['Int']['output'];
};

export type PaginatedGitRepository = {
  __typename?: 'PaginatedGitRepository';
  items?: Maybe<Array<GitRepository>>;
  totalCount: Scalars['Int']['output'];
};

export type PaginatedJob = {
  __typename?: 'PaginatedJob';
  items?: Maybe<Array<Job>>;
  totalCount: Scalars['Int']['output'];
};

export type PaginatedMailLog = {
  __typename?: 'PaginatedMailLog';
  items?: Maybe<Array<MailLog>>;
  totalCount: Scalars['Int']['output'];
};

export type PaginatedMailTemplate = {
  __typename?: 'PaginatedMailTemplate';
  items?: Maybe<Array<MailTemplate>>;
  totalCount: Scalars['Int']['output'];
};

export type PaginatedMailTemplateFill = {
  __typename?: 'PaginatedMailTemplateFill';
  items?: Maybe<Array<MailTemplateFill>>;
  totalCount: Scalars['Int']['output'];
};

export type PaginatedNotificationSetting = {
  __typename?: 'PaginatedNotificationSetting';
  items?: Maybe<Array<NotificationSetting>>;
  totalCount: Scalars['Int']['output'];
};

export type PaginatedPermission = {
  __typename?: 'PaginatedPermission';
  items?: Maybe<Array<Permission>>;
  totalCount: Scalars['Int']['output'];
};

export type PaginatedResetPassword = {
  __typename?: 'PaginatedResetPassword';
  items?: Maybe<Array<ResetPassword>>;
  totalCount: Scalars['Int']['output'];
};

export type PaginatedRole = {
  __typename?: 'PaginatedRole';
  items?: Maybe<Array<Role>>;
  totalCount: Scalars['Int']['output'];
};

export type PaginatedScan = {
  __typename?: 'PaginatedScan';
  items?: Maybe<Array<Scan>>;
  totalCount: Scalars['Int']['output'];
};

export type PaginatedScanFile = {
  __typename?: 'PaginatedScanFile';
  items?: Maybe<Array<ScanFile>>;
  totalCount: Scalars['Int']['output'];
};

export type PaginatedTenant = {
  __typename?: 'PaginatedTenant';
  items?: Maybe<Array<Tenant>>;
  totalCount: Scalars['Int']['output'];
};

export type PaginatedUser = {
  __typename?: 'PaginatedUser';
  items?: Maybe<Array<User>>;
  totalCount: Scalars['Int']['output'];
};

export type PaginatedUserTenantRole = {
  __typename?: 'PaginatedUserTenantRole';
  items?: Maybe<Array<UserTenantRole>>;
  totalCount: Scalars['Int']['output'];
};

export type Permission = {
  __typename?: 'Permission';
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  roles: PaginatedRole;
};

export type PermissionFilterArgs = {
  description?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<StringFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  roles?: InputMaybe<RoleFilterArgs>;
};

export type PermissionSortArgs = {
  direction: SortDirection;
  field: PermissionSortFields;
};

export enum PermissionSortFields {
  Description = 'description',
  Id = 'id',
  Name = 'name'
}

export type Query = {
  __typename?: 'Query';
  advisories: PaginatedAdvisory;
  advisory?: Maybe<Advisory>;
  branch?: Maybe<Branch>;
  branches: PaginatedBranch;
  content?: Maybe<Content>;
  contentFill?: Maybe<ContentFill>;
  contentFills: PaginatedContentFill;
  contents: PaginatedContent;
  errorLog?: Maybe<ErrorLog>;
  errorLogs: PaginatedErrorLog;
  eventLog?: Maybe<EventLog>;
  eventLogs: PaginatedEventLog;
  gitRepositories: PaginatedGitRepository;
  gitRepository?: Maybe<GitRepository>;
  job?: Maybe<Job>;
  jobs: PaginatedJob;
  mailLog?: Maybe<MailLog>;
  mailLogs: PaginatedMailLog;
  mailTemplate?: Maybe<MailTemplate>;
  mailTemplateFill?: Maybe<MailTemplateFill>;
  mailTemplateFills: PaginatedMailTemplateFill;
  mailTemplates: PaginatedMailTemplate;
  notificationSetting?: Maybe<NotificationSetting>;
  notificationSettings: PaginatedNotificationSetting;
  permission?: Maybe<Permission>;
  permissions: PaginatedPermission;
  resetPassword?: Maybe<ResetPassword>;
  resetPasswords: PaginatedResetPassword;
  role?: Maybe<Role>;
  roles: PaginatedRole;
  scan?: Maybe<Scan>;
  scanFile?: Maybe<ScanFile>;
  scanFiles: PaginatedScanFile;
  scans: PaginatedScan;
  tenant?: Maybe<Tenant>;
  tenants: PaginatedTenant;
  user?: Maybe<User>;
  userTenantRole?: Maybe<UserTenantRole>;
  userTenantRoles: PaginatedUserTenantRole;
  users: PaginatedUser;
};


export type QueryAdvisoriesArgs = {
  filter?: InputMaybe<AdvisoryFilterArgs>;
  paging?: InputMaybe<PageArgs>;
  sorting?: InputMaybe<Array<AdvisorySortArgs>>;
};


export type QueryAdvisoryArgs = {
  id: Scalars['ID']['input'];
};


export type QueryBranchArgs = {
  id: Scalars['ID']['input'];
};


export type QueryBranchesArgs = {
  filter?: InputMaybe<BranchFilterArgs>;
  paging?: InputMaybe<PageArgs>;
  sorting?: InputMaybe<Array<BranchSortArgs>>;
};


export type QueryContentArgs = {
  id: Scalars['ID']['input'];
};


export type QueryContentFillArgs = {
  id: Scalars['ID']['input'];
};


export type QueryContentFillsArgs = {
  filter?: InputMaybe<ContentFillFilterArgs>;
  paging?: InputMaybe<PageArgs>;
  sorting?: InputMaybe<Array<ContentFillSortArgs>>;
};


export type QueryContentsArgs = {
  filter?: InputMaybe<ContentFilterArgs>;
  paging?: InputMaybe<PageArgs>;
  sorting?: InputMaybe<Array<ContentSortArgs>>;
};


export type QueryErrorLogArgs = {
  id: Scalars['ID']['input'];
};


export type QueryErrorLogsArgs = {
  filter?: InputMaybe<ErrorLogFilterArgs>;
  paging?: InputMaybe<PageArgs>;
  sorting?: InputMaybe<Array<ErrorLogSortArgs>>;
};


export type QueryEventLogArgs = {
  id: Scalars['ID']['input'];
};


export type QueryEventLogsArgs = {
  filter?: InputMaybe<EventLogFilterArgs>;
  paging?: InputMaybe<PageArgs>;
  sorting?: InputMaybe<Array<EventLogSortArgs>>;
};


export type QueryGitRepositoriesArgs = {
  filter?: InputMaybe<GitRepositoryFilterArgs>;
  paging?: InputMaybe<PageArgs>;
  sorting?: InputMaybe<Array<GitRepositorySortArgs>>;
};


export type QueryGitRepositoryArgs = {
  id: Scalars['ID']['input'];
};


export type QueryJobArgs = {
  id: Scalars['ID']['input'];
};


export type QueryJobsArgs = {
  filter?: InputMaybe<JobFilterArgs>;
  paging?: InputMaybe<PageArgs>;
  sorting?: InputMaybe<Array<JobSortArgs>>;
};


export type QueryMailLogArgs = {
  id: Scalars['ID']['input'];
};


export type QueryMailLogsArgs = {
  filter?: InputMaybe<MailLogFilterArgs>;
  paging?: InputMaybe<PageArgs>;
  sorting?: InputMaybe<Array<MailLogSortArgs>>;
};


export type QueryMailTemplateArgs = {
  id: Scalars['ID']['input'];
};


export type QueryMailTemplateFillArgs = {
  id: Scalars['ID']['input'];
};


export type QueryMailTemplateFillsArgs = {
  filter?: InputMaybe<MailTemplateFillFilterArgs>;
  paging?: InputMaybe<PageArgs>;
  sorting?: InputMaybe<Array<MailTemplateFillSortArgs>>;
};


export type QueryMailTemplatesArgs = {
  filter?: InputMaybe<MailTemplateFilterArgs>;
  paging?: InputMaybe<PageArgs>;
  sorting?: InputMaybe<Array<MailTemplateSortArgs>>;
};


export type QueryNotificationSettingArgs = {
  id: Scalars['ID']['input'];
};


export type QueryNotificationSettingsArgs = {
  filter?: InputMaybe<NotificationSettingFilterArgs>;
  paging?: InputMaybe<PageArgs>;
  sorting?: InputMaybe<Array<NotificationSettingSortArgs>>;
};


export type QueryPermissionArgs = {
  id: Scalars['ID']['input'];
};


export type QueryPermissionsArgs = {
  filter?: InputMaybe<PermissionFilterArgs>;
  paging?: InputMaybe<PageArgs>;
  sorting?: InputMaybe<Array<PermissionSortArgs>>;
};


export type QueryResetPasswordArgs = {
  id: Scalars['ID']['input'];
};


export type QueryResetPasswordsArgs = {
  filter?: InputMaybe<ResetPasswordFilterArgs>;
  paging?: InputMaybe<PageArgs>;
  sorting?: InputMaybe<Array<ResetPasswordSortArgs>>;
};


export type QueryRoleArgs = {
  id: Scalars['ID']['input'];
};


export type QueryRolesArgs = {
  filter?: InputMaybe<RoleFilterArgs>;
  paging?: InputMaybe<PageArgs>;
  sorting?: InputMaybe<Array<RoleSortArgs>>;
};


export type QueryScanArgs = {
  id: Scalars['ID']['input'];
};


export type QueryScanFileArgs = {
  id: Scalars['ID']['input'];
};


export type QueryScanFilesArgs = {
  filter?: InputMaybe<ScanFileFilterArgs>;
  paging?: InputMaybe<PageArgs>;
  sorting?: InputMaybe<Array<ScanFileSortArgs>>;
};


export type QueryScansArgs = {
  filter?: InputMaybe<ScanFilterArgs>;
  paging?: InputMaybe<PageArgs>;
  sorting?: InputMaybe<Array<ScanSortArgs>>;
};


export type QueryTenantArgs = {
  id: Scalars['ID']['input'];
};


export type QueryTenantsArgs = {
  filter?: InputMaybe<TenantFilterArgs>;
  paging?: InputMaybe<PageArgs>;
  sorting?: InputMaybe<Array<TenantSortArgs>>;
};


export type QueryUserArgs = {
  id: Scalars['ID']['input'];
};


export type QueryUserTenantRoleArgs = {
  id: Scalars['ID']['input'];
};


export type QueryUserTenantRolesArgs = {
  filter?: InputMaybe<UserTenantRoleFilterArgs>;
  paging?: InputMaybe<PageArgs>;
  sorting?: InputMaybe<Array<UserTenantRoleSortArgs>>;
};


export type QueryUsersArgs = {
  filter?: InputMaybe<UserFilterArgs>;
  paging?: InputMaybe<PageArgs>;
  sorting?: InputMaybe<Array<UserSortArgs>>;
};

export type ResetPassword = {
  __typename?: 'ResetPassword';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  token: Scalars['String']['output'];
  type: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ResetPasswordFilterArgs = {
  createdAt?: InputMaybe<DateFieldComparison>;
  deletedAt?: InputMaybe<DateFieldComparison>;
  email?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<StringFieldComparison>;
  token?: InputMaybe<StringFieldComparison>;
  type?: InputMaybe<StringFieldComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type ResetPasswordSortArgs = {
  direction: SortDirection;
  field: ResetPasswordSortFields;
};

export enum ResetPasswordSortFields {
  CreatedAt = 'createdAt',
  DeletedAt = 'deletedAt',
  Email = 'email',
  Id = 'id',
  Token = 'token',
  Type = 'type',
  UpdatedAt = 'updatedAt'
}

export type Role = {
  __typename?: 'Role';
  description: Scalars['String']['output'];
  displayName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  machineName: Scalars['String']['output'];
  permissions: PaginatedPermission;
  userTenantRoles: PaginatedUserTenantRole;
};

export type RoleFilterArgs = {
  description?: InputMaybe<StringFieldComparison>;
  displayName?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<StringFieldComparison>;
  machineName?: InputMaybe<StringFieldComparison>;
  permissions?: InputMaybe<PermissionFilterArgs>;
  userTenantRoles?: InputMaybe<UserTenantRoleFilterArgs>;
};

export type RoleSortArgs = {
  direction: SortDirection;
  field: RoleSortFields;
};

export enum RoleSortFields {
  Description = 'description',
  DisplayName = 'displayName',
  Id = 'id',
  MachineName = 'machineName'
}

export type Scan = {
  __typename?: 'Scan';
  advisories: PaginatedAdvisory;
  branch?: Maybe<Branch>;
  branchId: Scalars['ID']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  directory: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  scanFiles: PaginatedScanFile;
  type: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ScanFile = {
  __typename?: 'ScanFile';
  contents: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  scan?: Maybe<Scan>;
  scanId: Scalars['ID']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ScanFileFilterArgs = {
  contents?: InputMaybe<StringFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  deletedAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<StringFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  scan?: InputMaybe<ScanFilterArgs>;
  scanId?: InputMaybe<StringFieldComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type ScanFileSortArgs = {
  direction: SortDirection;
  field: ScanFileSortFields;
};

export enum ScanFileSortFields {
  Contents = 'contents',
  CreatedAt = 'createdAt',
  DeletedAt = 'deletedAt',
  Id = 'id',
  Name = 'name',
  UpdatedAt = 'updatedAt'
}

export type ScanFilterArgs = {
  advisories?: InputMaybe<AdvisoryFilterArgs>;
  branch?: InputMaybe<BranchFilterArgs>;
  branchId?: InputMaybe<StringFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  deletedAt?: InputMaybe<DateFieldComparison>;
  directory?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<StringFieldComparison>;
  scanFiles?: InputMaybe<ScanFileFilterArgs>;
  type?: InputMaybe<StringFieldComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type ScanSortArgs = {
  direction: SortDirection;
  field: ScanSortFields;
};

export enum ScanSortFields {
  CreatedAt = 'createdAt',
  DeletedAt = 'deletedAt',
  Directory = 'directory',
  Id = 'id',
  Type = 'type',
  UpdatedAt = 'updatedAt'
}

export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type StringFieldComparison = {
  eq?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<Scalars['String']['input']>;
  neq?: InputMaybe<Scalars['String']['input']>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  notLike?: InputMaybe<Scalars['String']['input']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  createdAdvisory: Advisory;
  createdBranch: Branch;
  createdContent: Content;
  createdContentFill: ContentFill;
  createdErrorLog: ErrorLog;
  createdEventLog: EventLog;
  createdGitRepository: GitRepository;
  createdJob: Job;
  createdMailLog: MailLog;
  createdMailTemplate: MailTemplate;
  createdMailTemplateFill: MailTemplateFill;
  createdNotificationSetting: NotificationSetting;
  createdPermission: Permission;
  createdResetPassword: ResetPassword;
  createdRole: Role;
  createdScan: Scan;
  createdScanFile: ScanFile;
  createdTenant: Tenant;
  createdUser: User;
  createdUserTenantRole: UserTenantRole;
  deletedAdvisory: Advisory;
  deletedBranch: Branch;
  deletedContent: Content;
  deletedContentFill: ContentFill;
  deletedErrorLog: ErrorLog;
  deletedEventLog: EventLog;
  deletedGitRepository: GitRepository;
  deletedJob: Job;
  deletedMailLog: MailLog;
  deletedMailTemplate: MailTemplate;
  deletedMailTemplateFill: MailTemplateFill;
  deletedNotificationSetting: NotificationSetting;
  deletedPermission: Permission;
  deletedResetPassword: ResetPassword;
  deletedRole: Role;
  deletedScan: Scan;
  deletedScanFile: ScanFile;
  deletedTenant: Tenant;
  deletedUser: User;
  deletedUserTenantRole: UserTenantRole;
  restoredAdvisory: Advisory;
  restoredBranch: Branch;
  restoredErrorLog: ErrorLog;
  restoredEventLog: EventLog;
  restoredGitRepository: GitRepository;
  restoredJob: Job;
  restoredMailLog: MailLog;
  restoredNotificationSetting: NotificationSetting;
  restoredResetPassword: ResetPassword;
  restoredScan: Scan;
  restoredScanFile: ScanFile;
  restoredUser: User;
  updatedAdvisory: Advisory;
  updatedBranch: Branch;
  updatedContent: Content;
  updatedContentFill: ContentFill;
  updatedErrorLog: ErrorLog;
  updatedEventLog: EventLog;
  updatedGitRepository: GitRepository;
  updatedJob: Job;
  updatedMailLog: MailLog;
  updatedMailTemplate: MailTemplate;
  updatedMailTemplateFill: MailTemplateFill;
  updatedNotificationSetting: NotificationSetting;
  updatedPermission: Permission;
  updatedResetPassword: ResetPassword;
  updatedRole: Role;
  updatedScan: Scan;
  updatedScanFile: ScanFile;
  updatedTenant: Tenant;
  updatedUser: User;
  updatedUserTenantRole: UserTenantRole;
};


export type SubscriptionCreatedAdvisoryArgs = {
  filter?: InputMaybe<AdvisoryFilterArgs>;
  paging?: InputMaybe<PageArgs>;
  sorting?: InputMaybe<Array<AdvisorySortArgs>>;
};


export type SubscriptionCreatedBranchArgs = {
  filter?: InputMaybe<BranchFilterArgs>;
  paging?: InputMaybe<PageArgs>;
  sorting?: InputMaybe<Array<BranchSortArgs>>;
};


export type SubscriptionCreatedContentArgs = {
  filter?: InputMaybe<ContentFilterArgs>;
  paging?: InputMaybe<PageArgs>;
  sorting?: InputMaybe<Array<ContentSortArgs>>;
};


export type SubscriptionCreatedContentFillArgs = {
  filter?: InputMaybe<ContentFillFilterArgs>;
  paging?: InputMaybe<PageArgs>;
  sorting?: InputMaybe<Array<ContentFillSortArgs>>;
};


export type SubscriptionCreatedErrorLogArgs = {
  filter?: InputMaybe<ErrorLogFilterArgs>;
  paging?: InputMaybe<PageArgs>;
  sorting?: InputMaybe<Array<ErrorLogSortArgs>>;
};


export type SubscriptionCreatedEventLogArgs = {
  filter?: InputMaybe<EventLogFilterArgs>;
  paging?: InputMaybe<PageArgs>;
  sorting?: InputMaybe<Array<EventLogSortArgs>>;
};


export type SubscriptionCreatedGitRepositoryArgs = {
  filter?: InputMaybe<GitRepositoryFilterArgs>;
  paging?: InputMaybe<PageArgs>;
  sorting?: InputMaybe<Array<GitRepositorySortArgs>>;
};


export type SubscriptionCreatedJobArgs = {
  filter?: InputMaybe<JobFilterArgs>;
  paging?: InputMaybe<PageArgs>;
  sorting?: InputMaybe<Array<JobSortArgs>>;
};


export type SubscriptionCreatedMailLogArgs = {
  filter?: InputMaybe<MailLogFilterArgs>;
  paging?: InputMaybe<PageArgs>;
  sorting?: InputMaybe<Array<MailLogSortArgs>>;
};


export type SubscriptionCreatedMailTemplateArgs = {
  filter?: InputMaybe<MailTemplateFilterArgs>;
  paging?: InputMaybe<PageArgs>;
  sorting?: InputMaybe<Array<MailTemplateSortArgs>>;
};


export type SubscriptionCreatedMailTemplateFillArgs = {
  filter?: InputMaybe<MailTemplateFillFilterArgs>;
  paging?: InputMaybe<PageArgs>;
  sorting?: InputMaybe<Array<MailTemplateFillSortArgs>>;
};


export type SubscriptionCreatedNotificationSettingArgs = {
  filter?: InputMaybe<NotificationSettingFilterArgs>;
  paging?: InputMaybe<PageArgs>;
  sorting?: InputMaybe<Array<NotificationSettingSortArgs>>;
};


export type SubscriptionCreatedPermissionArgs = {
  filter?: InputMaybe<PermissionFilterArgs>;
  paging?: InputMaybe<PageArgs>;
  sorting?: InputMaybe<Array<PermissionSortArgs>>;
};


export type SubscriptionCreatedResetPasswordArgs = {
  filter?: InputMaybe<ResetPasswordFilterArgs>;
  paging?: InputMaybe<PageArgs>;
  sorting?: InputMaybe<Array<ResetPasswordSortArgs>>;
};


export type SubscriptionCreatedRoleArgs = {
  filter?: InputMaybe<RoleFilterArgs>;
  paging?: InputMaybe<PageArgs>;
  sorting?: InputMaybe<Array<RoleSortArgs>>;
};


export type SubscriptionCreatedScanArgs = {
  filter?: InputMaybe<ScanFilterArgs>;
  paging?: InputMaybe<PageArgs>;
  sorting?: InputMaybe<Array<ScanSortArgs>>;
};


export type SubscriptionCreatedScanFileArgs = {
  filter?: InputMaybe<ScanFileFilterArgs>;
  paging?: InputMaybe<PageArgs>;
  sorting?: InputMaybe<Array<ScanFileSortArgs>>;
};


export type SubscriptionCreatedTenantArgs = {
  filter?: InputMaybe<TenantFilterArgs>;
  paging?: InputMaybe<PageArgs>;
  sorting?: InputMaybe<Array<TenantSortArgs>>;
};


export type SubscriptionCreatedUserArgs = {
  filter?: InputMaybe<UserFilterArgs>;
  paging?: InputMaybe<PageArgs>;
  sorting?: InputMaybe<Array<UserSortArgs>>;
};


export type SubscriptionCreatedUserTenantRoleArgs = {
  filter?: InputMaybe<UserTenantRoleFilterArgs>;
  paging?: InputMaybe<PageArgs>;
  sorting?: InputMaybe<Array<UserTenantRoleSortArgs>>;
};


export type SubscriptionDeletedAdvisoryArgs = {
  filter?: InputMaybe<AdvisoryFilterArgs>;
  paging?: InputMaybe<PageArgs>;
  sorting?: InputMaybe<Array<AdvisorySortArgs>>;
};


export type SubscriptionDeletedBranchArgs = {
  filter?: InputMaybe<BranchFilterArgs>;
  paging?: InputMaybe<PageArgs>;
  sorting?: InputMaybe<Array<BranchSortArgs>>;
};


export type SubscriptionDeletedContentArgs = {
  filter?: InputMaybe<ContentFilterArgs>;
  paging?: InputMaybe<PageArgs>;
  sorting?: InputMaybe<Array<ContentSortArgs>>;
};


export type SubscriptionDeletedContentFillArgs = {
  filter?: InputMaybe<ContentFillFilterArgs>;
  paging?: InputMaybe<PageArgs>;
  sorting?: InputMaybe<Array<ContentFillSortArgs>>;
};


export type SubscriptionDeletedErrorLogArgs = {
  filter?: InputMaybe<ErrorLogFilterArgs>;
  paging?: InputMaybe<PageArgs>;
  sorting?: InputMaybe<Array<ErrorLogSortArgs>>;
};


export type SubscriptionDeletedEventLogArgs = {
  filter?: InputMaybe<EventLogFilterArgs>;
  paging?: InputMaybe<PageArgs>;
  sorting?: InputMaybe<Array<EventLogSortArgs>>;
};


export type SubscriptionDeletedGitRepositoryArgs = {
  filter?: InputMaybe<GitRepositoryFilterArgs>;
  paging?: InputMaybe<PageArgs>;
  sorting?: InputMaybe<Array<GitRepositorySortArgs>>;
};


export type SubscriptionDeletedJobArgs = {
  filter?: InputMaybe<JobFilterArgs>;
  paging?: InputMaybe<PageArgs>;
  sorting?: InputMaybe<Array<JobSortArgs>>;
};


export type SubscriptionDeletedMailLogArgs = {
  filter?: InputMaybe<MailLogFilterArgs>;
  paging?: InputMaybe<PageArgs>;
  sorting?: InputMaybe<Array<MailLogSortArgs>>;
};


export type SubscriptionDeletedMailTemplateArgs = {
  filter?: InputMaybe<MailTemplateFilterArgs>;
  paging?: InputMaybe<PageArgs>;
  sorting?: InputMaybe<Array<MailTemplateSortArgs>>;
};


export type SubscriptionDeletedMailTemplateFillArgs = {
  filter?: InputMaybe<MailTemplateFillFilterArgs>;
  paging?: InputMaybe<PageArgs>;
  sorting?: InputMaybe<Array<MailTemplateFillSortArgs>>;
};


export type SubscriptionDeletedNotificationSettingArgs = {
  filter?: InputMaybe<NotificationSettingFilterArgs>;
  paging?: InputMaybe<PageArgs>;
  sorting?: InputMaybe<Array<NotificationSettingSortArgs>>;
};


export type SubscriptionDeletedPermissionArgs = {
  filter?: InputMaybe<PermissionFilterArgs>;
  paging?: InputMaybe<PageArgs>;
  sorting?: InputMaybe<Array<PermissionSortArgs>>;
};


export type SubscriptionDeletedResetPasswordArgs = {
  filter?: InputMaybe<ResetPasswordFilterArgs>;
  paging?: InputMaybe<PageArgs>;
  sorting?: InputMaybe<Array<ResetPasswordSortArgs>>;
};


export type SubscriptionDeletedRoleArgs = {
  filter?: InputMaybe<RoleFilterArgs>;
  paging?: InputMaybe<PageArgs>;
  sorting?: InputMaybe<Array<RoleSortArgs>>;
};


export type SubscriptionDeletedScanArgs = {
  filter?: InputMaybe<ScanFilterArgs>;
  paging?: InputMaybe<PageArgs>;
  sorting?: InputMaybe<Array<ScanSortArgs>>;
};


export type SubscriptionDeletedScanFileArgs = {
  filter?: InputMaybe<ScanFileFilterArgs>;
  paging?: InputMaybe<PageArgs>;
  sorting?: InputMaybe<Array<ScanFileSortArgs>>;
};


export type SubscriptionDeletedTenantArgs = {
  filter?: InputMaybe<TenantFilterArgs>;
  paging?: InputMaybe<PageArgs>;
  sorting?: InputMaybe<Array<TenantSortArgs>>;
};


export type SubscriptionDeletedUserArgs = {
  filter?: InputMaybe<UserFilterArgs>;
  paging?: InputMaybe<PageArgs>;
  sorting?: InputMaybe<Array<UserSortArgs>>;
};


export type SubscriptionDeletedUserTenantRoleArgs = {
  filter?: InputMaybe<UserTenantRoleFilterArgs>;
  paging?: InputMaybe<PageArgs>;
  sorting?: InputMaybe<Array<UserTenantRoleSortArgs>>;
};


export type SubscriptionRestoredAdvisoryArgs = {
  filter?: InputMaybe<AdvisoryFilterArgs>;
  paging?: InputMaybe<PageArgs>;
  sorting?: InputMaybe<Array<AdvisorySortArgs>>;
};


export type SubscriptionRestoredBranchArgs = {
  filter?: InputMaybe<BranchFilterArgs>;
  paging?: InputMaybe<PageArgs>;
  sorting?: InputMaybe<Array<BranchSortArgs>>;
};


export type SubscriptionRestoredErrorLogArgs = {
  filter?: InputMaybe<ErrorLogFilterArgs>;
  paging?: InputMaybe<PageArgs>;
  sorting?: InputMaybe<Array<ErrorLogSortArgs>>;
};


export type SubscriptionRestoredEventLogArgs = {
  filter?: InputMaybe<EventLogFilterArgs>;
  paging?: InputMaybe<PageArgs>;
  sorting?: InputMaybe<Array<EventLogSortArgs>>;
};


export type SubscriptionRestoredGitRepositoryArgs = {
  filter?: InputMaybe<GitRepositoryFilterArgs>;
  paging?: InputMaybe<PageArgs>;
  sorting?: InputMaybe<Array<GitRepositorySortArgs>>;
};


export type SubscriptionRestoredJobArgs = {
  filter?: InputMaybe<JobFilterArgs>;
  paging?: InputMaybe<PageArgs>;
  sorting?: InputMaybe<Array<JobSortArgs>>;
};


export type SubscriptionRestoredMailLogArgs = {
  filter?: InputMaybe<MailLogFilterArgs>;
  paging?: InputMaybe<PageArgs>;
  sorting?: InputMaybe<Array<MailLogSortArgs>>;
};


export type SubscriptionRestoredNotificationSettingArgs = {
  filter?: InputMaybe<NotificationSettingFilterArgs>;
  paging?: InputMaybe<PageArgs>;
  sorting?: InputMaybe<Array<NotificationSettingSortArgs>>;
};


export type SubscriptionRestoredResetPasswordArgs = {
  filter?: InputMaybe<ResetPasswordFilterArgs>;
  paging?: InputMaybe<PageArgs>;
  sorting?: InputMaybe<Array<ResetPasswordSortArgs>>;
};


export type SubscriptionRestoredScanArgs = {
  filter?: InputMaybe<ScanFilterArgs>;
  paging?: InputMaybe<PageArgs>;
  sorting?: InputMaybe<Array<ScanSortArgs>>;
};


export type SubscriptionRestoredScanFileArgs = {
  filter?: InputMaybe<ScanFileFilterArgs>;
  paging?: InputMaybe<PageArgs>;
  sorting?: InputMaybe<Array<ScanFileSortArgs>>;
};


export type SubscriptionRestoredUserArgs = {
  filter?: InputMaybe<UserFilterArgs>;
  paging?: InputMaybe<PageArgs>;
  sorting?: InputMaybe<Array<UserSortArgs>>;
};


export type SubscriptionUpdatedAdvisoryArgs = {
  filter?: InputMaybe<AdvisoryFilterArgs>;
  paging?: InputMaybe<PageArgs>;
  sorting?: InputMaybe<Array<AdvisorySortArgs>>;
};


export type SubscriptionUpdatedBranchArgs = {
  filter?: InputMaybe<BranchFilterArgs>;
  paging?: InputMaybe<PageArgs>;
  sorting?: InputMaybe<Array<BranchSortArgs>>;
};


export type SubscriptionUpdatedContentArgs = {
  filter?: InputMaybe<ContentFilterArgs>;
  paging?: InputMaybe<PageArgs>;
  sorting?: InputMaybe<Array<ContentSortArgs>>;
};


export type SubscriptionUpdatedContentFillArgs = {
  filter?: InputMaybe<ContentFillFilterArgs>;
  paging?: InputMaybe<PageArgs>;
  sorting?: InputMaybe<Array<ContentFillSortArgs>>;
};


export type SubscriptionUpdatedErrorLogArgs = {
  filter?: InputMaybe<ErrorLogFilterArgs>;
  paging?: InputMaybe<PageArgs>;
  sorting?: InputMaybe<Array<ErrorLogSortArgs>>;
};


export type SubscriptionUpdatedEventLogArgs = {
  filter?: InputMaybe<EventLogFilterArgs>;
  paging?: InputMaybe<PageArgs>;
  sorting?: InputMaybe<Array<EventLogSortArgs>>;
};


export type SubscriptionUpdatedGitRepositoryArgs = {
  filter?: InputMaybe<GitRepositoryFilterArgs>;
  paging?: InputMaybe<PageArgs>;
  sorting?: InputMaybe<Array<GitRepositorySortArgs>>;
};


export type SubscriptionUpdatedJobArgs = {
  filter?: InputMaybe<JobFilterArgs>;
  paging?: InputMaybe<PageArgs>;
  sorting?: InputMaybe<Array<JobSortArgs>>;
};


export type SubscriptionUpdatedMailLogArgs = {
  filter?: InputMaybe<MailLogFilterArgs>;
  paging?: InputMaybe<PageArgs>;
  sorting?: InputMaybe<Array<MailLogSortArgs>>;
};


export type SubscriptionUpdatedMailTemplateArgs = {
  filter?: InputMaybe<MailTemplateFilterArgs>;
  paging?: InputMaybe<PageArgs>;
  sorting?: InputMaybe<Array<MailTemplateSortArgs>>;
};


export type SubscriptionUpdatedMailTemplateFillArgs = {
  filter?: InputMaybe<MailTemplateFillFilterArgs>;
  paging?: InputMaybe<PageArgs>;
  sorting?: InputMaybe<Array<MailTemplateFillSortArgs>>;
};


export type SubscriptionUpdatedNotificationSettingArgs = {
  filter?: InputMaybe<NotificationSettingFilterArgs>;
  paging?: InputMaybe<PageArgs>;
  sorting?: InputMaybe<Array<NotificationSettingSortArgs>>;
};


export type SubscriptionUpdatedPermissionArgs = {
  filter?: InputMaybe<PermissionFilterArgs>;
  paging?: InputMaybe<PageArgs>;
  sorting?: InputMaybe<Array<PermissionSortArgs>>;
};


export type SubscriptionUpdatedResetPasswordArgs = {
  filter?: InputMaybe<ResetPasswordFilterArgs>;
  paging?: InputMaybe<PageArgs>;
  sorting?: InputMaybe<Array<ResetPasswordSortArgs>>;
};


export type SubscriptionUpdatedRoleArgs = {
  filter?: InputMaybe<RoleFilterArgs>;
  paging?: InputMaybe<PageArgs>;
  sorting?: InputMaybe<Array<RoleSortArgs>>;
};


export type SubscriptionUpdatedScanArgs = {
  filter?: InputMaybe<ScanFilterArgs>;
  paging?: InputMaybe<PageArgs>;
  sorting?: InputMaybe<Array<ScanSortArgs>>;
};


export type SubscriptionUpdatedScanFileArgs = {
  filter?: InputMaybe<ScanFileFilterArgs>;
  paging?: InputMaybe<PageArgs>;
  sorting?: InputMaybe<Array<ScanFileSortArgs>>;
};


export type SubscriptionUpdatedTenantArgs = {
  filter?: InputMaybe<TenantFilterArgs>;
  paging?: InputMaybe<PageArgs>;
  sorting?: InputMaybe<Array<TenantSortArgs>>;
};


export type SubscriptionUpdatedUserArgs = {
  filter?: InputMaybe<UserFilterArgs>;
  paging?: InputMaybe<PageArgs>;
  sorting?: InputMaybe<Array<UserSortArgs>>;
};


export type SubscriptionUpdatedUserTenantRoleArgs = {
  filter?: InputMaybe<UserTenantRoleFilterArgs>;
  paging?: InputMaybe<PageArgs>;
  sorting?: InputMaybe<Array<UserTenantRoleSortArgs>>;
};

export type Tenant = {
  __typename?: 'Tenant';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  timeZone: Scalars['String']['output'];
  userTenantRoles: PaginatedUserTenantRole;
};

export type TenantFilterArgs = {
  id?: InputMaybe<StringFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  timeZone?: InputMaybe<StringFieldComparison>;
  userTenantRoles?: InputMaybe<UserTenantRoleFilterArgs>;
};

export type TenantSortArgs = {
  direction: SortDirection;
  field: TenantSortFields;
};

export enum TenantSortFields {
  Id = 'id',
  Name = 'name',
  TimeZone = 'timeZone'
}

export type User = {
  __typename?: 'User';
  active: Scalars['Boolean']['output'];
  avatar?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  email: Scalars['String']['output'];
  emailVerifiedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  locale: Scalars['String']['output'];
  name: Scalars['String']['output'];
  notificationSettings: PaginatedNotificationSetting;
  termsAndConditions?: Maybe<Scalars['Boolean']['output']>;
  timeZone: Scalars['String']['output'];
  totpVerified?: Maybe<Scalars['Boolean']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  userTenantRoles: PaginatedUserTenantRole;
};

export type UserFilterArgs = {
  active?: InputMaybe<BooleanFieldComparison>;
  avatar?: InputMaybe<StringFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  deletedAt?: InputMaybe<DateFieldComparison>;
  email?: InputMaybe<StringFieldComparison>;
  emailVerifiedAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<StringFieldComparison>;
  locale?: InputMaybe<StringFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  notificationSettings?: InputMaybe<NotificationSettingFilterArgs>;
  termsAndConditions?: InputMaybe<BooleanFieldComparison>;
  timeZone?: InputMaybe<StringFieldComparison>;
  totpVerified?: InputMaybe<BooleanFieldComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  userTenantRoles?: InputMaybe<UserTenantRoleFilterArgs>;
};

export type UserSortArgs = {
  direction: SortDirection;
  field: UserSortFields;
};

export enum UserSortFields {
  Active = 'active',
  Avatar = 'avatar',
  CreatedAt = 'createdAt',
  DeletedAt = 'deletedAt',
  Email = 'email',
  EmailVerifiedAt = 'emailVerifiedAt',
  Id = 'id',
  Locale = 'locale',
  Name = 'name',
  TermsAndConditions = 'termsAndConditions',
  TimeZone = 'timeZone',
  TotpVerified = 'totpVerified',
  UpdatedAt = 'updatedAt'
}

export type UserTenantRole = {
  __typename?: 'UserTenantRole';
  id: Scalars['ID']['output'];
  role: Role;
  roleId: Scalars['ID']['output'];
  tenant: Tenant;
  tenantId: Scalars['ID']['output'];
  user?: Maybe<User>;
  userId: Scalars['ID']['output'];
};

export type UserTenantRoleFilterArgs = {
  id?: InputMaybe<StringFieldComparison>;
  role?: InputMaybe<RoleFilterArgs>;
  roleId?: InputMaybe<StringFieldComparison>;
  tenant?: InputMaybe<TenantFilterArgs>;
  tenantId?: InputMaybe<StringFieldComparison>;
  user?: InputMaybe<UserFilterArgs>;
  userId?: InputMaybe<StringFieldComparison>;
};

export type UserTenantRoleSortArgs = {
  direction: SortDirection;
  field: UserTenantRoleSortFields;
};

export enum UserTenantRoleSortFields {
  Id = 'id'
}

export type AdvisoryFieldsFragment = { __typename?: 'Advisory', id: string, externalId: string, title: string, package: string, parentPackage?: string | null, version: string, patchedVersion?: string | null, severity?: string | null, raw: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, scan?: { __typename?: 'Scan', id: string, directory: string, type: string, branchId: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, displayField: string } | null };

export type GetAdvisoriesQueryVariables = Exact<{
  filter?: InputMaybe<AdvisoryFilterArgs>;
  paging?: InputMaybe<PageArgs>;
  sorting?: InputMaybe<Array<AdvisorySortArgs> | AdvisorySortArgs>;
  withScan?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type GetAdvisoriesQuery = { __typename?: 'Query', advisories: { __typename?: 'PaginatedAdvisory', totalCount: number, items?: Array<{ __typename?: 'Advisory', id: string, externalId: string, title: string, package: string, parentPackage?: string | null, version: string, patchedVersion?: string | null, severity?: string | null, raw: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, scan?: { __typename?: 'Scan', id: string, directory: string, type: string, branchId: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, displayField: string } | null }> | null } };

export type GetAdvisoryQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  withScan?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type GetAdvisoryQuery = { __typename?: 'Query', advisory?: { __typename?: 'Advisory', id: string, externalId: string, title: string, package: string, parentPackage?: string | null, version: string, patchedVersion?: string | null, severity?: string | null, raw: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, scan?: { __typename?: 'Scan', id: string, directory: string, type: string, branchId: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, displayField: string } | null } | null };

export type GetAllRelatedDataForAdvisoryQueryVariables = Exact<{
  withScans?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type GetAllRelatedDataForAdvisoryQuery = { __typename?: 'Query', scans?: { __typename?: 'PaginatedScan', items?: Array<{ __typename?: 'Scan', id: string, displayField: string }> | null } };

export type CreateAdvisoryMutationVariables = Exact<{
  externalId: Scalars['String']['input'];
  title: Scalars['String']['input'];
  package: Scalars['String']['input'];
  parentPackage?: InputMaybe<Scalars['String']['input']>;
  version: Scalars['String']['input'];
  patchedVersion?: InputMaybe<Scalars['String']['input']>;
  severity?: InputMaybe<Scalars['String']['input']>;
  raw: Scalars['String']['input'];
  scanId: Scalars['ID']['input'];
  withScan?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type CreateAdvisoryMutation = { __typename?: 'Mutation', createAdvisory: { __typename?: 'Advisory', id: string, externalId: string, title: string, package: string, parentPackage?: string | null, version: string, patchedVersion?: string | null, severity?: string | null, raw: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, scan?: { __typename?: 'Scan', id: string, directory: string, type: string, branchId: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, displayField: string } | null } };

export type UpdateAdvisoryMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  externalId?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  package?: InputMaybe<Scalars['String']['input']>;
  parentPackage?: InputMaybe<Scalars['String']['input']>;
  version?: InputMaybe<Scalars['String']['input']>;
  patchedVersion?: InputMaybe<Scalars['String']['input']>;
  severity?: InputMaybe<Scalars['String']['input']>;
  raw?: InputMaybe<Scalars['String']['input']>;
  scanId?: InputMaybe<Scalars['ID']['input']>;
  withScan?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type UpdateAdvisoryMutation = { __typename?: 'Mutation', updateAdvisory: { __typename?: 'Advisory', id: string, externalId: string, title: string, package: string, parentPackage?: string | null, version: string, patchedVersion?: string | null, severity?: string | null, raw: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, scan?: { __typename?: 'Scan', id: string, directory: string, type: string, branchId: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, displayField: string } | null } };

export type DeleteAdvisoryMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  withScan?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type DeleteAdvisoryMutation = { __typename?: 'Mutation', deleteAdvisory: { __typename?: 'Advisory', id: string, externalId: string, title: string, package: string, parentPackage?: string | null, version: string, patchedVersion?: string | null, severity?: string | null, raw: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, scan?: { __typename?: 'Scan', id: string, directory: string, type: string, branchId: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, displayField: string } | null } };

export type CreatedAdvisorySubscriptionVariables = Exact<{
  filter?: InputMaybe<AdvisoryFilterArgs>;
  withScan?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type CreatedAdvisorySubscription = { __typename?: 'Subscription', createdAdvisory: { __typename?: 'Advisory', id: string, externalId: string, title: string, package: string, parentPackage?: string | null, version: string, patchedVersion?: string | null, severity?: string | null, raw: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, scan?: { __typename?: 'Scan', id: string, directory: string, type: string, branchId: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, displayField: string } | null } };

export type UpdatedAdvisorySubscriptionVariables = Exact<{
  filter?: InputMaybe<AdvisoryFilterArgs>;
  withScan?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type UpdatedAdvisorySubscription = { __typename?: 'Subscription', updatedAdvisory: { __typename?: 'Advisory', id: string, externalId: string, title: string, package: string, parentPackage?: string | null, version: string, patchedVersion?: string | null, severity?: string | null, raw: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, scan?: { __typename?: 'Scan', id: string, directory: string, type: string, branchId: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, displayField: string } | null } };

export type DeletedAdvisorySubscriptionVariables = Exact<{
  filter?: InputMaybe<AdvisoryFilterArgs>;
}>;


export type DeletedAdvisorySubscription = { __typename?: 'Subscription', deletedAdvisory: { __typename?: 'Advisory', id: string } };

export type RestoredAdvisorySubscriptionVariables = Exact<{
  filter?: InputMaybe<AdvisoryFilterArgs>;
  withScan?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type RestoredAdvisorySubscription = { __typename?: 'Subscription', restoredAdvisory: { __typename?: 'Advisory', id: string, externalId: string, title: string, package: string, parentPackage?: string | null, version: string, patchedVersion?: string | null, severity?: string | null, raw: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, scan?: { __typename?: 'Scan', id: string, directory: string, type: string, branchId: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, displayField: string } | null } };

export type BranchFieldsFragment = { __typename?: 'Branch', id: string, name: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, gitRepository?: { __typename?: 'GitRepository', id: string, name: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, displayField: string } | null, scans?: { __typename?: 'PaginatedScan', totalCount: number, items?: Array<{ __typename?: 'Scan', id: string, directory: string, type: string, branchId: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, displayField: string }> | null } };

export type GetBranchesQueryVariables = Exact<{
  filter?: InputMaybe<BranchFilterArgs>;
  paging?: InputMaybe<PageArgs>;
  sorting?: InputMaybe<Array<BranchSortArgs> | BranchSortArgs>;
  withGitRepository?: InputMaybe<Scalars['Boolean']['input']>;
  withScans?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type GetBranchesQuery = { __typename?: 'Query', branches: { __typename?: 'PaginatedBranch', totalCount: number, items?: Array<{ __typename?: 'Branch', id: string, name: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, gitRepository?: { __typename?: 'GitRepository', id: string, name: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, displayField: string } | null, scans?: { __typename?: 'PaginatedScan', totalCount: number, items?: Array<{ __typename?: 'Scan', id: string, directory: string, type: string, branchId: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, displayField: string }> | null } }> | null } };

export type GetBranchQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  withGitRepository?: InputMaybe<Scalars['Boolean']['input']>;
  withScans?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type GetBranchQuery = { __typename?: 'Query', branch?: { __typename?: 'Branch', id: string, name: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, gitRepository?: { __typename?: 'GitRepository', id: string, name: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, displayField: string } | null, scans?: { __typename?: 'PaginatedScan', totalCount: number, items?: Array<{ __typename?: 'Scan', id: string, directory: string, type: string, branchId: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, displayField: string }> | null } } | null };

export type GetAllRelatedDataForBranchQueryVariables = Exact<{
  withGitRepositories?: InputMaybe<Scalars['Boolean']['input']>;
  withScans?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type GetAllRelatedDataForBranchQuery = { __typename?: 'Query', gitRepositories?: { __typename?: 'PaginatedGitRepository', items?: Array<{ __typename?: 'GitRepository', id: string, displayField: string }> | null }, scans?: { __typename?: 'PaginatedScan', items?: Array<{ __typename?: 'Scan', id: string, displayField: string }> | null } };

export type CreateBranchMutationVariables = Exact<{
  name: Scalars['String']['input'];
  gitRepositoryId: Scalars['ID']['input'];
  scans?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>> | InputMaybe<Scalars['ID']['input']>>;
  withGitRepository?: InputMaybe<Scalars['Boolean']['input']>;
  withScans?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type CreateBranchMutation = { __typename?: 'Mutation', createBranch: { __typename?: 'Branch', id: string, name: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, gitRepository?: { __typename?: 'GitRepository', id: string, name: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, displayField: string } | null, scans?: { __typename?: 'PaginatedScan', totalCount: number, items?: Array<{ __typename?: 'Scan', id: string, directory: string, type: string, branchId: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, displayField: string }> | null } } };

export type UpdateBranchMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  gitRepositoryId?: InputMaybe<Scalars['ID']['input']>;
  scans?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>> | InputMaybe<Scalars['ID']['input']>>;
  withGitRepository?: InputMaybe<Scalars['Boolean']['input']>;
  withScans?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type UpdateBranchMutation = { __typename?: 'Mutation', updateBranch: { __typename?: 'Branch', id: string, name: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, gitRepository?: { __typename?: 'GitRepository', id: string, name: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, displayField: string } | null, scans?: { __typename?: 'PaginatedScan', totalCount: number, items?: Array<{ __typename?: 'Scan', id: string, directory: string, type: string, branchId: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, displayField: string }> | null } } };

export type DeleteBranchMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  withGitRepository?: InputMaybe<Scalars['Boolean']['input']>;
  withScans?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type DeleteBranchMutation = { __typename?: 'Mutation', deleteBranch: { __typename?: 'Branch', id: string, name: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, gitRepository?: { __typename?: 'GitRepository', id: string, name: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, displayField: string } | null, scans?: { __typename?: 'PaginatedScan', totalCount: number, items?: Array<{ __typename?: 'Scan', id: string, directory: string, type: string, branchId: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, displayField: string }> | null } } };

export type CreatedBranchSubscriptionVariables = Exact<{
  filter?: InputMaybe<BranchFilterArgs>;
  withGitRepository?: InputMaybe<Scalars['Boolean']['input']>;
  withScans?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type CreatedBranchSubscription = { __typename?: 'Subscription', createdBranch: { __typename?: 'Branch', id: string, name: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, gitRepository?: { __typename?: 'GitRepository', id: string, name: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, displayField: string } | null, scans?: { __typename?: 'PaginatedScan', totalCount: number, items?: Array<{ __typename?: 'Scan', id: string, directory: string, type: string, branchId: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, displayField: string }> | null } } };

export type UpdatedBranchSubscriptionVariables = Exact<{
  filter?: InputMaybe<BranchFilterArgs>;
  withGitRepository?: InputMaybe<Scalars['Boolean']['input']>;
  withScans?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type UpdatedBranchSubscription = { __typename?: 'Subscription', updatedBranch: { __typename?: 'Branch', id: string, name: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, gitRepository?: { __typename?: 'GitRepository', id: string, name: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, displayField: string } | null, scans?: { __typename?: 'PaginatedScan', totalCount: number, items?: Array<{ __typename?: 'Scan', id: string, directory: string, type: string, branchId: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, displayField: string }> | null } } };

export type DeletedBranchSubscriptionVariables = Exact<{
  filter?: InputMaybe<BranchFilterArgs>;
}>;


export type DeletedBranchSubscription = { __typename?: 'Subscription', deletedBranch: { __typename?: 'Branch', id: string } };

export type RestoredBranchSubscriptionVariables = Exact<{
  filter?: InputMaybe<BranchFilterArgs>;
  withGitRepository?: InputMaybe<Scalars['Boolean']['input']>;
  withScans?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type RestoredBranchSubscription = { __typename?: 'Subscription', restoredBranch: { __typename?: 'Branch', id: string, name: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, gitRepository?: { __typename?: 'GitRepository', id: string, name: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, displayField: string } | null, scans?: { __typename?: 'PaginatedScan', totalCount: number, items?: Array<{ __typename?: 'Scan', id: string, directory: string, type: string, branchId: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, displayField: string }> | null } } };

export type ContentFieldsFragment = { __typename?: 'Content', id: string, name: string, contentFills?: { __typename?: 'PaginatedContentFill', totalCount: number, items?: Array<{ __typename?: 'ContentFill', id: string, fill: string, language: string, contentId: string, displayField: string }> | null } };

export type GetContentsQueryVariables = Exact<{
  filter?: InputMaybe<ContentFilterArgs>;
  paging?: InputMaybe<PageArgs>;
  sorting?: InputMaybe<Array<ContentSortArgs> | ContentSortArgs>;
  withContentFills?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type GetContentsQuery = { __typename?: 'Query', contents: { __typename?: 'PaginatedContent', totalCount: number, items?: Array<{ __typename?: 'Content', id: string, name: string, contentFills?: { __typename?: 'PaginatedContentFill', totalCount: number, items?: Array<{ __typename?: 'ContentFill', id: string, fill: string, language: string, contentId: string, displayField: string }> | null } }> | null } };

export type GetContentQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  withContentFills?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type GetContentQuery = { __typename?: 'Query', content?: { __typename?: 'Content', id: string, name: string, contentFills?: { __typename?: 'PaginatedContentFill', totalCount: number, items?: Array<{ __typename?: 'ContentFill', id: string, fill: string, language: string, contentId: string, displayField: string }> | null } } | null };

export type GetAllRelatedDataForContentQueryVariables = Exact<{
  withContentFills?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type GetAllRelatedDataForContentQuery = { __typename?: 'Query', contentFills?: { __typename?: 'PaginatedContentFill', items?: Array<{ __typename?: 'ContentFill', id: string, displayField: string }> | null } };

export type CreateContentMutationVariables = Exact<{
  name: Scalars['String']['input'];
  contentFills?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>> | InputMaybe<Scalars['ID']['input']>>;
  withContentFills?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type CreateContentMutation = { __typename?: 'Mutation', createContent: { __typename?: 'Content', id: string, name: string, contentFills?: { __typename?: 'PaginatedContentFill', totalCount: number, items?: Array<{ __typename?: 'ContentFill', id: string, fill: string, language: string, contentId: string, displayField: string }> | null } } };

export type UpdateContentMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  contentFills?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>> | InputMaybe<Scalars['ID']['input']>>;
  withContentFills?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type UpdateContentMutation = { __typename?: 'Mutation', updateContent: { __typename?: 'Content', id: string, name: string, contentFills?: { __typename?: 'PaginatedContentFill', totalCount: number, items?: Array<{ __typename?: 'ContentFill', id: string, fill: string, language: string, contentId: string, displayField: string }> | null } } };

export type DeleteContentMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  withContentFills?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type DeleteContentMutation = { __typename?: 'Mutation', deleteContent: { __typename?: 'Content', id: string, name: string, contentFills?: { __typename?: 'PaginatedContentFill', totalCount: number, items?: Array<{ __typename?: 'ContentFill', id: string, fill: string, language: string, contentId: string, displayField: string }> | null } } };

export type CreatedContentSubscriptionVariables = Exact<{
  filter?: InputMaybe<ContentFilterArgs>;
  withContentFills?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type CreatedContentSubscription = { __typename?: 'Subscription', createdContent: { __typename?: 'Content', id: string, name: string, contentFills?: { __typename?: 'PaginatedContentFill', totalCount: number, items?: Array<{ __typename?: 'ContentFill', id: string, fill: string, language: string, contentId: string, displayField: string }> | null } } };

export type UpdatedContentSubscriptionVariables = Exact<{
  filter?: InputMaybe<ContentFilterArgs>;
  withContentFills?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type UpdatedContentSubscription = { __typename?: 'Subscription', updatedContent: { __typename?: 'Content', id: string, name: string, contentFills?: { __typename?: 'PaginatedContentFill', totalCount: number, items?: Array<{ __typename?: 'ContentFill', id: string, fill: string, language: string, contentId: string, displayField: string }> | null } } };

export type DeletedContentSubscriptionVariables = Exact<{
  filter?: InputMaybe<ContentFilterArgs>;
}>;


export type DeletedContentSubscription = { __typename?: 'Subscription', deletedContent: { __typename?: 'Content', id: string } };

export type ContentFillFieldsFragment = { __typename?: 'ContentFill', id: string, fill: string, language: string, content?: { __typename?: 'Content', id: string, name: string, displayField: string } };

export type GetContentFillsQueryVariables = Exact<{
  filter?: InputMaybe<ContentFillFilterArgs>;
  paging?: InputMaybe<PageArgs>;
  sorting?: InputMaybe<Array<ContentFillSortArgs> | ContentFillSortArgs>;
  withContent?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type GetContentFillsQuery = { __typename?: 'Query', contentFills: { __typename?: 'PaginatedContentFill', totalCount: number, items?: Array<{ __typename?: 'ContentFill', id: string, fill: string, language: string, content?: { __typename?: 'Content', id: string, name: string, displayField: string } }> | null } };

export type GetContentFillQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  withContent?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type GetContentFillQuery = { __typename?: 'Query', contentFill?: { __typename?: 'ContentFill', id: string, fill: string, language: string, content?: { __typename?: 'Content', id: string, name: string, displayField: string } } | null };

export type GetAllRelatedDataForContentFillQueryVariables = Exact<{
  withContents?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type GetAllRelatedDataForContentFillQuery = { __typename?: 'Query', contents?: { __typename?: 'PaginatedContent', items?: Array<{ __typename?: 'Content', id: string, displayField: string }> | null } };

export type CreateContentFillMutationVariables = Exact<{
  fill: Scalars['String']['input'];
  language: Scalars['String']['input'];
  contentId: Scalars['ID']['input'];
  withContent?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type CreateContentFillMutation = { __typename?: 'Mutation', createContentFill: { __typename?: 'ContentFill', id: string, fill: string, language: string, content?: { __typename?: 'Content', id: string, name: string, displayField: string } } };

export type UpdateContentFillMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  fill?: InputMaybe<Scalars['String']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  contentId?: InputMaybe<Scalars['ID']['input']>;
  withContent?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type UpdateContentFillMutation = { __typename?: 'Mutation', updateContentFill: { __typename?: 'ContentFill', id: string, fill: string, language: string, content?: { __typename?: 'Content', id: string, name: string, displayField: string } } };

export type DeleteContentFillMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  withContent?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type DeleteContentFillMutation = { __typename?: 'Mutation', deleteContentFill: { __typename?: 'ContentFill', id: string, fill: string, language: string, content?: { __typename?: 'Content', id: string, name: string, displayField: string } } };

export type CreatedContentFillSubscriptionVariables = Exact<{
  filter?: InputMaybe<ContentFillFilterArgs>;
  withContent?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type CreatedContentFillSubscription = { __typename?: 'Subscription', createdContentFill: { __typename?: 'ContentFill', id: string, fill: string, language: string, content?: { __typename?: 'Content', id: string, name: string, displayField: string } } };

export type UpdatedContentFillSubscriptionVariables = Exact<{
  filter?: InputMaybe<ContentFillFilterArgs>;
  withContent?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type UpdatedContentFillSubscription = { __typename?: 'Subscription', updatedContentFill: { __typename?: 'ContentFill', id: string, fill: string, language: string, content?: { __typename?: 'Content', id: string, name: string, displayField: string } } };

export type DeletedContentFillSubscriptionVariables = Exact<{
  filter?: InputMaybe<ContentFillFilterArgs>;
}>;


export type DeletedContentFillSubscription = { __typename?: 'Subscription', deletedContentFill: { __typename?: 'ContentFill', id: string } };

export type ErrorLogFieldsFragment = { __typename?: 'ErrorLog', id: string, error: string, stack?: string | null, domain: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null };

export type GetErrorLogsQueryVariables = Exact<{
  filter?: InputMaybe<ErrorLogFilterArgs>;
  paging?: InputMaybe<PageArgs>;
  sorting?: InputMaybe<Array<ErrorLogSortArgs> | ErrorLogSortArgs>;
}>;


export type GetErrorLogsQuery = { __typename?: 'Query', errorLogs: { __typename?: 'PaginatedErrorLog', totalCount: number, items?: Array<{ __typename?: 'ErrorLog', id: string, error: string, stack?: string | null, domain: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null }> | null } };

export type GetErrorLogQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetErrorLogQuery = { __typename?: 'Query', errorLog?: { __typename?: 'ErrorLog', id: string, error: string, stack?: string | null, domain: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null } | null };

export type CreateErrorLogMutationVariables = Exact<{
  error: Scalars['String']['input'];
  stack?: InputMaybe<Scalars['String']['input']>;
  domain: Scalars['String']['input'];
}>;


export type CreateErrorLogMutation = { __typename?: 'Mutation', createErrorLog: { __typename?: 'ErrorLog', id: string, error: string, stack?: string | null, domain: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null } };

export type UpdateErrorLogMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  error?: InputMaybe<Scalars['String']['input']>;
  stack?: InputMaybe<Scalars['String']['input']>;
  domain?: InputMaybe<Scalars['String']['input']>;
}>;


export type UpdateErrorLogMutation = { __typename?: 'Mutation', updateErrorLog: { __typename?: 'ErrorLog', id: string, error: string, stack?: string | null, domain: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null } };

export type DeleteErrorLogMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DeleteErrorLogMutation = { __typename?: 'Mutation', deleteErrorLog: { __typename?: 'ErrorLog', id: string, error: string, stack?: string | null, domain: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null } };

export type CreatedErrorLogSubscriptionVariables = Exact<{
  filter?: InputMaybe<ErrorLogFilterArgs>;
}>;


export type CreatedErrorLogSubscription = { __typename?: 'Subscription', createdErrorLog: { __typename?: 'ErrorLog', id: string, error: string, stack?: string | null, domain: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null } };

export type UpdatedErrorLogSubscriptionVariables = Exact<{
  filter?: InputMaybe<ErrorLogFilterArgs>;
}>;


export type UpdatedErrorLogSubscription = { __typename?: 'Subscription', updatedErrorLog: { __typename?: 'ErrorLog', id: string, error: string, stack?: string | null, domain: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null } };

export type DeletedErrorLogSubscriptionVariables = Exact<{
  filter?: InputMaybe<ErrorLogFilterArgs>;
}>;


export type DeletedErrorLogSubscription = { __typename?: 'Subscription', deletedErrorLog: { __typename?: 'ErrorLog', id: string } };

export type RestoredErrorLogSubscriptionVariables = Exact<{
  filter?: InputMaybe<ErrorLogFilterArgs>;
}>;


export type RestoredErrorLogSubscription = { __typename?: 'Subscription', restoredErrorLog: { __typename?: 'ErrorLog', id: string, error: string, stack?: string | null, domain: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null } };

export type EventLogFieldsFragment = { __typename?: 'EventLog', id: string, log: string, type: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null };

export type GetEventLogsQueryVariables = Exact<{
  filter?: InputMaybe<EventLogFilterArgs>;
  paging?: InputMaybe<PageArgs>;
  sorting?: InputMaybe<Array<EventLogSortArgs> | EventLogSortArgs>;
}>;


export type GetEventLogsQuery = { __typename?: 'Query', eventLogs: { __typename?: 'PaginatedEventLog', totalCount: number, items?: Array<{ __typename?: 'EventLog', id: string, log: string, type: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null }> | null } };

export type GetEventLogQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetEventLogQuery = { __typename?: 'Query', eventLog?: { __typename?: 'EventLog', id: string, log: string, type: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null } | null };

export type CreateEventLogMutationVariables = Exact<{
  log: Scalars['String']['input'];
  type: Scalars['String']['input'];
}>;


export type CreateEventLogMutation = { __typename?: 'Mutation', createEventLog: { __typename?: 'EventLog', id: string, log: string, type: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null } };

export type UpdateEventLogMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  log?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
}>;


export type UpdateEventLogMutation = { __typename?: 'Mutation', updateEventLog: { __typename?: 'EventLog', id: string, log: string, type: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null } };

export type DeleteEventLogMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DeleteEventLogMutation = { __typename?: 'Mutation', deleteEventLog: { __typename?: 'EventLog', id: string, log: string, type: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null } };

export type CreatedEventLogSubscriptionVariables = Exact<{
  filter?: InputMaybe<EventLogFilterArgs>;
}>;


export type CreatedEventLogSubscription = { __typename?: 'Subscription', createdEventLog: { __typename?: 'EventLog', id: string, log: string, type: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null } };

export type UpdatedEventLogSubscriptionVariables = Exact<{
  filter?: InputMaybe<EventLogFilterArgs>;
}>;


export type UpdatedEventLogSubscription = { __typename?: 'Subscription', updatedEventLog: { __typename?: 'EventLog', id: string, log: string, type: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null } };

export type DeletedEventLogSubscriptionVariables = Exact<{
  filter?: InputMaybe<EventLogFilterArgs>;
}>;


export type DeletedEventLogSubscription = { __typename?: 'Subscription', deletedEventLog: { __typename?: 'EventLog', id: string } };

export type RestoredEventLogSubscriptionVariables = Exact<{
  filter?: InputMaybe<EventLogFilterArgs>;
}>;


export type RestoredEventLogSubscription = { __typename?: 'Subscription', restoredEventLog: { __typename?: 'EventLog', id: string, log: string, type: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null } };

export type GitRepositoryFieldsFragment = { __typename?: 'GitRepository', id: string, name: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, branches?: { __typename?: 'PaginatedBranch', totalCount: number, items?: Array<{ __typename?: 'Branch', id: string, name: string, gitRepositoryId: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, displayField: string }> | null }, notificationSettings?: { __typename?: 'PaginatedNotificationSetting', totalCount: number, items?: Array<{ __typename?: 'NotificationSetting', id: string, userId: string, gitRepositoryId: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, displayField: string }> | null } };

export type GetGitRepositoriesQueryVariables = Exact<{
  filter?: InputMaybe<GitRepositoryFilterArgs>;
  paging?: InputMaybe<PageArgs>;
  sorting?: InputMaybe<Array<GitRepositorySortArgs> | GitRepositorySortArgs>;
  withBranches?: InputMaybe<Scalars['Boolean']['input']>;
  withNotificationSettings?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type GetGitRepositoriesQuery = { __typename?: 'Query', gitRepositories: { __typename?: 'PaginatedGitRepository', totalCount: number, items?: Array<{ __typename?: 'GitRepository', id: string, name: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, branches?: { __typename?: 'PaginatedBranch', totalCount: number, items?: Array<{ __typename?: 'Branch', id: string, name: string, gitRepositoryId: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, displayField: string }> | null }, notificationSettings?: { __typename?: 'PaginatedNotificationSetting', totalCount: number, items?: Array<{ __typename?: 'NotificationSetting', id: string, userId: string, gitRepositoryId: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, displayField: string }> | null } }> | null } };

export type GetGitRepositoryQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  withBranches?: InputMaybe<Scalars['Boolean']['input']>;
  withNotificationSettings?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type GetGitRepositoryQuery = { __typename?: 'Query', gitRepository?: { __typename?: 'GitRepository', id: string, name: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, branches?: { __typename?: 'PaginatedBranch', totalCount: number, items?: Array<{ __typename?: 'Branch', id: string, name: string, gitRepositoryId: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, displayField: string }> | null }, notificationSettings?: { __typename?: 'PaginatedNotificationSetting', totalCount: number, items?: Array<{ __typename?: 'NotificationSetting', id: string, userId: string, gitRepositoryId: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, displayField: string }> | null } } | null };

export type GetAllRelatedDataForGitRepositoryQueryVariables = Exact<{
  withBranches?: InputMaybe<Scalars['Boolean']['input']>;
  withNotificationSettings?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type GetAllRelatedDataForGitRepositoryQuery = { __typename?: 'Query', branches?: { __typename?: 'PaginatedBranch', items?: Array<{ __typename?: 'Branch', id: string, displayField: string }> | null }, notificationSettings?: { __typename?: 'PaginatedNotificationSetting', items?: Array<{ __typename?: 'NotificationSetting', id: string, displayField: string }> | null } };

export type CreateGitRepositoryMutationVariables = Exact<{
  name: Scalars['String']['input'];
  branches?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>> | InputMaybe<Scalars['ID']['input']>>;
  notificationSettings?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>> | InputMaybe<Scalars['ID']['input']>>;
  withBranches?: InputMaybe<Scalars['Boolean']['input']>;
  withNotificationSettings?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type CreateGitRepositoryMutation = { __typename?: 'Mutation', createGitRepository: { __typename?: 'GitRepository', id: string, name: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, branches?: { __typename?: 'PaginatedBranch', totalCount: number, items?: Array<{ __typename?: 'Branch', id: string, name: string, gitRepositoryId: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, displayField: string }> | null }, notificationSettings?: { __typename?: 'PaginatedNotificationSetting', totalCount: number, items?: Array<{ __typename?: 'NotificationSetting', id: string, userId: string, gitRepositoryId: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, displayField: string }> | null } } };

export type UpdateGitRepositoryMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  branches?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>> | InputMaybe<Scalars['ID']['input']>>;
  notificationSettings?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>> | InputMaybe<Scalars['ID']['input']>>;
  withBranches?: InputMaybe<Scalars['Boolean']['input']>;
  withNotificationSettings?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type UpdateGitRepositoryMutation = { __typename?: 'Mutation', updateGitRepository: { __typename?: 'GitRepository', id: string, name: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, branches?: { __typename?: 'PaginatedBranch', totalCount: number, items?: Array<{ __typename?: 'Branch', id: string, name: string, gitRepositoryId: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, displayField: string }> | null }, notificationSettings?: { __typename?: 'PaginatedNotificationSetting', totalCount: number, items?: Array<{ __typename?: 'NotificationSetting', id: string, userId: string, gitRepositoryId: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, displayField: string }> | null } } };

export type DeleteGitRepositoryMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  withBranches?: InputMaybe<Scalars['Boolean']['input']>;
  withNotificationSettings?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type DeleteGitRepositoryMutation = { __typename?: 'Mutation', deleteGitRepository: { __typename?: 'GitRepository', id: string, name: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, branches?: { __typename?: 'PaginatedBranch', totalCount: number, items?: Array<{ __typename?: 'Branch', id: string, name: string, gitRepositoryId: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, displayField: string }> | null }, notificationSettings?: { __typename?: 'PaginatedNotificationSetting', totalCount: number, items?: Array<{ __typename?: 'NotificationSetting', id: string, userId: string, gitRepositoryId: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, displayField: string }> | null } } };

export type CreatedGitRepositorySubscriptionVariables = Exact<{
  filter?: InputMaybe<GitRepositoryFilterArgs>;
  withBranches?: InputMaybe<Scalars['Boolean']['input']>;
  withNotificationSettings?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type CreatedGitRepositorySubscription = { __typename?: 'Subscription', createdGitRepository: { __typename?: 'GitRepository', id: string, name: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, branches?: { __typename?: 'PaginatedBranch', totalCount: number, items?: Array<{ __typename?: 'Branch', id: string, name: string, gitRepositoryId: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, displayField: string }> | null }, notificationSettings?: { __typename?: 'PaginatedNotificationSetting', totalCount: number, items?: Array<{ __typename?: 'NotificationSetting', id: string, userId: string, gitRepositoryId: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, displayField: string }> | null } } };

export type UpdatedGitRepositorySubscriptionVariables = Exact<{
  filter?: InputMaybe<GitRepositoryFilterArgs>;
  withBranches?: InputMaybe<Scalars['Boolean']['input']>;
  withNotificationSettings?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type UpdatedGitRepositorySubscription = { __typename?: 'Subscription', updatedGitRepository: { __typename?: 'GitRepository', id: string, name: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, branches?: { __typename?: 'PaginatedBranch', totalCount: number, items?: Array<{ __typename?: 'Branch', id: string, name: string, gitRepositoryId: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, displayField: string }> | null }, notificationSettings?: { __typename?: 'PaginatedNotificationSetting', totalCount: number, items?: Array<{ __typename?: 'NotificationSetting', id: string, userId: string, gitRepositoryId: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, displayField: string }> | null } } };

export type DeletedGitRepositorySubscriptionVariables = Exact<{
  filter?: InputMaybe<GitRepositoryFilterArgs>;
}>;


export type DeletedGitRepositorySubscription = { __typename?: 'Subscription', deletedGitRepository: { __typename?: 'GitRepository', id: string } };

export type RestoredGitRepositorySubscriptionVariables = Exact<{
  filter?: InputMaybe<GitRepositoryFilterArgs>;
  withBranches?: InputMaybe<Scalars['Boolean']['input']>;
  withNotificationSettings?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type RestoredGitRepositorySubscription = { __typename?: 'Subscription', restoredGitRepository: { __typename?: 'GitRepository', id: string, name: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, branches?: { __typename?: 'PaginatedBranch', totalCount: number, items?: Array<{ __typename?: 'Branch', id: string, name: string, gitRepositoryId: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, displayField: string }> | null }, notificationSettings?: { __typename?: 'PaginatedNotificationSetting', totalCount: number, items?: Array<{ __typename?: 'NotificationSetting', id: string, userId: string, gitRepositoryId: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, displayField: string }> | null } } };

export type JobFieldsFragment = { __typename?: 'Job', id: string, queue: string, status: string, log?: string | null, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null };

export type GetJobsQueryVariables = Exact<{
  filter?: InputMaybe<JobFilterArgs>;
  paging?: InputMaybe<PageArgs>;
  sorting?: InputMaybe<Array<JobSortArgs> | JobSortArgs>;
}>;


export type GetJobsQuery = { __typename?: 'Query', jobs: { __typename?: 'PaginatedJob', totalCount: number, items?: Array<{ __typename?: 'Job', id: string, queue: string, status: string, log?: string | null, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null }> | null } };

export type GetJobQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetJobQuery = { __typename?: 'Query', job?: { __typename?: 'Job', id: string, queue: string, status: string, log?: string | null, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null } | null };

export type CreateJobMutationVariables = Exact<{
  queue: Scalars['String']['input'];
  status: Scalars['String']['input'];
  log?: InputMaybe<Scalars['String']['input']>;
}>;


export type CreateJobMutation = { __typename?: 'Mutation', createJob: { __typename?: 'Job', id: string, queue: string, status: string, log?: string | null, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null } };

export type UpdateJobMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  queue?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  log?: InputMaybe<Scalars['String']['input']>;
}>;


export type UpdateJobMutation = { __typename?: 'Mutation', updateJob: { __typename?: 'Job', id: string, queue: string, status: string, log?: string | null, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null } };

export type DeleteJobMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DeleteJobMutation = { __typename?: 'Mutation', deleteJob: { __typename?: 'Job', id: string, queue: string, status: string, log?: string | null, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null } };

export type CreatedJobSubscriptionVariables = Exact<{
  filter?: InputMaybe<JobFilterArgs>;
}>;


export type CreatedJobSubscription = { __typename?: 'Subscription', createdJob: { __typename?: 'Job', id: string, queue: string, status: string, log?: string | null, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null } };

export type UpdatedJobSubscriptionVariables = Exact<{
  filter?: InputMaybe<JobFilterArgs>;
}>;


export type UpdatedJobSubscription = { __typename?: 'Subscription', updatedJob: { __typename?: 'Job', id: string, queue: string, status: string, log?: string | null, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null } };

export type DeletedJobSubscriptionVariables = Exact<{
  filter?: InputMaybe<JobFilterArgs>;
}>;


export type DeletedJobSubscription = { __typename?: 'Subscription', deletedJob: { __typename?: 'Job', id: string } };

export type RestoredJobSubscriptionVariables = Exact<{
  filter?: InputMaybe<JobFilterArgs>;
}>;


export type RestoredJobSubscription = { __typename?: 'Subscription', restoredJob: { __typename?: 'Job', id: string, queue: string, status: string, log?: string | null, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null } };

export type MailLogFieldsFragment = { __typename?: 'MailLog', id: string, sentTo: string, sentBy: string, emailTemplate?: string | null, status?: string | null, message?: string | null, reason?: string | null, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null };

export type GetMailLogsQueryVariables = Exact<{
  filter?: InputMaybe<MailLogFilterArgs>;
  paging?: InputMaybe<PageArgs>;
  sorting?: InputMaybe<Array<MailLogSortArgs> | MailLogSortArgs>;
}>;


export type GetMailLogsQuery = { __typename?: 'Query', mailLogs: { __typename?: 'PaginatedMailLog', totalCount: number, items?: Array<{ __typename?: 'MailLog', id: string, sentTo: string, sentBy: string, emailTemplate?: string | null, status?: string | null, message?: string | null, reason?: string | null, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null }> | null } };

export type GetMailLogQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetMailLogQuery = { __typename?: 'Query', mailLog?: { __typename?: 'MailLog', id: string, sentTo: string, sentBy: string, emailTemplate?: string | null, status?: string | null, message?: string | null, reason?: string | null, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null } | null };

export type CreateMailLogMutationVariables = Exact<{
  sentTo: Scalars['String']['input'];
  sentBy: Scalars['String']['input'];
  emailTemplate?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  reason?: InputMaybe<Scalars['String']['input']>;
}>;


export type CreateMailLogMutation = { __typename?: 'Mutation', createMailLog: { __typename?: 'MailLog', id: string, sentTo: string, sentBy: string, emailTemplate?: string | null, status?: string | null, message?: string | null, reason?: string | null, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null } };

export type UpdateMailLogMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  sentTo?: InputMaybe<Scalars['String']['input']>;
  sentBy?: InputMaybe<Scalars['String']['input']>;
  emailTemplate?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  reason?: InputMaybe<Scalars['String']['input']>;
}>;


export type UpdateMailLogMutation = { __typename?: 'Mutation', updateMailLog: { __typename?: 'MailLog', id: string, sentTo: string, sentBy: string, emailTemplate?: string | null, status?: string | null, message?: string | null, reason?: string | null, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null } };

export type DeleteMailLogMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DeleteMailLogMutation = { __typename?: 'Mutation', deleteMailLog: { __typename?: 'MailLog', id: string, sentTo: string, sentBy: string, emailTemplate?: string | null, status?: string | null, message?: string | null, reason?: string | null, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null } };

export type CreatedMailLogSubscriptionVariables = Exact<{
  filter?: InputMaybe<MailLogFilterArgs>;
}>;


export type CreatedMailLogSubscription = { __typename?: 'Subscription', createdMailLog: { __typename?: 'MailLog', id: string, sentTo: string, sentBy: string, emailTemplate?: string | null, status?: string | null, message?: string | null, reason?: string | null, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null } };

export type UpdatedMailLogSubscriptionVariables = Exact<{
  filter?: InputMaybe<MailLogFilterArgs>;
}>;


export type UpdatedMailLogSubscription = { __typename?: 'Subscription', updatedMailLog: { __typename?: 'MailLog', id: string, sentTo: string, sentBy: string, emailTemplate?: string | null, status?: string | null, message?: string | null, reason?: string | null, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null } };

export type DeletedMailLogSubscriptionVariables = Exact<{
  filter?: InputMaybe<MailLogFilterArgs>;
}>;


export type DeletedMailLogSubscription = { __typename?: 'Subscription', deletedMailLog: { __typename?: 'MailLog', id: string } };

export type RestoredMailLogSubscriptionVariables = Exact<{
  filter?: InputMaybe<MailLogFilterArgs>;
}>;


export type RestoredMailLogSubscription = { __typename?: 'Subscription', restoredMailLog: { __typename?: 'MailLog', id: string, sentTo: string, sentBy: string, emailTemplate?: string | null, status?: string | null, message?: string | null, reason?: string | null, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null } };

export type MailTemplateFieldsFragment = { __typename?: 'MailTemplate', id: string, active: boolean, mailTemplateFills?: { __typename?: 'PaginatedMailTemplateFill', totalCount: number, items?: Array<{ __typename?: 'MailTemplateFill', id: string, subject: string, urlText?: string | null, title?: string | null, heading?: string | null, preHeader?: string | null, disclaimer?: string | null, text: string, plainText: string, language: string, mailTemplateId: string, displayField?: string | null }> | null } };

export type GetMailTemplatesQueryVariables = Exact<{
  filter?: InputMaybe<MailTemplateFilterArgs>;
  paging?: InputMaybe<PageArgs>;
  sorting?: InputMaybe<Array<MailTemplateSortArgs> | MailTemplateSortArgs>;
  withMailTemplateFills?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type GetMailTemplatesQuery = { __typename?: 'Query', mailTemplates: { __typename?: 'PaginatedMailTemplate', totalCount: number, items?: Array<{ __typename?: 'MailTemplate', id: string, active: boolean, mailTemplateFills?: { __typename?: 'PaginatedMailTemplateFill', totalCount: number, items?: Array<{ __typename?: 'MailTemplateFill', id: string, subject: string, urlText?: string | null, title?: string | null, heading?: string | null, preHeader?: string | null, disclaimer?: string | null, text: string, plainText: string, language: string, mailTemplateId: string, displayField?: string | null }> | null } }> | null } };

export type GetMailTemplateQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  withMailTemplateFills?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type GetMailTemplateQuery = { __typename?: 'Query', mailTemplate?: { __typename?: 'MailTemplate', id: string, active: boolean, mailTemplateFills?: { __typename?: 'PaginatedMailTemplateFill', totalCount: number, items?: Array<{ __typename?: 'MailTemplateFill', id: string, subject: string, urlText?: string | null, title?: string | null, heading?: string | null, preHeader?: string | null, disclaimer?: string | null, text: string, plainText: string, language: string, mailTemplateId: string, displayField?: string | null }> | null } } | null };

export type GetAllRelatedDataForMailTemplateQueryVariables = Exact<{
  withMailTemplateFills?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type GetAllRelatedDataForMailTemplateQuery = { __typename?: 'Query', mailTemplateFills?: { __typename?: 'PaginatedMailTemplateFill', items?: Array<{ __typename?: 'MailTemplateFill', id: string, displayField?: string | null }> | null } };

export type CreateMailTemplateMutationVariables = Exact<{
  active: Scalars['Boolean']['input'];
  mailTemplateFills?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>> | InputMaybe<Scalars['ID']['input']>>;
  withMailTemplateFills?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type CreateMailTemplateMutation = { __typename?: 'Mutation', createMailTemplate: { __typename?: 'MailTemplate', id: string, active: boolean, mailTemplateFills?: { __typename?: 'PaginatedMailTemplateFill', totalCount: number, items?: Array<{ __typename?: 'MailTemplateFill', id: string, subject: string, urlText?: string | null, title?: string | null, heading?: string | null, preHeader?: string | null, disclaimer?: string | null, text: string, plainText: string, language: string, mailTemplateId: string, displayField?: string | null }> | null } } };

export type UpdateMailTemplateMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  active?: InputMaybe<Scalars['Boolean']['input']>;
  mailTemplateFills?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>> | InputMaybe<Scalars['ID']['input']>>;
  withMailTemplateFills?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type UpdateMailTemplateMutation = { __typename?: 'Mutation', updateMailTemplate: { __typename?: 'MailTemplate', id: string, active: boolean, mailTemplateFills?: { __typename?: 'PaginatedMailTemplateFill', totalCount: number, items?: Array<{ __typename?: 'MailTemplateFill', id: string, subject: string, urlText?: string | null, title?: string | null, heading?: string | null, preHeader?: string | null, disclaimer?: string | null, text: string, plainText: string, language: string, mailTemplateId: string, displayField?: string | null }> | null } } };

export type DeleteMailTemplateMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  withMailTemplateFills?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type DeleteMailTemplateMutation = { __typename?: 'Mutation', deleteMailTemplate: { __typename?: 'MailTemplate', id: string, active: boolean, mailTemplateFills?: { __typename?: 'PaginatedMailTemplateFill', totalCount: number, items?: Array<{ __typename?: 'MailTemplateFill', id: string, subject: string, urlText?: string | null, title?: string | null, heading?: string | null, preHeader?: string | null, disclaimer?: string | null, text: string, plainText: string, language: string, mailTemplateId: string, displayField?: string | null }> | null } } };

export type CreatedMailTemplateSubscriptionVariables = Exact<{
  filter?: InputMaybe<MailTemplateFilterArgs>;
  withMailTemplateFills?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type CreatedMailTemplateSubscription = { __typename?: 'Subscription', createdMailTemplate: { __typename?: 'MailTemplate', id: string, active: boolean, mailTemplateFills?: { __typename?: 'PaginatedMailTemplateFill', totalCount: number, items?: Array<{ __typename?: 'MailTemplateFill', id: string, subject: string, urlText?: string | null, title?: string | null, heading?: string | null, preHeader?: string | null, disclaimer?: string | null, text: string, plainText: string, language: string, mailTemplateId: string, displayField?: string | null }> | null } } };

export type UpdatedMailTemplateSubscriptionVariables = Exact<{
  filter?: InputMaybe<MailTemplateFilterArgs>;
  withMailTemplateFills?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type UpdatedMailTemplateSubscription = { __typename?: 'Subscription', updatedMailTemplate: { __typename?: 'MailTemplate', id: string, active: boolean, mailTemplateFills?: { __typename?: 'PaginatedMailTemplateFill', totalCount: number, items?: Array<{ __typename?: 'MailTemplateFill', id: string, subject: string, urlText?: string | null, title?: string | null, heading?: string | null, preHeader?: string | null, disclaimer?: string | null, text: string, plainText: string, language: string, mailTemplateId: string, displayField?: string | null }> | null } } };

export type DeletedMailTemplateSubscriptionVariables = Exact<{
  filter?: InputMaybe<MailTemplateFilterArgs>;
}>;


export type DeletedMailTemplateSubscription = { __typename?: 'Subscription', deletedMailTemplate: { __typename?: 'MailTemplate', id: string } };

export type MailTemplateFillFieldsFragment = { __typename?: 'MailTemplateFill', id: string, subject: string, urlText?: string | null, title?: string | null, heading?: string | null, preHeader?: string | null, disclaimer?: string | null, text: string, plainText: string, language: string, mailTemplate?: { __typename?: 'MailTemplate', id: string, active: boolean, displayField: string } };

export type GetMailTemplateFillsQueryVariables = Exact<{
  filter?: InputMaybe<MailTemplateFillFilterArgs>;
  paging?: InputMaybe<PageArgs>;
  sorting?: InputMaybe<Array<MailTemplateFillSortArgs> | MailTemplateFillSortArgs>;
  withMailTemplate?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type GetMailTemplateFillsQuery = { __typename?: 'Query', mailTemplateFills: { __typename?: 'PaginatedMailTemplateFill', totalCount: number, items?: Array<{ __typename?: 'MailTemplateFill', id: string, subject: string, urlText?: string | null, title?: string | null, heading?: string | null, preHeader?: string | null, disclaimer?: string | null, text: string, plainText: string, language: string, mailTemplate?: { __typename?: 'MailTemplate', id: string, active: boolean, displayField: string } }> | null } };

export type GetMailTemplateFillQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  withMailTemplate?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type GetMailTemplateFillQuery = { __typename?: 'Query', mailTemplateFill?: { __typename?: 'MailTemplateFill', id: string, subject: string, urlText?: string | null, title?: string | null, heading?: string | null, preHeader?: string | null, disclaimer?: string | null, text: string, plainText: string, language: string, mailTemplate?: { __typename?: 'MailTemplate', id: string, active: boolean, displayField: string } } | null };

export type GetAllRelatedDataForMailTemplateFillQueryVariables = Exact<{
  withMailTemplates?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type GetAllRelatedDataForMailTemplateFillQuery = { __typename?: 'Query', mailTemplates?: { __typename?: 'PaginatedMailTemplate', items?: Array<{ __typename?: 'MailTemplate', id: string, displayField: string }> | null } };

export type CreateMailTemplateFillMutationVariables = Exact<{
  subject: Scalars['String']['input'];
  urlText?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  heading?: InputMaybe<Scalars['String']['input']>;
  preHeader?: InputMaybe<Scalars['String']['input']>;
  disclaimer?: InputMaybe<Scalars['String']['input']>;
  text: Scalars['String']['input'];
  plainText: Scalars['String']['input'];
  language: Scalars['String']['input'];
  mailTemplateId: Scalars['ID']['input'];
  withMailTemplate?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type CreateMailTemplateFillMutation = { __typename?: 'Mutation', createMailTemplateFill: { __typename?: 'MailTemplateFill', id: string, subject: string, urlText?: string | null, title?: string | null, heading?: string | null, preHeader?: string | null, disclaimer?: string | null, text: string, plainText: string, language: string, mailTemplate?: { __typename?: 'MailTemplate', id: string, active: boolean, displayField: string } } };

export type UpdateMailTemplateFillMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  subject?: InputMaybe<Scalars['String']['input']>;
  urlText?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  heading?: InputMaybe<Scalars['String']['input']>;
  preHeader?: InputMaybe<Scalars['String']['input']>;
  disclaimer?: InputMaybe<Scalars['String']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  plainText?: InputMaybe<Scalars['String']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  mailTemplateId?: InputMaybe<Scalars['ID']['input']>;
  withMailTemplate?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type UpdateMailTemplateFillMutation = { __typename?: 'Mutation', updateMailTemplateFill: { __typename?: 'MailTemplateFill', id: string, subject: string, urlText?: string | null, title?: string | null, heading?: string | null, preHeader?: string | null, disclaimer?: string | null, text: string, plainText: string, language: string, mailTemplate?: { __typename?: 'MailTemplate', id: string, active: boolean, displayField: string } } };

export type DeleteMailTemplateFillMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  withMailTemplate?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type DeleteMailTemplateFillMutation = { __typename?: 'Mutation', deleteMailTemplateFill: { __typename?: 'MailTemplateFill', id: string, subject: string, urlText?: string | null, title?: string | null, heading?: string | null, preHeader?: string | null, disclaimer?: string | null, text: string, plainText: string, language: string, mailTemplate?: { __typename?: 'MailTemplate', id: string, active: boolean, displayField: string } } };

export type CreatedMailTemplateFillSubscriptionVariables = Exact<{
  filter?: InputMaybe<MailTemplateFillFilterArgs>;
  withMailTemplate?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type CreatedMailTemplateFillSubscription = { __typename?: 'Subscription', createdMailTemplateFill: { __typename?: 'MailTemplateFill', id: string, subject: string, urlText?: string | null, title?: string | null, heading?: string | null, preHeader?: string | null, disclaimer?: string | null, text: string, plainText: string, language: string, mailTemplate?: { __typename?: 'MailTemplate', id: string, active: boolean, displayField: string } } };

export type UpdatedMailTemplateFillSubscriptionVariables = Exact<{
  filter?: InputMaybe<MailTemplateFillFilterArgs>;
  withMailTemplate?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type UpdatedMailTemplateFillSubscription = { __typename?: 'Subscription', updatedMailTemplateFill: { __typename?: 'MailTemplateFill', id: string, subject: string, urlText?: string | null, title?: string | null, heading?: string | null, preHeader?: string | null, disclaimer?: string | null, text: string, plainText: string, language: string, mailTemplate?: { __typename?: 'MailTemplate', id: string, active: boolean, displayField: string } } };

export type DeletedMailTemplateFillSubscriptionVariables = Exact<{
  filter?: InputMaybe<MailTemplateFillFilterArgs>;
}>;


export type DeletedMailTemplateFillSubscription = { __typename?: 'Subscription', deletedMailTemplateFill: { __typename?: 'MailTemplateFill', id: string } };

export type NotificationSettingFieldsFragment = { __typename?: 'NotificationSetting', id: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, user?: { __typename?: 'User', id: string, name: string, email: string, emailVerifiedAt?: any | null, active: boolean, avatar?: string | null, totpVerified?: boolean | null, termsAndConditions?: boolean | null, locale: string, timeZone: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, displayField: string } | null, gitRepository?: { __typename?: 'GitRepository', id: string, name: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, displayField: string } | null };

export type GetNotificationSettingsQueryVariables = Exact<{
  filter?: InputMaybe<NotificationSettingFilterArgs>;
  paging?: InputMaybe<PageArgs>;
  sorting?: InputMaybe<Array<NotificationSettingSortArgs> | NotificationSettingSortArgs>;
  withUser?: InputMaybe<Scalars['Boolean']['input']>;
  withGitRepository?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type GetNotificationSettingsQuery = { __typename?: 'Query', notificationSettings: { __typename?: 'PaginatedNotificationSetting', totalCount: number, items?: Array<{ __typename?: 'NotificationSetting', id: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, user?: { __typename?: 'User', id: string, name: string, email: string, emailVerifiedAt?: any | null, active: boolean, avatar?: string | null, totpVerified?: boolean | null, termsAndConditions?: boolean | null, locale: string, timeZone: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, displayField: string } | null, gitRepository?: { __typename?: 'GitRepository', id: string, name: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, displayField: string } | null }> | null } };

export type GetNotificationSettingQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  withUser?: InputMaybe<Scalars['Boolean']['input']>;
  withGitRepository?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type GetNotificationSettingQuery = { __typename?: 'Query', notificationSetting?: { __typename?: 'NotificationSetting', id: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, user?: { __typename?: 'User', id: string, name: string, email: string, emailVerifiedAt?: any | null, active: boolean, avatar?: string | null, totpVerified?: boolean | null, termsAndConditions?: boolean | null, locale: string, timeZone: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, displayField: string } | null, gitRepository?: { __typename?: 'GitRepository', id: string, name: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, displayField: string } | null } | null };

export type GetAllRelatedDataForNotificationSettingQueryVariables = Exact<{
  withUsers?: InputMaybe<Scalars['Boolean']['input']>;
  withGitRepositories?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type GetAllRelatedDataForNotificationSettingQuery = { __typename?: 'Query', users?: { __typename?: 'PaginatedUser', items?: Array<{ __typename?: 'User', id: string, displayField: string }> | null }, gitRepositories?: { __typename?: 'PaginatedGitRepository', items?: Array<{ __typename?: 'GitRepository', id: string, displayField: string }> | null } };

export type CreateNotificationSettingMutationVariables = Exact<{
  userId: Scalars['ID']['input'];
  gitRepositoryId: Scalars['ID']['input'];
  withUser?: InputMaybe<Scalars['Boolean']['input']>;
  withGitRepository?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type CreateNotificationSettingMutation = { __typename?: 'Mutation', createNotificationSetting: { __typename?: 'NotificationSetting', id: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, user?: { __typename?: 'User', id: string, name: string, email: string, emailVerifiedAt?: any | null, active: boolean, avatar?: string | null, totpVerified?: boolean | null, termsAndConditions?: boolean | null, locale: string, timeZone: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, displayField: string } | null, gitRepository?: { __typename?: 'GitRepository', id: string, name: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, displayField: string } | null } };

export type UpdateNotificationSettingMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  userId?: InputMaybe<Scalars['ID']['input']>;
  gitRepositoryId?: InputMaybe<Scalars['ID']['input']>;
  withUser?: InputMaybe<Scalars['Boolean']['input']>;
  withGitRepository?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type UpdateNotificationSettingMutation = { __typename?: 'Mutation', updateNotificationSetting: { __typename?: 'NotificationSetting', id: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, user?: { __typename?: 'User', id: string, name: string, email: string, emailVerifiedAt?: any | null, active: boolean, avatar?: string | null, totpVerified?: boolean | null, termsAndConditions?: boolean | null, locale: string, timeZone: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, displayField: string } | null, gitRepository?: { __typename?: 'GitRepository', id: string, name: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, displayField: string } | null } };

export type DeleteNotificationSettingMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  withUser?: InputMaybe<Scalars['Boolean']['input']>;
  withGitRepository?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type DeleteNotificationSettingMutation = { __typename?: 'Mutation', deleteNotificationSetting: { __typename?: 'NotificationSetting', id: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, user?: { __typename?: 'User', id: string, name: string, email: string, emailVerifiedAt?: any | null, active: boolean, avatar?: string | null, totpVerified?: boolean | null, termsAndConditions?: boolean | null, locale: string, timeZone: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, displayField: string } | null, gitRepository?: { __typename?: 'GitRepository', id: string, name: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, displayField: string } | null } };

export type CreatedNotificationSettingSubscriptionVariables = Exact<{
  filter?: InputMaybe<NotificationSettingFilterArgs>;
  withUser?: InputMaybe<Scalars['Boolean']['input']>;
  withGitRepository?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type CreatedNotificationSettingSubscription = { __typename?: 'Subscription', createdNotificationSetting: { __typename?: 'NotificationSetting', id: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, user?: { __typename?: 'User', id: string, name: string, email: string, emailVerifiedAt?: any | null, active: boolean, avatar?: string | null, totpVerified?: boolean | null, termsAndConditions?: boolean | null, locale: string, timeZone: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, displayField: string } | null, gitRepository?: { __typename?: 'GitRepository', id: string, name: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, displayField: string } | null } };

export type UpdatedNotificationSettingSubscriptionVariables = Exact<{
  filter?: InputMaybe<NotificationSettingFilterArgs>;
  withUser?: InputMaybe<Scalars['Boolean']['input']>;
  withGitRepository?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type UpdatedNotificationSettingSubscription = { __typename?: 'Subscription', updatedNotificationSetting: { __typename?: 'NotificationSetting', id: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, user?: { __typename?: 'User', id: string, name: string, email: string, emailVerifiedAt?: any | null, active: boolean, avatar?: string | null, totpVerified?: boolean | null, termsAndConditions?: boolean | null, locale: string, timeZone: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, displayField: string } | null, gitRepository?: { __typename?: 'GitRepository', id: string, name: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, displayField: string } | null } };

export type DeletedNotificationSettingSubscriptionVariables = Exact<{
  filter?: InputMaybe<NotificationSettingFilterArgs>;
}>;


export type DeletedNotificationSettingSubscription = { __typename?: 'Subscription', deletedNotificationSetting: { __typename?: 'NotificationSetting', id: string } };

export type RestoredNotificationSettingSubscriptionVariables = Exact<{
  filter?: InputMaybe<NotificationSettingFilterArgs>;
  withUser?: InputMaybe<Scalars['Boolean']['input']>;
  withGitRepository?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type RestoredNotificationSettingSubscription = { __typename?: 'Subscription', restoredNotificationSetting: { __typename?: 'NotificationSetting', id: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, user?: { __typename?: 'User', id: string, name: string, email: string, emailVerifiedAt?: any | null, active: boolean, avatar?: string | null, totpVerified?: boolean | null, termsAndConditions?: boolean | null, locale: string, timeZone: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, displayField: string } | null, gitRepository?: { __typename?: 'GitRepository', id: string, name: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, displayField: string } | null } };

export type PermissionFieldsFragment = { __typename?: 'Permission', id: string, name: string, description: string, roles?: { __typename?: 'PaginatedRole', totalCount: number, items?: Array<{ __typename?: 'Role', id: string, machineName: string, displayName: string, description: string, displayField: string }> | null } };

export type GetPermissionsQueryVariables = Exact<{
  filter?: InputMaybe<PermissionFilterArgs>;
  paging?: InputMaybe<PageArgs>;
  sorting?: InputMaybe<Array<PermissionSortArgs> | PermissionSortArgs>;
  withRoles?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type GetPermissionsQuery = { __typename?: 'Query', permissions: { __typename?: 'PaginatedPermission', totalCount: number, items?: Array<{ __typename?: 'Permission', id: string, name: string, description: string, roles?: { __typename?: 'PaginatedRole', totalCount: number, items?: Array<{ __typename?: 'Role', id: string, machineName: string, displayName: string, description: string, displayField: string }> | null } }> | null } };

export type GetPermissionQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  withRoles?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type GetPermissionQuery = { __typename?: 'Query', permission?: { __typename?: 'Permission', id: string, name: string, description: string, roles?: { __typename?: 'PaginatedRole', totalCount: number, items?: Array<{ __typename?: 'Role', id: string, machineName: string, displayName: string, description: string, displayField: string }> | null } } | null };

export type GetAllRelatedDataForPermissionQueryVariables = Exact<{
  withRoles?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type GetAllRelatedDataForPermissionQuery = { __typename?: 'Query', roles?: { __typename?: 'PaginatedRole', items?: Array<{ __typename?: 'Role', id: string, displayField: string }> | null } };

export type CreatePermissionMutationVariables = Exact<{
  name: Scalars['String']['input'];
  description: Scalars['String']['input'];
  roles?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>> | InputMaybe<Scalars['ID']['input']>>;
  withRoles?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type CreatePermissionMutation = { __typename?: 'Mutation', createPermission: { __typename?: 'Permission', id: string, name: string, description: string, roles?: { __typename?: 'PaginatedRole', totalCount: number, items?: Array<{ __typename?: 'Role', id: string, machineName: string, displayName: string, description: string, displayField: string }> | null } } };

export type UpdatePermissionMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  roles?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>> | InputMaybe<Scalars['ID']['input']>>;
  withRoles?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type UpdatePermissionMutation = { __typename?: 'Mutation', updatePermission: { __typename?: 'Permission', id: string, name: string, description: string, roles?: { __typename?: 'PaginatedRole', totalCount: number, items?: Array<{ __typename?: 'Role', id: string, machineName: string, displayName: string, description: string, displayField: string }> | null } } };

export type DeletePermissionMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  withRoles?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type DeletePermissionMutation = { __typename?: 'Mutation', deletePermission: { __typename?: 'Permission', id: string, name: string, description: string, roles?: { __typename?: 'PaginatedRole', totalCount: number, items?: Array<{ __typename?: 'Role', id: string, machineName: string, displayName: string, description: string, displayField: string }> | null } } };

export type CreatedPermissionSubscriptionVariables = Exact<{
  filter?: InputMaybe<PermissionFilterArgs>;
  withRoles?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type CreatedPermissionSubscription = { __typename?: 'Subscription', createdPermission: { __typename?: 'Permission', id: string, name: string, description: string, roles?: { __typename?: 'PaginatedRole', totalCount: number, items?: Array<{ __typename?: 'Role', id: string, machineName: string, displayName: string, description: string, displayField: string }> | null } } };

export type UpdatedPermissionSubscriptionVariables = Exact<{
  filter?: InputMaybe<PermissionFilterArgs>;
  withRoles?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type UpdatedPermissionSubscription = { __typename?: 'Subscription', updatedPermission: { __typename?: 'Permission', id: string, name: string, description: string, roles?: { __typename?: 'PaginatedRole', totalCount: number, items?: Array<{ __typename?: 'Role', id: string, machineName: string, displayName: string, description: string, displayField: string }> | null } } };

export type DeletedPermissionSubscriptionVariables = Exact<{
  filter?: InputMaybe<PermissionFilterArgs>;
}>;


export type DeletedPermissionSubscription = { __typename?: 'Subscription', deletedPermission: { __typename?: 'Permission', id: string } };

export type ResetPasswordFieldsFragment = { __typename?: 'ResetPassword', id: string, email: string, token: string, type: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null };

export type GetResetPasswordsQueryVariables = Exact<{
  filter?: InputMaybe<ResetPasswordFilterArgs>;
  paging?: InputMaybe<PageArgs>;
  sorting?: InputMaybe<Array<ResetPasswordSortArgs> | ResetPasswordSortArgs>;
}>;


export type GetResetPasswordsQuery = { __typename?: 'Query', resetPasswords: { __typename?: 'PaginatedResetPassword', totalCount: number, items?: Array<{ __typename?: 'ResetPassword', id: string, email: string, token: string, type: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null }> | null } };

export type GetResetPasswordQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetResetPasswordQuery = { __typename?: 'Query', resetPassword?: { __typename?: 'ResetPassword', id: string, email: string, token: string, type: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null } | null };

export type CreateResetPasswordMutationVariables = Exact<{
  email: Scalars['String']['input'];
  token: Scalars['String']['input'];
  type: Scalars['String']['input'];
}>;


export type CreateResetPasswordMutation = { __typename?: 'Mutation', createResetPassword: { __typename?: 'ResetPassword', id: string, email: string, token: string, type: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null } };

export type UpdateResetPasswordMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
}>;


export type UpdateResetPasswordMutation = { __typename?: 'Mutation', updateResetPassword: { __typename?: 'ResetPassword', id: string, email: string, token: string, type: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null } };

export type DeleteResetPasswordMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DeleteResetPasswordMutation = { __typename?: 'Mutation', deleteResetPassword: { __typename?: 'ResetPassword', id: string, email: string, token: string, type: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null } };

export type CreatedResetPasswordSubscriptionVariables = Exact<{
  filter?: InputMaybe<ResetPasswordFilterArgs>;
}>;


export type CreatedResetPasswordSubscription = { __typename?: 'Subscription', createdResetPassword: { __typename?: 'ResetPassword', id: string, email: string, token: string, type: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null } };

export type UpdatedResetPasswordSubscriptionVariables = Exact<{
  filter?: InputMaybe<ResetPasswordFilterArgs>;
}>;


export type UpdatedResetPasswordSubscription = { __typename?: 'Subscription', updatedResetPassword: { __typename?: 'ResetPassword', id: string, email: string, token: string, type: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null } };

export type DeletedResetPasswordSubscriptionVariables = Exact<{
  filter?: InputMaybe<ResetPasswordFilterArgs>;
}>;


export type DeletedResetPasswordSubscription = { __typename?: 'Subscription', deletedResetPassword: { __typename?: 'ResetPassword', id: string } };

export type RestoredResetPasswordSubscriptionVariables = Exact<{
  filter?: InputMaybe<ResetPasswordFilterArgs>;
}>;


export type RestoredResetPasswordSubscription = { __typename?: 'Subscription', restoredResetPassword: { __typename?: 'ResetPassword', id: string, email: string, token: string, type: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null } };

export type RoleFieldsFragment = { __typename?: 'Role', id: string, machineName: string, displayName: string, description: string, permissions?: { __typename?: 'PaginatedPermission', totalCount: number, items?: Array<{ __typename?: 'Permission', id: string, name: string, description: string, displayField: string }> | null }, userTenantRoles?: { __typename?: 'PaginatedUserTenantRole', totalCount: number, items?: Array<{ __typename?: 'UserTenantRole', id: string, userId: string, tenantId: string, roleId: string, displayField: string }> | null } };

export type GetRolesQueryVariables = Exact<{
  filter?: InputMaybe<RoleFilterArgs>;
  paging?: InputMaybe<PageArgs>;
  sorting?: InputMaybe<Array<RoleSortArgs> | RoleSortArgs>;
  withPermissions?: InputMaybe<Scalars['Boolean']['input']>;
  withUserTenantRoles?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type GetRolesQuery = { __typename?: 'Query', roles: { __typename?: 'PaginatedRole', totalCount: number, items?: Array<{ __typename?: 'Role', id: string, machineName: string, displayName: string, description: string, permissions?: { __typename?: 'PaginatedPermission', totalCount: number, items?: Array<{ __typename?: 'Permission', id: string, name: string, description: string, displayField: string }> | null }, userTenantRoles?: { __typename?: 'PaginatedUserTenantRole', totalCount: number, items?: Array<{ __typename?: 'UserTenantRole', id: string, userId: string, tenantId: string, roleId: string, displayField: string }> | null } }> | null } };

export type GetRoleQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  withPermissions?: InputMaybe<Scalars['Boolean']['input']>;
  withUserTenantRoles?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type GetRoleQuery = { __typename?: 'Query', role?: { __typename?: 'Role', id: string, machineName: string, displayName: string, description: string, permissions?: { __typename?: 'PaginatedPermission', totalCount: number, items?: Array<{ __typename?: 'Permission', id: string, name: string, description: string, displayField: string }> | null }, userTenantRoles?: { __typename?: 'PaginatedUserTenantRole', totalCount: number, items?: Array<{ __typename?: 'UserTenantRole', id: string, userId: string, tenantId: string, roleId: string, displayField: string }> | null } } | null };

export type GetAllRelatedDataForRoleQueryVariables = Exact<{
  withPermissions?: InputMaybe<Scalars['Boolean']['input']>;
  withUserTenantRoles?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type GetAllRelatedDataForRoleQuery = { __typename?: 'Query', permissions?: { __typename?: 'PaginatedPermission', items?: Array<{ __typename?: 'Permission', id: string, displayField: string }> | null }, userTenantRoles?: { __typename?: 'PaginatedUserTenantRole', items?: Array<{ __typename?: 'UserTenantRole', id: string, displayField: string }> | null } };

export type CreateRoleMutationVariables = Exact<{
  displayName: Scalars['String']['input'];
  description: Scalars['String']['input'];
  permissions?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>> | InputMaybe<Scalars['ID']['input']>>;
  userTenantRoles?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>> | InputMaybe<Scalars['ID']['input']>>;
  withPermissions?: InputMaybe<Scalars['Boolean']['input']>;
  withUserTenantRoles?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type CreateRoleMutation = { __typename?: 'Mutation', createRole: { __typename?: 'Role', id: string, machineName: string, displayName: string, description: string, permissions?: { __typename?: 'PaginatedPermission', totalCount: number, items?: Array<{ __typename?: 'Permission', id: string, name: string, description: string, displayField: string }> | null }, userTenantRoles?: { __typename?: 'PaginatedUserTenantRole', totalCount: number, items?: Array<{ __typename?: 'UserTenantRole', id: string, userId: string, tenantId: string, roleId: string, displayField: string }> | null } } };

export type UpdateRoleMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  displayName?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  permissions?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>> | InputMaybe<Scalars['ID']['input']>>;
  userTenantRoles?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>> | InputMaybe<Scalars['ID']['input']>>;
  withPermissions?: InputMaybe<Scalars['Boolean']['input']>;
  withUserTenantRoles?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type UpdateRoleMutation = { __typename?: 'Mutation', updateRole: { __typename?: 'Role', id: string, machineName: string, displayName: string, description: string, permissions?: { __typename?: 'PaginatedPermission', totalCount: number, items?: Array<{ __typename?: 'Permission', id: string, name: string, description: string, displayField: string }> | null }, userTenantRoles?: { __typename?: 'PaginatedUserTenantRole', totalCount: number, items?: Array<{ __typename?: 'UserTenantRole', id: string, userId: string, tenantId: string, roleId: string, displayField: string }> | null } } };

export type DeleteRoleMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  withPermissions?: InputMaybe<Scalars['Boolean']['input']>;
  withUserTenantRoles?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type DeleteRoleMutation = { __typename?: 'Mutation', deleteRole: { __typename?: 'Role', id: string, machineName: string, displayName: string, description: string, permissions?: { __typename?: 'PaginatedPermission', totalCount: number, items?: Array<{ __typename?: 'Permission', id: string, name: string, description: string, displayField: string }> | null }, userTenantRoles?: { __typename?: 'PaginatedUserTenantRole', totalCount: number, items?: Array<{ __typename?: 'UserTenantRole', id: string, userId: string, tenantId: string, roleId: string, displayField: string }> | null } } };

export type CreatedRoleSubscriptionVariables = Exact<{
  filter?: InputMaybe<RoleFilterArgs>;
  withPermissions?: InputMaybe<Scalars['Boolean']['input']>;
  withUserTenantRoles?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type CreatedRoleSubscription = { __typename?: 'Subscription', createdRole: { __typename?: 'Role', id: string, machineName: string, displayName: string, description: string, permissions?: { __typename?: 'PaginatedPermission', totalCount: number, items?: Array<{ __typename?: 'Permission', id: string, name: string, description: string, displayField: string }> | null }, userTenantRoles?: { __typename?: 'PaginatedUserTenantRole', totalCount: number, items?: Array<{ __typename?: 'UserTenantRole', id: string, userId: string, tenantId: string, roleId: string, displayField: string }> | null } } };

export type UpdatedRoleSubscriptionVariables = Exact<{
  filter?: InputMaybe<RoleFilterArgs>;
  withPermissions?: InputMaybe<Scalars['Boolean']['input']>;
  withUserTenantRoles?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type UpdatedRoleSubscription = { __typename?: 'Subscription', updatedRole: { __typename?: 'Role', id: string, machineName: string, displayName: string, description: string, permissions?: { __typename?: 'PaginatedPermission', totalCount: number, items?: Array<{ __typename?: 'Permission', id: string, name: string, description: string, displayField: string }> | null }, userTenantRoles?: { __typename?: 'PaginatedUserTenantRole', totalCount: number, items?: Array<{ __typename?: 'UserTenantRole', id: string, userId: string, tenantId: string, roleId: string, displayField: string }> | null } } };

export type DeletedRoleSubscriptionVariables = Exact<{
  filter?: InputMaybe<RoleFilterArgs>;
}>;


export type DeletedRoleSubscription = { __typename?: 'Subscription', deletedRole: { __typename?: 'Role', id: string } };

export type ScanFieldsFragment = { __typename?: 'Scan', id: string, directory: string, type: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, branch?: { __typename?: 'Branch', id: string, name: string, gitRepositoryId: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, displayField: string } | null, advisories?: { __typename?: 'PaginatedAdvisory', totalCount: number, items?: Array<{ __typename?: 'Advisory', id: string, externalId: string, title: string, package: string, parentPackage?: string | null, version: string, patchedVersion?: string | null, severity?: string | null, raw: string, scanId: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, displayField: string }> | null }, scanFiles?: { __typename?: 'PaginatedScanFile', totalCount: number, items?: Array<{ __typename?: 'ScanFile', id: string, name: string, contents: string, scanId: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, displayField: string }> | null } };

export type GetScansQueryVariables = Exact<{
  filter?: InputMaybe<ScanFilterArgs>;
  paging?: InputMaybe<PageArgs>;
  sorting?: InputMaybe<Array<ScanSortArgs> | ScanSortArgs>;
  withBranch?: InputMaybe<Scalars['Boolean']['input']>;
  withAdvisories?: InputMaybe<Scalars['Boolean']['input']>;
  withScanFiles?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type GetScansQuery = { __typename?: 'Query', scans: { __typename?: 'PaginatedScan', totalCount: number, items?: Array<{ __typename?: 'Scan', id: string, directory: string, type: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, branch?: { __typename?: 'Branch', id: string, name: string, gitRepositoryId: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, displayField: string } | null, advisories?: { __typename?: 'PaginatedAdvisory', totalCount: number, items?: Array<{ __typename?: 'Advisory', id: string, externalId: string, title: string, package: string, parentPackage?: string | null, version: string, patchedVersion?: string | null, severity?: string | null, raw: string, scanId: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, displayField: string }> | null }, scanFiles?: { __typename?: 'PaginatedScanFile', totalCount: number, items?: Array<{ __typename?: 'ScanFile', id: string, name: string, contents: string, scanId: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, displayField: string }> | null } }> | null } };

export type GetScanQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  withBranch?: InputMaybe<Scalars['Boolean']['input']>;
  withAdvisories?: InputMaybe<Scalars['Boolean']['input']>;
  withScanFiles?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type GetScanQuery = { __typename?: 'Query', scan?: { __typename?: 'Scan', id: string, directory: string, type: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, branch?: { __typename?: 'Branch', id: string, name: string, gitRepositoryId: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, displayField: string } | null, advisories?: { __typename?: 'PaginatedAdvisory', totalCount: number, items?: Array<{ __typename?: 'Advisory', id: string, externalId: string, title: string, package: string, parentPackage?: string | null, version: string, patchedVersion?: string | null, severity?: string | null, raw: string, scanId: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, displayField: string }> | null }, scanFiles?: { __typename?: 'PaginatedScanFile', totalCount: number, items?: Array<{ __typename?: 'ScanFile', id: string, name: string, contents: string, scanId: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, displayField: string }> | null } } | null };

export type GetAllRelatedDataForScanQueryVariables = Exact<{
  withBranches?: InputMaybe<Scalars['Boolean']['input']>;
  withAdvisories?: InputMaybe<Scalars['Boolean']['input']>;
  withScanFiles?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type GetAllRelatedDataForScanQuery = { __typename?: 'Query', branches?: { __typename?: 'PaginatedBranch', items?: Array<{ __typename?: 'Branch', id: string, displayField: string }> | null }, advisories?: { __typename?: 'PaginatedAdvisory', items?: Array<{ __typename?: 'Advisory', id: string, displayField: string }> | null }, scanFiles?: { __typename?: 'PaginatedScanFile', items?: Array<{ __typename?: 'ScanFile', id: string, displayField: string }> | null } };

export type CreateScanMutationVariables = Exact<{
  directory: Scalars['String']['input'];
  type: Scalars['String']['input'];
  branchId: Scalars['ID']['input'];
  advisories?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>> | InputMaybe<Scalars['ID']['input']>>;
  scanFiles?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>> | InputMaybe<Scalars['ID']['input']>>;
  withBranch?: InputMaybe<Scalars['Boolean']['input']>;
  withAdvisories?: InputMaybe<Scalars['Boolean']['input']>;
  withScanFiles?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type CreateScanMutation = { __typename?: 'Mutation', createScan: { __typename?: 'Scan', id: string, directory: string, type: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, branch?: { __typename?: 'Branch', id: string, name: string, gitRepositoryId: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, displayField: string } | null, advisories?: { __typename?: 'PaginatedAdvisory', totalCount: number, items?: Array<{ __typename?: 'Advisory', id: string, externalId: string, title: string, package: string, parentPackage?: string | null, version: string, patchedVersion?: string | null, severity?: string | null, raw: string, scanId: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, displayField: string }> | null }, scanFiles?: { __typename?: 'PaginatedScanFile', totalCount: number, items?: Array<{ __typename?: 'ScanFile', id: string, name: string, contents: string, scanId: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, displayField: string }> | null } } };

export type UpdateScanMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  directory?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  branchId?: InputMaybe<Scalars['ID']['input']>;
  advisories?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>> | InputMaybe<Scalars['ID']['input']>>;
  scanFiles?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>> | InputMaybe<Scalars['ID']['input']>>;
  withBranch?: InputMaybe<Scalars['Boolean']['input']>;
  withAdvisories?: InputMaybe<Scalars['Boolean']['input']>;
  withScanFiles?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type UpdateScanMutation = { __typename?: 'Mutation', updateScan: { __typename?: 'Scan', id: string, directory: string, type: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, branch?: { __typename?: 'Branch', id: string, name: string, gitRepositoryId: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, displayField: string } | null, advisories?: { __typename?: 'PaginatedAdvisory', totalCount: number, items?: Array<{ __typename?: 'Advisory', id: string, externalId: string, title: string, package: string, parentPackage?: string | null, version: string, patchedVersion?: string | null, severity?: string | null, raw: string, scanId: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, displayField: string }> | null }, scanFiles?: { __typename?: 'PaginatedScanFile', totalCount: number, items?: Array<{ __typename?: 'ScanFile', id: string, name: string, contents: string, scanId: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, displayField: string }> | null } } };

export type DeleteScanMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  withBranch?: InputMaybe<Scalars['Boolean']['input']>;
  withAdvisories?: InputMaybe<Scalars['Boolean']['input']>;
  withScanFiles?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type DeleteScanMutation = { __typename?: 'Mutation', deleteScan: { __typename?: 'Scan', id: string, directory: string, type: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, branch?: { __typename?: 'Branch', id: string, name: string, gitRepositoryId: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, displayField: string } | null, advisories?: { __typename?: 'PaginatedAdvisory', totalCount: number, items?: Array<{ __typename?: 'Advisory', id: string, externalId: string, title: string, package: string, parentPackage?: string | null, version: string, patchedVersion?: string | null, severity?: string | null, raw: string, scanId: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, displayField: string }> | null }, scanFiles?: { __typename?: 'PaginatedScanFile', totalCount: number, items?: Array<{ __typename?: 'ScanFile', id: string, name: string, contents: string, scanId: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, displayField: string }> | null } } };

export type CreatedScanSubscriptionVariables = Exact<{
  filter?: InputMaybe<ScanFilterArgs>;
  withBranch?: InputMaybe<Scalars['Boolean']['input']>;
  withAdvisories?: InputMaybe<Scalars['Boolean']['input']>;
  withScanFiles?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type CreatedScanSubscription = { __typename?: 'Subscription', createdScan: { __typename?: 'Scan', id: string, directory: string, type: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, branch?: { __typename?: 'Branch', id: string, name: string, gitRepositoryId: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, displayField: string } | null, advisories?: { __typename?: 'PaginatedAdvisory', totalCount: number, items?: Array<{ __typename?: 'Advisory', id: string, externalId: string, title: string, package: string, parentPackage?: string | null, version: string, patchedVersion?: string | null, severity?: string | null, raw: string, scanId: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, displayField: string }> | null }, scanFiles?: { __typename?: 'PaginatedScanFile', totalCount: number, items?: Array<{ __typename?: 'ScanFile', id: string, name: string, contents: string, scanId: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, displayField: string }> | null } } };

export type UpdatedScanSubscriptionVariables = Exact<{
  filter?: InputMaybe<ScanFilterArgs>;
  withBranch?: InputMaybe<Scalars['Boolean']['input']>;
  withAdvisories?: InputMaybe<Scalars['Boolean']['input']>;
  withScanFiles?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type UpdatedScanSubscription = { __typename?: 'Subscription', updatedScan: { __typename?: 'Scan', id: string, directory: string, type: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, branch?: { __typename?: 'Branch', id: string, name: string, gitRepositoryId: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, displayField: string } | null, advisories?: { __typename?: 'PaginatedAdvisory', totalCount: number, items?: Array<{ __typename?: 'Advisory', id: string, externalId: string, title: string, package: string, parentPackage?: string | null, version: string, patchedVersion?: string | null, severity?: string | null, raw: string, scanId: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, displayField: string }> | null }, scanFiles?: { __typename?: 'PaginatedScanFile', totalCount: number, items?: Array<{ __typename?: 'ScanFile', id: string, name: string, contents: string, scanId: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, displayField: string }> | null } } };

export type DeletedScanSubscriptionVariables = Exact<{
  filter?: InputMaybe<ScanFilterArgs>;
}>;


export type DeletedScanSubscription = { __typename?: 'Subscription', deletedScan: { __typename?: 'Scan', id: string } };

export type RestoredScanSubscriptionVariables = Exact<{
  filter?: InputMaybe<ScanFilterArgs>;
  withBranch?: InputMaybe<Scalars['Boolean']['input']>;
  withAdvisories?: InputMaybe<Scalars['Boolean']['input']>;
  withScanFiles?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type RestoredScanSubscription = { __typename?: 'Subscription', restoredScan: { __typename?: 'Scan', id: string, directory: string, type: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, branch?: { __typename?: 'Branch', id: string, name: string, gitRepositoryId: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, displayField: string } | null, advisories?: { __typename?: 'PaginatedAdvisory', totalCount: number, items?: Array<{ __typename?: 'Advisory', id: string, externalId: string, title: string, package: string, parentPackage?: string | null, version: string, patchedVersion?: string | null, severity?: string | null, raw: string, scanId: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, displayField: string }> | null }, scanFiles?: { __typename?: 'PaginatedScanFile', totalCount: number, items?: Array<{ __typename?: 'ScanFile', id: string, name: string, contents: string, scanId: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, displayField: string }> | null } } };

export type ScanFileFieldsFragment = { __typename?: 'ScanFile', id: string, name: string, contents: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, scan?: { __typename?: 'Scan', id: string, directory: string, type: string, branchId: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, displayField: string } | null };

export type GetScanFilesQueryVariables = Exact<{
  filter?: InputMaybe<ScanFileFilterArgs>;
  paging?: InputMaybe<PageArgs>;
  sorting?: InputMaybe<Array<ScanFileSortArgs> | ScanFileSortArgs>;
  withScan?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type GetScanFilesQuery = { __typename?: 'Query', scanFiles: { __typename?: 'PaginatedScanFile', totalCount: number, items?: Array<{ __typename?: 'ScanFile', id: string, name: string, contents: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, scan?: { __typename?: 'Scan', id: string, directory: string, type: string, branchId: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, displayField: string } | null }> | null } };

export type GetScanFileQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  withScan?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type GetScanFileQuery = { __typename?: 'Query', scanFile?: { __typename?: 'ScanFile', id: string, name: string, contents: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, scan?: { __typename?: 'Scan', id: string, directory: string, type: string, branchId: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, displayField: string } | null } | null };

export type GetAllRelatedDataForScanFileQueryVariables = Exact<{
  withScans?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type GetAllRelatedDataForScanFileQuery = { __typename?: 'Query', scans?: { __typename?: 'PaginatedScan', items?: Array<{ __typename?: 'Scan', id: string, displayField: string }> | null } };

export type CreateScanFileMutationVariables = Exact<{
  name: Scalars['String']['input'];
  contents: Scalars['String']['input'];
  scanId: Scalars['ID']['input'];
  withScan?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type CreateScanFileMutation = { __typename?: 'Mutation', createScanFile: { __typename?: 'ScanFile', id: string, name: string, contents: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, scan?: { __typename?: 'Scan', id: string, directory: string, type: string, branchId: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, displayField: string } | null } };

export type UpdateScanFileMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  contents?: InputMaybe<Scalars['String']['input']>;
  scanId?: InputMaybe<Scalars['ID']['input']>;
  withScan?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type UpdateScanFileMutation = { __typename?: 'Mutation', updateScanFile: { __typename?: 'ScanFile', id: string, name: string, contents: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, scan?: { __typename?: 'Scan', id: string, directory: string, type: string, branchId: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, displayField: string } | null } };

export type DeleteScanFileMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  withScan?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type DeleteScanFileMutation = { __typename?: 'Mutation', deleteScanFile: { __typename?: 'ScanFile', id: string, name: string, contents: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, scan?: { __typename?: 'Scan', id: string, directory: string, type: string, branchId: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, displayField: string } | null } };

export type CreatedScanFileSubscriptionVariables = Exact<{
  filter?: InputMaybe<ScanFileFilterArgs>;
  withScan?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type CreatedScanFileSubscription = { __typename?: 'Subscription', createdScanFile: { __typename?: 'ScanFile', id: string, name: string, contents: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, scan?: { __typename?: 'Scan', id: string, directory: string, type: string, branchId: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, displayField: string } | null } };

export type UpdatedScanFileSubscriptionVariables = Exact<{
  filter?: InputMaybe<ScanFileFilterArgs>;
  withScan?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type UpdatedScanFileSubscription = { __typename?: 'Subscription', updatedScanFile: { __typename?: 'ScanFile', id: string, name: string, contents: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, scan?: { __typename?: 'Scan', id: string, directory: string, type: string, branchId: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, displayField: string } | null } };

export type DeletedScanFileSubscriptionVariables = Exact<{
  filter?: InputMaybe<ScanFileFilterArgs>;
}>;


export type DeletedScanFileSubscription = { __typename?: 'Subscription', deletedScanFile: { __typename?: 'ScanFile', id: string } };

export type RestoredScanFileSubscriptionVariables = Exact<{
  filter?: InputMaybe<ScanFileFilterArgs>;
  withScan?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type RestoredScanFileSubscription = { __typename?: 'Subscription', restoredScanFile: { __typename?: 'ScanFile', id: string, name: string, contents: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, scan?: { __typename?: 'Scan', id: string, directory: string, type: string, branchId: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, displayField: string } | null } };

export type TenantFieldsFragment = { __typename?: 'Tenant', id: string, name: string, timeZone: string, userTenantRoles?: { __typename?: 'PaginatedUserTenantRole', totalCount: number, items?: Array<{ __typename?: 'UserTenantRole', id: string, userId: string, tenantId: string, roleId: string, displayField: string }> | null } };

export type GetTenantsQueryVariables = Exact<{
  filter?: InputMaybe<TenantFilterArgs>;
  paging?: InputMaybe<PageArgs>;
  sorting?: InputMaybe<Array<TenantSortArgs> | TenantSortArgs>;
  withUserTenantRoles?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type GetTenantsQuery = { __typename?: 'Query', tenants: { __typename?: 'PaginatedTenant', totalCount: number, items?: Array<{ __typename?: 'Tenant', id: string, name: string, timeZone: string, userTenantRoles?: { __typename?: 'PaginatedUserTenantRole', totalCount: number, items?: Array<{ __typename?: 'UserTenantRole', id: string, userId: string, tenantId: string, roleId: string, displayField: string }> | null } }> | null } };

export type GetTenantQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  withUserTenantRoles?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type GetTenantQuery = { __typename?: 'Query', tenant?: { __typename?: 'Tenant', id: string, name: string, timeZone: string, userTenantRoles?: { __typename?: 'PaginatedUserTenantRole', totalCount: number, items?: Array<{ __typename?: 'UserTenantRole', id: string, userId: string, tenantId: string, roleId: string, displayField: string }> | null } } | null };

export type GetAllRelatedDataForTenantQueryVariables = Exact<{
  withUserTenantRoles?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type GetAllRelatedDataForTenantQuery = { __typename?: 'Query', userTenantRoles?: { __typename?: 'PaginatedUserTenantRole', items?: Array<{ __typename?: 'UserTenantRole', id: string, displayField: string }> | null } };

export type CreateTenantMutationVariables = Exact<{
  name: Scalars['String']['input'];
  timeZone: Scalars['String']['input'];
  userTenantRoles?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>> | InputMaybe<Scalars['ID']['input']>>;
  withUserTenantRoles?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type CreateTenantMutation = { __typename?: 'Mutation', createTenant: { __typename?: 'Tenant', id: string, name: string, timeZone: string, userTenantRoles?: { __typename?: 'PaginatedUserTenantRole', totalCount: number, items?: Array<{ __typename?: 'UserTenantRole', id: string, userId: string, tenantId: string, roleId: string, displayField: string }> | null } } };

export type UpdateTenantMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  timeZone?: InputMaybe<Scalars['String']['input']>;
  userTenantRoles?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>> | InputMaybe<Scalars['ID']['input']>>;
  withUserTenantRoles?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type UpdateTenantMutation = { __typename?: 'Mutation', updateTenant: { __typename?: 'Tenant', id: string, name: string, timeZone: string, userTenantRoles?: { __typename?: 'PaginatedUserTenantRole', totalCount: number, items?: Array<{ __typename?: 'UserTenantRole', id: string, userId: string, tenantId: string, roleId: string, displayField: string }> | null } } };

export type DeleteTenantMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  withUserTenantRoles?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type DeleteTenantMutation = { __typename?: 'Mutation', deleteTenant: { __typename?: 'Tenant', id: string, name: string, timeZone: string, userTenantRoles?: { __typename?: 'PaginatedUserTenantRole', totalCount: number, items?: Array<{ __typename?: 'UserTenantRole', id: string, userId: string, tenantId: string, roleId: string, displayField: string }> | null } } };

export type CreatedTenantSubscriptionVariables = Exact<{
  filter?: InputMaybe<TenantFilterArgs>;
  withUserTenantRoles?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type CreatedTenantSubscription = { __typename?: 'Subscription', createdTenant: { __typename?: 'Tenant', id: string, name: string, timeZone: string, userTenantRoles?: { __typename?: 'PaginatedUserTenantRole', totalCount: number, items?: Array<{ __typename?: 'UserTenantRole', id: string, userId: string, tenantId: string, roleId: string, displayField: string }> | null } } };

export type UpdatedTenantSubscriptionVariables = Exact<{
  filter?: InputMaybe<TenantFilterArgs>;
  withUserTenantRoles?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type UpdatedTenantSubscription = { __typename?: 'Subscription', updatedTenant: { __typename?: 'Tenant', id: string, name: string, timeZone: string, userTenantRoles?: { __typename?: 'PaginatedUserTenantRole', totalCount: number, items?: Array<{ __typename?: 'UserTenantRole', id: string, userId: string, tenantId: string, roleId: string, displayField: string }> | null } } };

export type DeletedTenantSubscriptionVariables = Exact<{
  filter?: InputMaybe<TenantFilterArgs>;
}>;


export type DeletedTenantSubscription = { __typename?: 'Subscription', deletedTenant: { __typename?: 'Tenant', id: string } };

export type UserFieldsFragment = { __typename?: 'User', id: string, name: string, email: string, emailVerifiedAt?: any | null, active: boolean, avatar?: string | null, totpVerified?: boolean | null, termsAndConditions?: boolean | null, locale: string, timeZone: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, userTenantRoles?: { __typename?: 'PaginatedUserTenantRole', totalCount: number, items?: Array<{ __typename?: 'UserTenantRole', id: string, userId: string, tenantId: string, roleId: string, displayField: string }> | null }, notificationSettings?: { __typename?: 'PaginatedNotificationSetting', totalCount: number, items?: Array<{ __typename?: 'NotificationSetting', id: string, userId: string, gitRepositoryId: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, displayField: string }> | null } };

export type GetUsersQueryVariables = Exact<{
  filter?: InputMaybe<UserFilterArgs>;
  paging?: InputMaybe<PageArgs>;
  sorting?: InputMaybe<Array<UserSortArgs> | UserSortArgs>;
  withUserTenantRoles?: InputMaybe<Scalars['Boolean']['input']>;
  withNotificationSettings?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type GetUsersQuery = { __typename?: 'Query', users: { __typename?: 'PaginatedUser', totalCount: number, items?: Array<{ __typename?: 'User', id: string, name: string, email: string, emailVerifiedAt?: any | null, active: boolean, avatar?: string | null, totpVerified?: boolean | null, termsAndConditions?: boolean | null, locale: string, timeZone: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, userTenantRoles?: { __typename?: 'PaginatedUserTenantRole', totalCount: number, items?: Array<{ __typename?: 'UserTenantRole', id: string, userId: string, tenantId: string, roleId: string, displayField: string }> | null }, notificationSettings?: { __typename?: 'PaginatedNotificationSetting', totalCount: number, items?: Array<{ __typename?: 'NotificationSetting', id: string, userId: string, gitRepositoryId: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, displayField: string }> | null } }> | null } };

export type GetUserQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  withUserTenantRoles?: InputMaybe<Scalars['Boolean']['input']>;
  withNotificationSettings?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type GetUserQuery = { __typename?: 'Query', user?: { __typename?: 'User', id: string, name: string, email: string, emailVerifiedAt?: any | null, active: boolean, avatar?: string | null, totpVerified?: boolean | null, termsAndConditions?: boolean | null, locale: string, timeZone: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, userTenantRoles?: { __typename?: 'PaginatedUserTenantRole', totalCount: number, items?: Array<{ __typename?: 'UserTenantRole', id: string, userId: string, tenantId: string, roleId: string, displayField: string }> | null }, notificationSettings?: { __typename?: 'PaginatedNotificationSetting', totalCount: number, items?: Array<{ __typename?: 'NotificationSetting', id: string, userId: string, gitRepositoryId: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, displayField: string }> | null } } | null };

export type GetAllRelatedDataForUserQueryVariables = Exact<{
  withUserTenantRoles?: InputMaybe<Scalars['Boolean']['input']>;
  withNotificationSettings?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type GetAllRelatedDataForUserQuery = { __typename?: 'Query', userTenantRoles?: { __typename?: 'PaginatedUserTenantRole', items?: Array<{ __typename?: 'UserTenantRole', id: string, displayField: string }> | null }, notificationSettings?: { __typename?: 'PaginatedNotificationSetting', items?: Array<{ __typename?: 'NotificationSetting', id: string, displayField: string }> | null } };

export type CreateUserMutationVariables = Exact<{
  name: Scalars['String']['input'];
  email: Scalars['String']['input'];
  active: Scalars['Boolean']['input'];
  avatar?: InputMaybe<Scalars['String']['input']>;
  termsAndConditions?: InputMaybe<Scalars['Boolean']['input']>;
  locale: Scalars['String']['input'];
  timeZone: Scalars['String']['input'];
  userTenantRoles?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>> | InputMaybe<Scalars['ID']['input']>>;
  notificationSettings?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>> | InputMaybe<Scalars['ID']['input']>>;
  withUserTenantRoles?: InputMaybe<Scalars['Boolean']['input']>;
  withNotificationSettings?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type CreateUserMutation = { __typename?: 'Mutation', createUser: { __typename?: 'User', id: string, name: string, email: string, emailVerifiedAt?: any | null, active: boolean, avatar?: string | null, totpVerified?: boolean | null, termsAndConditions?: boolean | null, locale: string, timeZone: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, userTenantRoles?: { __typename?: 'PaginatedUserTenantRole', totalCount: number, items?: Array<{ __typename?: 'UserTenantRole', id: string, userId: string, tenantId: string, roleId: string, displayField: string }> | null }, notificationSettings?: { __typename?: 'PaginatedNotificationSetting', totalCount: number, items?: Array<{ __typename?: 'NotificationSetting', id: string, userId: string, gitRepositoryId: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, displayField: string }> | null } } };

export type UpdateUserMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  active?: InputMaybe<Scalars['Boolean']['input']>;
  avatar?: InputMaybe<Scalars['String']['input']>;
  termsAndConditions?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  timeZone?: InputMaybe<Scalars['String']['input']>;
  userTenantRoles?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>> | InputMaybe<Scalars['ID']['input']>>;
  notificationSettings?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>> | InputMaybe<Scalars['ID']['input']>>;
  withUserTenantRoles?: InputMaybe<Scalars['Boolean']['input']>;
  withNotificationSettings?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', updateUser: { __typename?: 'User', id: string, name: string, email: string, emailVerifiedAt?: any | null, active: boolean, avatar?: string | null, totpVerified?: boolean | null, termsAndConditions?: boolean | null, locale: string, timeZone: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, userTenantRoles?: { __typename?: 'PaginatedUserTenantRole', totalCount: number, items?: Array<{ __typename?: 'UserTenantRole', id: string, userId: string, tenantId: string, roleId: string, displayField: string }> | null }, notificationSettings?: { __typename?: 'PaginatedNotificationSetting', totalCount: number, items?: Array<{ __typename?: 'NotificationSetting', id: string, userId: string, gitRepositoryId: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, displayField: string }> | null } } };

export type DeleteUserMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  withUserTenantRoles?: InputMaybe<Scalars['Boolean']['input']>;
  withNotificationSettings?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type DeleteUserMutation = { __typename?: 'Mutation', deleteUser: { __typename?: 'User', id: string, name: string, email: string, emailVerifiedAt?: any | null, active: boolean, avatar?: string | null, totpVerified?: boolean | null, termsAndConditions?: boolean | null, locale: string, timeZone: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, userTenantRoles?: { __typename?: 'PaginatedUserTenantRole', totalCount: number, items?: Array<{ __typename?: 'UserTenantRole', id: string, userId: string, tenantId: string, roleId: string, displayField: string }> | null }, notificationSettings?: { __typename?: 'PaginatedNotificationSetting', totalCount: number, items?: Array<{ __typename?: 'NotificationSetting', id: string, userId: string, gitRepositoryId: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, displayField: string }> | null } } };

export type CreatedUserSubscriptionVariables = Exact<{
  filter?: InputMaybe<UserFilterArgs>;
  withUserTenantRoles?: InputMaybe<Scalars['Boolean']['input']>;
  withNotificationSettings?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type CreatedUserSubscription = { __typename?: 'Subscription', createdUser: { __typename?: 'User', id: string, name: string, email: string, emailVerifiedAt?: any | null, active: boolean, avatar?: string | null, totpVerified?: boolean | null, termsAndConditions?: boolean | null, locale: string, timeZone: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, userTenantRoles?: { __typename?: 'PaginatedUserTenantRole', totalCount: number, items?: Array<{ __typename?: 'UserTenantRole', id: string, userId: string, tenantId: string, roleId: string, displayField: string }> | null }, notificationSettings?: { __typename?: 'PaginatedNotificationSetting', totalCount: number, items?: Array<{ __typename?: 'NotificationSetting', id: string, userId: string, gitRepositoryId: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, displayField: string }> | null } } };

export type UpdatedUserSubscriptionVariables = Exact<{
  filter?: InputMaybe<UserFilterArgs>;
  withUserTenantRoles?: InputMaybe<Scalars['Boolean']['input']>;
  withNotificationSettings?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type UpdatedUserSubscription = { __typename?: 'Subscription', updatedUser: { __typename?: 'User', id: string, name: string, email: string, emailVerifiedAt?: any | null, active: boolean, avatar?: string | null, totpVerified?: boolean | null, termsAndConditions?: boolean | null, locale: string, timeZone: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, userTenantRoles?: { __typename?: 'PaginatedUserTenantRole', totalCount: number, items?: Array<{ __typename?: 'UserTenantRole', id: string, userId: string, tenantId: string, roleId: string, displayField: string }> | null }, notificationSettings?: { __typename?: 'PaginatedNotificationSetting', totalCount: number, items?: Array<{ __typename?: 'NotificationSetting', id: string, userId: string, gitRepositoryId: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, displayField: string }> | null } } };

export type DeletedUserSubscriptionVariables = Exact<{
  filter?: InputMaybe<UserFilterArgs>;
}>;


export type DeletedUserSubscription = { __typename?: 'Subscription', deletedUser: { __typename?: 'User', id: string } };

export type RestoredUserSubscriptionVariables = Exact<{
  filter?: InputMaybe<UserFilterArgs>;
  withUserTenantRoles?: InputMaybe<Scalars['Boolean']['input']>;
  withNotificationSettings?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type RestoredUserSubscription = { __typename?: 'Subscription', restoredUser: { __typename?: 'User', id: string, name: string, email: string, emailVerifiedAt?: any | null, active: boolean, avatar?: string | null, totpVerified?: boolean | null, termsAndConditions?: boolean | null, locale: string, timeZone: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, userTenantRoles?: { __typename?: 'PaginatedUserTenantRole', totalCount: number, items?: Array<{ __typename?: 'UserTenantRole', id: string, userId: string, tenantId: string, roleId: string, displayField: string }> | null }, notificationSettings?: { __typename?: 'PaginatedNotificationSetting', totalCount: number, items?: Array<{ __typename?: 'NotificationSetting', id: string, userId: string, gitRepositoryId: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, displayField: string }> | null } } };

export type UserTenantRoleFieldsFragment = { __typename?: 'UserTenantRole', id: string, user?: { __typename?: 'User', id: string, name: string, email: string, emailVerifiedAt?: any | null, active: boolean, avatar?: string | null, totpVerified?: boolean | null, termsAndConditions?: boolean | null, locale: string, timeZone: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, displayField: string } | null, tenant?: { __typename?: 'Tenant', id: string, name: string, timeZone: string, displayField: string }, role?: { __typename?: 'Role', id: string, machineName: string, displayName: string, description: string, displayField: string } };

export type GetUserTenantRolesQueryVariables = Exact<{
  filter?: InputMaybe<UserTenantRoleFilterArgs>;
  paging?: InputMaybe<PageArgs>;
  sorting?: InputMaybe<Array<UserTenantRoleSortArgs> | UserTenantRoleSortArgs>;
  withUser?: InputMaybe<Scalars['Boolean']['input']>;
  withTenant?: InputMaybe<Scalars['Boolean']['input']>;
  withRole?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type GetUserTenantRolesQuery = { __typename?: 'Query', userTenantRoles: { __typename?: 'PaginatedUserTenantRole', totalCount: number, items?: Array<{ __typename?: 'UserTenantRole', id: string, user?: { __typename?: 'User', id: string, name: string, email: string, emailVerifiedAt?: any | null, active: boolean, avatar?: string | null, totpVerified?: boolean | null, termsAndConditions?: boolean | null, locale: string, timeZone: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, displayField: string } | null, tenant?: { __typename?: 'Tenant', id: string, name: string, timeZone: string, displayField: string }, role?: { __typename?: 'Role', id: string, machineName: string, displayName: string, description: string, displayField: string } }> | null } };

export type GetUserTenantRoleQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  withUser?: InputMaybe<Scalars['Boolean']['input']>;
  withTenant?: InputMaybe<Scalars['Boolean']['input']>;
  withRole?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type GetUserTenantRoleQuery = { __typename?: 'Query', userTenantRole?: { __typename?: 'UserTenantRole', id: string, user?: { __typename?: 'User', id: string, name: string, email: string, emailVerifiedAt?: any | null, active: boolean, avatar?: string | null, totpVerified?: boolean | null, termsAndConditions?: boolean | null, locale: string, timeZone: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, displayField: string } | null, tenant?: { __typename?: 'Tenant', id: string, name: string, timeZone: string, displayField: string }, role?: { __typename?: 'Role', id: string, machineName: string, displayName: string, description: string, displayField: string } } | null };

export type GetAllRelatedDataForUserTenantRoleQueryVariables = Exact<{
  withUsers?: InputMaybe<Scalars['Boolean']['input']>;
  withTenants?: InputMaybe<Scalars['Boolean']['input']>;
  withRoles?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type GetAllRelatedDataForUserTenantRoleQuery = { __typename?: 'Query', users?: { __typename?: 'PaginatedUser', items?: Array<{ __typename?: 'User', id: string, displayField: string }> | null }, tenants?: { __typename?: 'PaginatedTenant', items?: Array<{ __typename?: 'Tenant', id: string, displayField: string }> | null }, roles?: { __typename?: 'PaginatedRole', items?: Array<{ __typename?: 'Role', id: string, displayField: string }> | null } };

export type CreateUserTenantRoleMutationVariables = Exact<{
  userId: Scalars['ID']['input'];
  tenantId: Scalars['ID']['input'];
  roleId: Scalars['ID']['input'];
  withUser?: InputMaybe<Scalars['Boolean']['input']>;
  withTenant?: InputMaybe<Scalars['Boolean']['input']>;
  withRole?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type CreateUserTenantRoleMutation = { __typename?: 'Mutation', createUserTenantRole: { __typename?: 'UserTenantRole', id: string, user?: { __typename?: 'User', id: string, name: string, email: string, emailVerifiedAt?: any | null, active: boolean, avatar?: string | null, totpVerified?: boolean | null, termsAndConditions?: boolean | null, locale: string, timeZone: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, displayField: string } | null, tenant?: { __typename?: 'Tenant', id: string, name: string, timeZone: string, displayField: string }, role?: { __typename?: 'Role', id: string, machineName: string, displayName: string, description: string, displayField: string } } };

export type UpdateUserTenantRoleMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  userId?: InputMaybe<Scalars['ID']['input']>;
  tenantId?: InputMaybe<Scalars['ID']['input']>;
  roleId?: InputMaybe<Scalars['ID']['input']>;
  withUser?: InputMaybe<Scalars['Boolean']['input']>;
  withTenant?: InputMaybe<Scalars['Boolean']['input']>;
  withRole?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type UpdateUserTenantRoleMutation = { __typename?: 'Mutation', updateUserTenantRole: { __typename?: 'UserTenantRole', id: string, user?: { __typename?: 'User', id: string, name: string, email: string, emailVerifiedAt?: any | null, active: boolean, avatar?: string | null, totpVerified?: boolean | null, termsAndConditions?: boolean | null, locale: string, timeZone: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, displayField: string } | null, tenant?: { __typename?: 'Tenant', id: string, name: string, timeZone: string, displayField: string }, role?: { __typename?: 'Role', id: string, machineName: string, displayName: string, description: string, displayField: string } } };

export type DeleteUserTenantRoleMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  withUser?: InputMaybe<Scalars['Boolean']['input']>;
  withTenant?: InputMaybe<Scalars['Boolean']['input']>;
  withRole?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type DeleteUserTenantRoleMutation = { __typename?: 'Mutation', deleteUserTenantRole: { __typename?: 'UserTenantRole', id: string, user?: { __typename?: 'User', id: string, name: string, email: string, emailVerifiedAt?: any | null, active: boolean, avatar?: string | null, totpVerified?: boolean | null, termsAndConditions?: boolean | null, locale: string, timeZone: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, displayField: string } | null, tenant?: { __typename?: 'Tenant', id: string, name: string, timeZone: string, displayField: string }, role?: { __typename?: 'Role', id: string, machineName: string, displayName: string, description: string, displayField: string } } };

export type CreatedUserTenantRoleSubscriptionVariables = Exact<{
  filter?: InputMaybe<UserTenantRoleFilterArgs>;
  withUser?: InputMaybe<Scalars['Boolean']['input']>;
  withTenant?: InputMaybe<Scalars['Boolean']['input']>;
  withRole?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type CreatedUserTenantRoleSubscription = { __typename?: 'Subscription', createdUserTenantRole: { __typename?: 'UserTenantRole', id: string, user?: { __typename?: 'User', id: string, name: string, email: string, emailVerifiedAt?: any | null, active: boolean, avatar?: string | null, totpVerified?: boolean | null, termsAndConditions?: boolean | null, locale: string, timeZone: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, displayField: string } | null, tenant?: { __typename?: 'Tenant', id: string, name: string, timeZone: string, displayField: string }, role?: { __typename?: 'Role', id: string, machineName: string, displayName: string, description: string, displayField: string } } };

export type UpdatedUserTenantRoleSubscriptionVariables = Exact<{
  filter?: InputMaybe<UserTenantRoleFilterArgs>;
  withUser?: InputMaybe<Scalars['Boolean']['input']>;
  withTenant?: InputMaybe<Scalars['Boolean']['input']>;
  withRole?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type UpdatedUserTenantRoleSubscription = { __typename?: 'Subscription', updatedUserTenantRole: { __typename?: 'UserTenantRole', id: string, user?: { __typename?: 'User', id: string, name: string, email: string, emailVerifiedAt?: any | null, active: boolean, avatar?: string | null, totpVerified?: boolean | null, termsAndConditions?: boolean | null, locale: string, timeZone: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, displayField: string } | null, tenant?: { __typename?: 'Tenant', id: string, name: string, timeZone: string, displayField: string }, role?: { __typename?: 'Role', id: string, machineName: string, displayName: string, description: string, displayField: string } } };

export type DeletedUserTenantRoleSubscriptionVariables = Exact<{
  filter?: InputMaybe<UserTenantRoleFilterArgs>;
}>;


export type DeletedUserTenantRoleSubscription = { __typename?: 'Subscription', deletedUserTenantRole: { __typename?: 'UserTenantRole', id: string } };

export type GetFullUserQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetFullUserQuery = { __typename?: 'Query', user?: { __typename?: 'User', id: string, name: string, email: string, emailVerifiedAt?: any | null, active: boolean, avatar?: string | null, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, userTenantRoles: { __typename?: 'PaginatedUserTenantRole', totalCount: number, items?: Array<{ __typename?: 'UserTenantRole', id: string, userId: string, tenantId: string, roleId: string, displayField: string, role: { __typename?: 'Role', id: string, machineName: string, displayField: string }, tenant: { __typename?: 'Tenant', id: string, name: string, displayField: string } }> | null } } | null };

export type CreateUserWithTenantRoleMutationVariables = Exact<{
  name: Scalars['String']['input'];
  email: Scalars['String']['input'];
  active: Scalars['Boolean']['input'];
  avatar?: InputMaybe<Scalars['String']['input']>;
  termsAndConditions?: InputMaybe<Scalars['Boolean']['input']>;
  userTenantRoles?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>> | InputMaybe<Scalars['ID']['input']>>;
  tenantId?: InputMaybe<Scalars['String']['input']>;
  roleId?: InputMaybe<Scalars['String']['input']>;
  invite?: InputMaybe<Scalars['Boolean']['input']>;
  locale: Scalars['String']['input'];
  timeZone: Scalars['String']['input'];
  notificationSettings?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>> | InputMaybe<Scalars['ID']['input']>>;
  withUserTenantRoles?: InputMaybe<Scalars['Boolean']['input']>;
  withNotificationSettings?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type CreateUserWithTenantRoleMutation = { __typename?: 'Mutation', createUser: { __typename?: 'User', id: string, name: string, email: string, emailVerifiedAt?: any | null, active: boolean, avatar?: string | null, totpVerified?: boolean | null, termsAndConditions?: boolean | null, locale: string, timeZone: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, userTenantRoles?: { __typename?: 'PaginatedUserTenantRole', totalCount: number, items?: Array<{ __typename?: 'UserTenantRole', id: string, userId: string, tenantId: string, roleId: string, displayField: string }> | null }, notificationSettings?: { __typename?: 'PaginatedNotificationSetting', totalCount: number, items?: Array<{ __typename?: 'NotificationSetting', id: string, userId: string, gitRepositoryId: string, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, displayField: string }> | null } } };

export type CreateContentWithFillMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  fill: Scalars['String']['input'];
  language: Scalars['String']['input'];
}>;


export type CreateContentWithFillMutation = { __typename?: 'Mutation', createContentWithFill: boolean };

export type UpdateContentWithFillMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  fill: Scalars['String']['input'];
  language: Scalars['String']['input'];
}>;


export type UpdateContentWithFillMutation = { __typename?: 'Mutation', updateContentWithFill: boolean };

export type GetUserNotificationSettingsQueryVariables = Exact<{
  userId?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetUserNotificationSettingsQuery = { __typename?: 'Query', notificationSettings: { __typename?: 'PaginatedNotificationSetting', items?: Array<{ __typename?: 'NotificationSetting', id: string, gitRepositoryId: string }> | null } };

export type DeleteUserNotificationSettingMutationVariables = Exact<{
  gitRepositoryId: Scalars['ID']['input'];
}>;


export type DeleteUserNotificationSettingMutation = { __typename?: 'Mutation', deleteUserNotificationSetting: boolean };

export type GetAllRepositoryAdvisoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllRepositoryAdvisoriesQuery = { __typename?: 'Query', gitRepositories: { __typename?: 'PaginatedGitRepository', items?: Array<{ __typename?: 'GitRepository', id: string, name: string, branches: { __typename?: 'PaginatedBranch', items?: Array<{ __typename?: 'Branch', id: string, name: string, scans: { __typename?: 'PaginatedScan', items?: Array<{ __typename?: 'Scan', id: string, directory: string, type: string, updatedAt?: any | null, advisories: { __typename?: 'PaginatedAdvisory', items?: Array<{ __typename?: 'Advisory', id: string, package: string, parentPackage?: string | null, patchedVersion?: string | null, severity?: string | null, title: string, version: string }> | null } }> | null } }> | null } }> | null } };

export const AdvisoryFieldsFragmentDoc = gql`
    fragment advisoryFields on Advisory {
  id
  externalId
  title
  package
  parentPackage
  version
  patchedVersion
  severity
  raw
  scan @include(if: $withScan) {
    id
    directory
    type
    branchId
    createdAt
    updatedAt
    deletedAt
    displayField: id
  }
  createdAt
  updatedAt
  deletedAt
}
    `;
export const BranchFieldsFragmentDoc = gql`
    fragment branchFields on Branch {
  id
  name
  gitRepository @include(if: $withGitRepository) {
    id
    name
    createdAt
    updatedAt
    deletedAt
    displayField: name
  }
  scans @include(if: $withScans) {
    items {
      id
      directory
      type
      branchId
      createdAt
      updatedAt
      deletedAt
      displayField: id
    }
    totalCount
  }
  createdAt
  updatedAt
  deletedAt
}
    `;
export const ContentFieldsFragmentDoc = gql`
    fragment contentFields on Content {
  id
  name
  contentFills @include(if: $withContentFills) {
    items {
      id
      fill
      language
      contentId
      displayField: id
    }
    totalCount
  }
}
    `;
export const ContentFillFieldsFragmentDoc = gql`
    fragment contentFillFields on ContentFill {
  id
  fill
  language
  content @include(if: $withContent) {
    id
    name
    displayField: name
  }
}
    `;
export const ErrorLogFieldsFragmentDoc = gql`
    fragment errorLogFields on ErrorLog {
  id
  error
  stack
  domain
  createdAt
  updatedAt
  deletedAt
}
    `;
export const EventLogFieldsFragmentDoc = gql`
    fragment eventLogFields on EventLog {
  id
  log
  type
  createdAt
  updatedAt
  deletedAt
}
    `;
export const GitRepositoryFieldsFragmentDoc = gql`
    fragment gitRepositoryFields on GitRepository {
  id
  name
  branches @include(if: $withBranches) {
    items {
      id
      name
      gitRepositoryId
      createdAt
      updatedAt
      deletedAt
      displayField: name
    }
    totalCount
  }
  notificationSettings @include(if: $withNotificationSettings) {
    items {
      id
      userId
      gitRepositoryId
      createdAt
      updatedAt
      deletedAt
      displayField: id
    }
    totalCount
  }
  createdAt
  updatedAt
  deletedAt
}
    `;
export const JobFieldsFragmentDoc = gql`
    fragment jobFields on Job {
  id
  queue
  status
  log
  createdAt
  updatedAt
  deletedAt
}
    `;
export const MailLogFieldsFragmentDoc = gql`
    fragment mailLogFields on MailLog {
  id
  sentTo
  sentBy
  emailTemplate
  status
  message
  reason
  createdAt
  updatedAt
  deletedAt
}
    `;
export const MailTemplateFieldsFragmentDoc = gql`
    fragment mailTemplateFields on MailTemplate {
  id
  active
  mailTemplateFills @include(if: $withMailTemplateFills) {
    items {
      id
      subject
      urlText
      title
      heading
      preHeader
      disclaimer
      text
      plainText
      language
      mailTemplateId
      displayField: title
    }
    totalCount
  }
}
    `;
export const MailTemplateFillFieldsFragmentDoc = gql`
    fragment mailTemplateFillFields on MailTemplateFill {
  id
  subject
  urlText
  title
  heading
  preHeader
  disclaimer
  text
  plainText
  language
  mailTemplate @include(if: $withMailTemplate) {
    id
    active
    displayField: id
  }
}
    `;
export const NotificationSettingFieldsFragmentDoc = gql`
    fragment notificationSettingFields on NotificationSetting {
  id
  user @include(if: $withUser) {
    id
    name
    email
    emailVerifiedAt
    active
    avatar
    totpVerified
    termsAndConditions
    locale
    timeZone
    createdAt
    updatedAt
    deletedAt
    displayField: name
  }
  gitRepository @include(if: $withGitRepository) {
    id
    name
    createdAt
    updatedAt
    deletedAt
    displayField: name
  }
  createdAt
  updatedAt
  deletedAt
}
    `;
export const PermissionFieldsFragmentDoc = gql`
    fragment permissionFields on Permission {
  id
  name
  description
  roles @include(if: $withRoles) {
    items {
      id
      machineName
      displayName
      description
      displayField: displayName
    }
    totalCount
  }
}
    `;
export const ResetPasswordFieldsFragmentDoc = gql`
    fragment resetPasswordFields on ResetPassword {
  id
  email
  token
  type
  createdAt
  updatedAt
  deletedAt
}
    `;
export const RoleFieldsFragmentDoc = gql`
    fragment roleFields on Role {
  id
  machineName
  displayName
  description
  permissions @include(if: $withPermissions) {
    items {
      id
      name
      description
      displayField: name
    }
    totalCount
  }
  userTenantRoles @include(if: $withUserTenantRoles) {
    items {
      id
      userId
      tenantId
      roleId
      displayField: id
    }
    totalCount
  }
}
    `;
export const ScanFieldsFragmentDoc = gql`
    fragment scanFields on Scan {
  id
  directory
  type
  branch @include(if: $withBranch) {
    id
    name
    gitRepositoryId
    createdAt
    updatedAt
    deletedAt
    displayField: name
  }
  advisories @include(if: $withAdvisories) {
    items {
      id
      externalId
      title
      package
      parentPackage
      version
      patchedVersion
      severity
      raw
      scanId
      createdAt
      updatedAt
      deletedAt
      displayField: title
    }
    totalCount
  }
  scanFiles @include(if: $withScanFiles) {
    items {
      id
      name
      contents
      scanId
      createdAt
      updatedAt
      deletedAt
      displayField: name
    }
    totalCount
  }
  createdAt
  updatedAt
  deletedAt
}
    `;
export const ScanFileFieldsFragmentDoc = gql`
    fragment scanFileFields on ScanFile {
  id
  name
  contents
  scan @include(if: $withScan) {
    id
    directory
    type
    branchId
    createdAt
    updatedAt
    deletedAt
    displayField: id
  }
  createdAt
  updatedAt
  deletedAt
}
    `;
export const TenantFieldsFragmentDoc = gql`
    fragment tenantFields on Tenant {
  id
  name
  timeZone
  userTenantRoles @include(if: $withUserTenantRoles) {
    items {
      id
      userId
      tenantId
      roleId
      displayField: id
    }
    totalCount
  }
}
    `;
export const UserFieldsFragmentDoc = gql`
    fragment userFields on User {
  id
  name
  email
  emailVerifiedAt
  active
  avatar
  totpVerified
  termsAndConditions
  locale
  timeZone
  userTenantRoles @include(if: $withUserTenantRoles) {
    items {
      id
      userId
      tenantId
      roleId
      displayField: id
    }
    totalCount
  }
  createdAt
  updatedAt
  deletedAt
  notificationSettings @include(if: $withNotificationSettings) {
    items {
      id
      userId
      gitRepositoryId
      createdAt
      updatedAt
      deletedAt
      displayField: id
    }
    totalCount
  }
}
    `;
export const UserTenantRoleFieldsFragmentDoc = gql`
    fragment userTenantRoleFields on UserTenantRole {
  id
  user @include(if: $withUser) {
    id
    name
    email
    emailVerifiedAt
    active
    avatar
    totpVerified
    termsAndConditions
    locale
    timeZone
    createdAt
    updatedAt
    deletedAt
    displayField: name
  }
  tenant @include(if: $withTenant) {
    id
    name
    timeZone
    displayField: name
  }
  role @include(if: $withRole) {
    id
    machineName
    displayName
    description
    displayField: displayName
  }
}
    `;
export const GetAdvisoriesDocument = gql`
    query GetAdvisories($filter: AdvisoryFilterArgs, $paging: PageArgs, $sorting: [AdvisorySortArgs!], $withScan: Boolean = false) {
  advisories(filter: $filter, paging: $paging, sorting: $sorting) {
    items {
      ...advisoryFields
    }
    totalCount
  }
}
    ${AdvisoryFieldsFragmentDoc}`;

/**
 * __useGetAdvisoriesQuery__
 *
 * To run a query within a React component, call `useGetAdvisoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdvisoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdvisoriesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      paging: // value for 'paging'
 *      sorting: // value for 'sorting'
 *      withScan: // value for 'withScan'
 *   },
 * });
 */
export function useGetAdvisoriesQuery(baseOptions?: Apollo.QueryHookOptions<GetAdvisoriesQuery, GetAdvisoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAdvisoriesQuery, GetAdvisoriesQueryVariables>(GetAdvisoriesDocument, options);
      }
export function useGetAdvisoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAdvisoriesQuery, GetAdvisoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAdvisoriesQuery, GetAdvisoriesQueryVariables>(GetAdvisoriesDocument, options);
        }
export function useGetAdvisoriesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetAdvisoriesQuery, GetAdvisoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAdvisoriesQuery, GetAdvisoriesQueryVariables>(GetAdvisoriesDocument, options);
        }
export type GetAdvisoriesQueryHookResult = ReturnType<typeof useGetAdvisoriesQuery>;
export type GetAdvisoriesLazyQueryHookResult = ReturnType<typeof useGetAdvisoriesLazyQuery>;
export type GetAdvisoriesSuspenseQueryHookResult = ReturnType<typeof useGetAdvisoriesSuspenseQuery>;
export type GetAdvisoriesQueryResult = Apollo.QueryResult<GetAdvisoriesQuery, GetAdvisoriesQueryVariables>;
export const GetAdvisoryDocument = gql`
    query GetAdvisory($id: ID!, $withScan: Boolean = false) {
  advisory(id: $id) {
    ...advisoryFields
  }
}
    ${AdvisoryFieldsFragmentDoc}`;

/**
 * __useGetAdvisoryQuery__
 *
 * To run a query within a React component, call `useGetAdvisoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdvisoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdvisoryQuery({
 *   variables: {
 *      id: // value for 'id'
 *      withScan: // value for 'withScan'
 *   },
 * });
 */
export function useGetAdvisoryQuery(baseOptions: Apollo.QueryHookOptions<GetAdvisoryQuery, GetAdvisoryQueryVariables> & ({ variables: GetAdvisoryQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAdvisoryQuery, GetAdvisoryQueryVariables>(GetAdvisoryDocument, options);
      }
export function useGetAdvisoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAdvisoryQuery, GetAdvisoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAdvisoryQuery, GetAdvisoryQueryVariables>(GetAdvisoryDocument, options);
        }
export function useGetAdvisorySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetAdvisoryQuery, GetAdvisoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAdvisoryQuery, GetAdvisoryQueryVariables>(GetAdvisoryDocument, options);
        }
export type GetAdvisoryQueryHookResult = ReturnType<typeof useGetAdvisoryQuery>;
export type GetAdvisoryLazyQueryHookResult = ReturnType<typeof useGetAdvisoryLazyQuery>;
export type GetAdvisorySuspenseQueryHookResult = ReturnType<typeof useGetAdvisorySuspenseQuery>;
export type GetAdvisoryQueryResult = Apollo.QueryResult<GetAdvisoryQuery, GetAdvisoryQueryVariables>;
export const GetAllRelatedDataForAdvisoryDocument = gql`
    query GetAllRelatedDataForAdvisory($withScans: Boolean = false) {
  scans @include(if: $withScans) {
    items {
      id
      displayField: id
    }
  }
}
    `;

/**
 * __useGetAllRelatedDataForAdvisoryQuery__
 *
 * To run a query within a React component, call `useGetAllRelatedDataForAdvisoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllRelatedDataForAdvisoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllRelatedDataForAdvisoryQuery({
 *   variables: {
 *      withScans: // value for 'withScans'
 *   },
 * });
 */
export function useGetAllRelatedDataForAdvisoryQuery(baseOptions?: Apollo.QueryHookOptions<GetAllRelatedDataForAdvisoryQuery, GetAllRelatedDataForAdvisoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllRelatedDataForAdvisoryQuery, GetAllRelatedDataForAdvisoryQueryVariables>(GetAllRelatedDataForAdvisoryDocument, options);
      }
export function useGetAllRelatedDataForAdvisoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllRelatedDataForAdvisoryQuery, GetAllRelatedDataForAdvisoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllRelatedDataForAdvisoryQuery, GetAllRelatedDataForAdvisoryQueryVariables>(GetAllRelatedDataForAdvisoryDocument, options);
        }
export function useGetAllRelatedDataForAdvisorySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetAllRelatedDataForAdvisoryQuery, GetAllRelatedDataForAdvisoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAllRelatedDataForAdvisoryQuery, GetAllRelatedDataForAdvisoryQueryVariables>(GetAllRelatedDataForAdvisoryDocument, options);
        }
export type GetAllRelatedDataForAdvisoryQueryHookResult = ReturnType<typeof useGetAllRelatedDataForAdvisoryQuery>;
export type GetAllRelatedDataForAdvisoryLazyQueryHookResult = ReturnType<typeof useGetAllRelatedDataForAdvisoryLazyQuery>;
export type GetAllRelatedDataForAdvisorySuspenseQueryHookResult = ReturnType<typeof useGetAllRelatedDataForAdvisorySuspenseQuery>;
export type GetAllRelatedDataForAdvisoryQueryResult = Apollo.QueryResult<GetAllRelatedDataForAdvisoryQuery, GetAllRelatedDataForAdvisoryQueryVariables>;
export const CreateAdvisoryDocument = gql`
    mutation CreateAdvisory($externalId: String!, $title: String!, $package: String!, $parentPackage: String, $version: String!, $patchedVersion: String, $severity: String, $raw: String!, $scanId: ID!, $withScan: Boolean = false) {
  createAdvisory(
    externalId: $externalId
    title: $title
    package: $package
    parentPackage: $parentPackage
    version: $version
    patchedVersion: $patchedVersion
    severity: $severity
    raw: $raw
    scanId: $scanId
  ) {
    ...advisoryFields
  }
}
    ${AdvisoryFieldsFragmentDoc}`;
export type CreateAdvisoryMutationFn = Apollo.MutationFunction<CreateAdvisoryMutation, CreateAdvisoryMutationVariables>;

/**
 * __useCreateAdvisoryMutation__
 *
 * To run a mutation, you first call `useCreateAdvisoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAdvisoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAdvisoryMutation, { data, loading, error }] = useCreateAdvisoryMutation({
 *   variables: {
 *      externalId: // value for 'externalId'
 *      title: // value for 'title'
 *      package: // value for 'package'
 *      parentPackage: // value for 'parentPackage'
 *      version: // value for 'version'
 *      patchedVersion: // value for 'patchedVersion'
 *      severity: // value for 'severity'
 *      raw: // value for 'raw'
 *      scanId: // value for 'scanId'
 *      withScan: // value for 'withScan'
 *   },
 * });
 */
export function useCreateAdvisoryMutation(baseOptions?: Apollo.MutationHookOptions<CreateAdvisoryMutation, CreateAdvisoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAdvisoryMutation, CreateAdvisoryMutationVariables>(CreateAdvisoryDocument, options);
      }
export type CreateAdvisoryMutationHookResult = ReturnType<typeof useCreateAdvisoryMutation>;
export type CreateAdvisoryMutationResult = Apollo.MutationResult<CreateAdvisoryMutation>;
export type CreateAdvisoryMutationOptions = Apollo.BaseMutationOptions<CreateAdvisoryMutation, CreateAdvisoryMutationVariables>;
export const UpdateAdvisoryDocument = gql`
    mutation UpdateAdvisory($id: ID!, $externalId: String, $title: String, $package: String, $parentPackage: String, $version: String, $patchedVersion: String, $severity: String, $raw: String, $scanId: ID, $withScan: Boolean = false) {
  updateAdvisory(
    id: $id
    externalId: $externalId
    title: $title
    package: $package
    parentPackage: $parentPackage
    version: $version
    patchedVersion: $patchedVersion
    severity: $severity
    raw: $raw
    scanId: $scanId
  ) {
    ...advisoryFields
  }
}
    ${AdvisoryFieldsFragmentDoc}`;
export type UpdateAdvisoryMutationFn = Apollo.MutationFunction<UpdateAdvisoryMutation, UpdateAdvisoryMutationVariables>;

/**
 * __useUpdateAdvisoryMutation__
 *
 * To run a mutation, you first call `useUpdateAdvisoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAdvisoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAdvisoryMutation, { data, loading, error }] = useUpdateAdvisoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *      externalId: // value for 'externalId'
 *      title: // value for 'title'
 *      package: // value for 'package'
 *      parentPackage: // value for 'parentPackage'
 *      version: // value for 'version'
 *      patchedVersion: // value for 'patchedVersion'
 *      severity: // value for 'severity'
 *      raw: // value for 'raw'
 *      scanId: // value for 'scanId'
 *      withScan: // value for 'withScan'
 *   },
 * });
 */
export function useUpdateAdvisoryMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAdvisoryMutation, UpdateAdvisoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAdvisoryMutation, UpdateAdvisoryMutationVariables>(UpdateAdvisoryDocument, options);
      }
export type UpdateAdvisoryMutationHookResult = ReturnType<typeof useUpdateAdvisoryMutation>;
export type UpdateAdvisoryMutationResult = Apollo.MutationResult<UpdateAdvisoryMutation>;
export type UpdateAdvisoryMutationOptions = Apollo.BaseMutationOptions<UpdateAdvisoryMutation, UpdateAdvisoryMutationVariables>;
export const DeleteAdvisoryDocument = gql`
    mutation DeleteAdvisory($id: ID!, $withScan: Boolean = false) {
  deleteAdvisory(id: $id) {
    ...advisoryFields
  }
}
    ${AdvisoryFieldsFragmentDoc}`;
export type DeleteAdvisoryMutationFn = Apollo.MutationFunction<DeleteAdvisoryMutation, DeleteAdvisoryMutationVariables>;

/**
 * __useDeleteAdvisoryMutation__
 *
 * To run a mutation, you first call `useDeleteAdvisoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAdvisoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAdvisoryMutation, { data, loading, error }] = useDeleteAdvisoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *      withScan: // value for 'withScan'
 *   },
 * });
 */
export function useDeleteAdvisoryMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAdvisoryMutation, DeleteAdvisoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAdvisoryMutation, DeleteAdvisoryMutationVariables>(DeleteAdvisoryDocument, options);
      }
export type DeleteAdvisoryMutationHookResult = ReturnType<typeof useDeleteAdvisoryMutation>;
export type DeleteAdvisoryMutationResult = Apollo.MutationResult<DeleteAdvisoryMutation>;
export type DeleteAdvisoryMutationOptions = Apollo.BaseMutationOptions<DeleteAdvisoryMutation, DeleteAdvisoryMutationVariables>;
export const CreatedAdvisoryDocument = gql`
    subscription CreatedAdvisory($filter: AdvisoryFilterArgs, $withScan: Boolean = false) {
  createdAdvisory(filter: $filter) {
    ...advisoryFields
  }
}
    ${AdvisoryFieldsFragmentDoc}`;

/**
 * __useCreatedAdvisorySubscription__
 *
 * To run a query within a React component, call `useCreatedAdvisorySubscription` and pass it any options that fit your needs.
 * When your component renders, `useCreatedAdvisorySubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreatedAdvisorySubscription({
 *   variables: {
 *      filter: // value for 'filter'
 *      withScan: // value for 'withScan'
 *   },
 * });
 */
export function useCreatedAdvisorySubscription(baseOptions?: Apollo.SubscriptionHookOptions<CreatedAdvisorySubscription, CreatedAdvisorySubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<CreatedAdvisorySubscription, CreatedAdvisorySubscriptionVariables>(CreatedAdvisoryDocument, options);
      }
export type CreatedAdvisorySubscriptionHookResult = ReturnType<typeof useCreatedAdvisorySubscription>;
export type CreatedAdvisorySubscriptionResult = Apollo.SubscriptionResult<CreatedAdvisorySubscription>;
export const UpdatedAdvisoryDocument = gql`
    subscription UpdatedAdvisory($filter: AdvisoryFilterArgs, $withScan: Boolean = false) {
  updatedAdvisory(filter: $filter) {
    ...advisoryFields
  }
}
    ${AdvisoryFieldsFragmentDoc}`;

/**
 * __useUpdatedAdvisorySubscription__
 *
 * To run a query within a React component, call `useUpdatedAdvisorySubscription` and pass it any options that fit your needs.
 * When your component renders, `useUpdatedAdvisorySubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpdatedAdvisorySubscription({
 *   variables: {
 *      filter: // value for 'filter'
 *      withScan: // value for 'withScan'
 *   },
 * });
 */
export function useUpdatedAdvisorySubscription(baseOptions?: Apollo.SubscriptionHookOptions<UpdatedAdvisorySubscription, UpdatedAdvisorySubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<UpdatedAdvisorySubscription, UpdatedAdvisorySubscriptionVariables>(UpdatedAdvisoryDocument, options);
      }
export type UpdatedAdvisorySubscriptionHookResult = ReturnType<typeof useUpdatedAdvisorySubscription>;
export type UpdatedAdvisorySubscriptionResult = Apollo.SubscriptionResult<UpdatedAdvisorySubscription>;
export const DeletedAdvisoryDocument = gql`
    subscription DeletedAdvisory($filter: AdvisoryFilterArgs) {
  deletedAdvisory(filter: $filter) {
    id
  }
}
    `;

/**
 * __useDeletedAdvisorySubscription__
 *
 * To run a query within a React component, call `useDeletedAdvisorySubscription` and pass it any options that fit your needs.
 * When your component renders, `useDeletedAdvisorySubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeletedAdvisorySubscription({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useDeletedAdvisorySubscription(baseOptions?: Apollo.SubscriptionHookOptions<DeletedAdvisorySubscription, DeletedAdvisorySubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<DeletedAdvisorySubscription, DeletedAdvisorySubscriptionVariables>(DeletedAdvisoryDocument, options);
      }
export type DeletedAdvisorySubscriptionHookResult = ReturnType<typeof useDeletedAdvisorySubscription>;
export type DeletedAdvisorySubscriptionResult = Apollo.SubscriptionResult<DeletedAdvisorySubscription>;
export const RestoredAdvisoryDocument = gql`
    subscription RestoredAdvisory($filter: AdvisoryFilterArgs, $withScan: Boolean = false) {
  restoredAdvisory(filter: $filter) {
    ...advisoryFields
  }
}
    ${AdvisoryFieldsFragmentDoc}`;

/**
 * __useRestoredAdvisorySubscription__
 *
 * To run a query within a React component, call `useRestoredAdvisorySubscription` and pass it any options that fit your needs.
 * When your component renders, `useRestoredAdvisorySubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRestoredAdvisorySubscription({
 *   variables: {
 *      filter: // value for 'filter'
 *      withScan: // value for 'withScan'
 *   },
 * });
 */
export function useRestoredAdvisorySubscription(baseOptions?: Apollo.SubscriptionHookOptions<RestoredAdvisorySubscription, RestoredAdvisorySubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<RestoredAdvisorySubscription, RestoredAdvisorySubscriptionVariables>(RestoredAdvisoryDocument, options);
      }
export type RestoredAdvisorySubscriptionHookResult = ReturnType<typeof useRestoredAdvisorySubscription>;
export type RestoredAdvisorySubscriptionResult = Apollo.SubscriptionResult<RestoredAdvisorySubscription>;
export const GetBranchesDocument = gql`
    query GetBranches($filter: BranchFilterArgs, $paging: PageArgs, $sorting: [BranchSortArgs!], $withGitRepository: Boolean = false, $withScans: Boolean = false) {
  branches(filter: $filter, paging: $paging, sorting: $sorting) {
    items {
      ...branchFields
    }
    totalCount
  }
}
    ${BranchFieldsFragmentDoc}`;

/**
 * __useGetBranchesQuery__
 *
 * To run a query within a React component, call `useGetBranchesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBranchesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBranchesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      paging: // value for 'paging'
 *      sorting: // value for 'sorting'
 *      withGitRepository: // value for 'withGitRepository'
 *      withScans: // value for 'withScans'
 *   },
 * });
 */
export function useGetBranchesQuery(baseOptions?: Apollo.QueryHookOptions<GetBranchesQuery, GetBranchesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBranchesQuery, GetBranchesQueryVariables>(GetBranchesDocument, options);
      }
export function useGetBranchesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBranchesQuery, GetBranchesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBranchesQuery, GetBranchesQueryVariables>(GetBranchesDocument, options);
        }
export function useGetBranchesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetBranchesQuery, GetBranchesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetBranchesQuery, GetBranchesQueryVariables>(GetBranchesDocument, options);
        }
export type GetBranchesQueryHookResult = ReturnType<typeof useGetBranchesQuery>;
export type GetBranchesLazyQueryHookResult = ReturnType<typeof useGetBranchesLazyQuery>;
export type GetBranchesSuspenseQueryHookResult = ReturnType<typeof useGetBranchesSuspenseQuery>;
export type GetBranchesQueryResult = Apollo.QueryResult<GetBranchesQuery, GetBranchesQueryVariables>;
export const GetBranchDocument = gql`
    query GetBranch($id: ID!, $withGitRepository: Boolean = false, $withScans: Boolean = false) {
  branch(id: $id) {
    ...branchFields
  }
}
    ${BranchFieldsFragmentDoc}`;

/**
 * __useGetBranchQuery__
 *
 * To run a query within a React component, call `useGetBranchQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBranchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBranchQuery({
 *   variables: {
 *      id: // value for 'id'
 *      withGitRepository: // value for 'withGitRepository'
 *      withScans: // value for 'withScans'
 *   },
 * });
 */
export function useGetBranchQuery(baseOptions: Apollo.QueryHookOptions<GetBranchQuery, GetBranchQueryVariables> & ({ variables: GetBranchQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBranchQuery, GetBranchQueryVariables>(GetBranchDocument, options);
      }
export function useGetBranchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBranchQuery, GetBranchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBranchQuery, GetBranchQueryVariables>(GetBranchDocument, options);
        }
export function useGetBranchSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetBranchQuery, GetBranchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetBranchQuery, GetBranchQueryVariables>(GetBranchDocument, options);
        }
export type GetBranchQueryHookResult = ReturnType<typeof useGetBranchQuery>;
export type GetBranchLazyQueryHookResult = ReturnType<typeof useGetBranchLazyQuery>;
export type GetBranchSuspenseQueryHookResult = ReturnType<typeof useGetBranchSuspenseQuery>;
export type GetBranchQueryResult = Apollo.QueryResult<GetBranchQuery, GetBranchQueryVariables>;
export const GetAllRelatedDataForBranchDocument = gql`
    query GetAllRelatedDataForBranch($withGitRepositories: Boolean = false, $withScans: Boolean = false) {
  gitRepositories @include(if: $withGitRepositories) {
    items {
      id
      displayField: name
    }
  }
  scans @include(if: $withScans) {
    items {
      id
      displayField: id
    }
  }
}
    `;

/**
 * __useGetAllRelatedDataForBranchQuery__
 *
 * To run a query within a React component, call `useGetAllRelatedDataForBranchQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllRelatedDataForBranchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllRelatedDataForBranchQuery({
 *   variables: {
 *      withGitRepositories: // value for 'withGitRepositories'
 *      withScans: // value for 'withScans'
 *   },
 * });
 */
export function useGetAllRelatedDataForBranchQuery(baseOptions?: Apollo.QueryHookOptions<GetAllRelatedDataForBranchQuery, GetAllRelatedDataForBranchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllRelatedDataForBranchQuery, GetAllRelatedDataForBranchQueryVariables>(GetAllRelatedDataForBranchDocument, options);
      }
export function useGetAllRelatedDataForBranchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllRelatedDataForBranchQuery, GetAllRelatedDataForBranchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllRelatedDataForBranchQuery, GetAllRelatedDataForBranchQueryVariables>(GetAllRelatedDataForBranchDocument, options);
        }
export function useGetAllRelatedDataForBranchSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetAllRelatedDataForBranchQuery, GetAllRelatedDataForBranchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAllRelatedDataForBranchQuery, GetAllRelatedDataForBranchQueryVariables>(GetAllRelatedDataForBranchDocument, options);
        }
export type GetAllRelatedDataForBranchQueryHookResult = ReturnType<typeof useGetAllRelatedDataForBranchQuery>;
export type GetAllRelatedDataForBranchLazyQueryHookResult = ReturnType<typeof useGetAllRelatedDataForBranchLazyQuery>;
export type GetAllRelatedDataForBranchSuspenseQueryHookResult = ReturnType<typeof useGetAllRelatedDataForBranchSuspenseQuery>;
export type GetAllRelatedDataForBranchQueryResult = Apollo.QueryResult<GetAllRelatedDataForBranchQuery, GetAllRelatedDataForBranchQueryVariables>;
export const CreateBranchDocument = gql`
    mutation CreateBranch($name: String!, $gitRepositoryId: ID!, $scans: [ID], $withGitRepository: Boolean = false, $withScans: Boolean = false) {
  createBranch(name: $name, gitRepositoryId: $gitRepositoryId, scanIds: $scans) {
    ...branchFields
  }
}
    ${BranchFieldsFragmentDoc}`;
export type CreateBranchMutationFn = Apollo.MutationFunction<CreateBranchMutation, CreateBranchMutationVariables>;

/**
 * __useCreateBranchMutation__
 *
 * To run a mutation, you first call `useCreateBranchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBranchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBranchMutation, { data, loading, error }] = useCreateBranchMutation({
 *   variables: {
 *      name: // value for 'name'
 *      gitRepositoryId: // value for 'gitRepositoryId'
 *      scans: // value for 'scans'
 *      withGitRepository: // value for 'withGitRepository'
 *      withScans: // value for 'withScans'
 *   },
 * });
 */
export function useCreateBranchMutation(baseOptions?: Apollo.MutationHookOptions<CreateBranchMutation, CreateBranchMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBranchMutation, CreateBranchMutationVariables>(CreateBranchDocument, options);
      }
export type CreateBranchMutationHookResult = ReturnType<typeof useCreateBranchMutation>;
export type CreateBranchMutationResult = Apollo.MutationResult<CreateBranchMutation>;
export type CreateBranchMutationOptions = Apollo.BaseMutationOptions<CreateBranchMutation, CreateBranchMutationVariables>;
export const UpdateBranchDocument = gql`
    mutation UpdateBranch($id: ID!, $name: String, $gitRepositoryId: ID, $scans: [ID], $withGitRepository: Boolean = false, $withScans: Boolean = false) {
  updateBranch(
    id: $id
    name: $name
    gitRepositoryId: $gitRepositoryId
    scanIds: $scans
  ) {
    ...branchFields
  }
}
    ${BranchFieldsFragmentDoc}`;
export type UpdateBranchMutationFn = Apollo.MutationFunction<UpdateBranchMutation, UpdateBranchMutationVariables>;

/**
 * __useUpdateBranchMutation__
 *
 * To run a mutation, you first call `useUpdateBranchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBranchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBranchMutation, { data, loading, error }] = useUpdateBranchMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      gitRepositoryId: // value for 'gitRepositoryId'
 *      scans: // value for 'scans'
 *      withGitRepository: // value for 'withGitRepository'
 *      withScans: // value for 'withScans'
 *   },
 * });
 */
export function useUpdateBranchMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBranchMutation, UpdateBranchMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBranchMutation, UpdateBranchMutationVariables>(UpdateBranchDocument, options);
      }
export type UpdateBranchMutationHookResult = ReturnType<typeof useUpdateBranchMutation>;
export type UpdateBranchMutationResult = Apollo.MutationResult<UpdateBranchMutation>;
export type UpdateBranchMutationOptions = Apollo.BaseMutationOptions<UpdateBranchMutation, UpdateBranchMutationVariables>;
export const DeleteBranchDocument = gql`
    mutation DeleteBranch($id: ID!, $withGitRepository: Boolean = false, $withScans: Boolean = false) {
  deleteBranch(id: $id) {
    ...branchFields
  }
}
    ${BranchFieldsFragmentDoc}`;
export type DeleteBranchMutationFn = Apollo.MutationFunction<DeleteBranchMutation, DeleteBranchMutationVariables>;

/**
 * __useDeleteBranchMutation__
 *
 * To run a mutation, you first call `useDeleteBranchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBranchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBranchMutation, { data, loading, error }] = useDeleteBranchMutation({
 *   variables: {
 *      id: // value for 'id'
 *      withGitRepository: // value for 'withGitRepository'
 *      withScans: // value for 'withScans'
 *   },
 * });
 */
export function useDeleteBranchMutation(baseOptions?: Apollo.MutationHookOptions<DeleteBranchMutation, DeleteBranchMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteBranchMutation, DeleteBranchMutationVariables>(DeleteBranchDocument, options);
      }
export type DeleteBranchMutationHookResult = ReturnType<typeof useDeleteBranchMutation>;
export type DeleteBranchMutationResult = Apollo.MutationResult<DeleteBranchMutation>;
export type DeleteBranchMutationOptions = Apollo.BaseMutationOptions<DeleteBranchMutation, DeleteBranchMutationVariables>;
export const CreatedBranchDocument = gql`
    subscription CreatedBranch($filter: BranchFilterArgs, $withGitRepository: Boolean = false, $withScans: Boolean = false) {
  createdBranch(filter: $filter) {
    ...branchFields
  }
}
    ${BranchFieldsFragmentDoc}`;

/**
 * __useCreatedBranchSubscription__
 *
 * To run a query within a React component, call `useCreatedBranchSubscription` and pass it any options that fit your needs.
 * When your component renders, `useCreatedBranchSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreatedBranchSubscription({
 *   variables: {
 *      filter: // value for 'filter'
 *      withGitRepository: // value for 'withGitRepository'
 *      withScans: // value for 'withScans'
 *   },
 * });
 */
export function useCreatedBranchSubscription(baseOptions?: Apollo.SubscriptionHookOptions<CreatedBranchSubscription, CreatedBranchSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<CreatedBranchSubscription, CreatedBranchSubscriptionVariables>(CreatedBranchDocument, options);
      }
export type CreatedBranchSubscriptionHookResult = ReturnType<typeof useCreatedBranchSubscription>;
export type CreatedBranchSubscriptionResult = Apollo.SubscriptionResult<CreatedBranchSubscription>;
export const UpdatedBranchDocument = gql`
    subscription UpdatedBranch($filter: BranchFilterArgs, $withGitRepository: Boolean = false, $withScans: Boolean = false) {
  updatedBranch(filter: $filter) {
    ...branchFields
  }
}
    ${BranchFieldsFragmentDoc}`;

/**
 * __useUpdatedBranchSubscription__
 *
 * To run a query within a React component, call `useUpdatedBranchSubscription` and pass it any options that fit your needs.
 * When your component renders, `useUpdatedBranchSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpdatedBranchSubscription({
 *   variables: {
 *      filter: // value for 'filter'
 *      withGitRepository: // value for 'withGitRepository'
 *      withScans: // value for 'withScans'
 *   },
 * });
 */
export function useUpdatedBranchSubscription(baseOptions?: Apollo.SubscriptionHookOptions<UpdatedBranchSubscription, UpdatedBranchSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<UpdatedBranchSubscription, UpdatedBranchSubscriptionVariables>(UpdatedBranchDocument, options);
      }
export type UpdatedBranchSubscriptionHookResult = ReturnType<typeof useUpdatedBranchSubscription>;
export type UpdatedBranchSubscriptionResult = Apollo.SubscriptionResult<UpdatedBranchSubscription>;
export const DeletedBranchDocument = gql`
    subscription DeletedBranch($filter: BranchFilterArgs) {
  deletedBranch(filter: $filter) {
    id
  }
}
    `;

/**
 * __useDeletedBranchSubscription__
 *
 * To run a query within a React component, call `useDeletedBranchSubscription` and pass it any options that fit your needs.
 * When your component renders, `useDeletedBranchSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeletedBranchSubscription({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useDeletedBranchSubscription(baseOptions?: Apollo.SubscriptionHookOptions<DeletedBranchSubscription, DeletedBranchSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<DeletedBranchSubscription, DeletedBranchSubscriptionVariables>(DeletedBranchDocument, options);
      }
export type DeletedBranchSubscriptionHookResult = ReturnType<typeof useDeletedBranchSubscription>;
export type DeletedBranchSubscriptionResult = Apollo.SubscriptionResult<DeletedBranchSubscription>;
export const RestoredBranchDocument = gql`
    subscription RestoredBranch($filter: BranchFilterArgs, $withGitRepository: Boolean = false, $withScans: Boolean = false) {
  restoredBranch(filter: $filter) {
    ...branchFields
  }
}
    ${BranchFieldsFragmentDoc}`;

/**
 * __useRestoredBranchSubscription__
 *
 * To run a query within a React component, call `useRestoredBranchSubscription` and pass it any options that fit your needs.
 * When your component renders, `useRestoredBranchSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRestoredBranchSubscription({
 *   variables: {
 *      filter: // value for 'filter'
 *      withGitRepository: // value for 'withGitRepository'
 *      withScans: // value for 'withScans'
 *   },
 * });
 */
export function useRestoredBranchSubscription(baseOptions?: Apollo.SubscriptionHookOptions<RestoredBranchSubscription, RestoredBranchSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<RestoredBranchSubscription, RestoredBranchSubscriptionVariables>(RestoredBranchDocument, options);
      }
export type RestoredBranchSubscriptionHookResult = ReturnType<typeof useRestoredBranchSubscription>;
export type RestoredBranchSubscriptionResult = Apollo.SubscriptionResult<RestoredBranchSubscription>;
export const GetContentsDocument = gql`
    query GetContents($filter: ContentFilterArgs, $paging: PageArgs, $sorting: [ContentSortArgs!], $withContentFills: Boolean = false) {
  contents(filter: $filter, paging: $paging, sorting: $sorting) {
    items {
      ...contentFields
    }
    totalCount
  }
}
    ${ContentFieldsFragmentDoc}`;

/**
 * __useGetContentsQuery__
 *
 * To run a query within a React component, call `useGetContentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContentsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      paging: // value for 'paging'
 *      sorting: // value for 'sorting'
 *      withContentFills: // value for 'withContentFills'
 *   },
 * });
 */
export function useGetContentsQuery(baseOptions?: Apollo.QueryHookOptions<GetContentsQuery, GetContentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetContentsQuery, GetContentsQueryVariables>(GetContentsDocument, options);
      }
export function useGetContentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContentsQuery, GetContentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetContentsQuery, GetContentsQueryVariables>(GetContentsDocument, options);
        }
export function useGetContentsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetContentsQuery, GetContentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetContentsQuery, GetContentsQueryVariables>(GetContentsDocument, options);
        }
export type GetContentsQueryHookResult = ReturnType<typeof useGetContentsQuery>;
export type GetContentsLazyQueryHookResult = ReturnType<typeof useGetContentsLazyQuery>;
export type GetContentsSuspenseQueryHookResult = ReturnType<typeof useGetContentsSuspenseQuery>;
export type GetContentsQueryResult = Apollo.QueryResult<GetContentsQuery, GetContentsQueryVariables>;
export const GetContentDocument = gql`
    query GetContent($id: ID!, $withContentFills: Boolean = false) {
  content(id: $id) {
    ...contentFields
  }
}
    ${ContentFieldsFragmentDoc}`;

/**
 * __useGetContentQuery__
 *
 * To run a query within a React component, call `useGetContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContentQuery({
 *   variables: {
 *      id: // value for 'id'
 *      withContentFills: // value for 'withContentFills'
 *   },
 * });
 */
export function useGetContentQuery(baseOptions: Apollo.QueryHookOptions<GetContentQuery, GetContentQueryVariables> & ({ variables: GetContentQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetContentQuery, GetContentQueryVariables>(GetContentDocument, options);
      }
export function useGetContentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContentQuery, GetContentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetContentQuery, GetContentQueryVariables>(GetContentDocument, options);
        }
export function useGetContentSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetContentQuery, GetContentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetContentQuery, GetContentQueryVariables>(GetContentDocument, options);
        }
export type GetContentQueryHookResult = ReturnType<typeof useGetContentQuery>;
export type GetContentLazyQueryHookResult = ReturnType<typeof useGetContentLazyQuery>;
export type GetContentSuspenseQueryHookResult = ReturnType<typeof useGetContentSuspenseQuery>;
export type GetContentQueryResult = Apollo.QueryResult<GetContentQuery, GetContentQueryVariables>;
export const GetAllRelatedDataForContentDocument = gql`
    query GetAllRelatedDataForContent($withContentFills: Boolean = false) {
  contentFills @include(if: $withContentFills) {
    items {
      id
      displayField: id
    }
  }
}
    `;

/**
 * __useGetAllRelatedDataForContentQuery__
 *
 * To run a query within a React component, call `useGetAllRelatedDataForContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllRelatedDataForContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllRelatedDataForContentQuery({
 *   variables: {
 *      withContentFills: // value for 'withContentFills'
 *   },
 * });
 */
export function useGetAllRelatedDataForContentQuery(baseOptions?: Apollo.QueryHookOptions<GetAllRelatedDataForContentQuery, GetAllRelatedDataForContentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllRelatedDataForContentQuery, GetAllRelatedDataForContentQueryVariables>(GetAllRelatedDataForContentDocument, options);
      }
export function useGetAllRelatedDataForContentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllRelatedDataForContentQuery, GetAllRelatedDataForContentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllRelatedDataForContentQuery, GetAllRelatedDataForContentQueryVariables>(GetAllRelatedDataForContentDocument, options);
        }
export function useGetAllRelatedDataForContentSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetAllRelatedDataForContentQuery, GetAllRelatedDataForContentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAllRelatedDataForContentQuery, GetAllRelatedDataForContentQueryVariables>(GetAllRelatedDataForContentDocument, options);
        }
export type GetAllRelatedDataForContentQueryHookResult = ReturnType<typeof useGetAllRelatedDataForContentQuery>;
export type GetAllRelatedDataForContentLazyQueryHookResult = ReturnType<typeof useGetAllRelatedDataForContentLazyQuery>;
export type GetAllRelatedDataForContentSuspenseQueryHookResult = ReturnType<typeof useGetAllRelatedDataForContentSuspenseQuery>;
export type GetAllRelatedDataForContentQueryResult = Apollo.QueryResult<GetAllRelatedDataForContentQuery, GetAllRelatedDataForContentQueryVariables>;
export const CreateContentDocument = gql`
    mutation CreateContent($name: String!, $contentFills: [ID], $withContentFills: Boolean = false) {
  createContent(name: $name, contentFillIds: $contentFills) {
    ...contentFields
  }
}
    ${ContentFieldsFragmentDoc}`;
export type CreateContentMutationFn = Apollo.MutationFunction<CreateContentMutation, CreateContentMutationVariables>;

/**
 * __useCreateContentMutation__
 *
 * To run a mutation, you first call `useCreateContentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContentMutation, { data, loading, error }] = useCreateContentMutation({
 *   variables: {
 *      name: // value for 'name'
 *      contentFills: // value for 'contentFills'
 *      withContentFills: // value for 'withContentFills'
 *   },
 * });
 */
export function useCreateContentMutation(baseOptions?: Apollo.MutationHookOptions<CreateContentMutation, CreateContentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateContentMutation, CreateContentMutationVariables>(CreateContentDocument, options);
      }
export type CreateContentMutationHookResult = ReturnType<typeof useCreateContentMutation>;
export type CreateContentMutationResult = Apollo.MutationResult<CreateContentMutation>;
export type CreateContentMutationOptions = Apollo.BaseMutationOptions<CreateContentMutation, CreateContentMutationVariables>;
export const UpdateContentDocument = gql`
    mutation UpdateContent($id: ID!, $name: String, $contentFills: [ID], $withContentFills: Boolean = false) {
  updateContent(id: $id, name: $name, contentFillIds: $contentFills) {
    ...contentFields
  }
}
    ${ContentFieldsFragmentDoc}`;
export type UpdateContentMutationFn = Apollo.MutationFunction<UpdateContentMutation, UpdateContentMutationVariables>;

/**
 * __useUpdateContentMutation__
 *
 * To run a mutation, you first call `useUpdateContentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContentMutation, { data, loading, error }] = useUpdateContentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      contentFills: // value for 'contentFills'
 *      withContentFills: // value for 'withContentFills'
 *   },
 * });
 */
export function useUpdateContentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateContentMutation, UpdateContentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateContentMutation, UpdateContentMutationVariables>(UpdateContentDocument, options);
      }
export type UpdateContentMutationHookResult = ReturnType<typeof useUpdateContentMutation>;
export type UpdateContentMutationResult = Apollo.MutationResult<UpdateContentMutation>;
export type UpdateContentMutationOptions = Apollo.BaseMutationOptions<UpdateContentMutation, UpdateContentMutationVariables>;
export const DeleteContentDocument = gql`
    mutation DeleteContent($id: ID!, $withContentFills: Boolean = false) {
  deleteContent(id: $id) {
    ...contentFields
  }
}
    ${ContentFieldsFragmentDoc}`;
export type DeleteContentMutationFn = Apollo.MutationFunction<DeleteContentMutation, DeleteContentMutationVariables>;

/**
 * __useDeleteContentMutation__
 *
 * To run a mutation, you first call `useDeleteContentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteContentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteContentMutation, { data, loading, error }] = useDeleteContentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      withContentFills: // value for 'withContentFills'
 *   },
 * });
 */
export function useDeleteContentMutation(baseOptions?: Apollo.MutationHookOptions<DeleteContentMutation, DeleteContentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteContentMutation, DeleteContentMutationVariables>(DeleteContentDocument, options);
      }
export type DeleteContentMutationHookResult = ReturnType<typeof useDeleteContentMutation>;
export type DeleteContentMutationResult = Apollo.MutationResult<DeleteContentMutation>;
export type DeleteContentMutationOptions = Apollo.BaseMutationOptions<DeleteContentMutation, DeleteContentMutationVariables>;
export const CreatedContentDocument = gql`
    subscription CreatedContent($filter: ContentFilterArgs, $withContentFills: Boolean = false) {
  createdContent(filter: $filter) {
    ...contentFields
  }
}
    ${ContentFieldsFragmentDoc}`;

/**
 * __useCreatedContentSubscription__
 *
 * To run a query within a React component, call `useCreatedContentSubscription` and pass it any options that fit your needs.
 * When your component renders, `useCreatedContentSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreatedContentSubscription({
 *   variables: {
 *      filter: // value for 'filter'
 *      withContentFills: // value for 'withContentFills'
 *   },
 * });
 */
export function useCreatedContentSubscription(baseOptions?: Apollo.SubscriptionHookOptions<CreatedContentSubscription, CreatedContentSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<CreatedContentSubscription, CreatedContentSubscriptionVariables>(CreatedContentDocument, options);
      }
export type CreatedContentSubscriptionHookResult = ReturnType<typeof useCreatedContentSubscription>;
export type CreatedContentSubscriptionResult = Apollo.SubscriptionResult<CreatedContentSubscription>;
export const UpdatedContentDocument = gql`
    subscription UpdatedContent($filter: ContentFilterArgs, $withContentFills: Boolean = false) {
  updatedContent(filter: $filter) {
    ...contentFields
  }
}
    ${ContentFieldsFragmentDoc}`;

/**
 * __useUpdatedContentSubscription__
 *
 * To run a query within a React component, call `useUpdatedContentSubscription` and pass it any options that fit your needs.
 * When your component renders, `useUpdatedContentSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpdatedContentSubscription({
 *   variables: {
 *      filter: // value for 'filter'
 *      withContentFills: // value for 'withContentFills'
 *   },
 * });
 */
export function useUpdatedContentSubscription(baseOptions?: Apollo.SubscriptionHookOptions<UpdatedContentSubscription, UpdatedContentSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<UpdatedContentSubscription, UpdatedContentSubscriptionVariables>(UpdatedContentDocument, options);
      }
export type UpdatedContentSubscriptionHookResult = ReturnType<typeof useUpdatedContentSubscription>;
export type UpdatedContentSubscriptionResult = Apollo.SubscriptionResult<UpdatedContentSubscription>;
export const DeletedContentDocument = gql`
    subscription DeletedContent($filter: ContentFilterArgs) {
  deletedContent(filter: $filter) {
    id
  }
}
    `;

/**
 * __useDeletedContentSubscription__
 *
 * To run a query within a React component, call `useDeletedContentSubscription` and pass it any options that fit your needs.
 * When your component renders, `useDeletedContentSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeletedContentSubscription({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useDeletedContentSubscription(baseOptions?: Apollo.SubscriptionHookOptions<DeletedContentSubscription, DeletedContentSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<DeletedContentSubscription, DeletedContentSubscriptionVariables>(DeletedContentDocument, options);
      }
export type DeletedContentSubscriptionHookResult = ReturnType<typeof useDeletedContentSubscription>;
export type DeletedContentSubscriptionResult = Apollo.SubscriptionResult<DeletedContentSubscription>;
export const GetContentFillsDocument = gql`
    query GetContentFills($filter: ContentFillFilterArgs, $paging: PageArgs, $sorting: [ContentFillSortArgs!], $withContent: Boolean = false) {
  contentFills(filter: $filter, paging: $paging, sorting: $sorting) {
    items {
      ...contentFillFields
    }
    totalCount
  }
}
    ${ContentFillFieldsFragmentDoc}`;

/**
 * __useGetContentFillsQuery__
 *
 * To run a query within a React component, call `useGetContentFillsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContentFillsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContentFillsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      paging: // value for 'paging'
 *      sorting: // value for 'sorting'
 *      withContent: // value for 'withContent'
 *   },
 * });
 */
export function useGetContentFillsQuery(baseOptions?: Apollo.QueryHookOptions<GetContentFillsQuery, GetContentFillsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetContentFillsQuery, GetContentFillsQueryVariables>(GetContentFillsDocument, options);
      }
export function useGetContentFillsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContentFillsQuery, GetContentFillsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetContentFillsQuery, GetContentFillsQueryVariables>(GetContentFillsDocument, options);
        }
export function useGetContentFillsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetContentFillsQuery, GetContentFillsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetContentFillsQuery, GetContentFillsQueryVariables>(GetContentFillsDocument, options);
        }
export type GetContentFillsQueryHookResult = ReturnType<typeof useGetContentFillsQuery>;
export type GetContentFillsLazyQueryHookResult = ReturnType<typeof useGetContentFillsLazyQuery>;
export type GetContentFillsSuspenseQueryHookResult = ReturnType<typeof useGetContentFillsSuspenseQuery>;
export type GetContentFillsQueryResult = Apollo.QueryResult<GetContentFillsQuery, GetContentFillsQueryVariables>;
export const GetContentFillDocument = gql`
    query GetContentFill($id: ID!, $withContent: Boolean = false) {
  contentFill(id: $id) {
    ...contentFillFields
  }
}
    ${ContentFillFieldsFragmentDoc}`;

/**
 * __useGetContentFillQuery__
 *
 * To run a query within a React component, call `useGetContentFillQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContentFillQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContentFillQuery({
 *   variables: {
 *      id: // value for 'id'
 *      withContent: // value for 'withContent'
 *   },
 * });
 */
export function useGetContentFillQuery(baseOptions: Apollo.QueryHookOptions<GetContentFillQuery, GetContentFillQueryVariables> & ({ variables: GetContentFillQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetContentFillQuery, GetContentFillQueryVariables>(GetContentFillDocument, options);
      }
export function useGetContentFillLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContentFillQuery, GetContentFillQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetContentFillQuery, GetContentFillQueryVariables>(GetContentFillDocument, options);
        }
export function useGetContentFillSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetContentFillQuery, GetContentFillQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetContentFillQuery, GetContentFillQueryVariables>(GetContentFillDocument, options);
        }
export type GetContentFillQueryHookResult = ReturnType<typeof useGetContentFillQuery>;
export type GetContentFillLazyQueryHookResult = ReturnType<typeof useGetContentFillLazyQuery>;
export type GetContentFillSuspenseQueryHookResult = ReturnType<typeof useGetContentFillSuspenseQuery>;
export type GetContentFillQueryResult = Apollo.QueryResult<GetContentFillQuery, GetContentFillQueryVariables>;
export const GetAllRelatedDataForContentFillDocument = gql`
    query GetAllRelatedDataForContentFill($withContents: Boolean = false) {
  contents @include(if: $withContents) {
    items {
      id
      displayField: name
    }
  }
}
    `;

/**
 * __useGetAllRelatedDataForContentFillQuery__
 *
 * To run a query within a React component, call `useGetAllRelatedDataForContentFillQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllRelatedDataForContentFillQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllRelatedDataForContentFillQuery({
 *   variables: {
 *      withContents: // value for 'withContents'
 *   },
 * });
 */
export function useGetAllRelatedDataForContentFillQuery(baseOptions?: Apollo.QueryHookOptions<GetAllRelatedDataForContentFillQuery, GetAllRelatedDataForContentFillQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllRelatedDataForContentFillQuery, GetAllRelatedDataForContentFillQueryVariables>(GetAllRelatedDataForContentFillDocument, options);
      }
export function useGetAllRelatedDataForContentFillLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllRelatedDataForContentFillQuery, GetAllRelatedDataForContentFillQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllRelatedDataForContentFillQuery, GetAllRelatedDataForContentFillQueryVariables>(GetAllRelatedDataForContentFillDocument, options);
        }
export function useGetAllRelatedDataForContentFillSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetAllRelatedDataForContentFillQuery, GetAllRelatedDataForContentFillQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAllRelatedDataForContentFillQuery, GetAllRelatedDataForContentFillQueryVariables>(GetAllRelatedDataForContentFillDocument, options);
        }
export type GetAllRelatedDataForContentFillQueryHookResult = ReturnType<typeof useGetAllRelatedDataForContentFillQuery>;
export type GetAllRelatedDataForContentFillLazyQueryHookResult = ReturnType<typeof useGetAllRelatedDataForContentFillLazyQuery>;
export type GetAllRelatedDataForContentFillSuspenseQueryHookResult = ReturnType<typeof useGetAllRelatedDataForContentFillSuspenseQuery>;
export type GetAllRelatedDataForContentFillQueryResult = Apollo.QueryResult<GetAllRelatedDataForContentFillQuery, GetAllRelatedDataForContentFillQueryVariables>;
export const CreateContentFillDocument = gql`
    mutation CreateContentFill($fill: String!, $language: String!, $contentId: ID!, $withContent: Boolean = false) {
  createContentFill(fill: $fill, language: $language, contentId: $contentId) {
    ...contentFillFields
  }
}
    ${ContentFillFieldsFragmentDoc}`;
export type CreateContentFillMutationFn = Apollo.MutationFunction<CreateContentFillMutation, CreateContentFillMutationVariables>;

/**
 * __useCreateContentFillMutation__
 *
 * To run a mutation, you first call `useCreateContentFillMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContentFillMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContentFillMutation, { data, loading, error }] = useCreateContentFillMutation({
 *   variables: {
 *      fill: // value for 'fill'
 *      language: // value for 'language'
 *      contentId: // value for 'contentId'
 *      withContent: // value for 'withContent'
 *   },
 * });
 */
export function useCreateContentFillMutation(baseOptions?: Apollo.MutationHookOptions<CreateContentFillMutation, CreateContentFillMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateContentFillMutation, CreateContentFillMutationVariables>(CreateContentFillDocument, options);
      }
export type CreateContentFillMutationHookResult = ReturnType<typeof useCreateContentFillMutation>;
export type CreateContentFillMutationResult = Apollo.MutationResult<CreateContentFillMutation>;
export type CreateContentFillMutationOptions = Apollo.BaseMutationOptions<CreateContentFillMutation, CreateContentFillMutationVariables>;
export const UpdateContentFillDocument = gql`
    mutation UpdateContentFill($id: ID!, $fill: String, $language: String, $contentId: ID, $withContent: Boolean = false) {
  updateContentFill(
    id: $id
    fill: $fill
    language: $language
    contentId: $contentId
  ) {
    ...contentFillFields
  }
}
    ${ContentFillFieldsFragmentDoc}`;
export type UpdateContentFillMutationFn = Apollo.MutationFunction<UpdateContentFillMutation, UpdateContentFillMutationVariables>;

/**
 * __useUpdateContentFillMutation__
 *
 * To run a mutation, you first call `useUpdateContentFillMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContentFillMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContentFillMutation, { data, loading, error }] = useUpdateContentFillMutation({
 *   variables: {
 *      id: // value for 'id'
 *      fill: // value for 'fill'
 *      language: // value for 'language'
 *      contentId: // value for 'contentId'
 *      withContent: // value for 'withContent'
 *   },
 * });
 */
export function useUpdateContentFillMutation(baseOptions?: Apollo.MutationHookOptions<UpdateContentFillMutation, UpdateContentFillMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateContentFillMutation, UpdateContentFillMutationVariables>(UpdateContentFillDocument, options);
      }
export type UpdateContentFillMutationHookResult = ReturnType<typeof useUpdateContentFillMutation>;
export type UpdateContentFillMutationResult = Apollo.MutationResult<UpdateContentFillMutation>;
export type UpdateContentFillMutationOptions = Apollo.BaseMutationOptions<UpdateContentFillMutation, UpdateContentFillMutationVariables>;
export const DeleteContentFillDocument = gql`
    mutation DeleteContentFill($id: ID!, $withContent: Boolean = false) {
  deleteContentFill(id: $id) {
    ...contentFillFields
  }
}
    ${ContentFillFieldsFragmentDoc}`;
export type DeleteContentFillMutationFn = Apollo.MutationFunction<DeleteContentFillMutation, DeleteContentFillMutationVariables>;

/**
 * __useDeleteContentFillMutation__
 *
 * To run a mutation, you first call `useDeleteContentFillMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteContentFillMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteContentFillMutation, { data, loading, error }] = useDeleteContentFillMutation({
 *   variables: {
 *      id: // value for 'id'
 *      withContent: // value for 'withContent'
 *   },
 * });
 */
export function useDeleteContentFillMutation(baseOptions?: Apollo.MutationHookOptions<DeleteContentFillMutation, DeleteContentFillMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteContentFillMutation, DeleteContentFillMutationVariables>(DeleteContentFillDocument, options);
      }
export type DeleteContentFillMutationHookResult = ReturnType<typeof useDeleteContentFillMutation>;
export type DeleteContentFillMutationResult = Apollo.MutationResult<DeleteContentFillMutation>;
export type DeleteContentFillMutationOptions = Apollo.BaseMutationOptions<DeleteContentFillMutation, DeleteContentFillMutationVariables>;
export const CreatedContentFillDocument = gql`
    subscription CreatedContentFill($filter: ContentFillFilterArgs, $withContent: Boolean = false) {
  createdContentFill(filter: $filter) {
    ...contentFillFields
  }
}
    ${ContentFillFieldsFragmentDoc}`;

/**
 * __useCreatedContentFillSubscription__
 *
 * To run a query within a React component, call `useCreatedContentFillSubscription` and pass it any options that fit your needs.
 * When your component renders, `useCreatedContentFillSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreatedContentFillSubscription({
 *   variables: {
 *      filter: // value for 'filter'
 *      withContent: // value for 'withContent'
 *   },
 * });
 */
export function useCreatedContentFillSubscription(baseOptions?: Apollo.SubscriptionHookOptions<CreatedContentFillSubscription, CreatedContentFillSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<CreatedContentFillSubscription, CreatedContentFillSubscriptionVariables>(CreatedContentFillDocument, options);
      }
export type CreatedContentFillSubscriptionHookResult = ReturnType<typeof useCreatedContentFillSubscription>;
export type CreatedContentFillSubscriptionResult = Apollo.SubscriptionResult<CreatedContentFillSubscription>;
export const UpdatedContentFillDocument = gql`
    subscription UpdatedContentFill($filter: ContentFillFilterArgs, $withContent: Boolean = false) {
  updatedContentFill(filter: $filter) {
    ...contentFillFields
  }
}
    ${ContentFillFieldsFragmentDoc}`;

/**
 * __useUpdatedContentFillSubscription__
 *
 * To run a query within a React component, call `useUpdatedContentFillSubscription` and pass it any options that fit your needs.
 * When your component renders, `useUpdatedContentFillSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpdatedContentFillSubscription({
 *   variables: {
 *      filter: // value for 'filter'
 *      withContent: // value for 'withContent'
 *   },
 * });
 */
export function useUpdatedContentFillSubscription(baseOptions?: Apollo.SubscriptionHookOptions<UpdatedContentFillSubscription, UpdatedContentFillSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<UpdatedContentFillSubscription, UpdatedContentFillSubscriptionVariables>(UpdatedContentFillDocument, options);
      }
export type UpdatedContentFillSubscriptionHookResult = ReturnType<typeof useUpdatedContentFillSubscription>;
export type UpdatedContentFillSubscriptionResult = Apollo.SubscriptionResult<UpdatedContentFillSubscription>;
export const DeletedContentFillDocument = gql`
    subscription DeletedContentFill($filter: ContentFillFilterArgs) {
  deletedContentFill(filter: $filter) {
    id
  }
}
    `;

/**
 * __useDeletedContentFillSubscription__
 *
 * To run a query within a React component, call `useDeletedContentFillSubscription` and pass it any options that fit your needs.
 * When your component renders, `useDeletedContentFillSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeletedContentFillSubscription({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useDeletedContentFillSubscription(baseOptions?: Apollo.SubscriptionHookOptions<DeletedContentFillSubscription, DeletedContentFillSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<DeletedContentFillSubscription, DeletedContentFillSubscriptionVariables>(DeletedContentFillDocument, options);
      }
export type DeletedContentFillSubscriptionHookResult = ReturnType<typeof useDeletedContentFillSubscription>;
export type DeletedContentFillSubscriptionResult = Apollo.SubscriptionResult<DeletedContentFillSubscription>;
export const GetErrorLogsDocument = gql`
    query GetErrorLogs($filter: ErrorLogFilterArgs, $paging: PageArgs, $sorting: [ErrorLogSortArgs!]) {
  errorLogs(filter: $filter, paging: $paging, sorting: $sorting) {
    items {
      ...errorLogFields
    }
    totalCount
  }
}
    ${ErrorLogFieldsFragmentDoc}`;

/**
 * __useGetErrorLogsQuery__
 *
 * To run a query within a React component, call `useGetErrorLogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetErrorLogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetErrorLogsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      paging: // value for 'paging'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useGetErrorLogsQuery(baseOptions?: Apollo.QueryHookOptions<GetErrorLogsQuery, GetErrorLogsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetErrorLogsQuery, GetErrorLogsQueryVariables>(GetErrorLogsDocument, options);
      }
export function useGetErrorLogsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetErrorLogsQuery, GetErrorLogsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetErrorLogsQuery, GetErrorLogsQueryVariables>(GetErrorLogsDocument, options);
        }
export function useGetErrorLogsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetErrorLogsQuery, GetErrorLogsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetErrorLogsQuery, GetErrorLogsQueryVariables>(GetErrorLogsDocument, options);
        }
export type GetErrorLogsQueryHookResult = ReturnType<typeof useGetErrorLogsQuery>;
export type GetErrorLogsLazyQueryHookResult = ReturnType<typeof useGetErrorLogsLazyQuery>;
export type GetErrorLogsSuspenseQueryHookResult = ReturnType<typeof useGetErrorLogsSuspenseQuery>;
export type GetErrorLogsQueryResult = Apollo.QueryResult<GetErrorLogsQuery, GetErrorLogsQueryVariables>;
export const GetErrorLogDocument = gql`
    query GetErrorLog($id: ID!) {
  errorLog(id: $id) {
    ...errorLogFields
  }
}
    ${ErrorLogFieldsFragmentDoc}`;

/**
 * __useGetErrorLogQuery__
 *
 * To run a query within a React component, call `useGetErrorLogQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetErrorLogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetErrorLogQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetErrorLogQuery(baseOptions: Apollo.QueryHookOptions<GetErrorLogQuery, GetErrorLogQueryVariables> & ({ variables: GetErrorLogQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetErrorLogQuery, GetErrorLogQueryVariables>(GetErrorLogDocument, options);
      }
export function useGetErrorLogLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetErrorLogQuery, GetErrorLogQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetErrorLogQuery, GetErrorLogQueryVariables>(GetErrorLogDocument, options);
        }
export function useGetErrorLogSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetErrorLogQuery, GetErrorLogQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetErrorLogQuery, GetErrorLogQueryVariables>(GetErrorLogDocument, options);
        }
export type GetErrorLogQueryHookResult = ReturnType<typeof useGetErrorLogQuery>;
export type GetErrorLogLazyQueryHookResult = ReturnType<typeof useGetErrorLogLazyQuery>;
export type GetErrorLogSuspenseQueryHookResult = ReturnType<typeof useGetErrorLogSuspenseQuery>;
export type GetErrorLogQueryResult = Apollo.QueryResult<GetErrorLogQuery, GetErrorLogQueryVariables>;
export const CreateErrorLogDocument = gql`
    mutation CreateErrorLog($error: String!, $stack: String, $domain: String!) {
  createErrorLog(error: $error, stack: $stack, domain: $domain) {
    ...errorLogFields
  }
}
    ${ErrorLogFieldsFragmentDoc}`;
export type CreateErrorLogMutationFn = Apollo.MutationFunction<CreateErrorLogMutation, CreateErrorLogMutationVariables>;

/**
 * __useCreateErrorLogMutation__
 *
 * To run a mutation, you first call `useCreateErrorLogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateErrorLogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createErrorLogMutation, { data, loading, error }] = useCreateErrorLogMutation({
 *   variables: {
 *      error: // value for 'error'
 *      stack: // value for 'stack'
 *      domain: // value for 'domain'
 *   },
 * });
 */
export function useCreateErrorLogMutation(baseOptions?: Apollo.MutationHookOptions<CreateErrorLogMutation, CreateErrorLogMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateErrorLogMutation, CreateErrorLogMutationVariables>(CreateErrorLogDocument, options);
      }
export type CreateErrorLogMutationHookResult = ReturnType<typeof useCreateErrorLogMutation>;
export type CreateErrorLogMutationResult = Apollo.MutationResult<CreateErrorLogMutation>;
export type CreateErrorLogMutationOptions = Apollo.BaseMutationOptions<CreateErrorLogMutation, CreateErrorLogMutationVariables>;
export const UpdateErrorLogDocument = gql`
    mutation UpdateErrorLog($id: ID!, $error: String, $stack: String, $domain: String) {
  updateErrorLog(id: $id, error: $error, stack: $stack, domain: $domain) {
    ...errorLogFields
  }
}
    ${ErrorLogFieldsFragmentDoc}`;
export type UpdateErrorLogMutationFn = Apollo.MutationFunction<UpdateErrorLogMutation, UpdateErrorLogMutationVariables>;

/**
 * __useUpdateErrorLogMutation__
 *
 * To run a mutation, you first call `useUpdateErrorLogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateErrorLogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateErrorLogMutation, { data, loading, error }] = useUpdateErrorLogMutation({
 *   variables: {
 *      id: // value for 'id'
 *      error: // value for 'error'
 *      stack: // value for 'stack'
 *      domain: // value for 'domain'
 *   },
 * });
 */
export function useUpdateErrorLogMutation(baseOptions?: Apollo.MutationHookOptions<UpdateErrorLogMutation, UpdateErrorLogMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateErrorLogMutation, UpdateErrorLogMutationVariables>(UpdateErrorLogDocument, options);
      }
export type UpdateErrorLogMutationHookResult = ReturnType<typeof useUpdateErrorLogMutation>;
export type UpdateErrorLogMutationResult = Apollo.MutationResult<UpdateErrorLogMutation>;
export type UpdateErrorLogMutationOptions = Apollo.BaseMutationOptions<UpdateErrorLogMutation, UpdateErrorLogMutationVariables>;
export const DeleteErrorLogDocument = gql`
    mutation DeleteErrorLog($id: ID!) {
  deleteErrorLog(id: $id) {
    ...errorLogFields
  }
}
    ${ErrorLogFieldsFragmentDoc}`;
export type DeleteErrorLogMutationFn = Apollo.MutationFunction<DeleteErrorLogMutation, DeleteErrorLogMutationVariables>;

/**
 * __useDeleteErrorLogMutation__
 *
 * To run a mutation, you first call `useDeleteErrorLogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteErrorLogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteErrorLogMutation, { data, loading, error }] = useDeleteErrorLogMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteErrorLogMutation(baseOptions?: Apollo.MutationHookOptions<DeleteErrorLogMutation, DeleteErrorLogMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteErrorLogMutation, DeleteErrorLogMutationVariables>(DeleteErrorLogDocument, options);
      }
export type DeleteErrorLogMutationHookResult = ReturnType<typeof useDeleteErrorLogMutation>;
export type DeleteErrorLogMutationResult = Apollo.MutationResult<DeleteErrorLogMutation>;
export type DeleteErrorLogMutationOptions = Apollo.BaseMutationOptions<DeleteErrorLogMutation, DeleteErrorLogMutationVariables>;
export const CreatedErrorLogDocument = gql`
    subscription CreatedErrorLog($filter: ErrorLogFilterArgs) {
  createdErrorLog(filter: $filter) {
    ...errorLogFields
  }
}
    ${ErrorLogFieldsFragmentDoc}`;

/**
 * __useCreatedErrorLogSubscription__
 *
 * To run a query within a React component, call `useCreatedErrorLogSubscription` and pass it any options that fit your needs.
 * When your component renders, `useCreatedErrorLogSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreatedErrorLogSubscription({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCreatedErrorLogSubscription(baseOptions?: Apollo.SubscriptionHookOptions<CreatedErrorLogSubscription, CreatedErrorLogSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<CreatedErrorLogSubscription, CreatedErrorLogSubscriptionVariables>(CreatedErrorLogDocument, options);
      }
export type CreatedErrorLogSubscriptionHookResult = ReturnType<typeof useCreatedErrorLogSubscription>;
export type CreatedErrorLogSubscriptionResult = Apollo.SubscriptionResult<CreatedErrorLogSubscription>;
export const UpdatedErrorLogDocument = gql`
    subscription UpdatedErrorLog($filter: ErrorLogFilterArgs) {
  updatedErrorLog(filter: $filter) {
    ...errorLogFields
  }
}
    ${ErrorLogFieldsFragmentDoc}`;

/**
 * __useUpdatedErrorLogSubscription__
 *
 * To run a query within a React component, call `useUpdatedErrorLogSubscription` and pass it any options that fit your needs.
 * When your component renders, `useUpdatedErrorLogSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpdatedErrorLogSubscription({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useUpdatedErrorLogSubscription(baseOptions?: Apollo.SubscriptionHookOptions<UpdatedErrorLogSubscription, UpdatedErrorLogSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<UpdatedErrorLogSubscription, UpdatedErrorLogSubscriptionVariables>(UpdatedErrorLogDocument, options);
      }
export type UpdatedErrorLogSubscriptionHookResult = ReturnType<typeof useUpdatedErrorLogSubscription>;
export type UpdatedErrorLogSubscriptionResult = Apollo.SubscriptionResult<UpdatedErrorLogSubscription>;
export const DeletedErrorLogDocument = gql`
    subscription DeletedErrorLog($filter: ErrorLogFilterArgs) {
  deletedErrorLog(filter: $filter) {
    id
  }
}
    `;

/**
 * __useDeletedErrorLogSubscription__
 *
 * To run a query within a React component, call `useDeletedErrorLogSubscription` and pass it any options that fit your needs.
 * When your component renders, `useDeletedErrorLogSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeletedErrorLogSubscription({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useDeletedErrorLogSubscription(baseOptions?: Apollo.SubscriptionHookOptions<DeletedErrorLogSubscription, DeletedErrorLogSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<DeletedErrorLogSubscription, DeletedErrorLogSubscriptionVariables>(DeletedErrorLogDocument, options);
      }
export type DeletedErrorLogSubscriptionHookResult = ReturnType<typeof useDeletedErrorLogSubscription>;
export type DeletedErrorLogSubscriptionResult = Apollo.SubscriptionResult<DeletedErrorLogSubscription>;
export const RestoredErrorLogDocument = gql`
    subscription RestoredErrorLog($filter: ErrorLogFilterArgs) {
  restoredErrorLog(filter: $filter) {
    ...errorLogFields
  }
}
    ${ErrorLogFieldsFragmentDoc}`;

/**
 * __useRestoredErrorLogSubscription__
 *
 * To run a query within a React component, call `useRestoredErrorLogSubscription` and pass it any options that fit your needs.
 * When your component renders, `useRestoredErrorLogSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRestoredErrorLogSubscription({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useRestoredErrorLogSubscription(baseOptions?: Apollo.SubscriptionHookOptions<RestoredErrorLogSubscription, RestoredErrorLogSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<RestoredErrorLogSubscription, RestoredErrorLogSubscriptionVariables>(RestoredErrorLogDocument, options);
      }
export type RestoredErrorLogSubscriptionHookResult = ReturnType<typeof useRestoredErrorLogSubscription>;
export type RestoredErrorLogSubscriptionResult = Apollo.SubscriptionResult<RestoredErrorLogSubscription>;
export const GetEventLogsDocument = gql`
    query GetEventLogs($filter: EventLogFilterArgs, $paging: PageArgs, $sorting: [EventLogSortArgs!]) {
  eventLogs(filter: $filter, paging: $paging, sorting: $sorting) {
    items {
      ...eventLogFields
    }
    totalCount
  }
}
    ${EventLogFieldsFragmentDoc}`;

/**
 * __useGetEventLogsQuery__
 *
 * To run a query within a React component, call `useGetEventLogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventLogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventLogsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      paging: // value for 'paging'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useGetEventLogsQuery(baseOptions?: Apollo.QueryHookOptions<GetEventLogsQuery, GetEventLogsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEventLogsQuery, GetEventLogsQueryVariables>(GetEventLogsDocument, options);
      }
export function useGetEventLogsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEventLogsQuery, GetEventLogsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEventLogsQuery, GetEventLogsQueryVariables>(GetEventLogsDocument, options);
        }
export function useGetEventLogsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetEventLogsQuery, GetEventLogsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetEventLogsQuery, GetEventLogsQueryVariables>(GetEventLogsDocument, options);
        }
export type GetEventLogsQueryHookResult = ReturnType<typeof useGetEventLogsQuery>;
export type GetEventLogsLazyQueryHookResult = ReturnType<typeof useGetEventLogsLazyQuery>;
export type GetEventLogsSuspenseQueryHookResult = ReturnType<typeof useGetEventLogsSuspenseQuery>;
export type GetEventLogsQueryResult = Apollo.QueryResult<GetEventLogsQuery, GetEventLogsQueryVariables>;
export const GetEventLogDocument = gql`
    query GetEventLog($id: ID!) {
  eventLog(id: $id) {
    ...eventLogFields
  }
}
    ${EventLogFieldsFragmentDoc}`;

/**
 * __useGetEventLogQuery__
 *
 * To run a query within a React component, call `useGetEventLogQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventLogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventLogQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetEventLogQuery(baseOptions: Apollo.QueryHookOptions<GetEventLogQuery, GetEventLogQueryVariables> & ({ variables: GetEventLogQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEventLogQuery, GetEventLogQueryVariables>(GetEventLogDocument, options);
      }
export function useGetEventLogLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEventLogQuery, GetEventLogQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEventLogQuery, GetEventLogQueryVariables>(GetEventLogDocument, options);
        }
export function useGetEventLogSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetEventLogQuery, GetEventLogQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetEventLogQuery, GetEventLogQueryVariables>(GetEventLogDocument, options);
        }
export type GetEventLogQueryHookResult = ReturnType<typeof useGetEventLogQuery>;
export type GetEventLogLazyQueryHookResult = ReturnType<typeof useGetEventLogLazyQuery>;
export type GetEventLogSuspenseQueryHookResult = ReturnType<typeof useGetEventLogSuspenseQuery>;
export type GetEventLogQueryResult = Apollo.QueryResult<GetEventLogQuery, GetEventLogQueryVariables>;
export const CreateEventLogDocument = gql`
    mutation CreateEventLog($log: String!, $type: String!) {
  createEventLog(log: $log, type: $type) {
    ...eventLogFields
  }
}
    ${EventLogFieldsFragmentDoc}`;
export type CreateEventLogMutationFn = Apollo.MutationFunction<CreateEventLogMutation, CreateEventLogMutationVariables>;

/**
 * __useCreateEventLogMutation__
 *
 * To run a mutation, you first call `useCreateEventLogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEventLogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEventLogMutation, { data, loading, error }] = useCreateEventLogMutation({
 *   variables: {
 *      log: // value for 'log'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useCreateEventLogMutation(baseOptions?: Apollo.MutationHookOptions<CreateEventLogMutation, CreateEventLogMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateEventLogMutation, CreateEventLogMutationVariables>(CreateEventLogDocument, options);
      }
export type CreateEventLogMutationHookResult = ReturnType<typeof useCreateEventLogMutation>;
export type CreateEventLogMutationResult = Apollo.MutationResult<CreateEventLogMutation>;
export type CreateEventLogMutationOptions = Apollo.BaseMutationOptions<CreateEventLogMutation, CreateEventLogMutationVariables>;
export const UpdateEventLogDocument = gql`
    mutation UpdateEventLog($id: ID!, $log: String, $type: String) {
  updateEventLog(id: $id, log: $log, type: $type) {
    ...eventLogFields
  }
}
    ${EventLogFieldsFragmentDoc}`;
export type UpdateEventLogMutationFn = Apollo.MutationFunction<UpdateEventLogMutation, UpdateEventLogMutationVariables>;

/**
 * __useUpdateEventLogMutation__
 *
 * To run a mutation, you first call `useUpdateEventLogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEventLogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEventLogMutation, { data, loading, error }] = useUpdateEventLogMutation({
 *   variables: {
 *      id: // value for 'id'
 *      log: // value for 'log'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useUpdateEventLogMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEventLogMutation, UpdateEventLogMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEventLogMutation, UpdateEventLogMutationVariables>(UpdateEventLogDocument, options);
      }
export type UpdateEventLogMutationHookResult = ReturnType<typeof useUpdateEventLogMutation>;
export type UpdateEventLogMutationResult = Apollo.MutationResult<UpdateEventLogMutation>;
export type UpdateEventLogMutationOptions = Apollo.BaseMutationOptions<UpdateEventLogMutation, UpdateEventLogMutationVariables>;
export const DeleteEventLogDocument = gql`
    mutation DeleteEventLog($id: ID!) {
  deleteEventLog(id: $id) {
    ...eventLogFields
  }
}
    ${EventLogFieldsFragmentDoc}`;
export type DeleteEventLogMutationFn = Apollo.MutationFunction<DeleteEventLogMutation, DeleteEventLogMutationVariables>;

/**
 * __useDeleteEventLogMutation__
 *
 * To run a mutation, you first call `useDeleteEventLogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEventLogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEventLogMutation, { data, loading, error }] = useDeleteEventLogMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteEventLogMutation(baseOptions?: Apollo.MutationHookOptions<DeleteEventLogMutation, DeleteEventLogMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteEventLogMutation, DeleteEventLogMutationVariables>(DeleteEventLogDocument, options);
      }
export type DeleteEventLogMutationHookResult = ReturnType<typeof useDeleteEventLogMutation>;
export type DeleteEventLogMutationResult = Apollo.MutationResult<DeleteEventLogMutation>;
export type DeleteEventLogMutationOptions = Apollo.BaseMutationOptions<DeleteEventLogMutation, DeleteEventLogMutationVariables>;
export const CreatedEventLogDocument = gql`
    subscription CreatedEventLog($filter: EventLogFilterArgs) {
  createdEventLog(filter: $filter) {
    ...eventLogFields
  }
}
    ${EventLogFieldsFragmentDoc}`;

/**
 * __useCreatedEventLogSubscription__
 *
 * To run a query within a React component, call `useCreatedEventLogSubscription` and pass it any options that fit your needs.
 * When your component renders, `useCreatedEventLogSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreatedEventLogSubscription({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCreatedEventLogSubscription(baseOptions?: Apollo.SubscriptionHookOptions<CreatedEventLogSubscription, CreatedEventLogSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<CreatedEventLogSubscription, CreatedEventLogSubscriptionVariables>(CreatedEventLogDocument, options);
      }
export type CreatedEventLogSubscriptionHookResult = ReturnType<typeof useCreatedEventLogSubscription>;
export type CreatedEventLogSubscriptionResult = Apollo.SubscriptionResult<CreatedEventLogSubscription>;
export const UpdatedEventLogDocument = gql`
    subscription UpdatedEventLog($filter: EventLogFilterArgs) {
  updatedEventLog(filter: $filter) {
    ...eventLogFields
  }
}
    ${EventLogFieldsFragmentDoc}`;

/**
 * __useUpdatedEventLogSubscription__
 *
 * To run a query within a React component, call `useUpdatedEventLogSubscription` and pass it any options that fit your needs.
 * When your component renders, `useUpdatedEventLogSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpdatedEventLogSubscription({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useUpdatedEventLogSubscription(baseOptions?: Apollo.SubscriptionHookOptions<UpdatedEventLogSubscription, UpdatedEventLogSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<UpdatedEventLogSubscription, UpdatedEventLogSubscriptionVariables>(UpdatedEventLogDocument, options);
      }
export type UpdatedEventLogSubscriptionHookResult = ReturnType<typeof useUpdatedEventLogSubscription>;
export type UpdatedEventLogSubscriptionResult = Apollo.SubscriptionResult<UpdatedEventLogSubscription>;
export const DeletedEventLogDocument = gql`
    subscription DeletedEventLog($filter: EventLogFilterArgs) {
  deletedEventLog(filter: $filter) {
    id
  }
}
    `;

/**
 * __useDeletedEventLogSubscription__
 *
 * To run a query within a React component, call `useDeletedEventLogSubscription` and pass it any options that fit your needs.
 * When your component renders, `useDeletedEventLogSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeletedEventLogSubscription({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useDeletedEventLogSubscription(baseOptions?: Apollo.SubscriptionHookOptions<DeletedEventLogSubscription, DeletedEventLogSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<DeletedEventLogSubscription, DeletedEventLogSubscriptionVariables>(DeletedEventLogDocument, options);
      }
export type DeletedEventLogSubscriptionHookResult = ReturnType<typeof useDeletedEventLogSubscription>;
export type DeletedEventLogSubscriptionResult = Apollo.SubscriptionResult<DeletedEventLogSubscription>;
export const RestoredEventLogDocument = gql`
    subscription RestoredEventLog($filter: EventLogFilterArgs) {
  restoredEventLog(filter: $filter) {
    ...eventLogFields
  }
}
    ${EventLogFieldsFragmentDoc}`;

/**
 * __useRestoredEventLogSubscription__
 *
 * To run a query within a React component, call `useRestoredEventLogSubscription` and pass it any options that fit your needs.
 * When your component renders, `useRestoredEventLogSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRestoredEventLogSubscription({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useRestoredEventLogSubscription(baseOptions?: Apollo.SubscriptionHookOptions<RestoredEventLogSubscription, RestoredEventLogSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<RestoredEventLogSubscription, RestoredEventLogSubscriptionVariables>(RestoredEventLogDocument, options);
      }
export type RestoredEventLogSubscriptionHookResult = ReturnType<typeof useRestoredEventLogSubscription>;
export type RestoredEventLogSubscriptionResult = Apollo.SubscriptionResult<RestoredEventLogSubscription>;
export const GetGitRepositoriesDocument = gql`
    query GetGitRepositories($filter: GitRepositoryFilterArgs, $paging: PageArgs, $sorting: [GitRepositorySortArgs!], $withBranches: Boolean = false, $withNotificationSettings: Boolean = false) {
  gitRepositories(filter: $filter, paging: $paging, sorting: $sorting) {
    items {
      ...gitRepositoryFields
    }
    totalCount
  }
}
    ${GitRepositoryFieldsFragmentDoc}`;

/**
 * __useGetGitRepositoriesQuery__
 *
 * To run a query within a React component, call `useGetGitRepositoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGitRepositoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGitRepositoriesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      paging: // value for 'paging'
 *      sorting: // value for 'sorting'
 *      withBranches: // value for 'withBranches'
 *      withNotificationSettings: // value for 'withNotificationSettings'
 *   },
 * });
 */
export function useGetGitRepositoriesQuery(baseOptions?: Apollo.QueryHookOptions<GetGitRepositoriesQuery, GetGitRepositoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGitRepositoriesQuery, GetGitRepositoriesQueryVariables>(GetGitRepositoriesDocument, options);
      }
export function useGetGitRepositoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGitRepositoriesQuery, GetGitRepositoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGitRepositoriesQuery, GetGitRepositoriesQueryVariables>(GetGitRepositoriesDocument, options);
        }
export function useGetGitRepositoriesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetGitRepositoriesQuery, GetGitRepositoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetGitRepositoriesQuery, GetGitRepositoriesQueryVariables>(GetGitRepositoriesDocument, options);
        }
export type GetGitRepositoriesQueryHookResult = ReturnType<typeof useGetGitRepositoriesQuery>;
export type GetGitRepositoriesLazyQueryHookResult = ReturnType<typeof useGetGitRepositoriesLazyQuery>;
export type GetGitRepositoriesSuspenseQueryHookResult = ReturnType<typeof useGetGitRepositoriesSuspenseQuery>;
export type GetGitRepositoriesQueryResult = Apollo.QueryResult<GetGitRepositoriesQuery, GetGitRepositoriesQueryVariables>;
export const GetGitRepositoryDocument = gql`
    query GetGitRepository($id: ID!, $withBranches: Boolean = false, $withNotificationSettings: Boolean = false) {
  gitRepository(id: $id) {
    ...gitRepositoryFields
  }
}
    ${GitRepositoryFieldsFragmentDoc}`;

/**
 * __useGetGitRepositoryQuery__
 *
 * To run a query within a React component, call `useGetGitRepositoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGitRepositoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGitRepositoryQuery({
 *   variables: {
 *      id: // value for 'id'
 *      withBranches: // value for 'withBranches'
 *      withNotificationSettings: // value for 'withNotificationSettings'
 *   },
 * });
 */
export function useGetGitRepositoryQuery(baseOptions: Apollo.QueryHookOptions<GetGitRepositoryQuery, GetGitRepositoryQueryVariables> & ({ variables: GetGitRepositoryQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGitRepositoryQuery, GetGitRepositoryQueryVariables>(GetGitRepositoryDocument, options);
      }
export function useGetGitRepositoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGitRepositoryQuery, GetGitRepositoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGitRepositoryQuery, GetGitRepositoryQueryVariables>(GetGitRepositoryDocument, options);
        }
export function useGetGitRepositorySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetGitRepositoryQuery, GetGitRepositoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetGitRepositoryQuery, GetGitRepositoryQueryVariables>(GetGitRepositoryDocument, options);
        }
export type GetGitRepositoryQueryHookResult = ReturnType<typeof useGetGitRepositoryQuery>;
export type GetGitRepositoryLazyQueryHookResult = ReturnType<typeof useGetGitRepositoryLazyQuery>;
export type GetGitRepositorySuspenseQueryHookResult = ReturnType<typeof useGetGitRepositorySuspenseQuery>;
export type GetGitRepositoryQueryResult = Apollo.QueryResult<GetGitRepositoryQuery, GetGitRepositoryQueryVariables>;
export const GetAllRelatedDataForGitRepositoryDocument = gql`
    query GetAllRelatedDataForGitRepository($withBranches: Boolean = false, $withNotificationSettings: Boolean = false) {
  branches @include(if: $withBranches) {
    items {
      id
      displayField: name
    }
  }
  notificationSettings @include(if: $withNotificationSettings) {
    items {
      id
      displayField: id
    }
  }
}
    `;

/**
 * __useGetAllRelatedDataForGitRepositoryQuery__
 *
 * To run a query within a React component, call `useGetAllRelatedDataForGitRepositoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllRelatedDataForGitRepositoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllRelatedDataForGitRepositoryQuery({
 *   variables: {
 *      withBranches: // value for 'withBranches'
 *      withNotificationSettings: // value for 'withNotificationSettings'
 *   },
 * });
 */
export function useGetAllRelatedDataForGitRepositoryQuery(baseOptions?: Apollo.QueryHookOptions<GetAllRelatedDataForGitRepositoryQuery, GetAllRelatedDataForGitRepositoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllRelatedDataForGitRepositoryQuery, GetAllRelatedDataForGitRepositoryQueryVariables>(GetAllRelatedDataForGitRepositoryDocument, options);
      }
export function useGetAllRelatedDataForGitRepositoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllRelatedDataForGitRepositoryQuery, GetAllRelatedDataForGitRepositoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllRelatedDataForGitRepositoryQuery, GetAllRelatedDataForGitRepositoryQueryVariables>(GetAllRelatedDataForGitRepositoryDocument, options);
        }
export function useGetAllRelatedDataForGitRepositorySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetAllRelatedDataForGitRepositoryQuery, GetAllRelatedDataForGitRepositoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAllRelatedDataForGitRepositoryQuery, GetAllRelatedDataForGitRepositoryQueryVariables>(GetAllRelatedDataForGitRepositoryDocument, options);
        }
export type GetAllRelatedDataForGitRepositoryQueryHookResult = ReturnType<typeof useGetAllRelatedDataForGitRepositoryQuery>;
export type GetAllRelatedDataForGitRepositoryLazyQueryHookResult = ReturnType<typeof useGetAllRelatedDataForGitRepositoryLazyQuery>;
export type GetAllRelatedDataForGitRepositorySuspenseQueryHookResult = ReturnType<typeof useGetAllRelatedDataForGitRepositorySuspenseQuery>;
export type GetAllRelatedDataForGitRepositoryQueryResult = Apollo.QueryResult<GetAllRelatedDataForGitRepositoryQuery, GetAllRelatedDataForGitRepositoryQueryVariables>;
export const CreateGitRepositoryDocument = gql`
    mutation CreateGitRepository($name: String!, $branches: [ID], $notificationSettings: [ID], $withBranches: Boolean = false, $withNotificationSettings: Boolean = false) {
  createGitRepository(
    name: $name
    branchIds: $branches
    notificationSettingIds: $notificationSettings
  ) {
    ...gitRepositoryFields
  }
}
    ${GitRepositoryFieldsFragmentDoc}`;
export type CreateGitRepositoryMutationFn = Apollo.MutationFunction<CreateGitRepositoryMutation, CreateGitRepositoryMutationVariables>;

/**
 * __useCreateGitRepositoryMutation__
 *
 * To run a mutation, you first call `useCreateGitRepositoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGitRepositoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGitRepositoryMutation, { data, loading, error }] = useCreateGitRepositoryMutation({
 *   variables: {
 *      name: // value for 'name'
 *      branches: // value for 'branches'
 *      notificationSettings: // value for 'notificationSettings'
 *      withBranches: // value for 'withBranches'
 *      withNotificationSettings: // value for 'withNotificationSettings'
 *   },
 * });
 */
export function useCreateGitRepositoryMutation(baseOptions?: Apollo.MutationHookOptions<CreateGitRepositoryMutation, CreateGitRepositoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateGitRepositoryMutation, CreateGitRepositoryMutationVariables>(CreateGitRepositoryDocument, options);
      }
export type CreateGitRepositoryMutationHookResult = ReturnType<typeof useCreateGitRepositoryMutation>;
export type CreateGitRepositoryMutationResult = Apollo.MutationResult<CreateGitRepositoryMutation>;
export type CreateGitRepositoryMutationOptions = Apollo.BaseMutationOptions<CreateGitRepositoryMutation, CreateGitRepositoryMutationVariables>;
export const UpdateGitRepositoryDocument = gql`
    mutation UpdateGitRepository($id: ID!, $name: String, $branches: [ID], $notificationSettings: [ID], $withBranches: Boolean = false, $withNotificationSettings: Boolean = false) {
  updateGitRepository(
    id: $id
    name: $name
    branchIds: $branches
    notificationSettingIds: $notificationSettings
  ) {
    ...gitRepositoryFields
  }
}
    ${GitRepositoryFieldsFragmentDoc}`;
export type UpdateGitRepositoryMutationFn = Apollo.MutationFunction<UpdateGitRepositoryMutation, UpdateGitRepositoryMutationVariables>;

/**
 * __useUpdateGitRepositoryMutation__
 *
 * To run a mutation, you first call `useUpdateGitRepositoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGitRepositoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGitRepositoryMutation, { data, loading, error }] = useUpdateGitRepositoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      branches: // value for 'branches'
 *      notificationSettings: // value for 'notificationSettings'
 *      withBranches: // value for 'withBranches'
 *      withNotificationSettings: // value for 'withNotificationSettings'
 *   },
 * });
 */
export function useUpdateGitRepositoryMutation(baseOptions?: Apollo.MutationHookOptions<UpdateGitRepositoryMutation, UpdateGitRepositoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateGitRepositoryMutation, UpdateGitRepositoryMutationVariables>(UpdateGitRepositoryDocument, options);
      }
export type UpdateGitRepositoryMutationHookResult = ReturnType<typeof useUpdateGitRepositoryMutation>;
export type UpdateGitRepositoryMutationResult = Apollo.MutationResult<UpdateGitRepositoryMutation>;
export type UpdateGitRepositoryMutationOptions = Apollo.BaseMutationOptions<UpdateGitRepositoryMutation, UpdateGitRepositoryMutationVariables>;
export const DeleteGitRepositoryDocument = gql`
    mutation DeleteGitRepository($id: ID!, $withBranches: Boolean = false, $withNotificationSettings: Boolean = false) {
  deleteGitRepository(id: $id) {
    ...gitRepositoryFields
  }
}
    ${GitRepositoryFieldsFragmentDoc}`;
export type DeleteGitRepositoryMutationFn = Apollo.MutationFunction<DeleteGitRepositoryMutation, DeleteGitRepositoryMutationVariables>;

/**
 * __useDeleteGitRepositoryMutation__
 *
 * To run a mutation, you first call `useDeleteGitRepositoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGitRepositoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGitRepositoryMutation, { data, loading, error }] = useDeleteGitRepositoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *      withBranches: // value for 'withBranches'
 *      withNotificationSettings: // value for 'withNotificationSettings'
 *   },
 * });
 */
export function useDeleteGitRepositoryMutation(baseOptions?: Apollo.MutationHookOptions<DeleteGitRepositoryMutation, DeleteGitRepositoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteGitRepositoryMutation, DeleteGitRepositoryMutationVariables>(DeleteGitRepositoryDocument, options);
      }
export type DeleteGitRepositoryMutationHookResult = ReturnType<typeof useDeleteGitRepositoryMutation>;
export type DeleteGitRepositoryMutationResult = Apollo.MutationResult<DeleteGitRepositoryMutation>;
export type DeleteGitRepositoryMutationOptions = Apollo.BaseMutationOptions<DeleteGitRepositoryMutation, DeleteGitRepositoryMutationVariables>;
export const CreatedGitRepositoryDocument = gql`
    subscription CreatedGitRepository($filter: GitRepositoryFilterArgs, $withBranches: Boolean = false, $withNotificationSettings: Boolean = false) {
  createdGitRepository(filter: $filter) {
    ...gitRepositoryFields
  }
}
    ${GitRepositoryFieldsFragmentDoc}`;

/**
 * __useCreatedGitRepositorySubscription__
 *
 * To run a query within a React component, call `useCreatedGitRepositorySubscription` and pass it any options that fit your needs.
 * When your component renders, `useCreatedGitRepositorySubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreatedGitRepositorySubscription({
 *   variables: {
 *      filter: // value for 'filter'
 *      withBranches: // value for 'withBranches'
 *      withNotificationSettings: // value for 'withNotificationSettings'
 *   },
 * });
 */
export function useCreatedGitRepositorySubscription(baseOptions?: Apollo.SubscriptionHookOptions<CreatedGitRepositorySubscription, CreatedGitRepositorySubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<CreatedGitRepositorySubscription, CreatedGitRepositorySubscriptionVariables>(CreatedGitRepositoryDocument, options);
      }
export type CreatedGitRepositorySubscriptionHookResult = ReturnType<typeof useCreatedGitRepositorySubscription>;
export type CreatedGitRepositorySubscriptionResult = Apollo.SubscriptionResult<CreatedGitRepositorySubscription>;
export const UpdatedGitRepositoryDocument = gql`
    subscription UpdatedGitRepository($filter: GitRepositoryFilterArgs, $withBranches: Boolean = false, $withNotificationSettings: Boolean = false) {
  updatedGitRepository(filter: $filter) {
    ...gitRepositoryFields
  }
}
    ${GitRepositoryFieldsFragmentDoc}`;

/**
 * __useUpdatedGitRepositorySubscription__
 *
 * To run a query within a React component, call `useUpdatedGitRepositorySubscription` and pass it any options that fit your needs.
 * When your component renders, `useUpdatedGitRepositorySubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpdatedGitRepositorySubscription({
 *   variables: {
 *      filter: // value for 'filter'
 *      withBranches: // value for 'withBranches'
 *      withNotificationSettings: // value for 'withNotificationSettings'
 *   },
 * });
 */
export function useUpdatedGitRepositorySubscription(baseOptions?: Apollo.SubscriptionHookOptions<UpdatedGitRepositorySubscription, UpdatedGitRepositorySubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<UpdatedGitRepositorySubscription, UpdatedGitRepositorySubscriptionVariables>(UpdatedGitRepositoryDocument, options);
      }
export type UpdatedGitRepositorySubscriptionHookResult = ReturnType<typeof useUpdatedGitRepositorySubscription>;
export type UpdatedGitRepositorySubscriptionResult = Apollo.SubscriptionResult<UpdatedGitRepositorySubscription>;
export const DeletedGitRepositoryDocument = gql`
    subscription DeletedGitRepository($filter: GitRepositoryFilterArgs) {
  deletedGitRepository(filter: $filter) {
    id
  }
}
    `;

/**
 * __useDeletedGitRepositorySubscription__
 *
 * To run a query within a React component, call `useDeletedGitRepositorySubscription` and pass it any options that fit your needs.
 * When your component renders, `useDeletedGitRepositorySubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeletedGitRepositorySubscription({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useDeletedGitRepositorySubscription(baseOptions?: Apollo.SubscriptionHookOptions<DeletedGitRepositorySubscription, DeletedGitRepositorySubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<DeletedGitRepositorySubscription, DeletedGitRepositorySubscriptionVariables>(DeletedGitRepositoryDocument, options);
      }
export type DeletedGitRepositorySubscriptionHookResult = ReturnType<typeof useDeletedGitRepositorySubscription>;
export type DeletedGitRepositorySubscriptionResult = Apollo.SubscriptionResult<DeletedGitRepositorySubscription>;
export const RestoredGitRepositoryDocument = gql`
    subscription RestoredGitRepository($filter: GitRepositoryFilterArgs, $withBranches: Boolean = false, $withNotificationSettings: Boolean = false) {
  restoredGitRepository(filter: $filter) {
    ...gitRepositoryFields
  }
}
    ${GitRepositoryFieldsFragmentDoc}`;

/**
 * __useRestoredGitRepositorySubscription__
 *
 * To run a query within a React component, call `useRestoredGitRepositorySubscription` and pass it any options that fit your needs.
 * When your component renders, `useRestoredGitRepositorySubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRestoredGitRepositorySubscription({
 *   variables: {
 *      filter: // value for 'filter'
 *      withBranches: // value for 'withBranches'
 *      withNotificationSettings: // value for 'withNotificationSettings'
 *   },
 * });
 */
export function useRestoredGitRepositorySubscription(baseOptions?: Apollo.SubscriptionHookOptions<RestoredGitRepositorySubscription, RestoredGitRepositorySubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<RestoredGitRepositorySubscription, RestoredGitRepositorySubscriptionVariables>(RestoredGitRepositoryDocument, options);
      }
export type RestoredGitRepositorySubscriptionHookResult = ReturnType<typeof useRestoredGitRepositorySubscription>;
export type RestoredGitRepositorySubscriptionResult = Apollo.SubscriptionResult<RestoredGitRepositorySubscription>;
export const GetJobsDocument = gql`
    query GetJobs($filter: JobFilterArgs, $paging: PageArgs, $sorting: [JobSortArgs!]) {
  jobs(filter: $filter, paging: $paging, sorting: $sorting) {
    items {
      ...jobFields
    }
    totalCount
  }
}
    ${JobFieldsFragmentDoc}`;

/**
 * __useGetJobsQuery__
 *
 * To run a query within a React component, call `useGetJobsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      paging: // value for 'paging'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useGetJobsQuery(baseOptions?: Apollo.QueryHookOptions<GetJobsQuery, GetJobsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetJobsQuery, GetJobsQueryVariables>(GetJobsDocument, options);
      }
export function useGetJobsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetJobsQuery, GetJobsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetJobsQuery, GetJobsQueryVariables>(GetJobsDocument, options);
        }
export function useGetJobsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetJobsQuery, GetJobsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetJobsQuery, GetJobsQueryVariables>(GetJobsDocument, options);
        }
export type GetJobsQueryHookResult = ReturnType<typeof useGetJobsQuery>;
export type GetJobsLazyQueryHookResult = ReturnType<typeof useGetJobsLazyQuery>;
export type GetJobsSuspenseQueryHookResult = ReturnType<typeof useGetJobsSuspenseQuery>;
export type GetJobsQueryResult = Apollo.QueryResult<GetJobsQuery, GetJobsQueryVariables>;
export const GetJobDocument = gql`
    query GetJob($id: ID!) {
  job(id: $id) {
    ...jobFields
  }
}
    ${JobFieldsFragmentDoc}`;

/**
 * __useGetJobQuery__
 *
 * To run a query within a React component, call `useGetJobQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetJobQuery(baseOptions: Apollo.QueryHookOptions<GetJobQuery, GetJobQueryVariables> & ({ variables: GetJobQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetJobQuery, GetJobQueryVariables>(GetJobDocument, options);
      }
export function useGetJobLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetJobQuery, GetJobQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetJobQuery, GetJobQueryVariables>(GetJobDocument, options);
        }
export function useGetJobSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetJobQuery, GetJobQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetJobQuery, GetJobQueryVariables>(GetJobDocument, options);
        }
export type GetJobQueryHookResult = ReturnType<typeof useGetJobQuery>;
export type GetJobLazyQueryHookResult = ReturnType<typeof useGetJobLazyQuery>;
export type GetJobSuspenseQueryHookResult = ReturnType<typeof useGetJobSuspenseQuery>;
export type GetJobQueryResult = Apollo.QueryResult<GetJobQuery, GetJobQueryVariables>;
export const CreateJobDocument = gql`
    mutation CreateJob($queue: String!, $status: String!, $log: String) {
  createJob(queue: $queue, status: $status, log: $log) {
    ...jobFields
  }
}
    ${JobFieldsFragmentDoc}`;
export type CreateJobMutationFn = Apollo.MutationFunction<CreateJobMutation, CreateJobMutationVariables>;

/**
 * __useCreateJobMutation__
 *
 * To run a mutation, you first call `useCreateJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createJobMutation, { data, loading, error }] = useCreateJobMutation({
 *   variables: {
 *      queue: // value for 'queue'
 *      status: // value for 'status'
 *      log: // value for 'log'
 *   },
 * });
 */
export function useCreateJobMutation(baseOptions?: Apollo.MutationHookOptions<CreateJobMutation, CreateJobMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateJobMutation, CreateJobMutationVariables>(CreateJobDocument, options);
      }
export type CreateJobMutationHookResult = ReturnType<typeof useCreateJobMutation>;
export type CreateJobMutationResult = Apollo.MutationResult<CreateJobMutation>;
export type CreateJobMutationOptions = Apollo.BaseMutationOptions<CreateJobMutation, CreateJobMutationVariables>;
export const UpdateJobDocument = gql`
    mutation UpdateJob($id: ID!, $queue: String, $status: String, $log: String) {
  updateJob(id: $id, queue: $queue, status: $status, log: $log) {
    ...jobFields
  }
}
    ${JobFieldsFragmentDoc}`;
export type UpdateJobMutationFn = Apollo.MutationFunction<UpdateJobMutation, UpdateJobMutationVariables>;

/**
 * __useUpdateJobMutation__
 *
 * To run a mutation, you first call `useUpdateJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateJobMutation, { data, loading, error }] = useUpdateJobMutation({
 *   variables: {
 *      id: // value for 'id'
 *      queue: // value for 'queue'
 *      status: // value for 'status'
 *      log: // value for 'log'
 *   },
 * });
 */
export function useUpdateJobMutation(baseOptions?: Apollo.MutationHookOptions<UpdateJobMutation, UpdateJobMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateJobMutation, UpdateJobMutationVariables>(UpdateJobDocument, options);
      }
export type UpdateJobMutationHookResult = ReturnType<typeof useUpdateJobMutation>;
export type UpdateJobMutationResult = Apollo.MutationResult<UpdateJobMutation>;
export type UpdateJobMutationOptions = Apollo.BaseMutationOptions<UpdateJobMutation, UpdateJobMutationVariables>;
export const DeleteJobDocument = gql`
    mutation DeleteJob($id: ID!) {
  deleteJob(id: $id) {
    ...jobFields
  }
}
    ${JobFieldsFragmentDoc}`;
export type DeleteJobMutationFn = Apollo.MutationFunction<DeleteJobMutation, DeleteJobMutationVariables>;

/**
 * __useDeleteJobMutation__
 *
 * To run a mutation, you first call `useDeleteJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteJobMutation, { data, loading, error }] = useDeleteJobMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteJobMutation(baseOptions?: Apollo.MutationHookOptions<DeleteJobMutation, DeleteJobMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteJobMutation, DeleteJobMutationVariables>(DeleteJobDocument, options);
      }
export type DeleteJobMutationHookResult = ReturnType<typeof useDeleteJobMutation>;
export type DeleteJobMutationResult = Apollo.MutationResult<DeleteJobMutation>;
export type DeleteJobMutationOptions = Apollo.BaseMutationOptions<DeleteJobMutation, DeleteJobMutationVariables>;
export const CreatedJobDocument = gql`
    subscription CreatedJob($filter: JobFilterArgs) {
  createdJob(filter: $filter) {
    ...jobFields
  }
}
    ${JobFieldsFragmentDoc}`;

/**
 * __useCreatedJobSubscription__
 *
 * To run a query within a React component, call `useCreatedJobSubscription` and pass it any options that fit your needs.
 * When your component renders, `useCreatedJobSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreatedJobSubscription({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCreatedJobSubscription(baseOptions?: Apollo.SubscriptionHookOptions<CreatedJobSubscription, CreatedJobSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<CreatedJobSubscription, CreatedJobSubscriptionVariables>(CreatedJobDocument, options);
      }
export type CreatedJobSubscriptionHookResult = ReturnType<typeof useCreatedJobSubscription>;
export type CreatedJobSubscriptionResult = Apollo.SubscriptionResult<CreatedJobSubscription>;
export const UpdatedJobDocument = gql`
    subscription UpdatedJob($filter: JobFilterArgs) {
  updatedJob(filter: $filter) {
    ...jobFields
  }
}
    ${JobFieldsFragmentDoc}`;

/**
 * __useUpdatedJobSubscription__
 *
 * To run a query within a React component, call `useUpdatedJobSubscription` and pass it any options that fit your needs.
 * When your component renders, `useUpdatedJobSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpdatedJobSubscription({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useUpdatedJobSubscription(baseOptions?: Apollo.SubscriptionHookOptions<UpdatedJobSubscription, UpdatedJobSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<UpdatedJobSubscription, UpdatedJobSubscriptionVariables>(UpdatedJobDocument, options);
      }
export type UpdatedJobSubscriptionHookResult = ReturnType<typeof useUpdatedJobSubscription>;
export type UpdatedJobSubscriptionResult = Apollo.SubscriptionResult<UpdatedJobSubscription>;
export const DeletedJobDocument = gql`
    subscription DeletedJob($filter: JobFilterArgs) {
  deletedJob(filter: $filter) {
    id
  }
}
    `;

/**
 * __useDeletedJobSubscription__
 *
 * To run a query within a React component, call `useDeletedJobSubscription` and pass it any options that fit your needs.
 * When your component renders, `useDeletedJobSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeletedJobSubscription({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useDeletedJobSubscription(baseOptions?: Apollo.SubscriptionHookOptions<DeletedJobSubscription, DeletedJobSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<DeletedJobSubscription, DeletedJobSubscriptionVariables>(DeletedJobDocument, options);
      }
export type DeletedJobSubscriptionHookResult = ReturnType<typeof useDeletedJobSubscription>;
export type DeletedJobSubscriptionResult = Apollo.SubscriptionResult<DeletedJobSubscription>;
export const RestoredJobDocument = gql`
    subscription RestoredJob($filter: JobFilterArgs) {
  restoredJob(filter: $filter) {
    ...jobFields
  }
}
    ${JobFieldsFragmentDoc}`;

/**
 * __useRestoredJobSubscription__
 *
 * To run a query within a React component, call `useRestoredJobSubscription` and pass it any options that fit your needs.
 * When your component renders, `useRestoredJobSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRestoredJobSubscription({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useRestoredJobSubscription(baseOptions?: Apollo.SubscriptionHookOptions<RestoredJobSubscription, RestoredJobSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<RestoredJobSubscription, RestoredJobSubscriptionVariables>(RestoredJobDocument, options);
      }
export type RestoredJobSubscriptionHookResult = ReturnType<typeof useRestoredJobSubscription>;
export type RestoredJobSubscriptionResult = Apollo.SubscriptionResult<RestoredJobSubscription>;
export const GetMailLogsDocument = gql`
    query GetMailLogs($filter: MailLogFilterArgs, $paging: PageArgs, $sorting: [MailLogSortArgs!]) {
  mailLogs(filter: $filter, paging: $paging, sorting: $sorting) {
    items {
      ...mailLogFields
    }
    totalCount
  }
}
    ${MailLogFieldsFragmentDoc}`;

/**
 * __useGetMailLogsQuery__
 *
 * To run a query within a React component, call `useGetMailLogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMailLogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMailLogsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      paging: // value for 'paging'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useGetMailLogsQuery(baseOptions?: Apollo.QueryHookOptions<GetMailLogsQuery, GetMailLogsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMailLogsQuery, GetMailLogsQueryVariables>(GetMailLogsDocument, options);
      }
export function useGetMailLogsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMailLogsQuery, GetMailLogsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMailLogsQuery, GetMailLogsQueryVariables>(GetMailLogsDocument, options);
        }
export function useGetMailLogsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetMailLogsQuery, GetMailLogsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetMailLogsQuery, GetMailLogsQueryVariables>(GetMailLogsDocument, options);
        }
export type GetMailLogsQueryHookResult = ReturnType<typeof useGetMailLogsQuery>;
export type GetMailLogsLazyQueryHookResult = ReturnType<typeof useGetMailLogsLazyQuery>;
export type GetMailLogsSuspenseQueryHookResult = ReturnType<typeof useGetMailLogsSuspenseQuery>;
export type GetMailLogsQueryResult = Apollo.QueryResult<GetMailLogsQuery, GetMailLogsQueryVariables>;
export const GetMailLogDocument = gql`
    query GetMailLog($id: ID!) {
  mailLog(id: $id) {
    ...mailLogFields
  }
}
    ${MailLogFieldsFragmentDoc}`;

/**
 * __useGetMailLogQuery__
 *
 * To run a query within a React component, call `useGetMailLogQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMailLogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMailLogQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMailLogQuery(baseOptions: Apollo.QueryHookOptions<GetMailLogQuery, GetMailLogQueryVariables> & ({ variables: GetMailLogQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMailLogQuery, GetMailLogQueryVariables>(GetMailLogDocument, options);
      }
export function useGetMailLogLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMailLogQuery, GetMailLogQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMailLogQuery, GetMailLogQueryVariables>(GetMailLogDocument, options);
        }
export function useGetMailLogSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetMailLogQuery, GetMailLogQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetMailLogQuery, GetMailLogQueryVariables>(GetMailLogDocument, options);
        }
export type GetMailLogQueryHookResult = ReturnType<typeof useGetMailLogQuery>;
export type GetMailLogLazyQueryHookResult = ReturnType<typeof useGetMailLogLazyQuery>;
export type GetMailLogSuspenseQueryHookResult = ReturnType<typeof useGetMailLogSuspenseQuery>;
export type GetMailLogQueryResult = Apollo.QueryResult<GetMailLogQuery, GetMailLogQueryVariables>;
export const CreateMailLogDocument = gql`
    mutation CreateMailLog($sentTo: String!, $sentBy: String!, $emailTemplate: String, $status: String, $message: String, $reason: String) {
  createMailLog(
    sentTo: $sentTo
    sentBy: $sentBy
    emailTemplate: $emailTemplate
    status: $status
    message: $message
    reason: $reason
  ) {
    ...mailLogFields
  }
}
    ${MailLogFieldsFragmentDoc}`;
export type CreateMailLogMutationFn = Apollo.MutationFunction<CreateMailLogMutation, CreateMailLogMutationVariables>;

/**
 * __useCreateMailLogMutation__
 *
 * To run a mutation, you first call `useCreateMailLogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMailLogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMailLogMutation, { data, loading, error }] = useCreateMailLogMutation({
 *   variables: {
 *      sentTo: // value for 'sentTo'
 *      sentBy: // value for 'sentBy'
 *      emailTemplate: // value for 'emailTemplate'
 *      status: // value for 'status'
 *      message: // value for 'message'
 *      reason: // value for 'reason'
 *   },
 * });
 */
export function useCreateMailLogMutation(baseOptions?: Apollo.MutationHookOptions<CreateMailLogMutation, CreateMailLogMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMailLogMutation, CreateMailLogMutationVariables>(CreateMailLogDocument, options);
      }
export type CreateMailLogMutationHookResult = ReturnType<typeof useCreateMailLogMutation>;
export type CreateMailLogMutationResult = Apollo.MutationResult<CreateMailLogMutation>;
export type CreateMailLogMutationOptions = Apollo.BaseMutationOptions<CreateMailLogMutation, CreateMailLogMutationVariables>;
export const UpdateMailLogDocument = gql`
    mutation UpdateMailLog($id: ID!, $sentTo: String, $sentBy: String, $emailTemplate: String, $status: String, $message: String, $reason: String) {
  updateMailLog(
    id: $id
    sentTo: $sentTo
    sentBy: $sentBy
    emailTemplate: $emailTemplate
    status: $status
    message: $message
    reason: $reason
  ) {
    ...mailLogFields
  }
}
    ${MailLogFieldsFragmentDoc}`;
export type UpdateMailLogMutationFn = Apollo.MutationFunction<UpdateMailLogMutation, UpdateMailLogMutationVariables>;

/**
 * __useUpdateMailLogMutation__
 *
 * To run a mutation, you first call `useUpdateMailLogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMailLogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMailLogMutation, { data, loading, error }] = useUpdateMailLogMutation({
 *   variables: {
 *      id: // value for 'id'
 *      sentTo: // value for 'sentTo'
 *      sentBy: // value for 'sentBy'
 *      emailTemplate: // value for 'emailTemplate'
 *      status: // value for 'status'
 *      message: // value for 'message'
 *      reason: // value for 'reason'
 *   },
 * });
 */
export function useUpdateMailLogMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMailLogMutation, UpdateMailLogMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMailLogMutation, UpdateMailLogMutationVariables>(UpdateMailLogDocument, options);
      }
export type UpdateMailLogMutationHookResult = ReturnType<typeof useUpdateMailLogMutation>;
export type UpdateMailLogMutationResult = Apollo.MutationResult<UpdateMailLogMutation>;
export type UpdateMailLogMutationOptions = Apollo.BaseMutationOptions<UpdateMailLogMutation, UpdateMailLogMutationVariables>;
export const DeleteMailLogDocument = gql`
    mutation DeleteMailLog($id: ID!) {
  deleteMailLog(id: $id) {
    ...mailLogFields
  }
}
    ${MailLogFieldsFragmentDoc}`;
export type DeleteMailLogMutationFn = Apollo.MutationFunction<DeleteMailLogMutation, DeleteMailLogMutationVariables>;

/**
 * __useDeleteMailLogMutation__
 *
 * To run a mutation, you first call `useDeleteMailLogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMailLogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMailLogMutation, { data, loading, error }] = useDeleteMailLogMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteMailLogMutation(baseOptions?: Apollo.MutationHookOptions<DeleteMailLogMutation, DeleteMailLogMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteMailLogMutation, DeleteMailLogMutationVariables>(DeleteMailLogDocument, options);
      }
export type DeleteMailLogMutationHookResult = ReturnType<typeof useDeleteMailLogMutation>;
export type DeleteMailLogMutationResult = Apollo.MutationResult<DeleteMailLogMutation>;
export type DeleteMailLogMutationOptions = Apollo.BaseMutationOptions<DeleteMailLogMutation, DeleteMailLogMutationVariables>;
export const CreatedMailLogDocument = gql`
    subscription CreatedMailLog($filter: MailLogFilterArgs) {
  createdMailLog(filter: $filter) {
    ...mailLogFields
  }
}
    ${MailLogFieldsFragmentDoc}`;

/**
 * __useCreatedMailLogSubscription__
 *
 * To run a query within a React component, call `useCreatedMailLogSubscription` and pass it any options that fit your needs.
 * When your component renders, `useCreatedMailLogSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreatedMailLogSubscription({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCreatedMailLogSubscription(baseOptions?: Apollo.SubscriptionHookOptions<CreatedMailLogSubscription, CreatedMailLogSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<CreatedMailLogSubscription, CreatedMailLogSubscriptionVariables>(CreatedMailLogDocument, options);
      }
export type CreatedMailLogSubscriptionHookResult = ReturnType<typeof useCreatedMailLogSubscription>;
export type CreatedMailLogSubscriptionResult = Apollo.SubscriptionResult<CreatedMailLogSubscription>;
export const UpdatedMailLogDocument = gql`
    subscription UpdatedMailLog($filter: MailLogFilterArgs) {
  updatedMailLog(filter: $filter) {
    ...mailLogFields
  }
}
    ${MailLogFieldsFragmentDoc}`;

/**
 * __useUpdatedMailLogSubscription__
 *
 * To run a query within a React component, call `useUpdatedMailLogSubscription` and pass it any options that fit your needs.
 * When your component renders, `useUpdatedMailLogSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpdatedMailLogSubscription({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useUpdatedMailLogSubscription(baseOptions?: Apollo.SubscriptionHookOptions<UpdatedMailLogSubscription, UpdatedMailLogSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<UpdatedMailLogSubscription, UpdatedMailLogSubscriptionVariables>(UpdatedMailLogDocument, options);
      }
export type UpdatedMailLogSubscriptionHookResult = ReturnType<typeof useUpdatedMailLogSubscription>;
export type UpdatedMailLogSubscriptionResult = Apollo.SubscriptionResult<UpdatedMailLogSubscription>;
export const DeletedMailLogDocument = gql`
    subscription DeletedMailLog($filter: MailLogFilterArgs) {
  deletedMailLog(filter: $filter) {
    id
  }
}
    `;

/**
 * __useDeletedMailLogSubscription__
 *
 * To run a query within a React component, call `useDeletedMailLogSubscription` and pass it any options that fit your needs.
 * When your component renders, `useDeletedMailLogSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeletedMailLogSubscription({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useDeletedMailLogSubscription(baseOptions?: Apollo.SubscriptionHookOptions<DeletedMailLogSubscription, DeletedMailLogSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<DeletedMailLogSubscription, DeletedMailLogSubscriptionVariables>(DeletedMailLogDocument, options);
      }
export type DeletedMailLogSubscriptionHookResult = ReturnType<typeof useDeletedMailLogSubscription>;
export type DeletedMailLogSubscriptionResult = Apollo.SubscriptionResult<DeletedMailLogSubscription>;
export const RestoredMailLogDocument = gql`
    subscription RestoredMailLog($filter: MailLogFilterArgs) {
  restoredMailLog(filter: $filter) {
    ...mailLogFields
  }
}
    ${MailLogFieldsFragmentDoc}`;

/**
 * __useRestoredMailLogSubscription__
 *
 * To run a query within a React component, call `useRestoredMailLogSubscription` and pass it any options that fit your needs.
 * When your component renders, `useRestoredMailLogSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRestoredMailLogSubscription({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useRestoredMailLogSubscription(baseOptions?: Apollo.SubscriptionHookOptions<RestoredMailLogSubscription, RestoredMailLogSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<RestoredMailLogSubscription, RestoredMailLogSubscriptionVariables>(RestoredMailLogDocument, options);
      }
export type RestoredMailLogSubscriptionHookResult = ReturnType<typeof useRestoredMailLogSubscription>;
export type RestoredMailLogSubscriptionResult = Apollo.SubscriptionResult<RestoredMailLogSubscription>;
export const GetMailTemplatesDocument = gql`
    query GetMailTemplates($filter: MailTemplateFilterArgs, $paging: PageArgs, $sorting: [MailTemplateSortArgs!], $withMailTemplateFills: Boolean = false) {
  mailTemplates(filter: $filter, paging: $paging, sorting: $sorting) {
    items {
      ...mailTemplateFields
    }
    totalCount
  }
}
    ${MailTemplateFieldsFragmentDoc}`;

/**
 * __useGetMailTemplatesQuery__
 *
 * To run a query within a React component, call `useGetMailTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMailTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMailTemplatesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      paging: // value for 'paging'
 *      sorting: // value for 'sorting'
 *      withMailTemplateFills: // value for 'withMailTemplateFills'
 *   },
 * });
 */
export function useGetMailTemplatesQuery(baseOptions?: Apollo.QueryHookOptions<GetMailTemplatesQuery, GetMailTemplatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMailTemplatesQuery, GetMailTemplatesQueryVariables>(GetMailTemplatesDocument, options);
      }
export function useGetMailTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMailTemplatesQuery, GetMailTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMailTemplatesQuery, GetMailTemplatesQueryVariables>(GetMailTemplatesDocument, options);
        }
export function useGetMailTemplatesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetMailTemplatesQuery, GetMailTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetMailTemplatesQuery, GetMailTemplatesQueryVariables>(GetMailTemplatesDocument, options);
        }
export type GetMailTemplatesQueryHookResult = ReturnType<typeof useGetMailTemplatesQuery>;
export type GetMailTemplatesLazyQueryHookResult = ReturnType<typeof useGetMailTemplatesLazyQuery>;
export type GetMailTemplatesSuspenseQueryHookResult = ReturnType<typeof useGetMailTemplatesSuspenseQuery>;
export type GetMailTemplatesQueryResult = Apollo.QueryResult<GetMailTemplatesQuery, GetMailTemplatesQueryVariables>;
export const GetMailTemplateDocument = gql`
    query GetMailTemplate($id: ID!, $withMailTemplateFills: Boolean = false) {
  mailTemplate(id: $id) {
    ...mailTemplateFields
  }
}
    ${MailTemplateFieldsFragmentDoc}`;

/**
 * __useGetMailTemplateQuery__
 *
 * To run a query within a React component, call `useGetMailTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMailTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMailTemplateQuery({
 *   variables: {
 *      id: // value for 'id'
 *      withMailTemplateFills: // value for 'withMailTemplateFills'
 *   },
 * });
 */
export function useGetMailTemplateQuery(baseOptions: Apollo.QueryHookOptions<GetMailTemplateQuery, GetMailTemplateQueryVariables> & ({ variables: GetMailTemplateQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMailTemplateQuery, GetMailTemplateQueryVariables>(GetMailTemplateDocument, options);
      }
export function useGetMailTemplateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMailTemplateQuery, GetMailTemplateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMailTemplateQuery, GetMailTemplateQueryVariables>(GetMailTemplateDocument, options);
        }
export function useGetMailTemplateSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetMailTemplateQuery, GetMailTemplateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetMailTemplateQuery, GetMailTemplateQueryVariables>(GetMailTemplateDocument, options);
        }
export type GetMailTemplateQueryHookResult = ReturnType<typeof useGetMailTemplateQuery>;
export type GetMailTemplateLazyQueryHookResult = ReturnType<typeof useGetMailTemplateLazyQuery>;
export type GetMailTemplateSuspenseQueryHookResult = ReturnType<typeof useGetMailTemplateSuspenseQuery>;
export type GetMailTemplateQueryResult = Apollo.QueryResult<GetMailTemplateQuery, GetMailTemplateQueryVariables>;
export const GetAllRelatedDataForMailTemplateDocument = gql`
    query GetAllRelatedDataForMailTemplate($withMailTemplateFills: Boolean = false) {
  mailTemplateFills @include(if: $withMailTemplateFills) {
    items {
      id
      displayField: title
    }
  }
}
    `;

/**
 * __useGetAllRelatedDataForMailTemplateQuery__
 *
 * To run a query within a React component, call `useGetAllRelatedDataForMailTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllRelatedDataForMailTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllRelatedDataForMailTemplateQuery({
 *   variables: {
 *      withMailTemplateFills: // value for 'withMailTemplateFills'
 *   },
 * });
 */
export function useGetAllRelatedDataForMailTemplateQuery(baseOptions?: Apollo.QueryHookOptions<GetAllRelatedDataForMailTemplateQuery, GetAllRelatedDataForMailTemplateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllRelatedDataForMailTemplateQuery, GetAllRelatedDataForMailTemplateQueryVariables>(GetAllRelatedDataForMailTemplateDocument, options);
      }
export function useGetAllRelatedDataForMailTemplateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllRelatedDataForMailTemplateQuery, GetAllRelatedDataForMailTemplateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllRelatedDataForMailTemplateQuery, GetAllRelatedDataForMailTemplateQueryVariables>(GetAllRelatedDataForMailTemplateDocument, options);
        }
export function useGetAllRelatedDataForMailTemplateSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetAllRelatedDataForMailTemplateQuery, GetAllRelatedDataForMailTemplateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAllRelatedDataForMailTemplateQuery, GetAllRelatedDataForMailTemplateQueryVariables>(GetAllRelatedDataForMailTemplateDocument, options);
        }
export type GetAllRelatedDataForMailTemplateQueryHookResult = ReturnType<typeof useGetAllRelatedDataForMailTemplateQuery>;
export type GetAllRelatedDataForMailTemplateLazyQueryHookResult = ReturnType<typeof useGetAllRelatedDataForMailTemplateLazyQuery>;
export type GetAllRelatedDataForMailTemplateSuspenseQueryHookResult = ReturnType<typeof useGetAllRelatedDataForMailTemplateSuspenseQuery>;
export type GetAllRelatedDataForMailTemplateQueryResult = Apollo.QueryResult<GetAllRelatedDataForMailTemplateQuery, GetAllRelatedDataForMailTemplateQueryVariables>;
export const CreateMailTemplateDocument = gql`
    mutation CreateMailTemplate($active: Boolean!, $mailTemplateFills: [ID], $withMailTemplateFills: Boolean = false) {
  createMailTemplate(active: $active, mailTemplateFillIds: $mailTemplateFills) {
    ...mailTemplateFields
  }
}
    ${MailTemplateFieldsFragmentDoc}`;
export type CreateMailTemplateMutationFn = Apollo.MutationFunction<CreateMailTemplateMutation, CreateMailTemplateMutationVariables>;

/**
 * __useCreateMailTemplateMutation__
 *
 * To run a mutation, you first call `useCreateMailTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMailTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMailTemplateMutation, { data, loading, error }] = useCreateMailTemplateMutation({
 *   variables: {
 *      active: // value for 'active'
 *      mailTemplateFills: // value for 'mailTemplateFills'
 *      withMailTemplateFills: // value for 'withMailTemplateFills'
 *   },
 * });
 */
export function useCreateMailTemplateMutation(baseOptions?: Apollo.MutationHookOptions<CreateMailTemplateMutation, CreateMailTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMailTemplateMutation, CreateMailTemplateMutationVariables>(CreateMailTemplateDocument, options);
      }
export type CreateMailTemplateMutationHookResult = ReturnType<typeof useCreateMailTemplateMutation>;
export type CreateMailTemplateMutationResult = Apollo.MutationResult<CreateMailTemplateMutation>;
export type CreateMailTemplateMutationOptions = Apollo.BaseMutationOptions<CreateMailTemplateMutation, CreateMailTemplateMutationVariables>;
export const UpdateMailTemplateDocument = gql`
    mutation UpdateMailTemplate($id: ID!, $active: Boolean, $mailTemplateFills: [ID], $withMailTemplateFills: Boolean = false) {
  updateMailTemplate(
    id: $id
    active: $active
    mailTemplateFillIds: $mailTemplateFills
  ) {
    ...mailTemplateFields
  }
}
    ${MailTemplateFieldsFragmentDoc}`;
export type UpdateMailTemplateMutationFn = Apollo.MutationFunction<UpdateMailTemplateMutation, UpdateMailTemplateMutationVariables>;

/**
 * __useUpdateMailTemplateMutation__
 *
 * To run a mutation, you first call `useUpdateMailTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMailTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMailTemplateMutation, { data, loading, error }] = useUpdateMailTemplateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      active: // value for 'active'
 *      mailTemplateFills: // value for 'mailTemplateFills'
 *      withMailTemplateFills: // value for 'withMailTemplateFills'
 *   },
 * });
 */
export function useUpdateMailTemplateMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMailTemplateMutation, UpdateMailTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMailTemplateMutation, UpdateMailTemplateMutationVariables>(UpdateMailTemplateDocument, options);
      }
export type UpdateMailTemplateMutationHookResult = ReturnType<typeof useUpdateMailTemplateMutation>;
export type UpdateMailTemplateMutationResult = Apollo.MutationResult<UpdateMailTemplateMutation>;
export type UpdateMailTemplateMutationOptions = Apollo.BaseMutationOptions<UpdateMailTemplateMutation, UpdateMailTemplateMutationVariables>;
export const DeleteMailTemplateDocument = gql`
    mutation DeleteMailTemplate($id: ID!, $withMailTemplateFills: Boolean = false) {
  deleteMailTemplate(id: $id) {
    ...mailTemplateFields
  }
}
    ${MailTemplateFieldsFragmentDoc}`;
export type DeleteMailTemplateMutationFn = Apollo.MutationFunction<DeleteMailTemplateMutation, DeleteMailTemplateMutationVariables>;

/**
 * __useDeleteMailTemplateMutation__
 *
 * To run a mutation, you first call `useDeleteMailTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMailTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMailTemplateMutation, { data, loading, error }] = useDeleteMailTemplateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      withMailTemplateFills: // value for 'withMailTemplateFills'
 *   },
 * });
 */
export function useDeleteMailTemplateMutation(baseOptions?: Apollo.MutationHookOptions<DeleteMailTemplateMutation, DeleteMailTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteMailTemplateMutation, DeleteMailTemplateMutationVariables>(DeleteMailTemplateDocument, options);
      }
export type DeleteMailTemplateMutationHookResult = ReturnType<typeof useDeleteMailTemplateMutation>;
export type DeleteMailTemplateMutationResult = Apollo.MutationResult<DeleteMailTemplateMutation>;
export type DeleteMailTemplateMutationOptions = Apollo.BaseMutationOptions<DeleteMailTemplateMutation, DeleteMailTemplateMutationVariables>;
export const CreatedMailTemplateDocument = gql`
    subscription CreatedMailTemplate($filter: MailTemplateFilterArgs, $withMailTemplateFills: Boolean = false) {
  createdMailTemplate(filter: $filter) {
    ...mailTemplateFields
  }
}
    ${MailTemplateFieldsFragmentDoc}`;

/**
 * __useCreatedMailTemplateSubscription__
 *
 * To run a query within a React component, call `useCreatedMailTemplateSubscription` and pass it any options that fit your needs.
 * When your component renders, `useCreatedMailTemplateSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreatedMailTemplateSubscription({
 *   variables: {
 *      filter: // value for 'filter'
 *      withMailTemplateFills: // value for 'withMailTemplateFills'
 *   },
 * });
 */
export function useCreatedMailTemplateSubscription(baseOptions?: Apollo.SubscriptionHookOptions<CreatedMailTemplateSubscription, CreatedMailTemplateSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<CreatedMailTemplateSubscription, CreatedMailTemplateSubscriptionVariables>(CreatedMailTemplateDocument, options);
      }
export type CreatedMailTemplateSubscriptionHookResult = ReturnType<typeof useCreatedMailTemplateSubscription>;
export type CreatedMailTemplateSubscriptionResult = Apollo.SubscriptionResult<CreatedMailTemplateSubscription>;
export const UpdatedMailTemplateDocument = gql`
    subscription UpdatedMailTemplate($filter: MailTemplateFilterArgs, $withMailTemplateFills: Boolean = false) {
  updatedMailTemplate(filter: $filter) {
    ...mailTemplateFields
  }
}
    ${MailTemplateFieldsFragmentDoc}`;

/**
 * __useUpdatedMailTemplateSubscription__
 *
 * To run a query within a React component, call `useUpdatedMailTemplateSubscription` and pass it any options that fit your needs.
 * When your component renders, `useUpdatedMailTemplateSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpdatedMailTemplateSubscription({
 *   variables: {
 *      filter: // value for 'filter'
 *      withMailTemplateFills: // value for 'withMailTemplateFills'
 *   },
 * });
 */
export function useUpdatedMailTemplateSubscription(baseOptions?: Apollo.SubscriptionHookOptions<UpdatedMailTemplateSubscription, UpdatedMailTemplateSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<UpdatedMailTemplateSubscription, UpdatedMailTemplateSubscriptionVariables>(UpdatedMailTemplateDocument, options);
      }
export type UpdatedMailTemplateSubscriptionHookResult = ReturnType<typeof useUpdatedMailTemplateSubscription>;
export type UpdatedMailTemplateSubscriptionResult = Apollo.SubscriptionResult<UpdatedMailTemplateSubscription>;
export const DeletedMailTemplateDocument = gql`
    subscription DeletedMailTemplate($filter: MailTemplateFilterArgs) {
  deletedMailTemplate(filter: $filter) {
    id
  }
}
    `;

/**
 * __useDeletedMailTemplateSubscription__
 *
 * To run a query within a React component, call `useDeletedMailTemplateSubscription` and pass it any options that fit your needs.
 * When your component renders, `useDeletedMailTemplateSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeletedMailTemplateSubscription({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useDeletedMailTemplateSubscription(baseOptions?: Apollo.SubscriptionHookOptions<DeletedMailTemplateSubscription, DeletedMailTemplateSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<DeletedMailTemplateSubscription, DeletedMailTemplateSubscriptionVariables>(DeletedMailTemplateDocument, options);
      }
export type DeletedMailTemplateSubscriptionHookResult = ReturnType<typeof useDeletedMailTemplateSubscription>;
export type DeletedMailTemplateSubscriptionResult = Apollo.SubscriptionResult<DeletedMailTemplateSubscription>;
export const GetMailTemplateFillsDocument = gql`
    query GetMailTemplateFills($filter: MailTemplateFillFilterArgs, $paging: PageArgs, $sorting: [MailTemplateFillSortArgs!], $withMailTemplate: Boolean = false) {
  mailTemplateFills(filter: $filter, paging: $paging, sorting: $sorting) {
    items {
      ...mailTemplateFillFields
    }
    totalCount
  }
}
    ${MailTemplateFillFieldsFragmentDoc}`;

/**
 * __useGetMailTemplateFillsQuery__
 *
 * To run a query within a React component, call `useGetMailTemplateFillsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMailTemplateFillsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMailTemplateFillsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      paging: // value for 'paging'
 *      sorting: // value for 'sorting'
 *      withMailTemplate: // value for 'withMailTemplate'
 *   },
 * });
 */
export function useGetMailTemplateFillsQuery(baseOptions?: Apollo.QueryHookOptions<GetMailTemplateFillsQuery, GetMailTemplateFillsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMailTemplateFillsQuery, GetMailTemplateFillsQueryVariables>(GetMailTemplateFillsDocument, options);
      }
export function useGetMailTemplateFillsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMailTemplateFillsQuery, GetMailTemplateFillsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMailTemplateFillsQuery, GetMailTemplateFillsQueryVariables>(GetMailTemplateFillsDocument, options);
        }
export function useGetMailTemplateFillsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetMailTemplateFillsQuery, GetMailTemplateFillsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetMailTemplateFillsQuery, GetMailTemplateFillsQueryVariables>(GetMailTemplateFillsDocument, options);
        }
export type GetMailTemplateFillsQueryHookResult = ReturnType<typeof useGetMailTemplateFillsQuery>;
export type GetMailTemplateFillsLazyQueryHookResult = ReturnType<typeof useGetMailTemplateFillsLazyQuery>;
export type GetMailTemplateFillsSuspenseQueryHookResult = ReturnType<typeof useGetMailTemplateFillsSuspenseQuery>;
export type GetMailTemplateFillsQueryResult = Apollo.QueryResult<GetMailTemplateFillsQuery, GetMailTemplateFillsQueryVariables>;
export const GetMailTemplateFillDocument = gql`
    query GetMailTemplateFill($id: ID!, $withMailTemplate: Boolean = false) {
  mailTemplateFill(id: $id) {
    ...mailTemplateFillFields
  }
}
    ${MailTemplateFillFieldsFragmentDoc}`;

/**
 * __useGetMailTemplateFillQuery__
 *
 * To run a query within a React component, call `useGetMailTemplateFillQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMailTemplateFillQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMailTemplateFillQuery({
 *   variables: {
 *      id: // value for 'id'
 *      withMailTemplate: // value for 'withMailTemplate'
 *   },
 * });
 */
export function useGetMailTemplateFillQuery(baseOptions: Apollo.QueryHookOptions<GetMailTemplateFillQuery, GetMailTemplateFillQueryVariables> & ({ variables: GetMailTemplateFillQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMailTemplateFillQuery, GetMailTemplateFillQueryVariables>(GetMailTemplateFillDocument, options);
      }
export function useGetMailTemplateFillLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMailTemplateFillQuery, GetMailTemplateFillQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMailTemplateFillQuery, GetMailTemplateFillQueryVariables>(GetMailTemplateFillDocument, options);
        }
export function useGetMailTemplateFillSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetMailTemplateFillQuery, GetMailTemplateFillQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetMailTemplateFillQuery, GetMailTemplateFillQueryVariables>(GetMailTemplateFillDocument, options);
        }
export type GetMailTemplateFillQueryHookResult = ReturnType<typeof useGetMailTemplateFillQuery>;
export type GetMailTemplateFillLazyQueryHookResult = ReturnType<typeof useGetMailTemplateFillLazyQuery>;
export type GetMailTemplateFillSuspenseQueryHookResult = ReturnType<typeof useGetMailTemplateFillSuspenseQuery>;
export type GetMailTemplateFillQueryResult = Apollo.QueryResult<GetMailTemplateFillQuery, GetMailTemplateFillQueryVariables>;
export const GetAllRelatedDataForMailTemplateFillDocument = gql`
    query GetAllRelatedDataForMailTemplateFill($withMailTemplates: Boolean = false) {
  mailTemplates @include(if: $withMailTemplates) {
    items {
      id
      displayField: id
    }
  }
}
    `;

/**
 * __useGetAllRelatedDataForMailTemplateFillQuery__
 *
 * To run a query within a React component, call `useGetAllRelatedDataForMailTemplateFillQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllRelatedDataForMailTemplateFillQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllRelatedDataForMailTemplateFillQuery({
 *   variables: {
 *      withMailTemplates: // value for 'withMailTemplates'
 *   },
 * });
 */
export function useGetAllRelatedDataForMailTemplateFillQuery(baseOptions?: Apollo.QueryHookOptions<GetAllRelatedDataForMailTemplateFillQuery, GetAllRelatedDataForMailTemplateFillQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllRelatedDataForMailTemplateFillQuery, GetAllRelatedDataForMailTemplateFillQueryVariables>(GetAllRelatedDataForMailTemplateFillDocument, options);
      }
export function useGetAllRelatedDataForMailTemplateFillLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllRelatedDataForMailTemplateFillQuery, GetAllRelatedDataForMailTemplateFillQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllRelatedDataForMailTemplateFillQuery, GetAllRelatedDataForMailTemplateFillQueryVariables>(GetAllRelatedDataForMailTemplateFillDocument, options);
        }
export function useGetAllRelatedDataForMailTemplateFillSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetAllRelatedDataForMailTemplateFillQuery, GetAllRelatedDataForMailTemplateFillQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAllRelatedDataForMailTemplateFillQuery, GetAllRelatedDataForMailTemplateFillQueryVariables>(GetAllRelatedDataForMailTemplateFillDocument, options);
        }
export type GetAllRelatedDataForMailTemplateFillQueryHookResult = ReturnType<typeof useGetAllRelatedDataForMailTemplateFillQuery>;
export type GetAllRelatedDataForMailTemplateFillLazyQueryHookResult = ReturnType<typeof useGetAllRelatedDataForMailTemplateFillLazyQuery>;
export type GetAllRelatedDataForMailTemplateFillSuspenseQueryHookResult = ReturnType<typeof useGetAllRelatedDataForMailTemplateFillSuspenseQuery>;
export type GetAllRelatedDataForMailTemplateFillQueryResult = Apollo.QueryResult<GetAllRelatedDataForMailTemplateFillQuery, GetAllRelatedDataForMailTemplateFillQueryVariables>;
export const CreateMailTemplateFillDocument = gql`
    mutation CreateMailTemplateFill($subject: String!, $urlText: String, $title: String, $heading: String, $preHeader: String, $disclaimer: String, $text: String!, $plainText: String!, $language: String!, $mailTemplateId: ID!, $withMailTemplate: Boolean = false) {
  createMailTemplateFill(
    subject: $subject
    urlText: $urlText
    title: $title
    heading: $heading
    preHeader: $preHeader
    disclaimer: $disclaimer
    text: $text
    plainText: $plainText
    language: $language
    mailTemplateId: $mailTemplateId
  ) {
    ...mailTemplateFillFields
  }
}
    ${MailTemplateFillFieldsFragmentDoc}`;
export type CreateMailTemplateFillMutationFn = Apollo.MutationFunction<CreateMailTemplateFillMutation, CreateMailTemplateFillMutationVariables>;

/**
 * __useCreateMailTemplateFillMutation__
 *
 * To run a mutation, you first call `useCreateMailTemplateFillMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMailTemplateFillMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMailTemplateFillMutation, { data, loading, error }] = useCreateMailTemplateFillMutation({
 *   variables: {
 *      subject: // value for 'subject'
 *      urlText: // value for 'urlText'
 *      title: // value for 'title'
 *      heading: // value for 'heading'
 *      preHeader: // value for 'preHeader'
 *      disclaimer: // value for 'disclaimer'
 *      text: // value for 'text'
 *      plainText: // value for 'plainText'
 *      language: // value for 'language'
 *      mailTemplateId: // value for 'mailTemplateId'
 *      withMailTemplate: // value for 'withMailTemplate'
 *   },
 * });
 */
export function useCreateMailTemplateFillMutation(baseOptions?: Apollo.MutationHookOptions<CreateMailTemplateFillMutation, CreateMailTemplateFillMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMailTemplateFillMutation, CreateMailTemplateFillMutationVariables>(CreateMailTemplateFillDocument, options);
      }
export type CreateMailTemplateFillMutationHookResult = ReturnType<typeof useCreateMailTemplateFillMutation>;
export type CreateMailTemplateFillMutationResult = Apollo.MutationResult<CreateMailTemplateFillMutation>;
export type CreateMailTemplateFillMutationOptions = Apollo.BaseMutationOptions<CreateMailTemplateFillMutation, CreateMailTemplateFillMutationVariables>;
export const UpdateMailTemplateFillDocument = gql`
    mutation UpdateMailTemplateFill($id: ID!, $subject: String, $urlText: String, $title: String, $heading: String, $preHeader: String, $disclaimer: String, $text: String, $plainText: String, $language: String, $mailTemplateId: ID, $withMailTemplate: Boolean = false) {
  updateMailTemplateFill(
    id: $id
    subject: $subject
    urlText: $urlText
    title: $title
    heading: $heading
    preHeader: $preHeader
    disclaimer: $disclaimer
    text: $text
    plainText: $plainText
    language: $language
    mailTemplateId: $mailTemplateId
  ) {
    ...mailTemplateFillFields
  }
}
    ${MailTemplateFillFieldsFragmentDoc}`;
export type UpdateMailTemplateFillMutationFn = Apollo.MutationFunction<UpdateMailTemplateFillMutation, UpdateMailTemplateFillMutationVariables>;

/**
 * __useUpdateMailTemplateFillMutation__
 *
 * To run a mutation, you first call `useUpdateMailTemplateFillMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMailTemplateFillMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMailTemplateFillMutation, { data, loading, error }] = useUpdateMailTemplateFillMutation({
 *   variables: {
 *      id: // value for 'id'
 *      subject: // value for 'subject'
 *      urlText: // value for 'urlText'
 *      title: // value for 'title'
 *      heading: // value for 'heading'
 *      preHeader: // value for 'preHeader'
 *      disclaimer: // value for 'disclaimer'
 *      text: // value for 'text'
 *      plainText: // value for 'plainText'
 *      language: // value for 'language'
 *      mailTemplateId: // value for 'mailTemplateId'
 *      withMailTemplate: // value for 'withMailTemplate'
 *   },
 * });
 */
export function useUpdateMailTemplateFillMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMailTemplateFillMutation, UpdateMailTemplateFillMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMailTemplateFillMutation, UpdateMailTemplateFillMutationVariables>(UpdateMailTemplateFillDocument, options);
      }
export type UpdateMailTemplateFillMutationHookResult = ReturnType<typeof useUpdateMailTemplateFillMutation>;
export type UpdateMailTemplateFillMutationResult = Apollo.MutationResult<UpdateMailTemplateFillMutation>;
export type UpdateMailTemplateFillMutationOptions = Apollo.BaseMutationOptions<UpdateMailTemplateFillMutation, UpdateMailTemplateFillMutationVariables>;
export const DeleteMailTemplateFillDocument = gql`
    mutation DeleteMailTemplateFill($id: ID!, $withMailTemplate: Boolean = false) {
  deleteMailTemplateFill(id: $id) {
    ...mailTemplateFillFields
  }
}
    ${MailTemplateFillFieldsFragmentDoc}`;
export type DeleteMailTemplateFillMutationFn = Apollo.MutationFunction<DeleteMailTemplateFillMutation, DeleteMailTemplateFillMutationVariables>;

/**
 * __useDeleteMailTemplateFillMutation__
 *
 * To run a mutation, you first call `useDeleteMailTemplateFillMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMailTemplateFillMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMailTemplateFillMutation, { data, loading, error }] = useDeleteMailTemplateFillMutation({
 *   variables: {
 *      id: // value for 'id'
 *      withMailTemplate: // value for 'withMailTemplate'
 *   },
 * });
 */
export function useDeleteMailTemplateFillMutation(baseOptions?: Apollo.MutationHookOptions<DeleteMailTemplateFillMutation, DeleteMailTemplateFillMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteMailTemplateFillMutation, DeleteMailTemplateFillMutationVariables>(DeleteMailTemplateFillDocument, options);
      }
export type DeleteMailTemplateFillMutationHookResult = ReturnType<typeof useDeleteMailTemplateFillMutation>;
export type DeleteMailTemplateFillMutationResult = Apollo.MutationResult<DeleteMailTemplateFillMutation>;
export type DeleteMailTemplateFillMutationOptions = Apollo.BaseMutationOptions<DeleteMailTemplateFillMutation, DeleteMailTemplateFillMutationVariables>;
export const CreatedMailTemplateFillDocument = gql`
    subscription CreatedMailTemplateFill($filter: MailTemplateFillFilterArgs, $withMailTemplate: Boolean = false) {
  createdMailTemplateFill(filter: $filter) {
    ...mailTemplateFillFields
  }
}
    ${MailTemplateFillFieldsFragmentDoc}`;

/**
 * __useCreatedMailTemplateFillSubscription__
 *
 * To run a query within a React component, call `useCreatedMailTemplateFillSubscription` and pass it any options that fit your needs.
 * When your component renders, `useCreatedMailTemplateFillSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreatedMailTemplateFillSubscription({
 *   variables: {
 *      filter: // value for 'filter'
 *      withMailTemplate: // value for 'withMailTemplate'
 *   },
 * });
 */
export function useCreatedMailTemplateFillSubscription(baseOptions?: Apollo.SubscriptionHookOptions<CreatedMailTemplateFillSubscription, CreatedMailTemplateFillSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<CreatedMailTemplateFillSubscription, CreatedMailTemplateFillSubscriptionVariables>(CreatedMailTemplateFillDocument, options);
      }
export type CreatedMailTemplateFillSubscriptionHookResult = ReturnType<typeof useCreatedMailTemplateFillSubscription>;
export type CreatedMailTemplateFillSubscriptionResult = Apollo.SubscriptionResult<CreatedMailTemplateFillSubscription>;
export const UpdatedMailTemplateFillDocument = gql`
    subscription UpdatedMailTemplateFill($filter: MailTemplateFillFilterArgs, $withMailTemplate: Boolean = false) {
  updatedMailTemplateFill(filter: $filter) {
    ...mailTemplateFillFields
  }
}
    ${MailTemplateFillFieldsFragmentDoc}`;

/**
 * __useUpdatedMailTemplateFillSubscription__
 *
 * To run a query within a React component, call `useUpdatedMailTemplateFillSubscription` and pass it any options that fit your needs.
 * When your component renders, `useUpdatedMailTemplateFillSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpdatedMailTemplateFillSubscription({
 *   variables: {
 *      filter: // value for 'filter'
 *      withMailTemplate: // value for 'withMailTemplate'
 *   },
 * });
 */
export function useUpdatedMailTemplateFillSubscription(baseOptions?: Apollo.SubscriptionHookOptions<UpdatedMailTemplateFillSubscription, UpdatedMailTemplateFillSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<UpdatedMailTemplateFillSubscription, UpdatedMailTemplateFillSubscriptionVariables>(UpdatedMailTemplateFillDocument, options);
      }
export type UpdatedMailTemplateFillSubscriptionHookResult = ReturnType<typeof useUpdatedMailTemplateFillSubscription>;
export type UpdatedMailTemplateFillSubscriptionResult = Apollo.SubscriptionResult<UpdatedMailTemplateFillSubscription>;
export const DeletedMailTemplateFillDocument = gql`
    subscription DeletedMailTemplateFill($filter: MailTemplateFillFilterArgs) {
  deletedMailTemplateFill(filter: $filter) {
    id
  }
}
    `;

/**
 * __useDeletedMailTemplateFillSubscription__
 *
 * To run a query within a React component, call `useDeletedMailTemplateFillSubscription` and pass it any options that fit your needs.
 * When your component renders, `useDeletedMailTemplateFillSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeletedMailTemplateFillSubscription({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useDeletedMailTemplateFillSubscription(baseOptions?: Apollo.SubscriptionHookOptions<DeletedMailTemplateFillSubscription, DeletedMailTemplateFillSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<DeletedMailTemplateFillSubscription, DeletedMailTemplateFillSubscriptionVariables>(DeletedMailTemplateFillDocument, options);
      }
export type DeletedMailTemplateFillSubscriptionHookResult = ReturnType<typeof useDeletedMailTemplateFillSubscription>;
export type DeletedMailTemplateFillSubscriptionResult = Apollo.SubscriptionResult<DeletedMailTemplateFillSubscription>;
export const GetNotificationSettingsDocument = gql`
    query GetNotificationSettings($filter: NotificationSettingFilterArgs, $paging: PageArgs, $sorting: [NotificationSettingSortArgs!], $withUser: Boolean = false, $withGitRepository: Boolean = false) {
  notificationSettings(filter: $filter, paging: $paging, sorting: $sorting) {
    items {
      ...notificationSettingFields
    }
    totalCount
  }
}
    ${NotificationSettingFieldsFragmentDoc}`;

/**
 * __useGetNotificationSettingsQuery__
 *
 * To run a query within a React component, call `useGetNotificationSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotificationSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotificationSettingsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      paging: // value for 'paging'
 *      sorting: // value for 'sorting'
 *      withUser: // value for 'withUser'
 *      withGitRepository: // value for 'withGitRepository'
 *   },
 * });
 */
export function useGetNotificationSettingsQuery(baseOptions?: Apollo.QueryHookOptions<GetNotificationSettingsQuery, GetNotificationSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNotificationSettingsQuery, GetNotificationSettingsQueryVariables>(GetNotificationSettingsDocument, options);
      }
export function useGetNotificationSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNotificationSettingsQuery, GetNotificationSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNotificationSettingsQuery, GetNotificationSettingsQueryVariables>(GetNotificationSettingsDocument, options);
        }
export function useGetNotificationSettingsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetNotificationSettingsQuery, GetNotificationSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetNotificationSettingsQuery, GetNotificationSettingsQueryVariables>(GetNotificationSettingsDocument, options);
        }
export type GetNotificationSettingsQueryHookResult = ReturnType<typeof useGetNotificationSettingsQuery>;
export type GetNotificationSettingsLazyQueryHookResult = ReturnType<typeof useGetNotificationSettingsLazyQuery>;
export type GetNotificationSettingsSuspenseQueryHookResult = ReturnType<typeof useGetNotificationSettingsSuspenseQuery>;
export type GetNotificationSettingsQueryResult = Apollo.QueryResult<GetNotificationSettingsQuery, GetNotificationSettingsQueryVariables>;
export const GetNotificationSettingDocument = gql`
    query GetNotificationSetting($id: ID!, $withUser: Boolean = false, $withGitRepository: Boolean = false) {
  notificationSetting(id: $id) {
    ...notificationSettingFields
  }
}
    ${NotificationSettingFieldsFragmentDoc}`;

/**
 * __useGetNotificationSettingQuery__
 *
 * To run a query within a React component, call `useGetNotificationSettingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotificationSettingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotificationSettingQuery({
 *   variables: {
 *      id: // value for 'id'
 *      withUser: // value for 'withUser'
 *      withGitRepository: // value for 'withGitRepository'
 *   },
 * });
 */
export function useGetNotificationSettingQuery(baseOptions: Apollo.QueryHookOptions<GetNotificationSettingQuery, GetNotificationSettingQueryVariables> & ({ variables: GetNotificationSettingQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNotificationSettingQuery, GetNotificationSettingQueryVariables>(GetNotificationSettingDocument, options);
      }
export function useGetNotificationSettingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNotificationSettingQuery, GetNotificationSettingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNotificationSettingQuery, GetNotificationSettingQueryVariables>(GetNotificationSettingDocument, options);
        }
export function useGetNotificationSettingSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetNotificationSettingQuery, GetNotificationSettingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetNotificationSettingQuery, GetNotificationSettingQueryVariables>(GetNotificationSettingDocument, options);
        }
export type GetNotificationSettingQueryHookResult = ReturnType<typeof useGetNotificationSettingQuery>;
export type GetNotificationSettingLazyQueryHookResult = ReturnType<typeof useGetNotificationSettingLazyQuery>;
export type GetNotificationSettingSuspenseQueryHookResult = ReturnType<typeof useGetNotificationSettingSuspenseQuery>;
export type GetNotificationSettingQueryResult = Apollo.QueryResult<GetNotificationSettingQuery, GetNotificationSettingQueryVariables>;
export const GetAllRelatedDataForNotificationSettingDocument = gql`
    query GetAllRelatedDataForNotificationSetting($withUsers: Boolean = false, $withGitRepositories: Boolean = false) {
  users @include(if: $withUsers) {
    items {
      id
      displayField: name
    }
  }
  gitRepositories @include(if: $withGitRepositories) {
    items {
      id
      displayField: name
    }
  }
}
    `;

/**
 * __useGetAllRelatedDataForNotificationSettingQuery__
 *
 * To run a query within a React component, call `useGetAllRelatedDataForNotificationSettingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllRelatedDataForNotificationSettingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllRelatedDataForNotificationSettingQuery({
 *   variables: {
 *      withUsers: // value for 'withUsers'
 *      withGitRepositories: // value for 'withGitRepositories'
 *   },
 * });
 */
export function useGetAllRelatedDataForNotificationSettingQuery(baseOptions?: Apollo.QueryHookOptions<GetAllRelatedDataForNotificationSettingQuery, GetAllRelatedDataForNotificationSettingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllRelatedDataForNotificationSettingQuery, GetAllRelatedDataForNotificationSettingQueryVariables>(GetAllRelatedDataForNotificationSettingDocument, options);
      }
export function useGetAllRelatedDataForNotificationSettingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllRelatedDataForNotificationSettingQuery, GetAllRelatedDataForNotificationSettingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllRelatedDataForNotificationSettingQuery, GetAllRelatedDataForNotificationSettingQueryVariables>(GetAllRelatedDataForNotificationSettingDocument, options);
        }
export function useGetAllRelatedDataForNotificationSettingSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetAllRelatedDataForNotificationSettingQuery, GetAllRelatedDataForNotificationSettingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAllRelatedDataForNotificationSettingQuery, GetAllRelatedDataForNotificationSettingQueryVariables>(GetAllRelatedDataForNotificationSettingDocument, options);
        }
export type GetAllRelatedDataForNotificationSettingQueryHookResult = ReturnType<typeof useGetAllRelatedDataForNotificationSettingQuery>;
export type GetAllRelatedDataForNotificationSettingLazyQueryHookResult = ReturnType<typeof useGetAllRelatedDataForNotificationSettingLazyQuery>;
export type GetAllRelatedDataForNotificationSettingSuspenseQueryHookResult = ReturnType<typeof useGetAllRelatedDataForNotificationSettingSuspenseQuery>;
export type GetAllRelatedDataForNotificationSettingQueryResult = Apollo.QueryResult<GetAllRelatedDataForNotificationSettingQuery, GetAllRelatedDataForNotificationSettingQueryVariables>;
export const CreateNotificationSettingDocument = gql`
    mutation CreateNotificationSetting($userId: ID!, $gitRepositoryId: ID!, $withUser: Boolean = false, $withGitRepository: Boolean = false) {
  createNotificationSetting(userId: $userId, gitRepositoryId: $gitRepositoryId) {
    ...notificationSettingFields
  }
}
    ${NotificationSettingFieldsFragmentDoc}`;
export type CreateNotificationSettingMutationFn = Apollo.MutationFunction<CreateNotificationSettingMutation, CreateNotificationSettingMutationVariables>;

/**
 * __useCreateNotificationSettingMutation__
 *
 * To run a mutation, you first call `useCreateNotificationSettingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNotificationSettingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNotificationSettingMutation, { data, loading, error }] = useCreateNotificationSettingMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      gitRepositoryId: // value for 'gitRepositoryId'
 *      withUser: // value for 'withUser'
 *      withGitRepository: // value for 'withGitRepository'
 *   },
 * });
 */
export function useCreateNotificationSettingMutation(baseOptions?: Apollo.MutationHookOptions<CreateNotificationSettingMutation, CreateNotificationSettingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateNotificationSettingMutation, CreateNotificationSettingMutationVariables>(CreateNotificationSettingDocument, options);
      }
export type CreateNotificationSettingMutationHookResult = ReturnType<typeof useCreateNotificationSettingMutation>;
export type CreateNotificationSettingMutationResult = Apollo.MutationResult<CreateNotificationSettingMutation>;
export type CreateNotificationSettingMutationOptions = Apollo.BaseMutationOptions<CreateNotificationSettingMutation, CreateNotificationSettingMutationVariables>;
export const UpdateNotificationSettingDocument = gql`
    mutation UpdateNotificationSetting($id: ID!, $userId: ID, $gitRepositoryId: ID, $withUser: Boolean = false, $withGitRepository: Boolean = false) {
  updateNotificationSetting(
    id: $id
    userId: $userId
    gitRepositoryId: $gitRepositoryId
  ) {
    ...notificationSettingFields
  }
}
    ${NotificationSettingFieldsFragmentDoc}`;
export type UpdateNotificationSettingMutationFn = Apollo.MutationFunction<UpdateNotificationSettingMutation, UpdateNotificationSettingMutationVariables>;

/**
 * __useUpdateNotificationSettingMutation__
 *
 * To run a mutation, you first call `useUpdateNotificationSettingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNotificationSettingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNotificationSettingMutation, { data, loading, error }] = useUpdateNotificationSettingMutation({
 *   variables: {
 *      id: // value for 'id'
 *      userId: // value for 'userId'
 *      gitRepositoryId: // value for 'gitRepositoryId'
 *      withUser: // value for 'withUser'
 *      withGitRepository: // value for 'withGitRepository'
 *   },
 * });
 */
export function useUpdateNotificationSettingMutation(baseOptions?: Apollo.MutationHookOptions<UpdateNotificationSettingMutation, UpdateNotificationSettingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateNotificationSettingMutation, UpdateNotificationSettingMutationVariables>(UpdateNotificationSettingDocument, options);
      }
export type UpdateNotificationSettingMutationHookResult = ReturnType<typeof useUpdateNotificationSettingMutation>;
export type UpdateNotificationSettingMutationResult = Apollo.MutationResult<UpdateNotificationSettingMutation>;
export type UpdateNotificationSettingMutationOptions = Apollo.BaseMutationOptions<UpdateNotificationSettingMutation, UpdateNotificationSettingMutationVariables>;
export const DeleteNotificationSettingDocument = gql`
    mutation DeleteNotificationSetting($id: ID!, $withUser: Boolean = false, $withGitRepository: Boolean = false) {
  deleteNotificationSetting(id: $id) {
    ...notificationSettingFields
  }
}
    ${NotificationSettingFieldsFragmentDoc}`;
export type DeleteNotificationSettingMutationFn = Apollo.MutationFunction<DeleteNotificationSettingMutation, DeleteNotificationSettingMutationVariables>;

/**
 * __useDeleteNotificationSettingMutation__
 *
 * To run a mutation, you first call `useDeleteNotificationSettingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteNotificationSettingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteNotificationSettingMutation, { data, loading, error }] = useDeleteNotificationSettingMutation({
 *   variables: {
 *      id: // value for 'id'
 *      withUser: // value for 'withUser'
 *      withGitRepository: // value for 'withGitRepository'
 *   },
 * });
 */
export function useDeleteNotificationSettingMutation(baseOptions?: Apollo.MutationHookOptions<DeleteNotificationSettingMutation, DeleteNotificationSettingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteNotificationSettingMutation, DeleteNotificationSettingMutationVariables>(DeleteNotificationSettingDocument, options);
      }
export type DeleteNotificationSettingMutationHookResult = ReturnType<typeof useDeleteNotificationSettingMutation>;
export type DeleteNotificationSettingMutationResult = Apollo.MutationResult<DeleteNotificationSettingMutation>;
export type DeleteNotificationSettingMutationOptions = Apollo.BaseMutationOptions<DeleteNotificationSettingMutation, DeleteNotificationSettingMutationVariables>;
export const CreatedNotificationSettingDocument = gql`
    subscription CreatedNotificationSetting($filter: NotificationSettingFilterArgs, $withUser: Boolean = false, $withGitRepository: Boolean = false) {
  createdNotificationSetting(filter: $filter) {
    ...notificationSettingFields
  }
}
    ${NotificationSettingFieldsFragmentDoc}`;

/**
 * __useCreatedNotificationSettingSubscription__
 *
 * To run a query within a React component, call `useCreatedNotificationSettingSubscription` and pass it any options that fit your needs.
 * When your component renders, `useCreatedNotificationSettingSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreatedNotificationSettingSubscription({
 *   variables: {
 *      filter: // value for 'filter'
 *      withUser: // value for 'withUser'
 *      withGitRepository: // value for 'withGitRepository'
 *   },
 * });
 */
export function useCreatedNotificationSettingSubscription(baseOptions?: Apollo.SubscriptionHookOptions<CreatedNotificationSettingSubscription, CreatedNotificationSettingSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<CreatedNotificationSettingSubscription, CreatedNotificationSettingSubscriptionVariables>(CreatedNotificationSettingDocument, options);
      }
export type CreatedNotificationSettingSubscriptionHookResult = ReturnType<typeof useCreatedNotificationSettingSubscription>;
export type CreatedNotificationSettingSubscriptionResult = Apollo.SubscriptionResult<CreatedNotificationSettingSubscription>;
export const UpdatedNotificationSettingDocument = gql`
    subscription UpdatedNotificationSetting($filter: NotificationSettingFilterArgs, $withUser: Boolean = false, $withGitRepository: Boolean = false) {
  updatedNotificationSetting(filter: $filter) {
    ...notificationSettingFields
  }
}
    ${NotificationSettingFieldsFragmentDoc}`;

/**
 * __useUpdatedNotificationSettingSubscription__
 *
 * To run a query within a React component, call `useUpdatedNotificationSettingSubscription` and pass it any options that fit your needs.
 * When your component renders, `useUpdatedNotificationSettingSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpdatedNotificationSettingSubscription({
 *   variables: {
 *      filter: // value for 'filter'
 *      withUser: // value for 'withUser'
 *      withGitRepository: // value for 'withGitRepository'
 *   },
 * });
 */
export function useUpdatedNotificationSettingSubscription(baseOptions?: Apollo.SubscriptionHookOptions<UpdatedNotificationSettingSubscription, UpdatedNotificationSettingSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<UpdatedNotificationSettingSubscription, UpdatedNotificationSettingSubscriptionVariables>(UpdatedNotificationSettingDocument, options);
      }
export type UpdatedNotificationSettingSubscriptionHookResult = ReturnType<typeof useUpdatedNotificationSettingSubscription>;
export type UpdatedNotificationSettingSubscriptionResult = Apollo.SubscriptionResult<UpdatedNotificationSettingSubscription>;
export const DeletedNotificationSettingDocument = gql`
    subscription DeletedNotificationSetting($filter: NotificationSettingFilterArgs) {
  deletedNotificationSetting(filter: $filter) {
    id
  }
}
    `;

/**
 * __useDeletedNotificationSettingSubscription__
 *
 * To run a query within a React component, call `useDeletedNotificationSettingSubscription` and pass it any options that fit your needs.
 * When your component renders, `useDeletedNotificationSettingSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeletedNotificationSettingSubscription({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useDeletedNotificationSettingSubscription(baseOptions?: Apollo.SubscriptionHookOptions<DeletedNotificationSettingSubscription, DeletedNotificationSettingSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<DeletedNotificationSettingSubscription, DeletedNotificationSettingSubscriptionVariables>(DeletedNotificationSettingDocument, options);
      }
export type DeletedNotificationSettingSubscriptionHookResult = ReturnType<typeof useDeletedNotificationSettingSubscription>;
export type DeletedNotificationSettingSubscriptionResult = Apollo.SubscriptionResult<DeletedNotificationSettingSubscription>;
export const RestoredNotificationSettingDocument = gql`
    subscription RestoredNotificationSetting($filter: NotificationSettingFilterArgs, $withUser: Boolean = false, $withGitRepository: Boolean = false) {
  restoredNotificationSetting(filter: $filter) {
    ...notificationSettingFields
  }
}
    ${NotificationSettingFieldsFragmentDoc}`;

/**
 * __useRestoredNotificationSettingSubscription__
 *
 * To run a query within a React component, call `useRestoredNotificationSettingSubscription` and pass it any options that fit your needs.
 * When your component renders, `useRestoredNotificationSettingSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRestoredNotificationSettingSubscription({
 *   variables: {
 *      filter: // value for 'filter'
 *      withUser: // value for 'withUser'
 *      withGitRepository: // value for 'withGitRepository'
 *   },
 * });
 */
export function useRestoredNotificationSettingSubscription(baseOptions?: Apollo.SubscriptionHookOptions<RestoredNotificationSettingSubscription, RestoredNotificationSettingSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<RestoredNotificationSettingSubscription, RestoredNotificationSettingSubscriptionVariables>(RestoredNotificationSettingDocument, options);
      }
export type RestoredNotificationSettingSubscriptionHookResult = ReturnType<typeof useRestoredNotificationSettingSubscription>;
export type RestoredNotificationSettingSubscriptionResult = Apollo.SubscriptionResult<RestoredNotificationSettingSubscription>;
export const GetPermissionsDocument = gql`
    query GetPermissions($filter: PermissionFilterArgs, $paging: PageArgs, $sorting: [PermissionSortArgs!], $withRoles: Boolean = false) {
  permissions(filter: $filter, paging: $paging, sorting: $sorting) {
    items {
      ...permissionFields
    }
    totalCount
  }
}
    ${PermissionFieldsFragmentDoc}`;

/**
 * __useGetPermissionsQuery__
 *
 * To run a query within a React component, call `useGetPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPermissionsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      paging: // value for 'paging'
 *      sorting: // value for 'sorting'
 *      withRoles: // value for 'withRoles'
 *   },
 * });
 */
export function useGetPermissionsQuery(baseOptions?: Apollo.QueryHookOptions<GetPermissionsQuery, GetPermissionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPermissionsQuery, GetPermissionsQueryVariables>(GetPermissionsDocument, options);
      }
export function useGetPermissionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPermissionsQuery, GetPermissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPermissionsQuery, GetPermissionsQueryVariables>(GetPermissionsDocument, options);
        }
export function useGetPermissionsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetPermissionsQuery, GetPermissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPermissionsQuery, GetPermissionsQueryVariables>(GetPermissionsDocument, options);
        }
export type GetPermissionsQueryHookResult = ReturnType<typeof useGetPermissionsQuery>;
export type GetPermissionsLazyQueryHookResult = ReturnType<typeof useGetPermissionsLazyQuery>;
export type GetPermissionsSuspenseQueryHookResult = ReturnType<typeof useGetPermissionsSuspenseQuery>;
export type GetPermissionsQueryResult = Apollo.QueryResult<GetPermissionsQuery, GetPermissionsQueryVariables>;
export const GetPermissionDocument = gql`
    query GetPermission($id: ID!, $withRoles: Boolean = false) {
  permission(id: $id) {
    ...permissionFields
  }
}
    ${PermissionFieldsFragmentDoc}`;

/**
 * __useGetPermissionQuery__
 *
 * To run a query within a React component, call `useGetPermissionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPermissionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPermissionQuery({
 *   variables: {
 *      id: // value for 'id'
 *      withRoles: // value for 'withRoles'
 *   },
 * });
 */
export function useGetPermissionQuery(baseOptions: Apollo.QueryHookOptions<GetPermissionQuery, GetPermissionQueryVariables> & ({ variables: GetPermissionQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPermissionQuery, GetPermissionQueryVariables>(GetPermissionDocument, options);
      }
export function useGetPermissionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPermissionQuery, GetPermissionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPermissionQuery, GetPermissionQueryVariables>(GetPermissionDocument, options);
        }
export function useGetPermissionSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetPermissionQuery, GetPermissionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPermissionQuery, GetPermissionQueryVariables>(GetPermissionDocument, options);
        }
export type GetPermissionQueryHookResult = ReturnType<typeof useGetPermissionQuery>;
export type GetPermissionLazyQueryHookResult = ReturnType<typeof useGetPermissionLazyQuery>;
export type GetPermissionSuspenseQueryHookResult = ReturnType<typeof useGetPermissionSuspenseQuery>;
export type GetPermissionQueryResult = Apollo.QueryResult<GetPermissionQuery, GetPermissionQueryVariables>;
export const GetAllRelatedDataForPermissionDocument = gql`
    query GetAllRelatedDataForPermission($withRoles: Boolean = false) {
  roles @include(if: $withRoles) {
    items {
      id
      displayField: displayName
    }
  }
}
    `;

/**
 * __useGetAllRelatedDataForPermissionQuery__
 *
 * To run a query within a React component, call `useGetAllRelatedDataForPermissionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllRelatedDataForPermissionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllRelatedDataForPermissionQuery({
 *   variables: {
 *      withRoles: // value for 'withRoles'
 *   },
 * });
 */
export function useGetAllRelatedDataForPermissionQuery(baseOptions?: Apollo.QueryHookOptions<GetAllRelatedDataForPermissionQuery, GetAllRelatedDataForPermissionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllRelatedDataForPermissionQuery, GetAllRelatedDataForPermissionQueryVariables>(GetAllRelatedDataForPermissionDocument, options);
      }
export function useGetAllRelatedDataForPermissionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllRelatedDataForPermissionQuery, GetAllRelatedDataForPermissionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllRelatedDataForPermissionQuery, GetAllRelatedDataForPermissionQueryVariables>(GetAllRelatedDataForPermissionDocument, options);
        }
export function useGetAllRelatedDataForPermissionSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetAllRelatedDataForPermissionQuery, GetAllRelatedDataForPermissionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAllRelatedDataForPermissionQuery, GetAllRelatedDataForPermissionQueryVariables>(GetAllRelatedDataForPermissionDocument, options);
        }
export type GetAllRelatedDataForPermissionQueryHookResult = ReturnType<typeof useGetAllRelatedDataForPermissionQuery>;
export type GetAllRelatedDataForPermissionLazyQueryHookResult = ReturnType<typeof useGetAllRelatedDataForPermissionLazyQuery>;
export type GetAllRelatedDataForPermissionSuspenseQueryHookResult = ReturnType<typeof useGetAllRelatedDataForPermissionSuspenseQuery>;
export type GetAllRelatedDataForPermissionQueryResult = Apollo.QueryResult<GetAllRelatedDataForPermissionQuery, GetAllRelatedDataForPermissionQueryVariables>;
export const CreatePermissionDocument = gql`
    mutation CreatePermission($name: String!, $description: String!, $roles: [ID], $withRoles: Boolean = false) {
  createPermission(name: $name, description: $description, roleIds: $roles) {
    ...permissionFields
  }
}
    ${PermissionFieldsFragmentDoc}`;
export type CreatePermissionMutationFn = Apollo.MutationFunction<CreatePermissionMutation, CreatePermissionMutationVariables>;

/**
 * __useCreatePermissionMutation__
 *
 * To run a mutation, you first call `useCreatePermissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePermissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPermissionMutation, { data, loading, error }] = useCreatePermissionMutation({
 *   variables: {
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      roles: // value for 'roles'
 *      withRoles: // value for 'withRoles'
 *   },
 * });
 */
export function useCreatePermissionMutation(baseOptions?: Apollo.MutationHookOptions<CreatePermissionMutation, CreatePermissionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePermissionMutation, CreatePermissionMutationVariables>(CreatePermissionDocument, options);
      }
export type CreatePermissionMutationHookResult = ReturnType<typeof useCreatePermissionMutation>;
export type CreatePermissionMutationResult = Apollo.MutationResult<CreatePermissionMutation>;
export type CreatePermissionMutationOptions = Apollo.BaseMutationOptions<CreatePermissionMutation, CreatePermissionMutationVariables>;
export const UpdatePermissionDocument = gql`
    mutation UpdatePermission($id: ID!, $name: String, $description: String, $roles: [ID], $withRoles: Boolean = false) {
  updatePermission(
    id: $id
    name: $name
    description: $description
    roleIds: $roles
  ) {
    ...permissionFields
  }
}
    ${PermissionFieldsFragmentDoc}`;
export type UpdatePermissionMutationFn = Apollo.MutationFunction<UpdatePermissionMutation, UpdatePermissionMutationVariables>;

/**
 * __useUpdatePermissionMutation__
 *
 * To run a mutation, you first call `useUpdatePermissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePermissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePermissionMutation, { data, loading, error }] = useUpdatePermissionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      roles: // value for 'roles'
 *      withRoles: // value for 'withRoles'
 *   },
 * });
 */
export function useUpdatePermissionMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePermissionMutation, UpdatePermissionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePermissionMutation, UpdatePermissionMutationVariables>(UpdatePermissionDocument, options);
      }
export type UpdatePermissionMutationHookResult = ReturnType<typeof useUpdatePermissionMutation>;
export type UpdatePermissionMutationResult = Apollo.MutationResult<UpdatePermissionMutation>;
export type UpdatePermissionMutationOptions = Apollo.BaseMutationOptions<UpdatePermissionMutation, UpdatePermissionMutationVariables>;
export const DeletePermissionDocument = gql`
    mutation DeletePermission($id: ID!, $withRoles: Boolean = false) {
  deletePermission(id: $id) {
    ...permissionFields
  }
}
    ${PermissionFieldsFragmentDoc}`;
export type DeletePermissionMutationFn = Apollo.MutationFunction<DeletePermissionMutation, DeletePermissionMutationVariables>;

/**
 * __useDeletePermissionMutation__
 *
 * To run a mutation, you first call `useDeletePermissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePermissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePermissionMutation, { data, loading, error }] = useDeletePermissionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      withRoles: // value for 'withRoles'
 *   },
 * });
 */
export function useDeletePermissionMutation(baseOptions?: Apollo.MutationHookOptions<DeletePermissionMutation, DeletePermissionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePermissionMutation, DeletePermissionMutationVariables>(DeletePermissionDocument, options);
      }
export type DeletePermissionMutationHookResult = ReturnType<typeof useDeletePermissionMutation>;
export type DeletePermissionMutationResult = Apollo.MutationResult<DeletePermissionMutation>;
export type DeletePermissionMutationOptions = Apollo.BaseMutationOptions<DeletePermissionMutation, DeletePermissionMutationVariables>;
export const CreatedPermissionDocument = gql`
    subscription CreatedPermission($filter: PermissionFilterArgs, $withRoles: Boolean = false) {
  createdPermission(filter: $filter) {
    ...permissionFields
  }
}
    ${PermissionFieldsFragmentDoc}`;

/**
 * __useCreatedPermissionSubscription__
 *
 * To run a query within a React component, call `useCreatedPermissionSubscription` and pass it any options that fit your needs.
 * When your component renders, `useCreatedPermissionSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreatedPermissionSubscription({
 *   variables: {
 *      filter: // value for 'filter'
 *      withRoles: // value for 'withRoles'
 *   },
 * });
 */
export function useCreatedPermissionSubscription(baseOptions?: Apollo.SubscriptionHookOptions<CreatedPermissionSubscription, CreatedPermissionSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<CreatedPermissionSubscription, CreatedPermissionSubscriptionVariables>(CreatedPermissionDocument, options);
      }
export type CreatedPermissionSubscriptionHookResult = ReturnType<typeof useCreatedPermissionSubscription>;
export type CreatedPermissionSubscriptionResult = Apollo.SubscriptionResult<CreatedPermissionSubscription>;
export const UpdatedPermissionDocument = gql`
    subscription UpdatedPermission($filter: PermissionFilterArgs, $withRoles: Boolean = false) {
  updatedPermission(filter: $filter) {
    ...permissionFields
  }
}
    ${PermissionFieldsFragmentDoc}`;

/**
 * __useUpdatedPermissionSubscription__
 *
 * To run a query within a React component, call `useUpdatedPermissionSubscription` and pass it any options that fit your needs.
 * When your component renders, `useUpdatedPermissionSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpdatedPermissionSubscription({
 *   variables: {
 *      filter: // value for 'filter'
 *      withRoles: // value for 'withRoles'
 *   },
 * });
 */
export function useUpdatedPermissionSubscription(baseOptions?: Apollo.SubscriptionHookOptions<UpdatedPermissionSubscription, UpdatedPermissionSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<UpdatedPermissionSubscription, UpdatedPermissionSubscriptionVariables>(UpdatedPermissionDocument, options);
      }
export type UpdatedPermissionSubscriptionHookResult = ReturnType<typeof useUpdatedPermissionSubscription>;
export type UpdatedPermissionSubscriptionResult = Apollo.SubscriptionResult<UpdatedPermissionSubscription>;
export const DeletedPermissionDocument = gql`
    subscription DeletedPermission($filter: PermissionFilterArgs) {
  deletedPermission(filter: $filter) {
    id
  }
}
    `;

/**
 * __useDeletedPermissionSubscription__
 *
 * To run a query within a React component, call `useDeletedPermissionSubscription` and pass it any options that fit your needs.
 * When your component renders, `useDeletedPermissionSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeletedPermissionSubscription({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useDeletedPermissionSubscription(baseOptions?: Apollo.SubscriptionHookOptions<DeletedPermissionSubscription, DeletedPermissionSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<DeletedPermissionSubscription, DeletedPermissionSubscriptionVariables>(DeletedPermissionDocument, options);
      }
export type DeletedPermissionSubscriptionHookResult = ReturnType<typeof useDeletedPermissionSubscription>;
export type DeletedPermissionSubscriptionResult = Apollo.SubscriptionResult<DeletedPermissionSubscription>;
export const GetResetPasswordsDocument = gql`
    query GetResetPasswords($filter: ResetPasswordFilterArgs, $paging: PageArgs, $sorting: [ResetPasswordSortArgs!]) {
  resetPasswords(filter: $filter, paging: $paging, sorting: $sorting) {
    items {
      ...resetPasswordFields
    }
    totalCount
  }
}
    ${ResetPasswordFieldsFragmentDoc}`;

/**
 * __useGetResetPasswordsQuery__
 *
 * To run a query within a React component, call `useGetResetPasswordsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetResetPasswordsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetResetPasswordsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      paging: // value for 'paging'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useGetResetPasswordsQuery(baseOptions?: Apollo.QueryHookOptions<GetResetPasswordsQuery, GetResetPasswordsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetResetPasswordsQuery, GetResetPasswordsQueryVariables>(GetResetPasswordsDocument, options);
      }
export function useGetResetPasswordsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetResetPasswordsQuery, GetResetPasswordsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetResetPasswordsQuery, GetResetPasswordsQueryVariables>(GetResetPasswordsDocument, options);
        }
export function useGetResetPasswordsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetResetPasswordsQuery, GetResetPasswordsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetResetPasswordsQuery, GetResetPasswordsQueryVariables>(GetResetPasswordsDocument, options);
        }
export type GetResetPasswordsQueryHookResult = ReturnType<typeof useGetResetPasswordsQuery>;
export type GetResetPasswordsLazyQueryHookResult = ReturnType<typeof useGetResetPasswordsLazyQuery>;
export type GetResetPasswordsSuspenseQueryHookResult = ReturnType<typeof useGetResetPasswordsSuspenseQuery>;
export type GetResetPasswordsQueryResult = Apollo.QueryResult<GetResetPasswordsQuery, GetResetPasswordsQueryVariables>;
export const GetResetPasswordDocument = gql`
    query GetResetPassword($id: ID!) {
  resetPassword(id: $id) {
    ...resetPasswordFields
  }
}
    ${ResetPasswordFieldsFragmentDoc}`;

/**
 * __useGetResetPasswordQuery__
 *
 * To run a query within a React component, call `useGetResetPasswordQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetResetPasswordQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetResetPasswordQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetResetPasswordQuery(baseOptions: Apollo.QueryHookOptions<GetResetPasswordQuery, GetResetPasswordQueryVariables> & ({ variables: GetResetPasswordQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetResetPasswordQuery, GetResetPasswordQueryVariables>(GetResetPasswordDocument, options);
      }
export function useGetResetPasswordLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetResetPasswordQuery, GetResetPasswordQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetResetPasswordQuery, GetResetPasswordQueryVariables>(GetResetPasswordDocument, options);
        }
export function useGetResetPasswordSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetResetPasswordQuery, GetResetPasswordQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetResetPasswordQuery, GetResetPasswordQueryVariables>(GetResetPasswordDocument, options);
        }
export type GetResetPasswordQueryHookResult = ReturnType<typeof useGetResetPasswordQuery>;
export type GetResetPasswordLazyQueryHookResult = ReturnType<typeof useGetResetPasswordLazyQuery>;
export type GetResetPasswordSuspenseQueryHookResult = ReturnType<typeof useGetResetPasswordSuspenseQuery>;
export type GetResetPasswordQueryResult = Apollo.QueryResult<GetResetPasswordQuery, GetResetPasswordQueryVariables>;
export const CreateResetPasswordDocument = gql`
    mutation CreateResetPassword($email: String!, $token: String!, $type: String!) {
  createResetPassword(email: $email, token: $token, type: $type) {
    ...resetPasswordFields
  }
}
    ${ResetPasswordFieldsFragmentDoc}`;
export type CreateResetPasswordMutationFn = Apollo.MutationFunction<CreateResetPasswordMutation, CreateResetPasswordMutationVariables>;

/**
 * __useCreateResetPasswordMutation__
 *
 * To run a mutation, you first call `useCreateResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createResetPasswordMutation, { data, loading, error }] = useCreateResetPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *      token: // value for 'token'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useCreateResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<CreateResetPasswordMutation, CreateResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateResetPasswordMutation, CreateResetPasswordMutationVariables>(CreateResetPasswordDocument, options);
      }
export type CreateResetPasswordMutationHookResult = ReturnType<typeof useCreateResetPasswordMutation>;
export type CreateResetPasswordMutationResult = Apollo.MutationResult<CreateResetPasswordMutation>;
export type CreateResetPasswordMutationOptions = Apollo.BaseMutationOptions<CreateResetPasswordMutation, CreateResetPasswordMutationVariables>;
export const UpdateResetPasswordDocument = gql`
    mutation UpdateResetPassword($id: ID!, $email: String, $token: String, $type: String) {
  updateResetPassword(id: $id, email: $email, token: $token, type: $type) {
    ...resetPasswordFields
  }
}
    ${ResetPasswordFieldsFragmentDoc}`;
export type UpdateResetPasswordMutationFn = Apollo.MutationFunction<UpdateResetPasswordMutation, UpdateResetPasswordMutationVariables>;

/**
 * __useUpdateResetPasswordMutation__
 *
 * To run a mutation, you first call `useUpdateResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateResetPasswordMutation, { data, loading, error }] = useUpdateResetPasswordMutation({
 *   variables: {
 *      id: // value for 'id'
 *      email: // value for 'email'
 *      token: // value for 'token'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useUpdateResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<UpdateResetPasswordMutation, UpdateResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateResetPasswordMutation, UpdateResetPasswordMutationVariables>(UpdateResetPasswordDocument, options);
      }
export type UpdateResetPasswordMutationHookResult = ReturnType<typeof useUpdateResetPasswordMutation>;
export type UpdateResetPasswordMutationResult = Apollo.MutationResult<UpdateResetPasswordMutation>;
export type UpdateResetPasswordMutationOptions = Apollo.BaseMutationOptions<UpdateResetPasswordMutation, UpdateResetPasswordMutationVariables>;
export const DeleteResetPasswordDocument = gql`
    mutation DeleteResetPassword($id: ID!) {
  deleteResetPassword(id: $id) {
    ...resetPasswordFields
  }
}
    ${ResetPasswordFieldsFragmentDoc}`;
export type DeleteResetPasswordMutationFn = Apollo.MutationFunction<DeleteResetPasswordMutation, DeleteResetPasswordMutationVariables>;

/**
 * __useDeleteResetPasswordMutation__
 *
 * To run a mutation, you first call `useDeleteResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteResetPasswordMutation, { data, loading, error }] = useDeleteResetPasswordMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<DeleteResetPasswordMutation, DeleteResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteResetPasswordMutation, DeleteResetPasswordMutationVariables>(DeleteResetPasswordDocument, options);
      }
export type DeleteResetPasswordMutationHookResult = ReturnType<typeof useDeleteResetPasswordMutation>;
export type DeleteResetPasswordMutationResult = Apollo.MutationResult<DeleteResetPasswordMutation>;
export type DeleteResetPasswordMutationOptions = Apollo.BaseMutationOptions<DeleteResetPasswordMutation, DeleteResetPasswordMutationVariables>;
export const CreatedResetPasswordDocument = gql`
    subscription CreatedResetPassword($filter: ResetPasswordFilterArgs) {
  createdResetPassword(filter: $filter) {
    ...resetPasswordFields
  }
}
    ${ResetPasswordFieldsFragmentDoc}`;

/**
 * __useCreatedResetPasswordSubscription__
 *
 * To run a query within a React component, call `useCreatedResetPasswordSubscription` and pass it any options that fit your needs.
 * When your component renders, `useCreatedResetPasswordSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreatedResetPasswordSubscription({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCreatedResetPasswordSubscription(baseOptions?: Apollo.SubscriptionHookOptions<CreatedResetPasswordSubscription, CreatedResetPasswordSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<CreatedResetPasswordSubscription, CreatedResetPasswordSubscriptionVariables>(CreatedResetPasswordDocument, options);
      }
export type CreatedResetPasswordSubscriptionHookResult = ReturnType<typeof useCreatedResetPasswordSubscription>;
export type CreatedResetPasswordSubscriptionResult = Apollo.SubscriptionResult<CreatedResetPasswordSubscription>;
export const UpdatedResetPasswordDocument = gql`
    subscription UpdatedResetPassword($filter: ResetPasswordFilterArgs) {
  updatedResetPassword(filter: $filter) {
    ...resetPasswordFields
  }
}
    ${ResetPasswordFieldsFragmentDoc}`;

/**
 * __useUpdatedResetPasswordSubscription__
 *
 * To run a query within a React component, call `useUpdatedResetPasswordSubscription` and pass it any options that fit your needs.
 * When your component renders, `useUpdatedResetPasswordSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpdatedResetPasswordSubscription({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useUpdatedResetPasswordSubscription(baseOptions?: Apollo.SubscriptionHookOptions<UpdatedResetPasswordSubscription, UpdatedResetPasswordSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<UpdatedResetPasswordSubscription, UpdatedResetPasswordSubscriptionVariables>(UpdatedResetPasswordDocument, options);
      }
export type UpdatedResetPasswordSubscriptionHookResult = ReturnType<typeof useUpdatedResetPasswordSubscription>;
export type UpdatedResetPasswordSubscriptionResult = Apollo.SubscriptionResult<UpdatedResetPasswordSubscription>;
export const DeletedResetPasswordDocument = gql`
    subscription DeletedResetPassword($filter: ResetPasswordFilterArgs) {
  deletedResetPassword(filter: $filter) {
    id
  }
}
    `;

/**
 * __useDeletedResetPasswordSubscription__
 *
 * To run a query within a React component, call `useDeletedResetPasswordSubscription` and pass it any options that fit your needs.
 * When your component renders, `useDeletedResetPasswordSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeletedResetPasswordSubscription({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useDeletedResetPasswordSubscription(baseOptions?: Apollo.SubscriptionHookOptions<DeletedResetPasswordSubscription, DeletedResetPasswordSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<DeletedResetPasswordSubscription, DeletedResetPasswordSubscriptionVariables>(DeletedResetPasswordDocument, options);
      }
export type DeletedResetPasswordSubscriptionHookResult = ReturnType<typeof useDeletedResetPasswordSubscription>;
export type DeletedResetPasswordSubscriptionResult = Apollo.SubscriptionResult<DeletedResetPasswordSubscription>;
export const RestoredResetPasswordDocument = gql`
    subscription RestoredResetPassword($filter: ResetPasswordFilterArgs) {
  restoredResetPassword(filter: $filter) {
    ...resetPasswordFields
  }
}
    ${ResetPasswordFieldsFragmentDoc}`;

/**
 * __useRestoredResetPasswordSubscription__
 *
 * To run a query within a React component, call `useRestoredResetPasswordSubscription` and pass it any options that fit your needs.
 * When your component renders, `useRestoredResetPasswordSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRestoredResetPasswordSubscription({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useRestoredResetPasswordSubscription(baseOptions?: Apollo.SubscriptionHookOptions<RestoredResetPasswordSubscription, RestoredResetPasswordSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<RestoredResetPasswordSubscription, RestoredResetPasswordSubscriptionVariables>(RestoredResetPasswordDocument, options);
      }
export type RestoredResetPasswordSubscriptionHookResult = ReturnType<typeof useRestoredResetPasswordSubscription>;
export type RestoredResetPasswordSubscriptionResult = Apollo.SubscriptionResult<RestoredResetPasswordSubscription>;
export const GetRolesDocument = gql`
    query GetRoles($filter: RoleFilterArgs, $paging: PageArgs, $sorting: [RoleSortArgs!], $withPermissions: Boolean = false, $withUserTenantRoles: Boolean = false) {
  roles(filter: $filter, paging: $paging, sorting: $sorting) {
    items {
      ...roleFields
    }
    totalCount
  }
}
    ${RoleFieldsFragmentDoc}`;

/**
 * __useGetRolesQuery__
 *
 * To run a query within a React component, call `useGetRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRolesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      paging: // value for 'paging'
 *      sorting: // value for 'sorting'
 *      withPermissions: // value for 'withPermissions'
 *      withUserTenantRoles: // value for 'withUserTenantRoles'
 *   },
 * });
 */
export function useGetRolesQuery(baseOptions?: Apollo.QueryHookOptions<GetRolesQuery, GetRolesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRolesQuery, GetRolesQueryVariables>(GetRolesDocument, options);
      }
export function useGetRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRolesQuery, GetRolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRolesQuery, GetRolesQueryVariables>(GetRolesDocument, options);
        }
export function useGetRolesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetRolesQuery, GetRolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetRolesQuery, GetRolesQueryVariables>(GetRolesDocument, options);
        }
export type GetRolesQueryHookResult = ReturnType<typeof useGetRolesQuery>;
export type GetRolesLazyQueryHookResult = ReturnType<typeof useGetRolesLazyQuery>;
export type GetRolesSuspenseQueryHookResult = ReturnType<typeof useGetRolesSuspenseQuery>;
export type GetRolesQueryResult = Apollo.QueryResult<GetRolesQuery, GetRolesQueryVariables>;
export const GetRoleDocument = gql`
    query GetRole($id: ID!, $withPermissions: Boolean = false, $withUserTenantRoles: Boolean = false) {
  role(id: $id) {
    ...roleFields
  }
}
    ${RoleFieldsFragmentDoc}`;

/**
 * __useGetRoleQuery__
 *
 * To run a query within a React component, call `useGetRoleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoleQuery({
 *   variables: {
 *      id: // value for 'id'
 *      withPermissions: // value for 'withPermissions'
 *      withUserTenantRoles: // value for 'withUserTenantRoles'
 *   },
 * });
 */
export function useGetRoleQuery(baseOptions: Apollo.QueryHookOptions<GetRoleQuery, GetRoleQueryVariables> & ({ variables: GetRoleQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRoleQuery, GetRoleQueryVariables>(GetRoleDocument, options);
      }
export function useGetRoleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRoleQuery, GetRoleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRoleQuery, GetRoleQueryVariables>(GetRoleDocument, options);
        }
export function useGetRoleSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetRoleQuery, GetRoleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetRoleQuery, GetRoleQueryVariables>(GetRoleDocument, options);
        }
export type GetRoleQueryHookResult = ReturnType<typeof useGetRoleQuery>;
export type GetRoleLazyQueryHookResult = ReturnType<typeof useGetRoleLazyQuery>;
export type GetRoleSuspenseQueryHookResult = ReturnType<typeof useGetRoleSuspenseQuery>;
export type GetRoleQueryResult = Apollo.QueryResult<GetRoleQuery, GetRoleQueryVariables>;
export const GetAllRelatedDataForRoleDocument = gql`
    query GetAllRelatedDataForRole($withPermissions: Boolean = false, $withUserTenantRoles: Boolean = false) {
  permissions @include(if: $withPermissions) {
    items {
      id
      displayField: name
    }
  }
  userTenantRoles @include(if: $withUserTenantRoles) {
    items {
      id
      displayField: id
    }
  }
}
    `;

/**
 * __useGetAllRelatedDataForRoleQuery__
 *
 * To run a query within a React component, call `useGetAllRelatedDataForRoleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllRelatedDataForRoleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllRelatedDataForRoleQuery({
 *   variables: {
 *      withPermissions: // value for 'withPermissions'
 *      withUserTenantRoles: // value for 'withUserTenantRoles'
 *   },
 * });
 */
export function useGetAllRelatedDataForRoleQuery(baseOptions?: Apollo.QueryHookOptions<GetAllRelatedDataForRoleQuery, GetAllRelatedDataForRoleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllRelatedDataForRoleQuery, GetAllRelatedDataForRoleQueryVariables>(GetAllRelatedDataForRoleDocument, options);
      }
export function useGetAllRelatedDataForRoleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllRelatedDataForRoleQuery, GetAllRelatedDataForRoleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllRelatedDataForRoleQuery, GetAllRelatedDataForRoleQueryVariables>(GetAllRelatedDataForRoleDocument, options);
        }
export function useGetAllRelatedDataForRoleSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetAllRelatedDataForRoleQuery, GetAllRelatedDataForRoleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAllRelatedDataForRoleQuery, GetAllRelatedDataForRoleQueryVariables>(GetAllRelatedDataForRoleDocument, options);
        }
export type GetAllRelatedDataForRoleQueryHookResult = ReturnType<typeof useGetAllRelatedDataForRoleQuery>;
export type GetAllRelatedDataForRoleLazyQueryHookResult = ReturnType<typeof useGetAllRelatedDataForRoleLazyQuery>;
export type GetAllRelatedDataForRoleSuspenseQueryHookResult = ReturnType<typeof useGetAllRelatedDataForRoleSuspenseQuery>;
export type GetAllRelatedDataForRoleQueryResult = Apollo.QueryResult<GetAllRelatedDataForRoleQuery, GetAllRelatedDataForRoleQueryVariables>;
export const CreateRoleDocument = gql`
    mutation CreateRole($displayName: String!, $description: String!, $permissions: [ID], $userTenantRoles: [ID], $withPermissions: Boolean = false, $withUserTenantRoles: Boolean = false) {
  createRole(
    displayName: $displayName
    description: $description
    permissionIds: $permissions
    userTenantRoleIds: $userTenantRoles
  ) {
    ...roleFields
  }
}
    ${RoleFieldsFragmentDoc}`;
export type CreateRoleMutationFn = Apollo.MutationFunction<CreateRoleMutation, CreateRoleMutationVariables>;

/**
 * __useCreateRoleMutation__
 *
 * To run a mutation, you first call `useCreateRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRoleMutation, { data, loading, error }] = useCreateRoleMutation({
 *   variables: {
 *      displayName: // value for 'displayName'
 *      description: // value for 'description'
 *      permissions: // value for 'permissions'
 *      userTenantRoles: // value for 'userTenantRoles'
 *      withPermissions: // value for 'withPermissions'
 *      withUserTenantRoles: // value for 'withUserTenantRoles'
 *   },
 * });
 */
export function useCreateRoleMutation(baseOptions?: Apollo.MutationHookOptions<CreateRoleMutation, CreateRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateRoleMutation, CreateRoleMutationVariables>(CreateRoleDocument, options);
      }
export type CreateRoleMutationHookResult = ReturnType<typeof useCreateRoleMutation>;
export type CreateRoleMutationResult = Apollo.MutationResult<CreateRoleMutation>;
export type CreateRoleMutationOptions = Apollo.BaseMutationOptions<CreateRoleMutation, CreateRoleMutationVariables>;
export const UpdateRoleDocument = gql`
    mutation UpdateRole($id: ID!, $displayName: String, $description: String, $permissions: [ID], $userTenantRoles: [ID], $withPermissions: Boolean = false, $withUserTenantRoles: Boolean = false) {
  updateRole(
    id: $id
    displayName: $displayName
    description: $description
    permissionIds: $permissions
    userTenantRoleIds: $userTenantRoles
  ) {
    ...roleFields
  }
}
    ${RoleFieldsFragmentDoc}`;
export type UpdateRoleMutationFn = Apollo.MutationFunction<UpdateRoleMutation, UpdateRoleMutationVariables>;

/**
 * __useUpdateRoleMutation__
 *
 * To run a mutation, you first call `useUpdateRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRoleMutation, { data, loading, error }] = useUpdateRoleMutation({
 *   variables: {
 *      id: // value for 'id'
 *      displayName: // value for 'displayName'
 *      description: // value for 'description'
 *      permissions: // value for 'permissions'
 *      userTenantRoles: // value for 'userTenantRoles'
 *      withPermissions: // value for 'withPermissions'
 *      withUserTenantRoles: // value for 'withUserTenantRoles'
 *   },
 * });
 */
export function useUpdateRoleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRoleMutation, UpdateRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRoleMutation, UpdateRoleMutationVariables>(UpdateRoleDocument, options);
      }
export type UpdateRoleMutationHookResult = ReturnType<typeof useUpdateRoleMutation>;
export type UpdateRoleMutationResult = Apollo.MutationResult<UpdateRoleMutation>;
export type UpdateRoleMutationOptions = Apollo.BaseMutationOptions<UpdateRoleMutation, UpdateRoleMutationVariables>;
export const DeleteRoleDocument = gql`
    mutation DeleteRole($id: ID!, $withPermissions: Boolean = false, $withUserTenantRoles: Boolean = false) {
  deleteRole(id: $id) {
    ...roleFields
  }
}
    ${RoleFieldsFragmentDoc}`;
export type DeleteRoleMutationFn = Apollo.MutationFunction<DeleteRoleMutation, DeleteRoleMutationVariables>;

/**
 * __useDeleteRoleMutation__
 *
 * To run a mutation, you first call `useDeleteRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRoleMutation, { data, loading, error }] = useDeleteRoleMutation({
 *   variables: {
 *      id: // value for 'id'
 *      withPermissions: // value for 'withPermissions'
 *      withUserTenantRoles: // value for 'withUserTenantRoles'
 *   },
 * });
 */
export function useDeleteRoleMutation(baseOptions?: Apollo.MutationHookOptions<DeleteRoleMutation, DeleteRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteRoleMutation, DeleteRoleMutationVariables>(DeleteRoleDocument, options);
      }
export type DeleteRoleMutationHookResult = ReturnType<typeof useDeleteRoleMutation>;
export type DeleteRoleMutationResult = Apollo.MutationResult<DeleteRoleMutation>;
export type DeleteRoleMutationOptions = Apollo.BaseMutationOptions<DeleteRoleMutation, DeleteRoleMutationVariables>;
export const CreatedRoleDocument = gql`
    subscription CreatedRole($filter: RoleFilterArgs, $withPermissions: Boolean = false, $withUserTenantRoles: Boolean = false) {
  createdRole(filter: $filter) {
    ...roleFields
  }
}
    ${RoleFieldsFragmentDoc}`;

/**
 * __useCreatedRoleSubscription__
 *
 * To run a query within a React component, call `useCreatedRoleSubscription` and pass it any options that fit your needs.
 * When your component renders, `useCreatedRoleSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreatedRoleSubscription({
 *   variables: {
 *      filter: // value for 'filter'
 *      withPermissions: // value for 'withPermissions'
 *      withUserTenantRoles: // value for 'withUserTenantRoles'
 *   },
 * });
 */
export function useCreatedRoleSubscription(baseOptions?: Apollo.SubscriptionHookOptions<CreatedRoleSubscription, CreatedRoleSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<CreatedRoleSubscription, CreatedRoleSubscriptionVariables>(CreatedRoleDocument, options);
      }
export type CreatedRoleSubscriptionHookResult = ReturnType<typeof useCreatedRoleSubscription>;
export type CreatedRoleSubscriptionResult = Apollo.SubscriptionResult<CreatedRoleSubscription>;
export const UpdatedRoleDocument = gql`
    subscription UpdatedRole($filter: RoleFilterArgs, $withPermissions: Boolean = false, $withUserTenantRoles: Boolean = false) {
  updatedRole(filter: $filter) {
    ...roleFields
  }
}
    ${RoleFieldsFragmentDoc}`;

/**
 * __useUpdatedRoleSubscription__
 *
 * To run a query within a React component, call `useUpdatedRoleSubscription` and pass it any options that fit your needs.
 * When your component renders, `useUpdatedRoleSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpdatedRoleSubscription({
 *   variables: {
 *      filter: // value for 'filter'
 *      withPermissions: // value for 'withPermissions'
 *      withUserTenantRoles: // value for 'withUserTenantRoles'
 *   },
 * });
 */
export function useUpdatedRoleSubscription(baseOptions?: Apollo.SubscriptionHookOptions<UpdatedRoleSubscription, UpdatedRoleSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<UpdatedRoleSubscription, UpdatedRoleSubscriptionVariables>(UpdatedRoleDocument, options);
      }
export type UpdatedRoleSubscriptionHookResult = ReturnType<typeof useUpdatedRoleSubscription>;
export type UpdatedRoleSubscriptionResult = Apollo.SubscriptionResult<UpdatedRoleSubscription>;
export const DeletedRoleDocument = gql`
    subscription DeletedRole($filter: RoleFilterArgs) {
  deletedRole(filter: $filter) {
    id
  }
}
    `;

/**
 * __useDeletedRoleSubscription__
 *
 * To run a query within a React component, call `useDeletedRoleSubscription` and pass it any options that fit your needs.
 * When your component renders, `useDeletedRoleSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeletedRoleSubscription({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useDeletedRoleSubscription(baseOptions?: Apollo.SubscriptionHookOptions<DeletedRoleSubscription, DeletedRoleSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<DeletedRoleSubscription, DeletedRoleSubscriptionVariables>(DeletedRoleDocument, options);
      }
export type DeletedRoleSubscriptionHookResult = ReturnType<typeof useDeletedRoleSubscription>;
export type DeletedRoleSubscriptionResult = Apollo.SubscriptionResult<DeletedRoleSubscription>;
export const GetScansDocument = gql`
    query GetScans($filter: ScanFilterArgs, $paging: PageArgs, $sorting: [ScanSortArgs!], $withBranch: Boolean = false, $withAdvisories: Boolean = false, $withScanFiles: Boolean = false) {
  scans(filter: $filter, paging: $paging, sorting: $sorting) {
    items {
      ...scanFields
    }
    totalCount
  }
}
    ${ScanFieldsFragmentDoc}`;

/**
 * __useGetScansQuery__
 *
 * To run a query within a React component, call `useGetScansQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScansQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      paging: // value for 'paging'
 *      sorting: // value for 'sorting'
 *      withBranch: // value for 'withBranch'
 *      withAdvisories: // value for 'withAdvisories'
 *      withScanFiles: // value for 'withScanFiles'
 *   },
 * });
 */
export function useGetScansQuery(baseOptions?: Apollo.QueryHookOptions<GetScansQuery, GetScansQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetScansQuery, GetScansQueryVariables>(GetScansDocument, options);
      }
export function useGetScansLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetScansQuery, GetScansQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetScansQuery, GetScansQueryVariables>(GetScansDocument, options);
        }
export function useGetScansSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetScansQuery, GetScansQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetScansQuery, GetScansQueryVariables>(GetScansDocument, options);
        }
export type GetScansQueryHookResult = ReturnType<typeof useGetScansQuery>;
export type GetScansLazyQueryHookResult = ReturnType<typeof useGetScansLazyQuery>;
export type GetScansSuspenseQueryHookResult = ReturnType<typeof useGetScansSuspenseQuery>;
export type GetScansQueryResult = Apollo.QueryResult<GetScansQuery, GetScansQueryVariables>;
export const GetScanDocument = gql`
    query GetScan($id: ID!, $withBranch: Boolean = false, $withAdvisories: Boolean = false, $withScanFiles: Boolean = false) {
  scan(id: $id) {
    ...scanFields
  }
}
    ${ScanFieldsFragmentDoc}`;

/**
 * __useGetScanQuery__
 *
 * To run a query within a React component, call `useGetScanQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScanQuery({
 *   variables: {
 *      id: // value for 'id'
 *      withBranch: // value for 'withBranch'
 *      withAdvisories: // value for 'withAdvisories'
 *      withScanFiles: // value for 'withScanFiles'
 *   },
 * });
 */
export function useGetScanQuery(baseOptions: Apollo.QueryHookOptions<GetScanQuery, GetScanQueryVariables> & ({ variables: GetScanQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetScanQuery, GetScanQueryVariables>(GetScanDocument, options);
      }
export function useGetScanLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetScanQuery, GetScanQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetScanQuery, GetScanQueryVariables>(GetScanDocument, options);
        }
export function useGetScanSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetScanQuery, GetScanQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetScanQuery, GetScanQueryVariables>(GetScanDocument, options);
        }
export type GetScanQueryHookResult = ReturnType<typeof useGetScanQuery>;
export type GetScanLazyQueryHookResult = ReturnType<typeof useGetScanLazyQuery>;
export type GetScanSuspenseQueryHookResult = ReturnType<typeof useGetScanSuspenseQuery>;
export type GetScanQueryResult = Apollo.QueryResult<GetScanQuery, GetScanQueryVariables>;
export const GetAllRelatedDataForScanDocument = gql`
    query GetAllRelatedDataForScan($withBranches: Boolean = false, $withAdvisories: Boolean = false, $withScanFiles: Boolean = false) {
  branches @include(if: $withBranches) {
    items {
      id
      displayField: name
    }
  }
  advisories @include(if: $withAdvisories) {
    items {
      id
      displayField: title
    }
  }
  scanFiles @include(if: $withScanFiles) {
    items {
      id
      displayField: name
    }
  }
}
    `;

/**
 * __useGetAllRelatedDataForScanQuery__
 *
 * To run a query within a React component, call `useGetAllRelatedDataForScanQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllRelatedDataForScanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllRelatedDataForScanQuery({
 *   variables: {
 *      withBranches: // value for 'withBranches'
 *      withAdvisories: // value for 'withAdvisories'
 *      withScanFiles: // value for 'withScanFiles'
 *   },
 * });
 */
export function useGetAllRelatedDataForScanQuery(baseOptions?: Apollo.QueryHookOptions<GetAllRelatedDataForScanQuery, GetAllRelatedDataForScanQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllRelatedDataForScanQuery, GetAllRelatedDataForScanQueryVariables>(GetAllRelatedDataForScanDocument, options);
      }
export function useGetAllRelatedDataForScanLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllRelatedDataForScanQuery, GetAllRelatedDataForScanQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllRelatedDataForScanQuery, GetAllRelatedDataForScanQueryVariables>(GetAllRelatedDataForScanDocument, options);
        }
export function useGetAllRelatedDataForScanSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetAllRelatedDataForScanQuery, GetAllRelatedDataForScanQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAllRelatedDataForScanQuery, GetAllRelatedDataForScanQueryVariables>(GetAllRelatedDataForScanDocument, options);
        }
export type GetAllRelatedDataForScanQueryHookResult = ReturnType<typeof useGetAllRelatedDataForScanQuery>;
export type GetAllRelatedDataForScanLazyQueryHookResult = ReturnType<typeof useGetAllRelatedDataForScanLazyQuery>;
export type GetAllRelatedDataForScanSuspenseQueryHookResult = ReturnType<typeof useGetAllRelatedDataForScanSuspenseQuery>;
export type GetAllRelatedDataForScanQueryResult = Apollo.QueryResult<GetAllRelatedDataForScanQuery, GetAllRelatedDataForScanQueryVariables>;
export const CreateScanDocument = gql`
    mutation CreateScan($directory: String!, $type: String!, $branchId: ID!, $advisories: [ID], $scanFiles: [ID], $withBranch: Boolean = false, $withAdvisories: Boolean = false, $withScanFiles: Boolean = false) {
  createScan(
    directory: $directory
    type: $type
    branchId: $branchId
    advisoryIds: $advisories
    scanFileIds: $scanFiles
  ) {
    ...scanFields
  }
}
    ${ScanFieldsFragmentDoc}`;
export type CreateScanMutationFn = Apollo.MutationFunction<CreateScanMutation, CreateScanMutationVariables>;

/**
 * __useCreateScanMutation__
 *
 * To run a mutation, you first call `useCreateScanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateScanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createScanMutation, { data, loading, error }] = useCreateScanMutation({
 *   variables: {
 *      directory: // value for 'directory'
 *      type: // value for 'type'
 *      branchId: // value for 'branchId'
 *      advisories: // value for 'advisories'
 *      scanFiles: // value for 'scanFiles'
 *      withBranch: // value for 'withBranch'
 *      withAdvisories: // value for 'withAdvisories'
 *      withScanFiles: // value for 'withScanFiles'
 *   },
 * });
 */
export function useCreateScanMutation(baseOptions?: Apollo.MutationHookOptions<CreateScanMutation, CreateScanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateScanMutation, CreateScanMutationVariables>(CreateScanDocument, options);
      }
export type CreateScanMutationHookResult = ReturnType<typeof useCreateScanMutation>;
export type CreateScanMutationResult = Apollo.MutationResult<CreateScanMutation>;
export type CreateScanMutationOptions = Apollo.BaseMutationOptions<CreateScanMutation, CreateScanMutationVariables>;
export const UpdateScanDocument = gql`
    mutation UpdateScan($id: ID!, $directory: String, $type: String, $branchId: ID, $advisories: [ID], $scanFiles: [ID], $withBranch: Boolean = false, $withAdvisories: Boolean = false, $withScanFiles: Boolean = false) {
  updateScan(
    id: $id
    directory: $directory
    type: $type
    branchId: $branchId
    advisoryIds: $advisories
    scanFileIds: $scanFiles
  ) {
    ...scanFields
  }
}
    ${ScanFieldsFragmentDoc}`;
export type UpdateScanMutationFn = Apollo.MutationFunction<UpdateScanMutation, UpdateScanMutationVariables>;

/**
 * __useUpdateScanMutation__
 *
 * To run a mutation, you first call `useUpdateScanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateScanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateScanMutation, { data, loading, error }] = useUpdateScanMutation({
 *   variables: {
 *      id: // value for 'id'
 *      directory: // value for 'directory'
 *      type: // value for 'type'
 *      branchId: // value for 'branchId'
 *      advisories: // value for 'advisories'
 *      scanFiles: // value for 'scanFiles'
 *      withBranch: // value for 'withBranch'
 *      withAdvisories: // value for 'withAdvisories'
 *      withScanFiles: // value for 'withScanFiles'
 *   },
 * });
 */
export function useUpdateScanMutation(baseOptions?: Apollo.MutationHookOptions<UpdateScanMutation, UpdateScanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateScanMutation, UpdateScanMutationVariables>(UpdateScanDocument, options);
      }
export type UpdateScanMutationHookResult = ReturnType<typeof useUpdateScanMutation>;
export type UpdateScanMutationResult = Apollo.MutationResult<UpdateScanMutation>;
export type UpdateScanMutationOptions = Apollo.BaseMutationOptions<UpdateScanMutation, UpdateScanMutationVariables>;
export const DeleteScanDocument = gql`
    mutation DeleteScan($id: ID!, $withBranch: Boolean = false, $withAdvisories: Boolean = false, $withScanFiles: Boolean = false) {
  deleteScan(id: $id) {
    ...scanFields
  }
}
    ${ScanFieldsFragmentDoc}`;
export type DeleteScanMutationFn = Apollo.MutationFunction<DeleteScanMutation, DeleteScanMutationVariables>;

/**
 * __useDeleteScanMutation__
 *
 * To run a mutation, you first call `useDeleteScanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteScanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteScanMutation, { data, loading, error }] = useDeleteScanMutation({
 *   variables: {
 *      id: // value for 'id'
 *      withBranch: // value for 'withBranch'
 *      withAdvisories: // value for 'withAdvisories'
 *      withScanFiles: // value for 'withScanFiles'
 *   },
 * });
 */
export function useDeleteScanMutation(baseOptions?: Apollo.MutationHookOptions<DeleteScanMutation, DeleteScanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteScanMutation, DeleteScanMutationVariables>(DeleteScanDocument, options);
      }
export type DeleteScanMutationHookResult = ReturnType<typeof useDeleteScanMutation>;
export type DeleteScanMutationResult = Apollo.MutationResult<DeleteScanMutation>;
export type DeleteScanMutationOptions = Apollo.BaseMutationOptions<DeleteScanMutation, DeleteScanMutationVariables>;
export const CreatedScanDocument = gql`
    subscription CreatedScan($filter: ScanFilterArgs, $withBranch: Boolean = false, $withAdvisories: Boolean = false, $withScanFiles: Boolean = false) {
  createdScan(filter: $filter) {
    ...scanFields
  }
}
    ${ScanFieldsFragmentDoc}`;

/**
 * __useCreatedScanSubscription__
 *
 * To run a query within a React component, call `useCreatedScanSubscription` and pass it any options that fit your needs.
 * When your component renders, `useCreatedScanSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreatedScanSubscription({
 *   variables: {
 *      filter: // value for 'filter'
 *      withBranch: // value for 'withBranch'
 *      withAdvisories: // value for 'withAdvisories'
 *      withScanFiles: // value for 'withScanFiles'
 *   },
 * });
 */
export function useCreatedScanSubscription(baseOptions?: Apollo.SubscriptionHookOptions<CreatedScanSubscription, CreatedScanSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<CreatedScanSubscription, CreatedScanSubscriptionVariables>(CreatedScanDocument, options);
      }
export type CreatedScanSubscriptionHookResult = ReturnType<typeof useCreatedScanSubscription>;
export type CreatedScanSubscriptionResult = Apollo.SubscriptionResult<CreatedScanSubscription>;
export const UpdatedScanDocument = gql`
    subscription UpdatedScan($filter: ScanFilterArgs, $withBranch: Boolean = false, $withAdvisories: Boolean = false, $withScanFiles: Boolean = false) {
  updatedScan(filter: $filter) {
    ...scanFields
  }
}
    ${ScanFieldsFragmentDoc}`;

/**
 * __useUpdatedScanSubscription__
 *
 * To run a query within a React component, call `useUpdatedScanSubscription` and pass it any options that fit your needs.
 * When your component renders, `useUpdatedScanSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpdatedScanSubscription({
 *   variables: {
 *      filter: // value for 'filter'
 *      withBranch: // value for 'withBranch'
 *      withAdvisories: // value for 'withAdvisories'
 *      withScanFiles: // value for 'withScanFiles'
 *   },
 * });
 */
export function useUpdatedScanSubscription(baseOptions?: Apollo.SubscriptionHookOptions<UpdatedScanSubscription, UpdatedScanSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<UpdatedScanSubscription, UpdatedScanSubscriptionVariables>(UpdatedScanDocument, options);
      }
export type UpdatedScanSubscriptionHookResult = ReturnType<typeof useUpdatedScanSubscription>;
export type UpdatedScanSubscriptionResult = Apollo.SubscriptionResult<UpdatedScanSubscription>;
export const DeletedScanDocument = gql`
    subscription DeletedScan($filter: ScanFilterArgs) {
  deletedScan(filter: $filter) {
    id
  }
}
    `;

/**
 * __useDeletedScanSubscription__
 *
 * To run a query within a React component, call `useDeletedScanSubscription` and pass it any options that fit your needs.
 * When your component renders, `useDeletedScanSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeletedScanSubscription({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useDeletedScanSubscription(baseOptions?: Apollo.SubscriptionHookOptions<DeletedScanSubscription, DeletedScanSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<DeletedScanSubscription, DeletedScanSubscriptionVariables>(DeletedScanDocument, options);
      }
export type DeletedScanSubscriptionHookResult = ReturnType<typeof useDeletedScanSubscription>;
export type DeletedScanSubscriptionResult = Apollo.SubscriptionResult<DeletedScanSubscription>;
export const RestoredScanDocument = gql`
    subscription RestoredScan($filter: ScanFilterArgs, $withBranch: Boolean = false, $withAdvisories: Boolean = false, $withScanFiles: Boolean = false) {
  restoredScan(filter: $filter) {
    ...scanFields
  }
}
    ${ScanFieldsFragmentDoc}`;

/**
 * __useRestoredScanSubscription__
 *
 * To run a query within a React component, call `useRestoredScanSubscription` and pass it any options that fit your needs.
 * When your component renders, `useRestoredScanSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRestoredScanSubscription({
 *   variables: {
 *      filter: // value for 'filter'
 *      withBranch: // value for 'withBranch'
 *      withAdvisories: // value for 'withAdvisories'
 *      withScanFiles: // value for 'withScanFiles'
 *   },
 * });
 */
export function useRestoredScanSubscription(baseOptions?: Apollo.SubscriptionHookOptions<RestoredScanSubscription, RestoredScanSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<RestoredScanSubscription, RestoredScanSubscriptionVariables>(RestoredScanDocument, options);
      }
export type RestoredScanSubscriptionHookResult = ReturnType<typeof useRestoredScanSubscription>;
export type RestoredScanSubscriptionResult = Apollo.SubscriptionResult<RestoredScanSubscription>;
export const GetScanFilesDocument = gql`
    query GetScanFiles($filter: ScanFileFilterArgs, $paging: PageArgs, $sorting: [ScanFileSortArgs!], $withScan: Boolean = false) {
  scanFiles(filter: $filter, paging: $paging, sorting: $sorting) {
    items {
      ...scanFileFields
    }
    totalCount
  }
}
    ${ScanFileFieldsFragmentDoc}`;

/**
 * __useGetScanFilesQuery__
 *
 * To run a query within a React component, call `useGetScanFilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScanFilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScanFilesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      paging: // value for 'paging'
 *      sorting: // value for 'sorting'
 *      withScan: // value for 'withScan'
 *   },
 * });
 */
export function useGetScanFilesQuery(baseOptions?: Apollo.QueryHookOptions<GetScanFilesQuery, GetScanFilesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetScanFilesQuery, GetScanFilesQueryVariables>(GetScanFilesDocument, options);
      }
export function useGetScanFilesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetScanFilesQuery, GetScanFilesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetScanFilesQuery, GetScanFilesQueryVariables>(GetScanFilesDocument, options);
        }
export function useGetScanFilesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetScanFilesQuery, GetScanFilesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetScanFilesQuery, GetScanFilesQueryVariables>(GetScanFilesDocument, options);
        }
export type GetScanFilesQueryHookResult = ReturnType<typeof useGetScanFilesQuery>;
export type GetScanFilesLazyQueryHookResult = ReturnType<typeof useGetScanFilesLazyQuery>;
export type GetScanFilesSuspenseQueryHookResult = ReturnType<typeof useGetScanFilesSuspenseQuery>;
export type GetScanFilesQueryResult = Apollo.QueryResult<GetScanFilesQuery, GetScanFilesQueryVariables>;
export const GetScanFileDocument = gql`
    query GetScanFile($id: ID!, $withScan: Boolean = false) {
  scanFile(id: $id) {
    ...scanFileFields
  }
}
    ${ScanFileFieldsFragmentDoc}`;

/**
 * __useGetScanFileQuery__
 *
 * To run a query within a React component, call `useGetScanFileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScanFileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScanFileQuery({
 *   variables: {
 *      id: // value for 'id'
 *      withScan: // value for 'withScan'
 *   },
 * });
 */
export function useGetScanFileQuery(baseOptions: Apollo.QueryHookOptions<GetScanFileQuery, GetScanFileQueryVariables> & ({ variables: GetScanFileQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetScanFileQuery, GetScanFileQueryVariables>(GetScanFileDocument, options);
      }
export function useGetScanFileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetScanFileQuery, GetScanFileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetScanFileQuery, GetScanFileQueryVariables>(GetScanFileDocument, options);
        }
export function useGetScanFileSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetScanFileQuery, GetScanFileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetScanFileQuery, GetScanFileQueryVariables>(GetScanFileDocument, options);
        }
export type GetScanFileQueryHookResult = ReturnType<typeof useGetScanFileQuery>;
export type GetScanFileLazyQueryHookResult = ReturnType<typeof useGetScanFileLazyQuery>;
export type GetScanFileSuspenseQueryHookResult = ReturnType<typeof useGetScanFileSuspenseQuery>;
export type GetScanFileQueryResult = Apollo.QueryResult<GetScanFileQuery, GetScanFileQueryVariables>;
export const GetAllRelatedDataForScanFileDocument = gql`
    query GetAllRelatedDataForScanFile($withScans: Boolean = false) {
  scans @include(if: $withScans) {
    items {
      id
      displayField: id
    }
  }
}
    `;

/**
 * __useGetAllRelatedDataForScanFileQuery__
 *
 * To run a query within a React component, call `useGetAllRelatedDataForScanFileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllRelatedDataForScanFileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllRelatedDataForScanFileQuery({
 *   variables: {
 *      withScans: // value for 'withScans'
 *   },
 * });
 */
export function useGetAllRelatedDataForScanFileQuery(baseOptions?: Apollo.QueryHookOptions<GetAllRelatedDataForScanFileQuery, GetAllRelatedDataForScanFileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllRelatedDataForScanFileQuery, GetAllRelatedDataForScanFileQueryVariables>(GetAllRelatedDataForScanFileDocument, options);
      }
export function useGetAllRelatedDataForScanFileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllRelatedDataForScanFileQuery, GetAllRelatedDataForScanFileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllRelatedDataForScanFileQuery, GetAllRelatedDataForScanFileQueryVariables>(GetAllRelatedDataForScanFileDocument, options);
        }
export function useGetAllRelatedDataForScanFileSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetAllRelatedDataForScanFileQuery, GetAllRelatedDataForScanFileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAllRelatedDataForScanFileQuery, GetAllRelatedDataForScanFileQueryVariables>(GetAllRelatedDataForScanFileDocument, options);
        }
export type GetAllRelatedDataForScanFileQueryHookResult = ReturnType<typeof useGetAllRelatedDataForScanFileQuery>;
export type GetAllRelatedDataForScanFileLazyQueryHookResult = ReturnType<typeof useGetAllRelatedDataForScanFileLazyQuery>;
export type GetAllRelatedDataForScanFileSuspenseQueryHookResult = ReturnType<typeof useGetAllRelatedDataForScanFileSuspenseQuery>;
export type GetAllRelatedDataForScanFileQueryResult = Apollo.QueryResult<GetAllRelatedDataForScanFileQuery, GetAllRelatedDataForScanFileQueryVariables>;
export const CreateScanFileDocument = gql`
    mutation CreateScanFile($name: String!, $contents: String!, $scanId: ID!, $withScan: Boolean = false) {
  createScanFile(name: $name, contents: $contents, scanId: $scanId) {
    ...scanFileFields
  }
}
    ${ScanFileFieldsFragmentDoc}`;
export type CreateScanFileMutationFn = Apollo.MutationFunction<CreateScanFileMutation, CreateScanFileMutationVariables>;

/**
 * __useCreateScanFileMutation__
 *
 * To run a mutation, you first call `useCreateScanFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateScanFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createScanFileMutation, { data, loading, error }] = useCreateScanFileMutation({
 *   variables: {
 *      name: // value for 'name'
 *      contents: // value for 'contents'
 *      scanId: // value for 'scanId'
 *      withScan: // value for 'withScan'
 *   },
 * });
 */
export function useCreateScanFileMutation(baseOptions?: Apollo.MutationHookOptions<CreateScanFileMutation, CreateScanFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateScanFileMutation, CreateScanFileMutationVariables>(CreateScanFileDocument, options);
      }
export type CreateScanFileMutationHookResult = ReturnType<typeof useCreateScanFileMutation>;
export type CreateScanFileMutationResult = Apollo.MutationResult<CreateScanFileMutation>;
export type CreateScanFileMutationOptions = Apollo.BaseMutationOptions<CreateScanFileMutation, CreateScanFileMutationVariables>;
export const UpdateScanFileDocument = gql`
    mutation UpdateScanFile($id: ID!, $name: String, $contents: String, $scanId: ID, $withScan: Boolean = false) {
  updateScanFile(id: $id, name: $name, contents: $contents, scanId: $scanId) {
    ...scanFileFields
  }
}
    ${ScanFileFieldsFragmentDoc}`;
export type UpdateScanFileMutationFn = Apollo.MutationFunction<UpdateScanFileMutation, UpdateScanFileMutationVariables>;

/**
 * __useUpdateScanFileMutation__
 *
 * To run a mutation, you first call `useUpdateScanFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateScanFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateScanFileMutation, { data, loading, error }] = useUpdateScanFileMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      contents: // value for 'contents'
 *      scanId: // value for 'scanId'
 *      withScan: // value for 'withScan'
 *   },
 * });
 */
export function useUpdateScanFileMutation(baseOptions?: Apollo.MutationHookOptions<UpdateScanFileMutation, UpdateScanFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateScanFileMutation, UpdateScanFileMutationVariables>(UpdateScanFileDocument, options);
      }
export type UpdateScanFileMutationHookResult = ReturnType<typeof useUpdateScanFileMutation>;
export type UpdateScanFileMutationResult = Apollo.MutationResult<UpdateScanFileMutation>;
export type UpdateScanFileMutationOptions = Apollo.BaseMutationOptions<UpdateScanFileMutation, UpdateScanFileMutationVariables>;
export const DeleteScanFileDocument = gql`
    mutation DeleteScanFile($id: ID!, $withScan: Boolean = false) {
  deleteScanFile(id: $id) {
    ...scanFileFields
  }
}
    ${ScanFileFieldsFragmentDoc}`;
export type DeleteScanFileMutationFn = Apollo.MutationFunction<DeleteScanFileMutation, DeleteScanFileMutationVariables>;

/**
 * __useDeleteScanFileMutation__
 *
 * To run a mutation, you first call `useDeleteScanFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteScanFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteScanFileMutation, { data, loading, error }] = useDeleteScanFileMutation({
 *   variables: {
 *      id: // value for 'id'
 *      withScan: // value for 'withScan'
 *   },
 * });
 */
export function useDeleteScanFileMutation(baseOptions?: Apollo.MutationHookOptions<DeleteScanFileMutation, DeleteScanFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteScanFileMutation, DeleteScanFileMutationVariables>(DeleteScanFileDocument, options);
      }
export type DeleteScanFileMutationHookResult = ReturnType<typeof useDeleteScanFileMutation>;
export type DeleteScanFileMutationResult = Apollo.MutationResult<DeleteScanFileMutation>;
export type DeleteScanFileMutationOptions = Apollo.BaseMutationOptions<DeleteScanFileMutation, DeleteScanFileMutationVariables>;
export const CreatedScanFileDocument = gql`
    subscription CreatedScanFile($filter: ScanFileFilterArgs, $withScan: Boolean = false) {
  createdScanFile(filter: $filter) {
    ...scanFileFields
  }
}
    ${ScanFileFieldsFragmentDoc}`;

/**
 * __useCreatedScanFileSubscription__
 *
 * To run a query within a React component, call `useCreatedScanFileSubscription` and pass it any options that fit your needs.
 * When your component renders, `useCreatedScanFileSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreatedScanFileSubscription({
 *   variables: {
 *      filter: // value for 'filter'
 *      withScan: // value for 'withScan'
 *   },
 * });
 */
export function useCreatedScanFileSubscription(baseOptions?: Apollo.SubscriptionHookOptions<CreatedScanFileSubscription, CreatedScanFileSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<CreatedScanFileSubscription, CreatedScanFileSubscriptionVariables>(CreatedScanFileDocument, options);
      }
export type CreatedScanFileSubscriptionHookResult = ReturnType<typeof useCreatedScanFileSubscription>;
export type CreatedScanFileSubscriptionResult = Apollo.SubscriptionResult<CreatedScanFileSubscription>;
export const UpdatedScanFileDocument = gql`
    subscription UpdatedScanFile($filter: ScanFileFilterArgs, $withScan: Boolean = false) {
  updatedScanFile(filter: $filter) {
    ...scanFileFields
  }
}
    ${ScanFileFieldsFragmentDoc}`;

/**
 * __useUpdatedScanFileSubscription__
 *
 * To run a query within a React component, call `useUpdatedScanFileSubscription` and pass it any options that fit your needs.
 * When your component renders, `useUpdatedScanFileSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpdatedScanFileSubscription({
 *   variables: {
 *      filter: // value for 'filter'
 *      withScan: // value for 'withScan'
 *   },
 * });
 */
export function useUpdatedScanFileSubscription(baseOptions?: Apollo.SubscriptionHookOptions<UpdatedScanFileSubscription, UpdatedScanFileSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<UpdatedScanFileSubscription, UpdatedScanFileSubscriptionVariables>(UpdatedScanFileDocument, options);
      }
export type UpdatedScanFileSubscriptionHookResult = ReturnType<typeof useUpdatedScanFileSubscription>;
export type UpdatedScanFileSubscriptionResult = Apollo.SubscriptionResult<UpdatedScanFileSubscription>;
export const DeletedScanFileDocument = gql`
    subscription DeletedScanFile($filter: ScanFileFilterArgs) {
  deletedScanFile(filter: $filter) {
    id
  }
}
    `;

/**
 * __useDeletedScanFileSubscription__
 *
 * To run a query within a React component, call `useDeletedScanFileSubscription` and pass it any options that fit your needs.
 * When your component renders, `useDeletedScanFileSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeletedScanFileSubscription({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useDeletedScanFileSubscription(baseOptions?: Apollo.SubscriptionHookOptions<DeletedScanFileSubscription, DeletedScanFileSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<DeletedScanFileSubscription, DeletedScanFileSubscriptionVariables>(DeletedScanFileDocument, options);
      }
export type DeletedScanFileSubscriptionHookResult = ReturnType<typeof useDeletedScanFileSubscription>;
export type DeletedScanFileSubscriptionResult = Apollo.SubscriptionResult<DeletedScanFileSubscription>;
export const RestoredScanFileDocument = gql`
    subscription RestoredScanFile($filter: ScanFileFilterArgs, $withScan: Boolean = false) {
  restoredScanFile(filter: $filter) {
    ...scanFileFields
  }
}
    ${ScanFileFieldsFragmentDoc}`;

/**
 * __useRestoredScanFileSubscription__
 *
 * To run a query within a React component, call `useRestoredScanFileSubscription` and pass it any options that fit your needs.
 * When your component renders, `useRestoredScanFileSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRestoredScanFileSubscription({
 *   variables: {
 *      filter: // value for 'filter'
 *      withScan: // value for 'withScan'
 *   },
 * });
 */
export function useRestoredScanFileSubscription(baseOptions?: Apollo.SubscriptionHookOptions<RestoredScanFileSubscription, RestoredScanFileSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<RestoredScanFileSubscription, RestoredScanFileSubscriptionVariables>(RestoredScanFileDocument, options);
      }
export type RestoredScanFileSubscriptionHookResult = ReturnType<typeof useRestoredScanFileSubscription>;
export type RestoredScanFileSubscriptionResult = Apollo.SubscriptionResult<RestoredScanFileSubscription>;
export const GetTenantsDocument = gql`
    query GetTenants($filter: TenantFilterArgs, $paging: PageArgs, $sorting: [TenantSortArgs!], $withUserTenantRoles: Boolean = false) {
  tenants(filter: $filter, paging: $paging, sorting: $sorting) {
    items {
      ...tenantFields
    }
    totalCount
  }
}
    ${TenantFieldsFragmentDoc}`;

/**
 * __useGetTenantsQuery__
 *
 * To run a query within a React component, call `useGetTenantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTenantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTenantsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      paging: // value for 'paging'
 *      sorting: // value for 'sorting'
 *      withUserTenantRoles: // value for 'withUserTenantRoles'
 *   },
 * });
 */
export function useGetTenantsQuery(baseOptions?: Apollo.QueryHookOptions<GetTenantsQuery, GetTenantsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTenantsQuery, GetTenantsQueryVariables>(GetTenantsDocument, options);
      }
export function useGetTenantsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTenantsQuery, GetTenantsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTenantsQuery, GetTenantsQueryVariables>(GetTenantsDocument, options);
        }
export function useGetTenantsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetTenantsQuery, GetTenantsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetTenantsQuery, GetTenantsQueryVariables>(GetTenantsDocument, options);
        }
export type GetTenantsQueryHookResult = ReturnType<typeof useGetTenantsQuery>;
export type GetTenantsLazyQueryHookResult = ReturnType<typeof useGetTenantsLazyQuery>;
export type GetTenantsSuspenseQueryHookResult = ReturnType<typeof useGetTenantsSuspenseQuery>;
export type GetTenantsQueryResult = Apollo.QueryResult<GetTenantsQuery, GetTenantsQueryVariables>;
export const GetTenantDocument = gql`
    query GetTenant($id: ID!, $withUserTenantRoles: Boolean = false) {
  tenant(id: $id) {
    ...tenantFields
  }
}
    ${TenantFieldsFragmentDoc}`;

/**
 * __useGetTenantQuery__
 *
 * To run a query within a React component, call `useGetTenantQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTenantQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTenantQuery({
 *   variables: {
 *      id: // value for 'id'
 *      withUserTenantRoles: // value for 'withUserTenantRoles'
 *   },
 * });
 */
export function useGetTenantQuery(baseOptions: Apollo.QueryHookOptions<GetTenantQuery, GetTenantQueryVariables> & ({ variables: GetTenantQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTenantQuery, GetTenantQueryVariables>(GetTenantDocument, options);
      }
export function useGetTenantLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTenantQuery, GetTenantQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTenantQuery, GetTenantQueryVariables>(GetTenantDocument, options);
        }
export function useGetTenantSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetTenantQuery, GetTenantQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetTenantQuery, GetTenantQueryVariables>(GetTenantDocument, options);
        }
export type GetTenantQueryHookResult = ReturnType<typeof useGetTenantQuery>;
export type GetTenantLazyQueryHookResult = ReturnType<typeof useGetTenantLazyQuery>;
export type GetTenantSuspenseQueryHookResult = ReturnType<typeof useGetTenantSuspenseQuery>;
export type GetTenantQueryResult = Apollo.QueryResult<GetTenantQuery, GetTenantQueryVariables>;
export const GetAllRelatedDataForTenantDocument = gql`
    query GetAllRelatedDataForTenant($withUserTenantRoles: Boolean = false) {
  userTenantRoles @include(if: $withUserTenantRoles) {
    items {
      id
      displayField: id
    }
  }
}
    `;

/**
 * __useGetAllRelatedDataForTenantQuery__
 *
 * To run a query within a React component, call `useGetAllRelatedDataForTenantQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllRelatedDataForTenantQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllRelatedDataForTenantQuery({
 *   variables: {
 *      withUserTenantRoles: // value for 'withUserTenantRoles'
 *   },
 * });
 */
export function useGetAllRelatedDataForTenantQuery(baseOptions?: Apollo.QueryHookOptions<GetAllRelatedDataForTenantQuery, GetAllRelatedDataForTenantQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllRelatedDataForTenantQuery, GetAllRelatedDataForTenantQueryVariables>(GetAllRelatedDataForTenantDocument, options);
      }
export function useGetAllRelatedDataForTenantLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllRelatedDataForTenantQuery, GetAllRelatedDataForTenantQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllRelatedDataForTenantQuery, GetAllRelatedDataForTenantQueryVariables>(GetAllRelatedDataForTenantDocument, options);
        }
export function useGetAllRelatedDataForTenantSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetAllRelatedDataForTenantQuery, GetAllRelatedDataForTenantQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAllRelatedDataForTenantQuery, GetAllRelatedDataForTenantQueryVariables>(GetAllRelatedDataForTenantDocument, options);
        }
export type GetAllRelatedDataForTenantQueryHookResult = ReturnType<typeof useGetAllRelatedDataForTenantQuery>;
export type GetAllRelatedDataForTenantLazyQueryHookResult = ReturnType<typeof useGetAllRelatedDataForTenantLazyQuery>;
export type GetAllRelatedDataForTenantSuspenseQueryHookResult = ReturnType<typeof useGetAllRelatedDataForTenantSuspenseQuery>;
export type GetAllRelatedDataForTenantQueryResult = Apollo.QueryResult<GetAllRelatedDataForTenantQuery, GetAllRelatedDataForTenantQueryVariables>;
export const CreateTenantDocument = gql`
    mutation CreateTenant($name: String!, $timeZone: String!, $userTenantRoles: [ID], $withUserTenantRoles: Boolean = false) {
  createTenant(
    name: $name
    timeZone: $timeZone
    userTenantRoleIds: $userTenantRoles
  ) {
    ...tenantFields
  }
}
    ${TenantFieldsFragmentDoc}`;
export type CreateTenantMutationFn = Apollo.MutationFunction<CreateTenantMutation, CreateTenantMutationVariables>;

/**
 * __useCreateTenantMutation__
 *
 * To run a mutation, you first call `useCreateTenantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTenantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTenantMutation, { data, loading, error }] = useCreateTenantMutation({
 *   variables: {
 *      name: // value for 'name'
 *      timeZone: // value for 'timeZone'
 *      userTenantRoles: // value for 'userTenantRoles'
 *      withUserTenantRoles: // value for 'withUserTenantRoles'
 *   },
 * });
 */
export function useCreateTenantMutation(baseOptions?: Apollo.MutationHookOptions<CreateTenantMutation, CreateTenantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTenantMutation, CreateTenantMutationVariables>(CreateTenantDocument, options);
      }
export type CreateTenantMutationHookResult = ReturnType<typeof useCreateTenantMutation>;
export type CreateTenantMutationResult = Apollo.MutationResult<CreateTenantMutation>;
export type CreateTenantMutationOptions = Apollo.BaseMutationOptions<CreateTenantMutation, CreateTenantMutationVariables>;
export const UpdateTenantDocument = gql`
    mutation UpdateTenant($id: ID!, $name: String, $timeZone: String, $userTenantRoles: [ID], $withUserTenantRoles: Boolean = false) {
  updateTenant(
    id: $id
    name: $name
    timeZone: $timeZone
    userTenantRoleIds: $userTenantRoles
  ) {
    ...tenantFields
  }
}
    ${TenantFieldsFragmentDoc}`;
export type UpdateTenantMutationFn = Apollo.MutationFunction<UpdateTenantMutation, UpdateTenantMutationVariables>;

/**
 * __useUpdateTenantMutation__
 *
 * To run a mutation, you first call `useUpdateTenantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTenantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTenantMutation, { data, loading, error }] = useUpdateTenantMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      timeZone: // value for 'timeZone'
 *      userTenantRoles: // value for 'userTenantRoles'
 *      withUserTenantRoles: // value for 'withUserTenantRoles'
 *   },
 * });
 */
export function useUpdateTenantMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTenantMutation, UpdateTenantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTenantMutation, UpdateTenantMutationVariables>(UpdateTenantDocument, options);
      }
export type UpdateTenantMutationHookResult = ReturnType<typeof useUpdateTenantMutation>;
export type UpdateTenantMutationResult = Apollo.MutationResult<UpdateTenantMutation>;
export type UpdateTenantMutationOptions = Apollo.BaseMutationOptions<UpdateTenantMutation, UpdateTenantMutationVariables>;
export const DeleteTenantDocument = gql`
    mutation DeleteTenant($id: ID!, $withUserTenantRoles: Boolean = false) {
  deleteTenant(id: $id) {
    ...tenantFields
  }
}
    ${TenantFieldsFragmentDoc}`;
export type DeleteTenantMutationFn = Apollo.MutationFunction<DeleteTenantMutation, DeleteTenantMutationVariables>;

/**
 * __useDeleteTenantMutation__
 *
 * To run a mutation, you first call `useDeleteTenantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTenantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTenantMutation, { data, loading, error }] = useDeleteTenantMutation({
 *   variables: {
 *      id: // value for 'id'
 *      withUserTenantRoles: // value for 'withUserTenantRoles'
 *   },
 * });
 */
export function useDeleteTenantMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTenantMutation, DeleteTenantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTenantMutation, DeleteTenantMutationVariables>(DeleteTenantDocument, options);
      }
export type DeleteTenantMutationHookResult = ReturnType<typeof useDeleteTenantMutation>;
export type DeleteTenantMutationResult = Apollo.MutationResult<DeleteTenantMutation>;
export type DeleteTenantMutationOptions = Apollo.BaseMutationOptions<DeleteTenantMutation, DeleteTenantMutationVariables>;
export const CreatedTenantDocument = gql`
    subscription CreatedTenant($filter: TenantFilterArgs, $withUserTenantRoles: Boolean = false) {
  createdTenant(filter: $filter) {
    ...tenantFields
  }
}
    ${TenantFieldsFragmentDoc}`;

/**
 * __useCreatedTenantSubscription__
 *
 * To run a query within a React component, call `useCreatedTenantSubscription` and pass it any options that fit your needs.
 * When your component renders, `useCreatedTenantSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreatedTenantSubscription({
 *   variables: {
 *      filter: // value for 'filter'
 *      withUserTenantRoles: // value for 'withUserTenantRoles'
 *   },
 * });
 */
export function useCreatedTenantSubscription(baseOptions?: Apollo.SubscriptionHookOptions<CreatedTenantSubscription, CreatedTenantSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<CreatedTenantSubscription, CreatedTenantSubscriptionVariables>(CreatedTenantDocument, options);
      }
export type CreatedTenantSubscriptionHookResult = ReturnType<typeof useCreatedTenantSubscription>;
export type CreatedTenantSubscriptionResult = Apollo.SubscriptionResult<CreatedTenantSubscription>;
export const UpdatedTenantDocument = gql`
    subscription UpdatedTenant($filter: TenantFilterArgs, $withUserTenantRoles: Boolean = false) {
  updatedTenant(filter: $filter) {
    ...tenantFields
  }
}
    ${TenantFieldsFragmentDoc}`;

/**
 * __useUpdatedTenantSubscription__
 *
 * To run a query within a React component, call `useUpdatedTenantSubscription` and pass it any options that fit your needs.
 * When your component renders, `useUpdatedTenantSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpdatedTenantSubscription({
 *   variables: {
 *      filter: // value for 'filter'
 *      withUserTenantRoles: // value for 'withUserTenantRoles'
 *   },
 * });
 */
export function useUpdatedTenantSubscription(baseOptions?: Apollo.SubscriptionHookOptions<UpdatedTenantSubscription, UpdatedTenantSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<UpdatedTenantSubscription, UpdatedTenantSubscriptionVariables>(UpdatedTenantDocument, options);
      }
export type UpdatedTenantSubscriptionHookResult = ReturnType<typeof useUpdatedTenantSubscription>;
export type UpdatedTenantSubscriptionResult = Apollo.SubscriptionResult<UpdatedTenantSubscription>;
export const DeletedTenantDocument = gql`
    subscription DeletedTenant($filter: TenantFilterArgs) {
  deletedTenant(filter: $filter) {
    id
  }
}
    `;

/**
 * __useDeletedTenantSubscription__
 *
 * To run a query within a React component, call `useDeletedTenantSubscription` and pass it any options that fit your needs.
 * When your component renders, `useDeletedTenantSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeletedTenantSubscription({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useDeletedTenantSubscription(baseOptions?: Apollo.SubscriptionHookOptions<DeletedTenantSubscription, DeletedTenantSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<DeletedTenantSubscription, DeletedTenantSubscriptionVariables>(DeletedTenantDocument, options);
      }
export type DeletedTenantSubscriptionHookResult = ReturnType<typeof useDeletedTenantSubscription>;
export type DeletedTenantSubscriptionResult = Apollo.SubscriptionResult<DeletedTenantSubscription>;
export const GetUsersDocument = gql`
    query GetUsers($filter: UserFilterArgs, $paging: PageArgs, $sorting: [UserSortArgs!], $withUserTenantRoles: Boolean = false, $withNotificationSettings: Boolean = false) {
  users(filter: $filter, paging: $paging, sorting: $sorting) {
    items {
      ...userFields
    }
    totalCount
  }
}
    ${UserFieldsFragmentDoc}`;

/**
 * __useGetUsersQuery__
 *
 * To run a query within a React component, call `useGetUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      paging: // value for 'paging'
 *      sorting: // value for 'sorting'
 *      withUserTenantRoles: // value for 'withUserTenantRoles'
 *      withNotificationSettings: // value for 'withNotificationSettings'
 *   },
 * });
 */
export function useGetUsersQuery(baseOptions?: Apollo.QueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
      }
export function useGetUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
        }
export function useGetUsersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
        }
export type GetUsersQueryHookResult = ReturnType<typeof useGetUsersQuery>;
export type GetUsersLazyQueryHookResult = ReturnType<typeof useGetUsersLazyQuery>;
export type GetUsersSuspenseQueryHookResult = ReturnType<typeof useGetUsersSuspenseQuery>;
export type GetUsersQueryResult = Apollo.QueryResult<GetUsersQuery, GetUsersQueryVariables>;
export const GetUserDocument = gql`
    query GetUser($id: ID!, $withUserTenantRoles: Boolean = false, $withNotificationSettings: Boolean = false) {
  user(id: $id) {
    ...userFields
  }
}
    ${UserFieldsFragmentDoc}`;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *      withUserTenantRoles: // value for 'withUserTenantRoles'
 *      withNotificationSettings: // value for 'withNotificationSettings'
 *   },
 * });
 */
export function useGetUserQuery(baseOptions: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables> & ({ variables: GetUserQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
      }
export function useGetUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
        }
export function useGetUserSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
        }
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserSuspenseQueryHookResult = ReturnType<typeof useGetUserSuspenseQuery>;
export type GetUserQueryResult = Apollo.QueryResult<GetUserQuery, GetUserQueryVariables>;
export const GetAllRelatedDataForUserDocument = gql`
    query GetAllRelatedDataForUser($withUserTenantRoles: Boolean = false, $withNotificationSettings: Boolean = false) {
  userTenantRoles @include(if: $withUserTenantRoles) {
    items {
      id
      displayField: id
    }
  }
  notificationSettings @include(if: $withNotificationSettings) {
    items {
      id
      displayField: id
    }
  }
}
    `;

/**
 * __useGetAllRelatedDataForUserQuery__
 *
 * To run a query within a React component, call `useGetAllRelatedDataForUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllRelatedDataForUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllRelatedDataForUserQuery({
 *   variables: {
 *      withUserTenantRoles: // value for 'withUserTenantRoles'
 *      withNotificationSettings: // value for 'withNotificationSettings'
 *   },
 * });
 */
export function useGetAllRelatedDataForUserQuery(baseOptions?: Apollo.QueryHookOptions<GetAllRelatedDataForUserQuery, GetAllRelatedDataForUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllRelatedDataForUserQuery, GetAllRelatedDataForUserQueryVariables>(GetAllRelatedDataForUserDocument, options);
      }
export function useGetAllRelatedDataForUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllRelatedDataForUserQuery, GetAllRelatedDataForUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllRelatedDataForUserQuery, GetAllRelatedDataForUserQueryVariables>(GetAllRelatedDataForUserDocument, options);
        }
export function useGetAllRelatedDataForUserSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetAllRelatedDataForUserQuery, GetAllRelatedDataForUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAllRelatedDataForUserQuery, GetAllRelatedDataForUserQueryVariables>(GetAllRelatedDataForUserDocument, options);
        }
export type GetAllRelatedDataForUserQueryHookResult = ReturnType<typeof useGetAllRelatedDataForUserQuery>;
export type GetAllRelatedDataForUserLazyQueryHookResult = ReturnType<typeof useGetAllRelatedDataForUserLazyQuery>;
export type GetAllRelatedDataForUserSuspenseQueryHookResult = ReturnType<typeof useGetAllRelatedDataForUserSuspenseQuery>;
export type GetAllRelatedDataForUserQueryResult = Apollo.QueryResult<GetAllRelatedDataForUserQuery, GetAllRelatedDataForUserQueryVariables>;
export const CreateUserDocument = gql`
    mutation CreateUser($name: String!, $email: String!, $active: Boolean!, $avatar: String, $termsAndConditions: Boolean, $locale: String!, $timeZone: String!, $userTenantRoles: [ID], $notificationSettings: [ID], $withUserTenantRoles: Boolean = false, $withNotificationSettings: Boolean = false) {
  createUser(
    name: $name
    email: $email
    active: $active
    avatar: $avatar
    termsAndConditions: $termsAndConditions
    locale: $locale
    timeZone: $timeZone
    userTenantRoleIds: $userTenantRoles
    notificationSettingIds: $notificationSettings
  ) {
    ...userFields
  }
}
    ${UserFieldsFragmentDoc}`;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      name: // value for 'name'
 *      email: // value for 'email'
 *      active: // value for 'active'
 *      avatar: // value for 'avatar'
 *      termsAndConditions: // value for 'termsAndConditions'
 *      locale: // value for 'locale'
 *      timeZone: // value for 'timeZone'
 *      userTenantRoles: // value for 'userTenantRoles'
 *      notificationSettings: // value for 'notificationSettings'
 *      withUserTenantRoles: // value for 'withUserTenantRoles'
 *      withNotificationSettings: // value for 'withNotificationSettings'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($id: ID!, $name: String, $email: String, $active: Boolean, $avatar: String, $termsAndConditions: Boolean, $locale: String, $timeZone: String, $userTenantRoles: [ID], $notificationSettings: [ID], $withUserTenantRoles: Boolean = false, $withNotificationSettings: Boolean = false) {
  updateUser(
    id: $id
    name: $name
    email: $email
    active: $active
    avatar: $avatar
    termsAndConditions: $termsAndConditions
    locale: $locale
    timeZone: $timeZone
    userTenantRoleIds: $userTenantRoles
    notificationSettingIds: $notificationSettings
  ) {
    ...userFields
  }
}
    ${UserFieldsFragmentDoc}`;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      email: // value for 'email'
 *      active: // value for 'active'
 *      avatar: // value for 'avatar'
 *      termsAndConditions: // value for 'termsAndConditions'
 *      locale: // value for 'locale'
 *      timeZone: // value for 'timeZone'
 *      userTenantRoles: // value for 'userTenantRoles'
 *      notificationSettings: // value for 'notificationSettings'
 *      withUserTenantRoles: // value for 'withUserTenantRoles'
 *      withNotificationSettings: // value for 'withNotificationSettings'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const DeleteUserDocument = gql`
    mutation DeleteUser($id: ID!, $withUserTenantRoles: Boolean = false, $withNotificationSettings: Boolean = false) {
  deleteUser(id: $id) {
    ...userFields
  }
}
    ${UserFieldsFragmentDoc}`;
export type DeleteUserMutationFn = Apollo.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      withUserTenantRoles: // value for 'withUserTenantRoles'
 *      withNotificationSettings: // value for 'withNotificationSettings'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, options);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const CreatedUserDocument = gql`
    subscription CreatedUser($filter: UserFilterArgs, $withUserTenantRoles: Boolean = false, $withNotificationSettings: Boolean = false) {
  createdUser(filter: $filter) {
    ...userFields
  }
}
    ${UserFieldsFragmentDoc}`;

/**
 * __useCreatedUserSubscription__
 *
 * To run a query within a React component, call `useCreatedUserSubscription` and pass it any options that fit your needs.
 * When your component renders, `useCreatedUserSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreatedUserSubscription({
 *   variables: {
 *      filter: // value for 'filter'
 *      withUserTenantRoles: // value for 'withUserTenantRoles'
 *      withNotificationSettings: // value for 'withNotificationSettings'
 *   },
 * });
 */
export function useCreatedUserSubscription(baseOptions?: Apollo.SubscriptionHookOptions<CreatedUserSubscription, CreatedUserSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<CreatedUserSubscription, CreatedUserSubscriptionVariables>(CreatedUserDocument, options);
      }
export type CreatedUserSubscriptionHookResult = ReturnType<typeof useCreatedUserSubscription>;
export type CreatedUserSubscriptionResult = Apollo.SubscriptionResult<CreatedUserSubscription>;
export const UpdatedUserDocument = gql`
    subscription UpdatedUser($filter: UserFilterArgs, $withUserTenantRoles: Boolean = false, $withNotificationSettings: Boolean = false) {
  updatedUser(filter: $filter) {
    ...userFields
  }
}
    ${UserFieldsFragmentDoc}`;

/**
 * __useUpdatedUserSubscription__
 *
 * To run a query within a React component, call `useUpdatedUserSubscription` and pass it any options that fit your needs.
 * When your component renders, `useUpdatedUserSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpdatedUserSubscription({
 *   variables: {
 *      filter: // value for 'filter'
 *      withUserTenantRoles: // value for 'withUserTenantRoles'
 *      withNotificationSettings: // value for 'withNotificationSettings'
 *   },
 * });
 */
export function useUpdatedUserSubscription(baseOptions?: Apollo.SubscriptionHookOptions<UpdatedUserSubscription, UpdatedUserSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<UpdatedUserSubscription, UpdatedUserSubscriptionVariables>(UpdatedUserDocument, options);
      }
export type UpdatedUserSubscriptionHookResult = ReturnType<typeof useUpdatedUserSubscription>;
export type UpdatedUserSubscriptionResult = Apollo.SubscriptionResult<UpdatedUserSubscription>;
export const DeletedUserDocument = gql`
    subscription DeletedUser($filter: UserFilterArgs) {
  deletedUser(filter: $filter) {
    id
  }
}
    `;

/**
 * __useDeletedUserSubscription__
 *
 * To run a query within a React component, call `useDeletedUserSubscription` and pass it any options that fit your needs.
 * When your component renders, `useDeletedUserSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeletedUserSubscription({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useDeletedUserSubscription(baseOptions?: Apollo.SubscriptionHookOptions<DeletedUserSubscription, DeletedUserSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<DeletedUserSubscription, DeletedUserSubscriptionVariables>(DeletedUserDocument, options);
      }
export type DeletedUserSubscriptionHookResult = ReturnType<typeof useDeletedUserSubscription>;
export type DeletedUserSubscriptionResult = Apollo.SubscriptionResult<DeletedUserSubscription>;
export const RestoredUserDocument = gql`
    subscription RestoredUser($filter: UserFilterArgs, $withUserTenantRoles: Boolean = false, $withNotificationSettings: Boolean = false) {
  restoredUser(filter: $filter) {
    ...userFields
  }
}
    ${UserFieldsFragmentDoc}`;

/**
 * __useRestoredUserSubscription__
 *
 * To run a query within a React component, call `useRestoredUserSubscription` and pass it any options that fit your needs.
 * When your component renders, `useRestoredUserSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRestoredUserSubscription({
 *   variables: {
 *      filter: // value for 'filter'
 *      withUserTenantRoles: // value for 'withUserTenantRoles'
 *      withNotificationSettings: // value for 'withNotificationSettings'
 *   },
 * });
 */
export function useRestoredUserSubscription(baseOptions?: Apollo.SubscriptionHookOptions<RestoredUserSubscription, RestoredUserSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<RestoredUserSubscription, RestoredUserSubscriptionVariables>(RestoredUserDocument, options);
      }
export type RestoredUserSubscriptionHookResult = ReturnType<typeof useRestoredUserSubscription>;
export type RestoredUserSubscriptionResult = Apollo.SubscriptionResult<RestoredUserSubscription>;
export const GetUserTenantRolesDocument = gql`
    query GetUserTenantRoles($filter: UserTenantRoleFilterArgs, $paging: PageArgs, $sorting: [UserTenantRoleSortArgs!], $withUser: Boolean = false, $withTenant: Boolean = false, $withRole: Boolean = false) {
  userTenantRoles(filter: $filter, paging: $paging, sorting: $sorting) {
    items {
      ...userTenantRoleFields
    }
    totalCount
  }
}
    ${UserTenantRoleFieldsFragmentDoc}`;

/**
 * __useGetUserTenantRolesQuery__
 *
 * To run a query within a React component, call `useGetUserTenantRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserTenantRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserTenantRolesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      paging: // value for 'paging'
 *      sorting: // value for 'sorting'
 *      withUser: // value for 'withUser'
 *      withTenant: // value for 'withTenant'
 *      withRole: // value for 'withRole'
 *   },
 * });
 */
export function useGetUserTenantRolesQuery(baseOptions?: Apollo.QueryHookOptions<GetUserTenantRolesQuery, GetUserTenantRolesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserTenantRolesQuery, GetUserTenantRolesQueryVariables>(GetUserTenantRolesDocument, options);
      }
export function useGetUserTenantRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserTenantRolesQuery, GetUserTenantRolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserTenantRolesQuery, GetUserTenantRolesQueryVariables>(GetUserTenantRolesDocument, options);
        }
export function useGetUserTenantRolesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetUserTenantRolesQuery, GetUserTenantRolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUserTenantRolesQuery, GetUserTenantRolesQueryVariables>(GetUserTenantRolesDocument, options);
        }
export type GetUserTenantRolesQueryHookResult = ReturnType<typeof useGetUserTenantRolesQuery>;
export type GetUserTenantRolesLazyQueryHookResult = ReturnType<typeof useGetUserTenantRolesLazyQuery>;
export type GetUserTenantRolesSuspenseQueryHookResult = ReturnType<typeof useGetUserTenantRolesSuspenseQuery>;
export type GetUserTenantRolesQueryResult = Apollo.QueryResult<GetUserTenantRolesQuery, GetUserTenantRolesQueryVariables>;
export const GetUserTenantRoleDocument = gql`
    query GetUserTenantRole($id: ID!, $withUser: Boolean = false, $withTenant: Boolean = false, $withRole: Boolean = false) {
  userTenantRole(id: $id) {
    ...userTenantRoleFields
  }
}
    ${UserTenantRoleFieldsFragmentDoc}`;

/**
 * __useGetUserTenantRoleQuery__
 *
 * To run a query within a React component, call `useGetUserTenantRoleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserTenantRoleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserTenantRoleQuery({
 *   variables: {
 *      id: // value for 'id'
 *      withUser: // value for 'withUser'
 *      withTenant: // value for 'withTenant'
 *      withRole: // value for 'withRole'
 *   },
 * });
 */
export function useGetUserTenantRoleQuery(baseOptions: Apollo.QueryHookOptions<GetUserTenantRoleQuery, GetUserTenantRoleQueryVariables> & ({ variables: GetUserTenantRoleQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserTenantRoleQuery, GetUserTenantRoleQueryVariables>(GetUserTenantRoleDocument, options);
      }
export function useGetUserTenantRoleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserTenantRoleQuery, GetUserTenantRoleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserTenantRoleQuery, GetUserTenantRoleQueryVariables>(GetUserTenantRoleDocument, options);
        }
export function useGetUserTenantRoleSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetUserTenantRoleQuery, GetUserTenantRoleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUserTenantRoleQuery, GetUserTenantRoleQueryVariables>(GetUserTenantRoleDocument, options);
        }
export type GetUserTenantRoleQueryHookResult = ReturnType<typeof useGetUserTenantRoleQuery>;
export type GetUserTenantRoleLazyQueryHookResult = ReturnType<typeof useGetUserTenantRoleLazyQuery>;
export type GetUserTenantRoleSuspenseQueryHookResult = ReturnType<typeof useGetUserTenantRoleSuspenseQuery>;
export type GetUserTenantRoleQueryResult = Apollo.QueryResult<GetUserTenantRoleQuery, GetUserTenantRoleQueryVariables>;
export const GetAllRelatedDataForUserTenantRoleDocument = gql`
    query GetAllRelatedDataForUserTenantRole($withUsers: Boolean = false, $withTenants: Boolean = false, $withRoles: Boolean = false) {
  users @include(if: $withUsers) {
    items {
      id
      displayField: name
    }
  }
  tenants @include(if: $withTenants) {
    items {
      id
      displayField: name
    }
  }
  roles @include(if: $withRoles) {
    items {
      id
      displayField: displayName
    }
  }
}
    `;

/**
 * __useGetAllRelatedDataForUserTenantRoleQuery__
 *
 * To run a query within a React component, call `useGetAllRelatedDataForUserTenantRoleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllRelatedDataForUserTenantRoleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllRelatedDataForUserTenantRoleQuery({
 *   variables: {
 *      withUsers: // value for 'withUsers'
 *      withTenants: // value for 'withTenants'
 *      withRoles: // value for 'withRoles'
 *   },
 * });
 */
export function useGetAllRelatedDataForUserTenantRoleQuery(baseOptions?: Apollo.QueryHookOptions<GetAllRelatedDataForUserTenantRoleQuery, GetAllRelatedDataForUserTenantRoleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllRelatedDataForUserTenantRoleQuery, GetAllRelatedDataForUserTenantRoleQueryVariables>(GetAllRelatedDataForUserTenantRoleDocument, options);
      }
export function useGetAllRelatedDataForUserTenantRoleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllRelatedDataForUserTenantRoleQuery, GetAllRelatedDataForUserTenantRoleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllRelatedDataForUserTenantRoleQuery, GetAllRelatedDataForUserTenantRoleQueryVariables>(GetAllRelatedDataForUserTenantRoleDocument, options);
        }
export function useGetAllRelatedDataForUserTenantRoleSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetAllRelatedDataForUserTenantRoleQuery, GetAllRelatedDataForUserTenantRoleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAllRelatedDataForUserTenantRoleQuery, GetAllRelatedDataForUserTenantRoleQueryVariables>(GetAllRelatedDataForUserTenantRoleDocument, options);
        }
export type GetAllRelatedDataForUserTenantRoleQueryHookResult = ReturnType<typeof useGetAllRelatedDataForUserTenantRoleQuery>;
export type GetAllRelatedDataForUserTenantRoleLazyQueryHookResult = ReturnType<typeof useGetAllRelatedDataForUserTenantRoleLazyQuery>;
export type GetAllRelatedDataForUserTenantRoleSuspenseQueryHookResult = ReturnType<typeof useGetAllRelatedDataForUserTenantRoleSuspenseQuery>;
export type GetAllRelatedDataForUserTenantRoleQueryResult = Apollo.QueryResult<GetAllRelatedDataForUserTenantRoleQuery, GetAllRelatedDataForUserTenantRoleQueryVariables>;
export const CreateUserTenantRoleDocument = gql`
    mutation CreateUserTenantRole($userId: ID!, $tenantId: ID!, $roleId: ID!, $withUser: Boolean = false, $withTenant: Boolean = false, $withRole: Boolean = false) {
  createUserTenantRole(userId: $userId, tenantId: $tenantId, roleId: $roleId) {
    ...userTenantRoleFields
  }
}
    ${UserTenantRoleFieldsFragmentDoc}`;
export type CreateUserTenantRoleMutationFn = Apollo.MutationFunction<CreateUserTenantRoleMutation, CreateUserTenantRoleMutationVariables>;

/**
 * __useCreateUserTenantRoleMutation__
 *
 * To run a mutation, you first call `useCreateUserTenantRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserTenantRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserTenantRoleMutation, { data, loading, error }] = useCreateUserTenantRoleMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      tenantId: // value for 'tenantId'
 *      roleId: // value for 'roleId'
 *      withUser: // value for 'withUser'
 *      withTenant: // value for 'withTenant'
 *      withRole: // value for 'withRole'
 *   },
 * });
 */
export function useCreateUserTenantRoleMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserTenantRoleMutation, CreateUserTenantRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserTenantRoleMutation, CreateUserTenantRoleMutationVariables>(CreateUserTenantRoleDocument, options);
      }
export type CreateUserTenantRoleMutationHookResult = ReturnType<typeof useCreateUserTenantRoleMutation>;
export type CreateUserTenantRoleMutationResult = Apollo.MutationResult<CreateUserTenantRoleMutation>;
export type CreateUserTenantRoleMutationOptions = Apollo.BaseMutationOptions<CreateUserTenantRoleMutation, CreateUserTenantRoleMutationVariables>;
export const UpdateUserTenantRoleDocument = gql`
    mutation UpdateUserTenantRole($id: ID!, $userId: ID, $tenantId: ID, $roleId: ID, $withUser: Boolean = false, $withTenant: Boolean = false, $withRole: Boolean = false) {
  updateUserTenantRole(
    id: $id
    userId: $userId
    tenantId: $tenantId
    roleId: $roleId
  ) {
    ...userTenantRoleFields
  }
}
    ${UserTenantRoleFieldsFragmentDoc}`;
export type UpdateUserTenantRoleMutationFn = Apollo.MutationFunction<UpdateUserTenantRoleMutation, UpdateUserTenantRoleMutationVariables>;

/**
 * __useUpdateUserTenantRoleMutation__
 *
 * To run a mutation, you first call `useUpdateUserTenantRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserTenantRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserTenantRoleMutation, { data, loading, error }] = useUpdateUserTenantRoleMutation({
 *   variables: {
 *      id: // value for 'id'
 *      userId: // value for 'userId'
 *      tenantId: // value for 'tenantId'
 *      roleId: // value for 'roleId'
 *      withUser: // value for 'withUser'
 *      withTenant: // value for 'withTenant'
 *      withRole: // value for 'withRole'
 *   },
 * });
 */
export function useUpdateUserTenantRoleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserTenantRoleMutation, UpdateUserTenantRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserTenantRoleMutation, UpdateUserTenantRoleMutationVariables>(UpdateUserTenantRoleDocument, options);
      }
export type UpdateUserTenantRoleMutationHookResult = ReturnType<typeof useUpdateUserTenantRoleMutation>;
export type UpdateUserTenantRoleMutationResult = Apollo.MutationResult<UpdateUserTenantRoleMutation>;
export type UpdateUserTenantRoleMutationOptions = Apollo.BaseMutationOptions<UpdateUserTenantRoleMutation, UpdateUserTenantRoleMutationVariables>;
export const DeleteUserTenantRoleDocument = gql`
    mutation DeleteUserTenantRole($id: ID!, $withUser: Boolean = false, $withTenant: Boolean = false, $withRole: Boolean = false) {
  deleteUserTenantRole(id: $id) {
    ...userTenantRoleFields
  }
}
    ${UserTenantRoleFieldsFragmentDoc}`;
export type DeleteUserTenantRoleMutationFn = Apollo.MutationFunction<DeleteUserTenantRoleMutation, DeleteUserTenantRoleMutationVariables>;

/**
 * __useDeleteUserTenantRoleMutation__
 *
 * To run a mutation, you first call `useDeleteUserTenantRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserTenantRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserTenantRoleMutation, { data, loading, error }] = useDeleteUserTenantRoleMutation({
 *   variables: {
 *      id: // value for 'id'
 *      withUser: // value for 'withUser'
 *      withTenant: // value for 'withTenant'
 *      withRole: // value for 'withRole'
 *   },
 * });
 */
export function useDeleteUserTenantRoleMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserTenantRoleMutation, DeleteUserTenantRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserTenantRoleMutation, DeleteUserTenantRoleMutationVariables>(DeleteUserTenantRoleDocument, options);
      }
export type DeleteUserTenantRoleMutationHookResult = ReturnType<typeof useDeleteUserTenantRoleMutation>;
export type DeleteUserTenantRoleMutationResult = Apollo.MutationResult<DeleteUserTenantRoleMutation>;
export type DeleteUserTenantRoleMutationOptions = Apollo.BaseMutationOptions<DeleteUserTenantRoleMutation, DeleteUserTenantRoleMutationVariables>;
export const CreatedUserTenantRoleDocument = gql`
    subscription CreatedUserTenantRole($filter: UserTenantRoleFilterArgs, $withUser: Boolean = false, $withTenant: Boolean = false, $withRole: Boolean = false) {
  createdUserTenantRole(filter: $filter) {
    ...userTenantRoleFields
  }
}
    ${UserTenantRoleFieldsFragmentDoc}`;

/**
 * __useCreatedUserTenantRoleSubscription__
 *
 * To run a query within a React component, call `useCreatedUserTenantRoleSubscription` and pass it any options that fit your needs.
 * When your component renders, `useCreatedUserTenantRoleSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreatedUserTenantRoleSubscription({
 *   variables: {
 *      filter: // value for 'filter'
 *      withUser: // value for 'withUser'
 *      withTenant: // value for 'withTenant'
 *      withRole: // value for 'withRole'
 *   },
 * });
 */
export function useCreatedUserTenantRoleSubscription(baseOptions?: Apollo.SubscriptionHookOptions<CreatedUserTenantRoleSubscription, CreatedUserTenantRoleSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<CreatedUserTenantRoleSubscription, CreatedUserTenantRoleSubscriptionVariables>(CreatedUserTenantRoleDocument, options);
      }
export type CreatedUserTenantRoleSubscriptionHookResult = ReturnType<typeof useCreatedUserTenantRoleSubscription>;
export type CreatedUserTenantRoleSubscriptionResult = Apollo.SubscriptionResult<CreatedUserTenantRoleSubscription>;
export const UpdatedUserTenantRoleDocument = gql`
    subscription UpdatedUserTenantRole($filter: UserTenantRoleFilterArgs, $withUser: Boolean = false, $withTenant: Boolean = false, $withRole: Boolean = false) {
  updatedUserTenantRole(filter: $filter) {
    ...userTenantRoleFields
  }
}
    ${UserTenantRoleFieldsFragmentDoc}`;

/**
 * __useUpdatedUserTenantRoleSubscription__
 *
 * To run a query within a React component, call `useUpdatedUserTenantRoleSubscription` and pass it any options that fit your needs.
 * When your component renders, `useUpdatedUserTenantRoleSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpdatedUserTenantRoleSubscription({
 *   variables: {
 *      filter: // value for 'filter'
 *      withUser: // value for 'withUser'
 *      withTenant: // value for 'withTenant'
 *      withRole: // value for 'withRole'
 *   },
 * });
 */
export function useUpdatedUserTenantRoleSubscription(baseOptions?: Apollo.SubscriptionHookOptions<UpdatedUserTenantRoleSubscription, UpdatedUserTenantRoleSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<UpdatedUserTenantRoleSubscription, UpdatedUserTenantRoleSubscriptionVariables>(UpdatedUserTenantRoleDocument, options);
      }
export type UpdatedUserTenantRoleSubscriptionHookResult = ReturnType<typeof useUpdatedUserTenantRoleSubscription>;
export type UpdatedUserTenantRoleSubscriptionResult = Apollo.SubscriptionResult<UpdatedUserTenantRoleSubscription>;
export const DeletedUserTenantRoleDocument = gql`
    subscription DeletedUserTenantRole($filter: UserTenantRoleFilterArgs) {
  deletedUserTenantRole(filter: $filter) {
    id
  }
}
    `;

/**
 * __useDeletedUserTenantRoleSubscription__
 *
 * To run a query within a React component, call `useDeletedUserTenantRoleSubscription` and pass it any options that fit your needs.
 * When your component renders, `useDeletedUserTenantRoleSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeletedUserTenantRoleSubscription({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useDeletedUserTenantRoleSubscription(baseOptions?: Apollo.SubscriptionHookOptions<DeletedUserTenantRoleSubscription, DeletedUserTenantRoleSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<DeletedUserTenantRoleSubscription, DeletedUserTenantRoleSubscriptionVariables>(DeletedUserTenantRoleDocument, options);
      }
export type DeletedUserTenantRoleSubscriptionHookResult = ReturnType<typeof useDeletedUserTenantRoleSubscription>;
export type DeletedUserTenantRoleSubscriptionResult = Apollo.SubscriptionResult<DeletedUserTenantRoleSubscription>;
export const GetFullUserDocument = gql`
    query GetFullUser($id: ID!) {
  user(id: $id) {
    id
    name
    email
    emailVerifiedAt
    active
    avatar
    userTenantRoles {
      items {
        id
        userId
        tenantId
        roleId
        role {
          id
          machineName
          displayField: machineName
        }
        tenant {
          id
          name
          displayField: name
        }
        displayField: id
      }
      totalCount
    }
    createdAt
    updatedAt
    deletedAt
  }
}
    `;

/**
 * __useGetFullUserQuery__
 *
 * To run a query within a React component, call `useGetFullUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFullUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFullUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetFullUserQuery(baseOptions: Apollo.QueryHookOptions<GetFullUserQuery, GetFullUserQueryVariables> & ({ variables: GetFullUserQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFullUserQuery, GetFullUserQueryVariables>(GetFullUserDocument, options);
      }
export function useGetFullUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFullUserQuery, GetFullUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFullUserQuery, GetFullUserQueryVariables>(GetFullUserDocument, options);
        }
export function useGetFullUserSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetFullUserQuery, GetFullUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetFullUserQuery, GetFullUserQueryVariables>(GetFullUserDocument, options);
        }
export type GetFullUserQueryHookResult = ReturnType<typeof useGetFullUserQuery>;
export type GetFullUserLazyQueryHookResult = ReturnType<typeof useGetFullUserLazyQuery>;
export type GetFullUserSuspenseQueryHookResult = ReturnType<typeof useGetFullUserSuspenseQuery>;
export type GetFullUserQueryResult = Apollo.QueryResult<GetFullUserQuery, GetFullUserQueryVariables>;
export const CreateUserWithTenantRoleDocument = gql`
    mutation CreateUserWithTenantRole($name: String!, $email: String!, $active: Boolean!, $avatar: String, $termsAndConditions: Boolean, $userTenantRoles: [ID], $tenantId: String, $roleId: String, $invite: Boolean, $locale: String!, $timeZone: String!, $notificationSettings: [ID], $withUserTenantRoles: Boolean = false, $withNotificationSettings: Boolean = false) {
  createUser(
    name: $name
    email: $email
    active: $active
    avatar: $avatar
    termsAndConditions: $termsAndConditions
    userTenantRoleIds: $userTenantRoles
    tenantId: $tenantId
    roleId: $roleId
    invite: $invite
    locale: $locale
    timeZone: $timeZone
    notificationSettingIds: $notificationSettings
  ) {
    ...userFields
  }
}
    ${UserFieldsFragmentDoc}`;
export type CreateUserWithTenantRoleMutationFn = Apollo.MutationFunction<CreateUserWithTenantRoleMutation, CreateUserWithTenantRoleMutationVariables>;

/**
 * __useCreateUserWithTenantRoleMutation__
 *
 * To run a mutation, you first call `useCreateUserWithTenantRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserWithTenantRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserWithTenantRoleMutation, { data, loading, error }] = useCreateUserWithTenantRoleMutation({
 *   variables: {
 *      name: // value for 'name'
 *      email: // value for 'email'
 *      active: // value for 'active'
 *      avatar: // value for 'avatar'
 *      termsAndConditions: // value for 'termsAndConditions'
 *      userTenantRoles: // value for 'userTenantRoles'
 *      tenantId: // value for 'tenantId'
 *      roleId: // value for 'roleId'
 *      invite: // value for 'invite'
 *      locale: // value for 'locale'
 *      timeZone: // value for 'timeZone'
 *      notificationSettings: // value for 'notificationSettings'
 *      withUserTenantRoles: // value for 'withUserTenantRoles'
 *      withNotificationSettings: // value for 'withNotificationSettings'
 *   },
 * });
 */
export function useCreateUserWithTenantRoleMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserWithTenantRoleMutation, CreateUserWithTenantRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserWithTenantRoleMutation, CreateUserWithTenantRoleMutationVariables>(CreateUserWithTenantRoleDocument, options);
      }
export type CreateUserWithTenantRoleMutationHookResult = ReturnType<typeof useCreateUserWithTenantRoleMutation>;
export type CreateUserWithTenantRoleMutationResult = Apollo.MutationResult<CreateUserWithTenantRoleMutation>;
export type CreateUserWithTenantRoleMutationOptions = Apollo.BaseMutationOptions<CreateUserWithTenantRoleMutation, CreateUserWithTenantRoleMutationVariables>;
export const CreateContentWithFillDocument = gql`
    mutation createContentWithFill($id: ID!, $name: String!, $fill: String!, $language: String!) {
  createContentWithFill(id: $id, name: $name, fill: $fill, language: $language)
}
    `;
export type CreateContentWithFillMutationFn = Apollo.MutationFunction<CreateContentWithFillMutation, CreateContentWithFillMutationVariables>;

/**
 * __useCreateContentWithFillMutation__
 *
 * To run a mutation, you first call `useCreateContentWithFillMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContentWithFillMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContentWithFillMutation, { data, loading, error }] = useCreateContentWithFillMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      fill: // value for 'fill'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useCreateContentWithFillMutation(baseOptions?: Apollo.MutationHookOptions<CreateContentWithFillMutation, CreateContentWithFillMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateContentWithFillMutation, CreateContentWithFillMutationVariables>(CreateContentWithFillDocument, options);
      }
export type CreateContentWithFillMutationHookResult = ReturnType<typeof useCreateContentWithFillMutation>;
export type CreateContentWithFillMutationResult = Apollo.MutationResult<CreateContentWithFillMutation>;
export type CreateContentWithFillMutationOptions = Apollo.BaseMutationOptions<CreateContentWithFillMutation, CreateContentWithFillMutationVariables>;
export const UpdateContentWithFillDocument = gql`
    mutation updateContentWithFill($id: ID!, $name: String!, $fill: String!, $language: String!) {
  updateContentWithFill(id: $id, name: $name, fill: $fill, language: $language)
}
    `;
export type UpdateContentWithFillMutationFn = Apollo.MutationFunction<UpdateContentWithFillMutation, UpdateContentWithFillMutationVariables>;

/**
 * __useUpdateContentWithFillMutation__
 *
 * To run a mutation, you first call `useUpdateContentWithFillMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContentWithFillMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContentWithFillMutation, { data, loading, error }] = useUpdateContentWithFillMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      fill: // value for 'fill'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useUpdateContentWithFillMutation(baseOptions?: Apollo.MutationHookOptions<UpdateContentWithFillMutation, UpdateContentWithFillMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateContentWithFillMutation, UpdateContentWithFillMutationVariables>(UpdateContentWithFillDocument, options);
      }
export type UpdateContentWithFillMutationHookResult = ReturnType<typeof useUpdateContentWithFillMutation>;
export type UpdateContentWithFillMutationResult = Apollo.MutationResult<UpdateContentWithFillMutation>;
export type UpdateContentWithFillMutationOptions = Apollo.BaseMutationOptions<UpdateContentWithFillMutation, UpdateContentWithFillMutationVariables>;
export const GetUserNotificationSettingsDocument = gql`
    query GetUserNotificationSettings($userId: String) {
  notificationSettings(filter: {user: {id: {eq: $userId}}}) {
    items {
      id
      gitRepositoryId
    }
  }
}
    `;

/**
 * __useGetUserNotificationSettingsQuery__
 *
 * To run a query within a React component, call `useGetUserNotificationSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserNotificationSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserNotificationSettingsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetUserNotificationSettingsQuery(baseOptions?: Apollo.QueryHookOptions<GetUserNotificationSettingsQuery, GetUserNotificationSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserNotificationSettingsQuery, GetUserNotificationSettingsQueryVariables>(GetUserNotificationSettingsDocument, options);
      }
export function useGetUserNotificationSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserNotificationSettingsQuery, GetUserNotificationSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserNotificationSettingsQuery, GetUserNotificationSettingsQueryVariables>(GetUserNotificationSettingsDocument, options);
        }
export function useGetUserNotificationSettingsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetUserNotificationSettingsQuery, GetUserNotificationSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUserNotificationSettingsQuery, GetUserNotificationSettingsQueryVariables>(GetUserNotificationSettingsDocument, options);
        }
export type GetUserNotificationSettingsQueryHookResult = ReturnType<typeof useGetUserNotificationSettingsQuery>;
export type GetUserNotificationSettingsLazyQueryHookResult = ReturnType<typeof useGetUserNotificationSettingsLazyQuery>;
export type GetUserNotificationSettingsSuspenseQueryHookResult = ReturnType<typeof useGetUserNotificationSettingsSuspenseQuery>;
export type GetUserNotificationSettingsQueryResult = Apollo.QueryResult<GetUserNotificationSettingsQuery, GetUserNotificationSettingsQueryVariables>;
export const DeleteUserNotificationSettingDocument = gql`
    mutation DeleteUserNotificationSetting($gitRepositoryId: ID!) {
  deleteUserNotificationSetting(gitRepositoryId: $gitRepositoryId)
}
    `;
export type DeleteUserNotificationSettingMutationFn = Apollo.MutationFunction<DeleteUserNotificationSettingMutation, DeleteUserNotificationSettingMutationVariables>;

/**
 * __useDeleteUserNotificationSettingMutation__
 *
 * To run a mutation, you first call `useDeleteUserNotificationSettingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserNotificationSettingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserNotificationSettingMutation, { data, loading, error }] = useDeleteUserNotificationSettingMutation({
 *   variables: {
 *      gitRepositoryId: // value for 'gitRepositoryId'
 *   },
 * });
 */
export function useDeleteUserNotificationSettingMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserNotificationSettingMutation, DeleteUserNotificationSettingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserNotificationSettingMutation, DeleteUserNotificationSettingMutationVariables>(DeleteUserNotificationSettingDocument, options);
      }
export type DeleteUserNotificationSettingMutationHookResult = ReturnType<typeof useDeleteUserNotificationSettingMutation>;
export type DeleteUserNotificationSettingMutationResult = Apollo.MutationResult<DeleteUserNotificationSettingMutation>;
export type DeleteUserNotificationSettingMutationOptions = Apollo.BaseMutationOptions<DeleteUserNotificationSettingMutation, DeleteUserNotificationSettingMutationVariables>;
export const GetAllRepositoryAdvisoriesDocument = gql`
    query GetAllRepositoryAdvisories {
  gitRepositories(sorting: {field: name, direction: ASC}) {
    items {
      id
      name
      branches {
        items {
          id
          name
          scans {
            items {
              id
              directory
              type
              updatedAt
              advisories {
                items {
                  id
                  package
                  parentPackage
                  patchedVersion
                  severity
                  title
                  version
                }
              }
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetAllRepositoryAdvisoriesQuery__
 *
 * To run a query within a React component, call `useGetAllRepositoryAdvisoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllRepositoryAdvisoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllRepositoryAdvisoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllRepositoryAdvisoriesQuery(baseOptions?: Apollo.QueryHookOptions<GetAllRepositoryAdvisoriesQuery, GetAllRepositoryAdvisoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllRepositoryAdvisoriesQuery, GetAllRepositoryAdvisoriesQueryVariables>(GetAllRepositoryAdvisoriesDocument, options);
      }
export function useGetAllRepositoryAdvisoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllRepositoryAdvisoriesQuery, GetAllRepositoryAdvisoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllRepositoryAdvisoriesQuery, GetAllRepositoryAdvisoriesQueryVariables>(GetAllRepositoryAdvisoriesDocument, options);
        }
export function useGetAllRepositoryAdvisoriesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetAllRepositoryAdvisoriesQuery, GetAllRepositoryAdvisoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAllRepositoryAdvisoriesQuery, GetAllRepositoryAdvisoriesQueryVariables>(GetAllRepositoryAdvisoriesDocument, options);
        }
export type GetAllRepositoryAdvisoriesQueryHookResult = ReturnType<typeof useGetAllRepositoryAdvisoriesQuery>;
export type GetAllRepositoryAdvisoriesLazyQueryHookResult = ReturnType<typeof useGetAllRepositoryAdvisoriesLazyQuery>;
export type GetAllRepositoryAdvisoriesSuspenseQueryHookResult = ReturnType<typeof useGetAllRepositoryAdvisoriesSuspenseQuery>;
export type GetAllRepositoryAdvisoriesQueryResult = Apollo.QueryResult<GetAllRepositoryAdvisoriesQuery, GetAllRepositoryAdvisoriesQueryVariables>;